import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'chartjs-plugin-colorschemes';
import {Connect} from '../../services/connect';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../services/shared';
import ToastrService from '../../toastr-service-wrapper.service';
import {User} from '../../services/user';
import {Workflow} from '../../class/Workflow';
import {WorkflowTemplate} from '../../class/Workflow/WorkflowTemplate';

@Component({
    selector: 'workflow-modal',
    templateUrl: './workflow-modal.component.html',
})

export class WorkflowModalComponent implements OnInit {
    @Input() type;
    @Input() parent;
    @Input() workflow_base: Workflow;
    @Input() workflow_template: WorkflowTemplate;
    @Input() sending: boolean = false;


    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();
    @Output() applied: EventEmitter<Object> = new EventEmitter();

    public workflow: Workflow = null;
    public admin_id_a: Array<number> = [];
    public workflow_comment: string = null;

    private toasterService: ToastrService;

    constructor(private _router: Router, private _route: ActivatedRoute, private _connect: Connect, public _share: SharedService, private _user: User, toasterService: ToastrService) {
        this.admin_id_a = [null];
        this.toasterService = toasterService;
    }

    ngOnInit() {
        if (this.workflow_template) {
            this.workflow = this.workflow_template.workflow;
            this.changed()
        } else {
            this.workflow = this.workflow_base;
        }
    }

    isWorkflowTemplateExist() {
        return this.workflow_template;

    }

    isWorkflowTemplatePathExist() {
        //条件にあうtempalteがない場合はfalse

        return this.workflow_template && this.workflow_template.workflow.workflow_path_a.length > 0;
    }

    changed() {
        this.valueChanged.emit({
            'workflow': this.workflow,
            'workflow_comment': this.workflow_comment
        })

    }

    submit() {
        this.sending = true;
        if (!this.isWorkflowTemplateExist() && !this.workflow.validate(this._share.getMainDivisionId())) {
            this.toasterService.error(this.workflow.error_message, 'エラー');
            this.sending = false;
            return;
        }
        this.applied.emit()
        this.workflow_comment = '';
    }


    add() {
        this.workflow_comment = '';
        this.workflow.addWorkflowPath();
    }


    close() {
        this.parent.closeWorkflow()
    }

    delete(index) {
        this.workflow.deleteWorkflowPath(index)
        this.changed();
    }

    onWorkflowChanged($event) {
        this.workflow = $event.workflow;
        this.changed();
    }

}
