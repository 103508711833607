import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {NgxPaginationModule} from 'ngx-pagination';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ToastrModule} from 'ngx-toastr';
import {LaddaModule} from 'angular2-ladda';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AdminRoutingModule} from './admin-routing.module';
import {AdminComponent} from './admin.component';
import {DomSanitizer} from '@angular/platform-browser';
import {SearchparamComponent} from './searchparam.component';
import {CloudChartsModule} from '../charts/cloud-charts-module'
import {DatasetTableRowComponent} from './dataset-table-row.component';
import {DatasetTableCellComponent} from './dataset-table-cell.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {NgSelectModule} from '@ng-select/ng-select';
import {EditModule} from './edit/edit.module';
import {FormFieldEditModalComponent} from './form-field-edit-modal.component';
import {TsvImportComponent} from '../tsv-import/tsv-import.component';
import {ResizeColumnDirective} from 'app/resize-column.directive';
import {AdminTableComponent} from './admin-table.component';
import {AdminTreeComponent} from './admin-tree.component';
import {AdminCalendarViewComponent} from './admin-calendar-view.component';
import {LedgerImportComponent} from '../ledger-import/ledger-import.component';
import {AdminSharedModule} from './admin-shared.module';
import {PublicFormService} from '../services/public-form-service';
import {ViewModalComponent} from './view-modal.component';
import {ViewContentComponent} from './view/view-content.component';
import {ViewTableComponent} from './view/table.component';
import {ViewTableListComponent} from './view/table-list.component';
import {ViewImageListComponent} from './view/image-list.component';
import {ViewChipListComponent} from './view/chip-list.component';
import {MatChipsModule} from '@angular/material/chips';
import {BsDropdownModule} from 'ngx-bootstrap';
import {MatMenuModule} from '@angular/material/menu';


@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({name: 'keys', pure: false})
export class KeysPipe implements PipeTransform {
    transform(value: any, args: any[] = null): any {
        return Object.keys(value)// .map(key => value[key]);
    }
}

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin
]);

@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        ModalModule,
        DragDropModule,
        ToastrModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        CloudChartsModule,
        LaddaModule.forRoot({
            style: 'slide-up',
            spinnerSize: 25,
            spinnerColor: '#fff',
            spinnerLines: 12
        }),

        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        AdminRoutingModule,
        FroalaEditorModule,
        FroalaViewModule,
        NgSelectModule,
        EditModule,
        AdminSharedModule,
        PublicFormService,
        FullCalendarModule,
        MatChipsModule,
        BsDropdownModule,
        MatMenuModule
    ],
    exports: [
        AdminTableComponent,
        AdminTreeComponent,
        AdminCalendarViewComponent,
        ResizeColumnDirective,
        ViewTableComponent,
        ViewTableListComponent,
        ViewImageListComponent,
        ViewContentComponent,
        ViewChipListComponent,
        ViewModalComponent
    ],
    declarations: [
        AdminComponent,
        SearchparamComponent,
        KeysPipe,
        SafeHtmlPipe,
        DatasetTableRowComponent,
        DatasetTableCellComponent,
        FormFieldEditModalComponent,
        TsvImportComponent,
        LedgerImportComponent,
        ResizeColumnDirective,
        AdminTableComponent,
        AdminTreeComponent,
        AdminCalendarViewComponent,
        ViewTableComponent,
        ViewTableListComponent,
        ViewContentComponent,
        ViewChipListComponent,
        ViewImageListComponent,
        ViewModalComponent
    ]
})
export class AdminModule {
}
