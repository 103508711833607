import {Component, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router'
import {User} from './services/user';
import {Connect} from './services/connect';
import {SharedService} from './services/shared';
import {DOCUMENT} from '@angular/common';
import {CL} from './services/check-license';

@Component({
    // tslint:disable-next-line
    providers: [User],
    selector: 'body',
    template: '<router-outlet></router-outlet>',

})

export class AppComponent {
    menu_a;
    _share;

    constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute,
                private user: User, private _connect: Connect, private _cl: CL, _share: SharedService, @Inject(DOCUMENT) private document: any) {
        const __this = this;
        this._share = _share;
        let g = __this.document.location.href.match(/https?:\/\/.*?\/(.*?)\//)
        let path = __this.document.location.pathname
        let search_query = this.document.location.search
        if (g !== null && g.length > 1 && g[1] === 'cms') {
            g = __this.document.location.href.match(/https?:\/\/.*?\/.*?\/(.*?)\//)
        }
        if (g !== null && g.length > 1 && g[1] !== undefined) {
            _share.setAdminTable(g[1]);
        }
        if (__this.document.location.href.match(/\/public/)) {
            //PUBLIC IFRAME MODE
            return;
        }
        if (__this.document.location.href.match(/\/reset/)) {
            //RESETMODE
            return;
        }
        if (g === null) {
            location.href = '/admin/login';
            // __this.router.navigate(['admin','login']);
            // window.alert("URLが誤っています。");
            return;
        }

        user.getUser().then(
            (_user) => {
                if (_user === null) {
                    if (!path.match(/login/)) {
                        __this.router.navigate([__this._share.getAdminTable(), 'login'], {queryParams: {redirect_to: path+search_query}});
                    } else {
                        if (path == '/admin/login/master') {
                            __this.router.navigate([__this._share.getAdminTable(), 'login', 'master']);
                        } else {
                            __this.router.navigate([__this._share.getAdminTable(), 'login']);
                        }
                    }
                } else {
                    if (__this.document.location.href.match(/login/)) {
                        __this.router.navigate([__this._share.getAdminTable(), 'dashboard']);
                    }

                    __this._share.setUser(_user);
                }
            }
        );
        localStorage.removeItem('debug_mode');
        if(window.performance && window.performance.getEntriesByType('navigation').length && window.performance.getEntriesByType("navigation")[0]['type'] == 'reload') {
            localStorage.removeItem('set_position')
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                scrollTo(0, 0); // ページ遷移後最上部へスクロール
            }
        })
    }

}
