<div class="card card-accent-primary dataset-add-modal">
    <div class="card-block">
        <div class="form-group row admin-forms">
            <label class="col-md-3 form-control-label">
                テーブル名
                <span class="text-danger">※</span>
            </label>
            <div class="col-md-9 col-lg-7">
                <input id="table_name" [(ngModel)]="data.raw_data['label']" type="text" class="form-control" [disabled]="is_system_table">
            </div>
        </div>
        <hr>
        <div class="row" *ngIf="!is_system_table">
            <div class="col-12">
                <button type="button" class="btn btn-sm btn-outline-info" (click)="isDatasetSettingOptionCollapsed = !isDatasetSettingOptionCollapsed"
                        [attr.aria-expanded]="!isDatasetSettingOptionCollapsed" aria-controls="collapseDataset">
                    <i class="fa" [class.fa-bars]="isDatasetSettingOptionCollapsed" [class.fa-times]="!isDatasetSettingOptionCollapsed"></i>
                    追加オプション設定
                </button>
            </div>
            <div id="collapseDataset" class="col-12 mt-3 animated fadeIn" [ngbCollapse]="isDatasetSettingOptionCollapsed">
                <h4>一覧画面</h4>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        1ページあたりの表示データ数 (最大50）<br>
                        <!-- <small>項目数が多い場合、データ数が多いと重くなる場合があります</small>-->
                    </label>
                    <div class="col-md-5 col-lg-4">
                        <input [(ngModel)]="data.raw_data['per_page']" OnlyNumber [object]="data.raw_data" [key]="'per_page'" [min]="1" [max]="50" class="form-control input_per_page">
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        デフォルトの並び順
                    </label>
                    <div class="col-md-5 col-lg-4">
                        <select name="sort_field" class="input_sort_field" [(ngModel)]="data.raw_data['sort_field']" class="form-control">
                            <option value="id">ID</option>
                            <option *ngFor="let _child_data of data.child_data_by_table['dataset_field']" value="field__{{_child_data.raw_data.id}}">{{_child_data.raw_data.name}}
                            </option>
                            <option value="admin_id">作成者</option>
                            <option value="updated">更新日時</option>
                            <option value="created">作成日時</option>
                        </select>

                        <br>
                        <label>
                            <input type="radio" name="sort_order" class="input_sort_order" [(ngModel)]="data.raw_data['sort_order']" value="asc"> 昇順
                        </label>
                        <br>
                        <label>
                            <input type="radio" name="sort_order" class="input_sort_order" [(ngModel)]="data.raw_data['sort_order']" value="desc"> 降順
                        </label>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        IDを表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['show_id']" type="hidden" name="show_id" class="input_show_id">
                                <input type="checkbox" [checked]="data.raw_data['show_id']!='false'" (change)="data.raw_data['show_id']=(data.raw_data['show_id']=='true')?'false':'true';">
                                有効
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        更新日時を表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['show_updated']" type="hidden" name="show_updated" class="input_show_updated">
                                <input type="checkbox" [checked]="data.raw_data['show_updated']=='true'"
                                       (change)="data.raw_data['show_updated']=(data.raw_data['show_updated']=='true')?'false':'true';">
                                有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        作成日時を表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <!-- ngModelはhiddenに紐付け、checkboxでhiddenのvalueを変える -->
                                <input [(ngModel)]="data.raw_data['show_created']" type="hidden" name="show_created" class="input_show_created">
                                <input type="checkbox" [checked]="data.raw_data['show_created']=='true'"
                                       (change)="data.raw_data['show_created']=(data.raw_data['show_created']=='true')?'false':'true';">
                                有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        作成者を表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <!-- ngModelはhiddenに紐付け、checkboxでhiddenのvalueを変える -->
                                <input [(ngModel)]="data.raw_data['show_admin']" type="hidden" name="show_created" class="input_show_created">
                                <input type="checkbox" [checked]="data.raw_data['show_admin']=='true'" (change)="flgChange('show_admin',$event)">
                                有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        一覧編集／登録モード
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['editable_on_list']" type="hidden" name="editable_on_list" class="input_editable_on_list">
                                <input type="checkbox" [checked]="data.raw_data['editable_on_list']=='true'" value="true"
                                       (change)="flgChange('editable_on_list',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        カレンダー表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['is_calendar_view_enabled']" type="hidden">
                                <input type="checkbox" [checked]="data.raw_data['is_calendar_view_enabled']=='true'" (change)="flgChange('is_calendar_view_enabled',$event); calendarViewActive()">
                                有効
                            </label>
                        </div>
                        <ng-container *ngIf="data.raw_data['is_calendar_view_enabled']=='true'">

                            <div class="checkbox">
                                <label>
                                    <input [(ngModel)]="data.raw_data['from_to_calendar_view_datetime']" type="hidden">
                                    <input type="checkbox" [checked]="data.raw_data['from_to_calendar_view_datetime']=='true'"
                                           (change)="data.raw_data['from_to_calendar_view_datetime']=(data.raw_data['from_to_calendar_view_datetime']=='true')?'false':'true';">
                                    参照する日時フィールドをfrom,toの形式にする。
                                </label>
                            </div>

                            <ng-container *ngIf="data.raw_data['from_to_calendar_view_datetime']=='false'">
                                <label>参照したい日時フィールドを選択してください。</label>
                                <ng-select
                                    class="custom form-control"
                                    [items]="calendar_view_datetime_list"
                                    [(ngModel)]="data.raw_data['calendar_view_datetime']"
                                    appendTo="body"
                                    [searchWhileComposing]="false"
                                    [virtualScroll]="true"
                                    bindLabel="name"
                                    bindValue="value"
                                >
                                </ng-select>
                            </ng-container>
                            <ng-container *ngIf="data.raw_data['from_to_calendar_view_datetime']=='true'">
                                <label>開始日時で参照したい日時フィールドを選択してください。</label>
                                <br>
                                <ng-select
                                    class="custom form-control"
                                    [items]="calendar_view_datetime_list"
                                    [(ngModel)]="data.raw_data['calendar_view_datetime_from']"
                                    appendTo="body"
                                    [searchWhileComposing]="false"
                                    [virtualScroll]="true"
                                    bindLabel="name"
                                    bindValue="value"
                                >
                                </ng-select>
                                <label>終了日時で参照したい日時フィールドを選択してください。</label>
                                <br>
                                <ng-select
                                    class="custom form-control"
                                    [items]="calendar_view_datetime_list"
                                    [(ngModel)]="data.raw_data['calendar_view_datetime_to']"
                                    appendTo="body"
                                    [searchWhileComposing]="false"
                                    [virtualScroll]="true"
                                    bindLabel="name"
                                    bindValue="value"
                                >
                                </ng-select>
                            </ng-container>

                            <br>
                            <label>カレンダーで表示したいフィールドを入力してください。</label>
                            <br>
                            <input [(ngModel)]="data.raw_data['field_displaying_in_calendar_view']" type="text" placeholder="ex. {ID}: {表示したいフィールド名}" class="form-control">

                            <label>カレンダーで表示したい画像フィールドがあれば選択してください。</label>
                            <ng-select
                                class="custom form-control"
                                [items]="calendar_view_image_list"
                                [(ngModel)]="data.raw_data['image_field_displaying_in_calendar_view']"
                                appendTo="body"
                                [searchWhileComposing]="false"
                                [virtualScroll]="true"
                                bindLabel="name"
                                bindValue="value"
                            >
                            </ng-select>

                        </ng-container>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        メニューに「全てのデータを削除」を表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <!-- ngModelはhiddenに紐付け、checkboxでhiddenのvalueを変える -->
                                <input [(ngModel)]="data.raw_data['show_delete_all']" type="hidden" name="show_created" class="input_show_created">
                                <input type="checkbox" [checked]="data.raw_data['show_delete_all']=='true'"
                                       (change)="flgChange('show_delete_all',$event)">
                                有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        上部にメモを表示する
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['show_top_memo']=='true'"
                                       (change)="flgChange('show_top_memo',$event)"> 有効
                            </label>
                        </div>
                    </div>

                </div>


                <div class="child-container" *ngIf="data.raw_data['show_top_memo']=='true'">
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            メモの表示箇所
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" name="memo_view" [checked]="data.hasValueInJsonField('top_memo_display_view','list')"
                                           (change)="flgChangeJson('top_memo_display_view','list',$event)"> 一覧
                                </label>
                                <label>
                                    <input type="checkbox" name="memo_view" [checked]="data.hasValueInJsonField('top_memo_display_view','edit')"
                                           (change)="flgChangeJson('top_memo_display_view','edit',$event)"> 編集
                                </label>
                                <label>
                                    <input type="checkbox" name="memo_view" [checked]="data.hasValueInJsonField('top_memo_display_view','add')"
                                           (change)="flgChangeJson('top_memo_display_view','view',$event)"> 詳細
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 col-lg-3 form-control-label">
                            メモタイトル
                        </label>

                        <div class="col-md-9 col-lg-7">
                            <input [(ngModel)]="data.raw_data['top_memo_title']" type="text" class="form-control">
                        </div>

                    </div>
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 col-lg-3 form-control-label">
                            メモ本文
                        </label>

                        <div class="col-md-9 col-lg-7">
                            <div [froalaEditor]="_share.getFroalaOption()" [(froalaModel)]="data.raw_data['top_memo']"></div>
                        </div>

                    </div>
                </div>


                <h4>詳細画面</h4>


                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        ログとコメントをまとめて表示する
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['merge_comment_and_history']=='true'"
                                       (change)="flgChange('merge_comment_and_history',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        複製ボタンを非表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['hide_duplicate_button']=='true'"
                                       (change)="flgChange('hide_duplicate_button',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        レイアウトを詳細画面にも適用
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['layout_apply_view']=='true'"
                                       (change)="flgChange('layout_apply_view',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>

                <h4>編集画面</h4>


                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        保存時にコメントを残すポップアップを出す
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['popup_comment_after_save']=='true'"
                                       (change)="flgChange('popup_comment_after_save',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>


                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        フォームのスタイル
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <label>
                            <input type="radio" name="style" class="input_style" [(ngModel)]="data.raw_data['style']" value="form"> フォーム
                        </label>
                        <br>
                        <label>
                            <input type="radio" name="style" class="input_style" [(ngModel)]="data.raw_data['style']" value="questionnaire"> アンケート
                        </label>
                    </div>

                </div>


                <h4>ワークフロー</h4>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        ワークフロー
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['workflow']=='true'"
                                       (change)="flgChange('workflow',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="data.raw_data['workflow']=='true'" class="child-container">
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            ワークフロー承認者はデータ編集可能
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [checked]="data.raw_data['workflow_editable']=='true'"
                                           (change)="flgChange('workflow_editable',$event)"> 有効
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            一度承認されたデータも再申請可能
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [checked]="data.raw_data['workflow_reapply']=='true'"
                                           (change)="flgChange('workflow_reapply',$event)"> 有効
                                </label>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            引き上げ承認機能
                            <i class="fa fa-question-circle" aria-hidden="true" ngbTooltip="ユーザーに対しての申請の場合にのみ適用可能です。例えばAさん→Bさん→Cさんという承認の順番で、Aさんを飛ばしてBさんが承認可能です。"></i>
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [checked]="data.raw_data['workflow_salvage']=='true'"
                                           (change)="flgChange('workflow_salvage',$event)"> 有効
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            ワークフローのフローを固定する
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" [checked]="data.raw_data['workflow_flow_fixed']=='true'"
                                           (change)="flgChange('workflow_flow_fixed',$event)"> 有効
                                </label>
                            </div>
                        </div>

                        <div class="col-md-12" *ngIf="data.raw_data['workflow_flow_fixed']=='true'">
                            <ng-container *ngFor="let workflow_template of workflow_template_a; let i = index;">
                                <div class="workflow_template">
                                    <button class="btn btn-danger  btn-md pull-right" (click)="workflow_template_a.splice(i,1)"><i class="fa fa-minus"></i></button>
                                    <h5>条件
                                        <button class="btn-success btn-md ml-3" (click)="workflow_template.conditions.addCondition()"><i class="fa fa-plus"></i></button>
                                    </h5>
                                    <ng-container *ngFor="let condition of workflow_template.conditions.condition_a; let i = index;">
                                        <div style="margin-bottom:10px">
                                            <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="workflow_template.conditions.deleteCondition(i)"><i class="fa fa-minus"></i>
                                            </button>
                                            <condition-form [show_admin_or_division_select]="true" [table]="getTableName()" [default_condition]="condition"
                                                            (valueChanged)="onValueChanged(workflow_template,i,$event)" [index]="i"></condition-form>
                                        </div>
                                    </ng-container>
                                    <br style="clear: both">

                                    <br style="clear: both">
                                    <br style="clear: both">
                                    <h4>申請フロー
                                        <button class="ml-4 btn-success btn-sm btn" (click)="workflow_template.workflow.addWorkflowPath()"><i class="fa fa-plus"></i></button>
                                    </h4>
                                    <workflow-setting [workflow]="workflow_template.workflow" (valueChanged)="onWorkflowChanged(workflow_template,$event)"></workflow-setting>
                                    <br style="clear: both">
                                </div>
                            </ng-container>

                            <br style="clear: both">
                            テンプレートの追加&nbsp;<button class="ml-3 btn-success  btn-md" (click)="addWorkflowTempate()"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>


                <h4>メニュー</h4>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        メニューに表示
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['show_menu']" type="hidden" name="show_id" class="input_show_menu">
                                <input type="checkbox" [checked]="data.raw_data['show_menu']!='false'" (change)="data.raw_data['show_menu']=(data.raw_data['show_menu']=='true')?'false':'true';">
                                有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 col-lg-3 form-control-label">
                        アイコン
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <input [(ngModel)]="data.raw_data['icon_class']" type="text" class="form-control" placeholder="address-book">
                        <br>
                        <label>
                            <a href="https://fontawesome.com/v4.7/icons/" target="_blank">こちら</a>よりclassの名称を取得し、入力して下さい。（例：address-book
                        </label>
                    </div>
                </div>

                <!--
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        グループ
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <input [(ngModel)]="data.raw_data['group']" type="text" class="form-control">
                    </div>
                </div>
                -->

                <h4>その他</h4>


                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        画像を公開にする(API用機能)
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input [(ngModel)]="data.raw_data['public_image']" type="hidden" name="public_image" class="input_public_image">
                                <input type="checkbox" [checked]="data.raw_data['public_image']=='true'" value="true"
                                       (change)="flgChange('public_image',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        埋め込みフォームをONにする<br>（scriptを設置したwebサイトからデータ登録が可能になります。）
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['is_iframe_enabled']=='true'"
                                       (change)="flgChange('is_iframe_enabled',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        公開フォームをONにする<br>（ログインせずに、指定されたURLからデータ登録がになります。アンケート等用）
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['is_public_form_enabled']=='true'"
                                       (change)="flgChange('is_public_form_enabled',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>
                <div class="child-container" *ngIf="data.raw_data['is_public_form_enabled'] == 'true'">

                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            公開フォームのタイトル
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <input [(ngModel)]="data.raw_data['public_form_title']" type="text" class="form-control" placeholder="XXXに関するアンケート">
                        </div>
                    </div>
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            公開フォームの詳細
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <textarea [(ngModel)]="data.raw_data['public_form_description']" class="form-control" placeholder="XXXに関するアンケートを実施します。全ての項目に正確に記入して下さい。">
                            </textarea>
                        </div>
                    </div>
                    <div class="form-group row admin-forms">
                        <label class="col-md-3 form-control-label">
                            公開フォームの送信時テキスト
                            <small>空の場合は「送信が完了しました」が表示されます。</small>
                        </label>
                        <div class="col-md-9 col-lg-7">
                            <input [(ngModel)]="data.raw_data['public_form_sent_text']" type="text" class="form-control" placeholder="送信が完了しました">
                        </div>
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        CSVダウンロード/アップロードに子テーブルも含める
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="data.raw_data['use_child_on_csv']=='true'"
                                       (change)="flgChange('use_child_on_csv',$event)"> 有効
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
