<ng-template [ngIf]="!hasChildren && table_info!=undefined && grant!=undefined">
    <div class="row" [ngClass]="setClasses()" id="pc-list-view">
        <ng-container *ngIf="!simpleViewMode">
            <div class="col-lg-12" *ngIf="before_html!=undefined">
                <div class="card card-accent-primary">
                    <div class="card-block" [innerHTML]="before_html | safeHtml"></div>
                </div>
            </div>
            <!-- TOP MEMO -->
            <memo class="col-lg-12" [table_info]="table_info" [view_name]="'list'" (onOpenClose)="onOpenCloseMemo($event)"></memo>
            <!-- TOP MEMO END -->
        </ng-container>
        <div class="col-lg-12 padding-0">
            <div class="card">
                <div class="card-header" id="table-card-header">
                    <ng-container *ngIf="!simpleViewMode">

                        <button *ngIf="isEditMode" (click)="exitEditMode(false)" type="button"
                                class="btn btn-sm btn-default p-1 pl-5 pr-5 ml-2 edit_mode_btn">
                            キャンセル
                        </button>
                        <button *ngIf="isEditMode" (click)="exitEditMode(true)" type="button"
                                class="btn btn-sm btn-primary p-1 pl-5 pr-5 ml-2 edit_mode_btn">
                            保存
                        </button>
                        <ng-container *ngIf="!isEditMode">
                            <div class="pull-left" style="height: 34px;" *ngIf="isShowMenu()">
                                <button *ngIf="isInsertable()"
                                        (click)="add()" type="button" class="btn btn-sm btn-outline-primary pl-2 mr-2">
                                    <i class="fa fa-plus"></i>
                                </button>
                                <div class="btn-group" dropdown>
                                    <button dropdownToggle type="button"
                                            class="btn btn-sm btn-outline-primary  dropdown-toggle">
                                        <i class="fa fa-bars"></i>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
                                        <li role="menuitem" *ngIf="table_info.table==='dataset'"><a class="dropdown-item"
                                                                                                    href="javascript:void(0)" (click)="this.AdminTsvImport.openTsvImportMenu()"
                                                                                                    *ngIf="isInsertable()"><i
                                            class="fa fa-plus"></i> エクセルから追加</a></li>
                                        <ng-container *ngIf="table!='dataset' && table!='view'">
                                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                   (click)="openDlCsvModal()" [ladda]="downloading['csv']" data-spinner-color="#999"
                                                                   *ngIf="grant.csv_download &&  !table_info.is_child_form"><i class="fa fa-download"></i>
                                                CSVダウンロード</a>
                                            </li>
                                            <ng-container *ngIf="!isSummarizeMode">
                                                <li role="menuitem" *ngIf=" !table_info.is_child_form"><a class="dropdown-item" href="javascript:void(0)"
                                                                                                          (click)="openCsvModal()" *ngIf=" grant.csv_upload"
                                                                                                          [ladda]="downloading['csv-upload']"><i class="fa fa-upload"></i>
                                                    <ng-container>
                                                        CSVアップロード
                                                    </ng-container>
                                                </a>
                                                </li>

                                                <!--
                                                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                       (click)="create_view()" *ngIf="grant.create_view"><i
                                                    class="fa fa-download"></i>
                                                    ビュー作成</a></li>
                                                    -->
                                                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                       (click)="download_zip()" [ladda]="downloading['csv']"
                                                                       *ngIf="hasFileField && (!table_info.is_admin_table) && false"><i
                                                    class="fa fa-download"></i> ZIPダウンロード</a></li>
                                                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                       (click)="openTruncateModal()"
                                                                       *ngIf="grant.delete && table_info.menu.show_delete_all"><i
                                                    class="fa fa-trash"></i> 全てのデータを削除</a></li>
                                            </ng-container>
                                            <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                   (click)="summarize(true)" *ngIf="grant.summarize"><i
                                                class="fa fa-calculator"></i> 集計</a></li>
                                            <ng-container *ngIf="!table_info.is_child_form">
                                                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                       (click)="summarize(true,'chart')"><i class="fa fa-line-chart"></i>
                                                    チャート</a>
                                                </li>
                                                <li role="menuitem" *ngIf="_share.isMasterUser()"><a class="dropdown-item" href="javascript:void(0)"
                                                                                                     (click)="this.AdminLedgerImport.openImportMenu(this.table)"><i
                                                    class="fa fa-file-pdf-o"></i>
                                                    帳票登録</a>
                                                </li>

                                                <li role="menuitem" *ngIf="table_info.menu.is_iframe_enabled && !isSummarizeMode"><a
                                                    class="dropdown-item" href="javascript:void(0)"
                                                    (click)="showEmbedLink()"><i class="fa fa-plus"></i>
                                                    埋め込みフォームリンク</a>
                                                </li>
                                                <li role="menuitem" *ngIf="table_info.menu.is_public_form_enabled && !isSummarizeMode"><a
                                                    class="dropdown-item" href="javascript:void(0)"
                                                    (click)="showPublicFormLink()"><i class="fa fa-external-link"></i>
                                                    公開フォームリンク</a>
                                                </li>
                                                <li role="menuitem" *ngIf="table_info.menu.is_public_form_enabled && _share.cloud_setting['mail_option'] == 'true'">
                                                    <a class="dropdown-item" href="javascript:void(0)" (click)="showPublicFormEmailLink()">
                                                        <i class="fa fa-envelope-o"></i>
                                                        公開フォームをメール配信</a>
                                                </li>
                                                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                                       (click)="openTableSettingModal()"><i
                                                    class="fa fa-bell-o"></i>
                                                    通知設定</a>
                                                </li>
                                            </ng-container>
                                            <li role="menuitem" *ngIf="hasFileField"><a class="dropdown-item" href="javascript:void(0)"
                                                                                        (click)="uploadFilesByZip(template)"><i
                                                class="fa fa-file-archive-o"></i>
                                                ファイルのzipアップロード</a>
                                            </li>
                                        </ng-container>
                                        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                               (click)="add_template()" *ngIf="_share.dataset_add_grant && table=='dataset'"><i
                                            class="fa fa-plus"></i> テンプレートから追加 </a></li>
                                        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                               (click)="openJSONModal()"
                                                               *ngIf="_share.dataset_add_grant && table=='dataset'"><i class="fa fa-plus"></i>
                                            JSONから追加 </a></li>
                                    </ul>
                                </div>
                                <div *ngIf="table_info.is_ledger_active && _share.isMasterUser()" class="btn-group ml-2 hidden-sm-down" dropdown>
                                    <button dropdownToggle type="button"
                                            class="btn btn-sm btn-outline-primary  dropdown-toggle">
                                        帳票
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
                                        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                                               (click)="this.AdminLedgerImport.openImportMenu(this.table)"><i
                                            class="fa fa-plus"></i>
                                            追加</a>
                                        </li>
                                        <ng-container *ngFor="let ledger of table_info.ledger_a">
                                            <li role="menuitem" *ngIf="table_info.is_ledger_active"><a class="dropdown-item" href="javascript:void(0)"
                                                                                                       (click)="donwloadLedgerTemplate(ledger)"><i
                                                class="fa fa-download"></i>
                                                【{{ledger.name}}】ダウンロード </a>

                                            </li>

                                            <li role="menuitem" *ngIf="table_info.is_ledger_active"><a class="dropdown-item text-danger" href="javascript:void(0)"
                                                                                                       (click)="confirmDeleteLedgerTemplate(ledger)"><i
                                                class="fa fa-trash text-danger"></i>
                                                【{{ledger.name}}】削除 </a>

                                            </li>

                                        </ng-container>
                                    </ul>

                                </div>

                                <button *ngIf="searchable && table_info.table!='dataset'"
                                        (click)="search(true)" type="button" class="btn btn-sm btn-outline-primary pl-2 ml-2">
                                    <i class="fa fa-search"></i>
                                </button>
                                <button *ngIf="searchable" (click)="load(page)" type="button" [disabled]="loading"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">
                                    <i class="fa fa-refresh"></i>
                                </button>

                                <ng-container
                                    *ngIf="table_info.table != 'dataset' && table_info.menu.editable_on_list && this.grant.edit && !this.isSummarizeMode  && !switchCalendarView && !isChartMode()">

                                    <button *ngIf="!isEditMode" (click)="toEditMode()" type="button" [disabled]="loading"
                                            class="btn btn-sm btn-outline-primary pl-2 ml-2">
                                        編集モード
                                    </button>


                                </ng-container>

                            </div>
                            <div *ngIf="current_url=='/admin/dataset'" style="margin-top: -1px;" class="pull-left">
                                <button *ngIf="!this.switchGroupEdit" (click)="groupEdit()"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">グループ編集
                                </button>
                                <button *ngIf="this.switchGroupEdit" (click)="groupEdit()"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">グループの編集終了
                                </button>
                            </div>
                            <div *ngIf="current_url=='/admin/dataset'" style="margin-top: -1px;" class="pull-left">
                                <button *ngIf="this.switchGroupEdit" (click)="groupEditCancel()"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">キャンセル
                                </button>
                            </div>
                            <div *ngIf="table_info._menu.is_calendar_view_enabled" style="margin-top: -1px;" class="pull-left">
                                <button *ngIf="!this.switchCalendarView" (click)="this.switchCalendarView=true"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">カレンダー表示
                                </button>
                                <button *ngIf="this.switchCalendarView" (click)="this.switchCalendarView=false"
                                        class="btn btn-sm btn-outline-primary pl-2 ml-2">リスト表示
                                </button>
                            </div>
                            <div style="margin-top: -1px;" class="pull-left flex" *ngIf="table!='dataset' && !isChartMode()">
                                <form (submit)="searchAll($event)">
                                    <input [(ngModel)]="all_search_text" name="search_input" type="text" id="search_input"
                                           class="form-control　mr-2 mr-lg-0 pl-1 ml-4" placeholder="簡易検索">
                                </form>
                            </div>
                            <custom-filter-pulldown (onEditFilter)="summarize(false)" (onReset)="resetSearch()"
                                                    (onSelectFilter)="selectSavedFilter($event)"
                                                    [table_info]="table_info"
                                                    [customFilter]="customFilter"
                                                    [has_filter_params]="has_filter_params"
                                                    [deleteFilterModal]="deleteFilterModal"
                                                    [confirmFilterOverwriteConfirmModal]="confirmFilterOverwriteConfirmModal"
                                                    [chartTitleModal]="chartTitleModal"
                            ></custom-filter-pulldown>

                            <ng-container *ngIf=" checked_id_a.length>0">
                                <button class="btn btn-sm btn-danger ml-2" (click)="deleteAll()"
                                        *ngIf="grant.delete && isAllDataDelable()">
                                    <i class="fa fa-trash"></i> 一括削除
                                </button>
                                <ng-container *ngIf="table_info.menu.is_workflow">
                                    <button *ngIf="isAllDataAssigned()" class="btn btn-sm btn-success ml-4" (click)="workflowAll('accepted')"
                                    >
                                        <i class="fa fa-check"></i> 一括承認
                                    </button>
                                    <button *ngIf="isAllDataAssigned()" class="btn btn-sm btn-danger ml-2" (click)="workflowAll('rejected')"
                                    >
                                        <i class="fa fa-times"></i> 一括否認
                                    </button>
                                    <button *ngIf="isAllDataMyApplying()" class="btn btn-sm btn-warning ml-2" (click)="workflowAll('withdraw')">
                                        <i class="fa fa-times"></i> 一括取り下げ
                                    </button>
                                </ng-container>

                                <button class="btn btn-sm btn-primary ml-2" (click)="exportAll()" [disabled]='!export_ready'
                                        *ngIf="grant.edit && dataset_view">
                                    <i class="fa fa-download"></i> JSONをエクスポートする
                                </button>
                            </ng-container>

                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="simpleViewMode">
                        <a class="btn btn-success" href="javascript:void(0)"
                           (click)="openDlCsvModal()" [ladda]="downloading['csv']" data-spinner-color="#999"
                           *ngIf="grant.csv_download &&  !table_info.is_child_form"><i class="fa fa-download"></i>
                            CSVダウンロード</a>
                    </ng-container>


                    <!--ヘッダーに移動予定-->
                    <!--<div class="pull-left pl-2">{{page_title}}</div>-->
                    <div class="pull-right"
                         *ngIf="table_info.grant.table_editable && (table_info.isDatasetTable() || table_info.table === 'admin') ">
                        <button type="button" (click)="gotoSetting()" class="btn btn-sm ">
                            <i class="fa fa-gear"></i>
                        </button>
                    </div>
                    <div class="pull-right" *ngIf="!isSummarizeMode && !this.switchCalendarView && !loading" style="margin-top: -8px">
                        <!-- <div class="btn-group" dropdown style="display:none">
                        </div> -->

                        <ul style="display:none">
                            <li *ngFor="let item of numbers | paginate: { itemsPerPage: 1, currentPage: page }">
                                {{ item }}
                            </li>
                        </ul>
                        <pagination-controls (pageChange)="onPageChange($event)" [previousLabel]="''" [nextLabel]="''">
                        </pagination-controls>
                    </div>

                </div>
                <div>
                    <ng-container *ngIf="!simpleViewMode">
                        <div class="card card-bottom1" *ngIf="customFilter && this.customFilter.isSetSearchParam() && !isChartMode()">
                            <div class="card-body p-3 clearfix">
                                <div class="row">
                                    <div class="col-3">
                                        <h5><i class="fa fa-search"></i> 検索内容</h5>
                                    </div>
                                    <div class="col-9 row variable-container bg-light"
                                         *ngIf="all_search_variables.length>0">
                                        <!-- 変数の内容入力 -->
                                        <ng-container *ngFor="let variable of all_search_variables">
                                            <div class="col-3 text-center pt-1 title">{{variable.name}}</div>
                                            <div class="col-9">
                                                <search-field [table_info]="table_info" [placeholder]="'値'" [value]="variable.default_value"
                                                              [form]="variable.dummy_form" [is_timing_condition]="false"
                                                              (valueChange)="variableChanged($event,variable)" [condition]="condition"></search-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                                <hr style="width:100%" *ngIf="all_search_variables.length > 0">

                                <div class="row">
                                    <!-- 検索内容の表示 -->
                                    <ng-container
                                        *ngFor="let and_condition_group of customFilter.conditions.condition_or_group;let cond_i = index">
                                        <div class="col-md-12 row">
                                            <div class="col-5 ml-1 mb-1"
                                                 *ngFor="let condition of and_condition_group;let cond_i=index">
                                                <div class="condition">
                                                    <b>{{condition.field_label}}</b>&ngsp;が

                                                    <ng-container *ngIf="!condition.inc_table">
                                                        {{_share.condition_a[condition.condition]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="!isDaysShow(condition.condition)">
                                                        <ng-container *ngIf="condition.use_variable">
                                                            <ng-container
                                                                *ngIf="customFilter.getVariableByCondition(condition)!=null">
                                                                【{{customFilter.getVariableByCondition(condition).name}}】
                                                            </ng-container>

                                                        </ng-container>

                                                        <ng-container *ngIf="!condition.use_variable">
                                                            <ng-container
                                                                *ngIf="!condition.isIncludeTableOrNotInclude();else IS_INC_TABLE">
                                                            <span *ngIf="condition.condition !== 'null' && condition.condition !== 'not_null';else notConditionNull">
                                                                <ng-container *ngIf="!isEditCondition(condition);else isEditMode;">
                                                                    <ng-container *ngIf="!enableSelectDate(condition)">
                                                                        <!-- FIXME use async -->
                                                                        「{{condition.view_value}}」
                                                                    </ng-container>
                                                                    <ng-container *ngIf="enableSelectDate(condition)">
                                                                        「{{select_date_option['title']}}」
                                                                    </ng-container>
                                                                    <i *ngIf="conditionEditable(condition)" (click)="editCondition(condition)"
                                                                       class="fa fa-pencil-square-o text-warning"
                                                                       style="cursor: pointer"></i>
                                                                </ng-container>
                                                                <ng-template #isEditMode>
                                                                    <search-field [table_info]="condition.target_table_info" [ignore_change]="true" [placeholder]="''"
                                                                                  [value]="condition.value"
                                                                                  [form]="condition.target_form"
                                                                                  [is_timing_condition]="false"
                                                                                  [condition]="condition"
                                                                                  (valueChange)="conditionValueChanged($event,condition)">
                                                                </search-field>
                                                                </ng-template>
                                                            </span>
                                                                <ng-template #notConditionNull>
                                                                </ng-template>
                                                            </ng-container>
                                                            <ng-template #IS_INC_TABLE>
                                                                <ng-container
                                                                    *ngIf="_share.getTableInfoByTableName(condition.inc_table)">
                                                                    <!-- 別テーブルに含まれる（ない）場合 -->
                                                                    {{_share.getTableInfoByTableName(condition.inc_table).getLabel()}}
                                                                    <span *ngIf="condition.inc_filter_id">
                                                                    ({{_share.getFilterName(condition.inc_table, condition.inc_filter_id)|async}})

                                                                </span>
                                                                    に
                                                                    <span
                                                                        *ngIf="condition.condition == 'include_other_table'">含まれる</span>
                                                                    <span
                                                                        *ngIf="condition.condition == 'not_include_other_table'">含まれない</span>
                                                                </ng-container>

                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-container>
                                                    <i class="fa fa-times-circle-o text-danger delete"
                                                       (click)="deleteCondition(condition)"></i>

                                                </div>

                                                <!--子テーブル -->
                                                <!--
                                                <ng-container *ngIf="is_child_table(key)">
                                                    <div *ngFor="let child of table_info.child_a">
                                                        <search-param [fields]="child.fields" [forms]="child.forms"
                                                                      [key]="get_child_key(key)" [value]="search_params[key]"
                                                                      [child-table-name]="child.menu.name"></search-param>
                                                    </div>
                                                </ng-container>
                                                -->
                                            </div>


                                        </div>
                                        <div *ngIf="cond_i!=customFilter.conditions.condition_or_group.length-1"
                                             class="pt-1 pb-1 col-md-12 row">
                                            <div class="col-md-5">
                                                <hr style="width:100%">
                                            </div>
                                            <div class="col-md-2 text-center and_or">OR</div>
                                            <div class="col-md-5">
                                                <hr style="width:100%">
                                            </div>

                                        </div>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                        <!-- SEARCH FORM END -->
                    </ng-container>

                    <div *ngIf="loading;then loading_block else loaded_block"></div>
                    <ng-template #loading_block>
                        <div class="loader">Loading...</div>
                    </ng-template>
                    <div class="animated fadeIn" *ngIf="!first_loading" [hidden]="loading">
                        <div class="l-fixed-btns d-lg-none">
                            <button class="l-fixed-btns__btn -success"
                                    [ngClass]="{'t2-border': table_info.table === 'dataset' && tutorial_flag === 'false' ? true : false}"
                                    (click)="add()" *ngIf="grant.edit && isInsertable() && !isSummarizeMode"><i
                                class="fa fa-plus"></i><span class="d-none d-lg-inline"></span></button>
                            <button class="l-fixed-btns__btn -default" (click)="search()"
                                    *ngIf="searchable"><i class="fa fa-search"></i><span
                                class="d-none d-lg-inline"></span></button>
                        </div>
                        <div *ngIf="customFilter && isChartMode();else isTableMode" class="p-5">
                            <cloud-charts #sampleChart *ngIf="customFilter.type == 'chart'"
                                          [customFilter]="customFilter" [chart_index]="0" [type]="customFilter.type">
                            </cloud-charts>
                        </div>
                        <ng-template #isTableMode>
                            <div class="l-fixed-btns d-lg-none">
                                <button class="l-fixed-btns__btn -success"
                                        [ngClass]="{'t2-border': table_info.table === 'dataset' && tutorial_flag === 'false' ? true : false}"
                                        (click)="add()" *ngIf="grant.edit && isInsertable() && !isSummarizeMode"><i
                                    class="fa fa-plus"></i><span class="d-none d-lg-inline"></span></button>
                                <button class="l-fixed-btns__btn -default" (click)="search()"
                                        *ngIf="searchable"><i class="fa fa-search"></i><span
                                    class="d-none d-lg-inline"></span></button>
                            </div>
                            <div id="sticky-table-wrapper" #stickyTableWrapper
                                 [ngClass]="{'sticky-table-wrapper': (_share.admin_setting && _share.admin_setting.scrollable==='true') || data_a.length==0}"
                                 [ngStyle]="{'max-height':'calc(80vh - '+memo_height+'px)','min-height':+getTableMinHeight()+'px'}"
                                 (scroll)="scrollTable($event)">

                                <!-- データメインテーブル-->
                                <admin-tree *ngIf="current_url=='/admin/dataset' && !this.switchCalendarView" [data_a]="data_a"
                                            [isSummarizeMode]="isSummarizeMode" [isShowManageCol]="isShowManageColFunc()"
                                            [onCheckboxChange]="allCheckboxChange"
                                            [table_info]="table_info" [checked_delete_id_a]='checked_id_a'
                                            [checkboxChange]="checkboxChange" [isEditMode]="isEditMode"
                                            [openDeleteModal]="openDeleteModal" [switchGroupEdit]="switchGroupEdit"
                                            [group_edit_data_a]="group_edit_data_a" [is_group_opening]="click_group"
                                            [group_sort_data_a]="group_sort_data_a" [group_edit_data]="group_edit_data"
                                            (openDuplicateModal)="openDuplicateModal($event)"
                                            (onGroupChange)="adminTreeEvent($event)" [toasterService]="toasterService"></admin-tree>
                                <admin-table *ngIf="current_url!='/admin/dataset' && !this.switchCalendarView" [parent]="getThis()"
                                             #table
                                             [adminComponent]="getThis()" [data_a]="data_a" [customFilter]="customFilter"
                                             [sort_params]="sort_params" [table_info]="table_info"
                                             [isSummarizeMode]="isSummarizeMode" [hide_scroll]="hide_scroll"
                                             [isShowManageCol]="isShowManageColFunc()" [openDeleteModal]="openDeleteModal"
                                             (onCheckboxChange)="checkboxChange()" [setSelectedCellId]="setSelectedCellId"
                                             [selectedCellId]="selectedCellId"
                                             [setFormEditData]="setFormEditData" [showFormEditModal]="showFormEditModal"
                                             [closeFormEditModal]="closeFormEditModal" [isEditModalShown]="isEditModalShown"
                                             [isMovable]="isMovable" [onUpDown]="onUpDown"
                                             [onFormatViewData]="onFormatViewData" [reload]="reload"
                                             [onCellDataChanged]="onCellDataChanged" [child_a_by_id]="child_a_by_id"
                                             [sort]="sort" [isEditMode]="isEditMode"
                                             [crossTableHeader]="crossTableHeader"
                                             (onDuplicateSelectedIndex)="duplicateSelectedIndex()"
                                             (onSelectRow)="onSelectRowIndex($event)"
                                             (onAddToDeleteCommit)="deleteModal.show()"
                                             (viewModallistEvent)="viewmodalopen($event)"
                                             (changeSearchValue)="onChangeSearchValue($event)"
                                             (onOpenUnlockModal)="openUnlockModal($event)"
                                             (ctrlClickEvent)="ctrlClickEvent($event)"
                                >

                                </admin-table>
                                <div *ngIf="this.switchCalendarView && table_info">
                                    <admin-calendar-view
                                        [isSummarizeMode]="isSummarizeMode" [isShowManageCol]="isShowManageColFunc()"
                                        [table_info]="table_info" [checked_delete_id_a]='checked_id_a'
                                        [checkboxChange]="checkboxChange" [isEditMode]="isEditMode"
                                        [openDeleteModal]="openDeleteModal" [current_url]="current_url"
                                        [customFilter]="customFilter"></admin-calendar-view>
                                </div>
                            </div>

                            <div class="row card-header margin-left0">
                                <div class="col-3" *ngIf="isEditMode">
                                    <button class="btn btn-success"
                                            *ngIf=" table_info.menu.editable_on_list && !isSummarizeMode && grant.edit"
                                            (click)="appendEmptyDataObject()"><i class="fa fa-plus"></i></button>
                                </div>
                                <ng-container *ngIf="_share.getHeaderDatasetName()!=''">
                                    <div class="col-12">
                                        <h5 class="table-name sp_display">
                                            {{_share.getHeaderDatasetName()}}
                                            <small>合計{{_share.getHeaderDatasetCount()}}件</small>
                                        </h5>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>


                    </div>
                    <div *ngIf="tutorial_flag == 'false'">
                        <!-- tutorial 2 -->
                        <img src="../../assets/img/tutorials/t2-p1.png" *ngIf="(table_info.table== 'dataset')"
                             class="img-fluid t2-image-1 ml-4" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!--/.col-->
    </div>
    <!--/.row-->


    <div bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div *ngIf="deleteModal.isShown" class="modal-dialog modal-danger" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                    <button type="button" class="close" (click)="deleteModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>本当に削除してもよろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">キャンセル</button>
                    <button *ngIf="!isEditMode" type="button" class="btn btn-danger" [ladda]="sending"
                            (click)="delete(modal_data)">削除する
                    </button>
                    <button *ngIf="isEditMode" type="button" class="btn btn-danger"
                            (click)="addSelectedToDelete()">削除する
                    </button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->


    <div bsModal #viewModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true" (onHide)="resetUrl()">
        <div class="modal-dialog modal-default" role="document">
            <div class="modal-content">

                <div class="modal-header ">
                    <h4 class="modal-title">詳細情報</h4>
                    <button type="button" class="close" (click)="viewmodalhide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <!-- <p>本当に削除してもよろしいですか？</p> -->
                    <app-view-modal [data]="modalevent"></app-view-modal>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="copyUrl()">URLをコピー</button>
                    <button type="button" class="btn btn-warning" (click)="goToEdit()">編集</button>
                    <button type="button" class="btn" (click)="viewModal.hide()">閉じる</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <div bsModal #deleteFilterModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
         aria-labelledby="myModalLabel" aria-hidden="true">
        <div *ngIf="deleteFilterModal.isShown" class="modal-dialog modal-danger" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                    <button type="button" class="close" (click)="deleteFilterModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>本当に現在のフィルタを削除してもよろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="deleteFilterModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-danger" (click)="deleteFilter()">削除する</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <div bsModal #truncateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div *ngIf="truncateModal.isShown" class="modal-dialog modal-danger" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                    <button type="button" class="close" (click)="truncateModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>テーブル内のデータが全て削除されます。<br>本当に削除してもよろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="truncateModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-danger" (click)="truncate()">削除する</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <div bsModal #csvModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div *ngIf="csvModal.isShown" class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-upload"></i> CSVアップロード</h4>

                    <button type="button" class="close" (click)="csvModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-danger">
                        ※CSVのヘッダー(1行目)は編集しないでください。
                    </p>
                    <ul>
                        <li>ID列が空の行は新規データとして挿入されます。</li>
                        <li>ID列に入力されているIDと同じIDのデータがある場合、データが更新されます。</li>
                        <li>更新日時、作成日時は自動更新されます。</li>
                        <li>他テーブル参照のデータを更新する場合、参照するデータのID（追加オプション設定で設定している場合はその項目の値）を入力してください。</li>
                    </ul>
                    <p>
                        <button type="button" class="btn btn-outline-primary mr-3" (click)="prepare_csv()"
                                [ladda]="downloading['csv']">
                            <i class="fa fa-download"></i> CSVダウンロード
                        </button>
                        <button type="button" class="btn btn-outline-primary" (click)="downloadCsvForUpload()"
                                [ladda]="downloading['csv']">
                            <i class="fa fa-download"></i> CSVダウンロード（空）
                        </button>
                    </p>

                    <p>
                        <input #inputCsv id="inputCsv" type="file" (change)="changeCsv($event)" accept="text/csv">
                    </p>
                    <div class="checkbox" *ngIf="table_info.menu.show_delete_all && grant.delete">
                        <label>
                            <input [(ngModel)]="resetBeforeCsvUpload" type="hidden" name="show_id">
                            <input type="checkbox" [checked]="resetBeforeCsvUpload=='true'"
                                   (change)="resetBeforeCsvUpload=(resetBeforeCsvUpload=='true')?'false':'true';">
                            アップロード前にデータをリセットする
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="csvModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-primary" [ladda]='sending' (click)="uploadCsv()">アップロード
                    </button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <div bsModal #dlCsvModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div *ngIf="dlCsvModal.isShown" class="modal-dialog modal-default" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-upload"></i> CSVダウンロード</h4>

                    <button type="button" class="close" (click)="dlCsvModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="text-danger">
                        ※CSVアップロードに使用する場合、のヘッダー(1行目)は編集しないでください。<br>
                    </p>
                    <div class="checkbox">
                        <label>
                            <input [(ngModel)]="is_use_filter_for_csv_dl" type="hidden" name="show_id">
                            <input type="checkbox" [checked]="is_use_filter_for_csv_dl"
                                   (change)="is_use_filter_for_csv_dl=(is_use_filter_for_csv_dl)?false:true;">
                            CSVに現在のフィルタを反映する
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="dlCsvModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-primary" [ladda]='sending' (click)="prepare_csv()"> ダウンロード
                    </button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->


    <div bsModal #errorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-danger" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        {{modal_error_title}}</h4>
                    <button type="button" class="close" (click)="errorModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="error-modal-content py-3" [innerHTML]="modal_error_message | safeHtml">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="errorModal.hide()">閉じる</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <toaster-container></toaster-container>


</ng-template>

<div bsModal #jsonModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-upload"></i> JSONアップロード</h4>

                <button type="button" class="close" (click)="hideJSONModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row admin-forms">
                    <label class="col-md-3 form-control-label">
                        グループ名
                        <span class="text"> (任意) </span>
                    </label>
                    <div class="col-md-9 col-lg-7">
                        <input [(ngModel)]="group_name" type="text" class="form-control">
                    </div>

                </div>
                <p>
                    <input #inputJSON id="inputCsv" type="file" (change)="changeJson($event)" accept="text/json">
                </p>
                <!-- <div class="checkbox" *ngIf="table_info.menu.show_delete_all && grant.delete">
                    <label>
                        <input [(ngModel)]="resetBeforeCsvUpload" type="hidden" name="show_id">
                        <input type="checkbox" [checked]="resetBeforeCsvUpload=='true'"
                            (change)="resetBeforeCsvUpload=(resetBeforeCsvUpload=='true')?'false':'true';">
                        アップロード前にデータをリセットする
                    </label>
                </div> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="hideJSONModal()">キャンセル</button>
                <button type="button" class="btn btn-primary" [ladda]='sending' (click)="uploadJSON()">アップロード
                </button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #exportModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">テーブルのエクスポート</h4>
                <button type="button" class="close" (click)="exportModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="grant && grant.edit && missing_datasets.length>0" class="text-danger">
                    ※ 関連テーブルの【{{missing_datasets}}】も含めエクスポートされます。</p>
                <h5>ダウンロードオプション:</h5>
                <br/>
                <div class="checkbox">
                    <label>
                        <input class="ml-2" type="checkbox" name="export_data"
                               (change)="export_data=!export_data"/>
                        エクスポートにデータを含める
                    </label>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="exportModal.hide()">キャンセル</button>
                <a href="{{_connect.getApiUrl()}}/admin/download-json?export_data={{export_data}}&{{query_string}}"
                   target="_blank">
                    <button type="button" (click)="exportModal.hide()" class="btn btn-sm btn-primary ml-2"
                            [disabled]='!export_ready'>
                        {{missing_datasets.length > 0 ? "関連するデータセットをエクスポートする" : "エクスポート"}}
                    </button>
                </a>
                <!-- <button *ngIf="isEditMode" type="button" class="btn btn-danger"
                (click)="addSelectedToDelete()">削除する</button> -->
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
<div bsModal #chartModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <charts-modal [customFilter]="modalCustomFilter" [chart_index]="0" [parent]="getThis()" [save_on_edit]="false"
                  (onClickSaveButton)="onCloseChartModal($event,true)" [show_preview]="true"
                  (onClickCancelButton)="onCloseChartModal($event)" [table_info]="table_info"
                  (onClickPreviewButton)="onClickPreviewButton($event)"></charts-modal>

</div><!-- /.modal -->

<div bsModal #chartTitleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content" *ngIf="customFilter">
            <div class="modal-header">
                <h4 class="modal-title">タイトルを入力して下さい</h4>
                <button type="button" class="close" (click)="chartTitleModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-3 font-weight-bold">
                        タイトル
                    </div>
                    <div class="col-md-9">
                        <input [(ngModel)]="customFilter.name" class="form-control">
                    </div>

                    <div class="col-md-3 font-weight-bold">
                        権限
                    </div>
                    <div class="col-md-9">
                        <div class="checkbox">
                            <label><input type="radio" [(ngModel)]="customFilter.grant"
                                          value="public">&nbsp;全員に表示</label><br>
                            <label><input type="radio" [(ngModel)]="customFilter.grant"
                                          value="private">&nbsp;自分のみ表示</label><br>
                            <label><input type="radio" [(ngModel)]="customFilter.grant" value="custom"
                            >&nbsp;詳細権限設定</label>

                            <ng-container *ngIf="customFilter.grant === 'custom'">
                                <br>
                                <hr>
                                <grant-group-form [value]="customFilter.edit_grant_group_id" [title]="'編集可能ユーザー'" (onChangeValue)="onEditGrantGroupIdChanged($event)"></grant-group-form>
                                <hr>
                                <grant-group-form [value]="customFilter.view_grant_group_id" [title]="'閲覧のみ可能ユーザー'" (onChangeValue)="onViewGrantGroupIdChanged($event)"></grant-group-form>

                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="chartTitleModal.hide()">キャンセル</button>
                <button type="button" class="btn btn-warning" (click)="saveFilter(true)">保存する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->


</div><!-- /.modal -->


<div bsModal #mailPublicFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    testtest
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">公開フォームメールテンプレート作成</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 font-weight-bold">
                        １メールアドレスに対し、１登録に制限する
                        <br>
                        <small>
                            同じメールアドレスが重複してデータに存在する場合は、１度しか送られません。
                        </small>
                    </div>
                    <div class="col-md-6">
                        <label class="switch switch-text switch-pill switch-primary">
                            <input type="checkbox" class="switch-input" [value]="true" [(ngModel)]="mail_public_form_option.use_data_related_form">
                            <span class="switch-label" data-on="On" data-off="Off"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="mailPublicFormModal.hide()">キャンセル</button>
                <button type="button" class="btn btn-success" (click)="createEmailTempate()">作成する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->


</div><!-- /.modal -->


<form-field-edit-modal bsModal #editFormFieldModal *ngIf="formEditData" [form]="formEditData.form"
                       [table_info]="table_info" [field]="formEditData.field" [grant_menu_a]="formEditData.grant_menu_a"
                       [data_index]="formEditData.data_index" [is_setting]="formEditData.is_setting" [data]="formEditData.data"
                       [selectChange]="formEditData.selectChange" [child_a]="formEditData.data.child_a" (onHide)="onHiddenEditModal()"
                       (onEditEnd)="onEditModalChangeEnd($event)"></form-field-edit-modal>


<confirm-modal #exitEditModeModal [submit_button_text]="'はい'" [text]="'編集モードを終了して、変更を保存しますか？'" (onOk)="commit()"
               [sending]="sending"></confirm-modal>
<confirm-modal #cancelEditModeModal [submit_button_text]="'はい'" [text]="'編集モードをキャンセルしますか？'" (onOk)="cancelEdit()"
               [sending]="sending" [type]="'warning'"></confirm-modal>

<confirm-modal #deleteCheckedConfirmModal [submit_button_text]="'削除する'" [text]="'本当に削除してもよろしいですか？'"
               (onOk)="addCheckedToDelete()"></confirm-modal>

<confirm-modal #confirmFilterOverwriteConfirmModal [submit_button_text]="'上書き保存する'"
               [text]="'選択中のフィルタを上書き保存します。よろしいですか？'" (onOk)="saveFilter()" [sending]="sending"></confirm-modal>

<confirm-modal #unlockModal [submit_button_text]="'はい'" [text]="'ロックを強制的に解除しますか？'" (onOk)="unlock()" [type]="'warning'"
               [sending]="sending"></confirm-modal>

<confirm-modal #deleteLedgerConfirmModal [submit_button_text]="'はい'" [text]="'帳票を削除してよろしいですか？'" (onOk)="deleteLedgerTemplate()" [type]="'danger'"
               [sending]="sending"></confirm-modal>
<index-workflow-modal #indexWorkflowModal [table_info]="table_info" [workflow_status]="index_workflow_param.workflow_status" [data_a]="index_workflow_param.data_a"
                      (onOk)="onCompleteWorkflowStatusChange()"></index-workflow-modal>
<!-- <div bsModal #exportModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">テーブルのエクスポート</h4>
                <button type="button" class="close" (click)="exportModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p *ngIf="grant && grant.edit && missing_datasets.length>0" class="text-danger">
                    ※ 関連テーブルの【{{missing_datasets}}】も含めエクスポートされます。</p>
                <h5>ダウンロードオプション:</h5>
                <br />
                <div class="checkbox">
                    <label>
                        <input class="ml-2" type="checkbox" name="export_data" (change)="export_data=!export_data" />
                        エクスポートにデータを含める
                    </label>
                </div> -->


<div bsModal #exportCheckedConfirmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content modal-danger">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </h4>
                <button type="button" class="close" (click)="exportCheckedConfirmModal.hide()"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>本当に削除してもよろしいですか？</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        (click)="exportCheckedConfirmModal.hide()">キャンセル
                </button>
                <button type="button" class="btn btn-danger"
                        (click)="addCheckedToDelete()">削除する
                </button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->


</div>


<div bsModal #tableSettingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <div *ngIf="tableSettingModal.isShown" class="modal-dialog modal-default" role="document">
        <div class="modal-content modal-default">
            <div class="modal-header">
                <h4 class="modal-title">通知設定</h4>
                <button type="button" class="close" (click)="tableSettingModal.hide()"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="table_info">
                <edit-component [_id]="table_info.admin_table_setting.id" [_table]="'admin_table_setting'" [IS_EMBED_MODE]="true" [submit_text]="'設定'"
                                (onSubmit)="onSubmitAdminTableSetting()" (onCancel)="tableSettingModal.hide()"></edit-component>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>


<div bsModal #confirmFilterOverwriteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
    <div *ngIf="confirmFilterOverwriteModal.isShown" class="modal-dialog modal-success" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </h4>
                <button type="button" class="close" (click)="confirmFilterOverwriteModal.hide()"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>選択中のフィルタを上書き保存します。よろしいですか？</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        (click)="confirmFilterOverwriteModal.hide()">キャンセル
                </button>
                <button type="button" class="btn btn-success" (click)="saveFilter()">上書き保存する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #embedModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-defalut" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">埋め込みフォームリンク</h4>
                <button type="button" class="close" (click)="embedModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="p-3">
                    ※公開フォームでは、権限設定は無視され、フィルタの表示項目はすべて入力可能となります。
                    <div *ngIf="customFilter && customFilter.show_fields.length==0" class="text-danger">
                        ※表示項目を設定していません。<b>全ての項目</b>は権限に関係なく入力可能となります。<br>

                    </div>
                </div>
                <div class="p-3 text-center" style="background: #eee;">
                    {{getEmbedScriptTag()}}
                </div>
                <button type="button" class="btn btn-default pull-right mt-1"
                        (click)="copyEmbedScriptTag()">コピー
                </button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        (click)="embedModal.hide()">閉じる
                </button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<div bsModal #publicFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-defalut" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">公開フォームリンク</h4>
                <button type="button" class="close" (click)="publicFormModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="p-3">
                    ※公開フォームでは、権限設定は無視され、フィルタの表示項目はすべて入力可能となります。
                    <div *ngIf="customFilter && customFilter.show_fields.length==0" class="text-danger">
                        ※表示項目を設定していません。<b>全ての項目</b>は権限に関係なく入力可能となります。<br>

                    </div>
                </div>
                <div class="p-3 text-center" style="background: #eee;">
                    {{public_form_url}}
                </div>
                <button type="button" class="btn btn-primary pull-right mt-1 ml-2"
                        (click)="openPublicFormUrl()">開く
                </button>
                <button type="button" class="btn btn-default pull-right mt-1"
                        (click)="copyPublicFormUrl()">コピー
                </button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                        (click)="publicFormModal.hide()">閉じる
                </button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal #duplicateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">テーブルの複製</h4>
                <button type="button" class="close" (click)="duplicateModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4">
                <div class="row">

                    <div class="col-md-3 font-weight-bold ">
                        グループ
                    </div>
                    <div class="col-md-9 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="duplicate_hash['group']">
                    </div>
                    <div class="col-md-3 font-weight-bold">
                        テーブル名 (必須)
                    </div>
                    <div class="col-md-9 mb-2">
                        <input type="text" class="form-control" [(ngModel)]="duplicate_hash['label']">
                    </div>

                    <div class="col-md-3 font-weight-bold">
                        オプション
                    </div>
                    <div class="col-md-9">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="duplicate_hash['copy_grant']" [value]="true">&nbsp;権限設定をコピー
                            </label>
                            <br>
                            <label>
                                <input type="checkbox" [(ngModel)]="duplicate_hash['copy_notification']" [value]="true">&nbsp;通知設定をコピー
                            </label>
                            <br>
                            <label>
                                <input type="checkbox" [(ngModel)]="duplicate_hash['copy_filter']" [value]="true">&nbsp;フィルタをコピー
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="duplicateModal.hide()" [ladda]="loading">キャンセル</button>
                <button type="button" class="btn btn-warning" (click)="copyTable()" [ladda]="loading">保存する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->

</div><!-- /.modal -->

<admin-tsv-import #adminTsvImport></admin-tsv-import>
<ng-container *ngIf="_share.isMasterUser()">
    <admin-ledger-import (onImported)="onImportLedger()" #adminLedgerImport></admin-ledger-import>
</ng-container>
<router-outlet></router-outlet>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title">ZIPファイルのアップロード</h4>
    </div>
    <div class="modal-body">
        <div>
            フォルダ構成は下記を参考に作成して下さい。<br>
            <a href="https://help.pigeon-fw.com/documentation/record_common/zip_upload.html">マニュアル</a>
        </div>
        <div class="form-group row">
            <div class="col-md-12">
                <input type="file" class="file-upload" accept=".zip,.rar,.7zip" id="zip_upload" (change)="onFileSelected($event)" #fileUpload>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <button type="button" class="btn btn-primary" id="zip_upload_btn" (click)="upload_zip_submit()">Upload</button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
    </div>
</ng-template>
