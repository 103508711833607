import {Base} from './Base';
import {Data} from './Data';
import {TableInfo} from './TableInfo';

export class RelationTable extends Base {

    private _label: string;
    private _table: string;
    private _data_a: Array<Data>
    private _view_mode: boolean = false;

    private _view_fieldname_a:Array<string>;

    constructor(hash) {
        super(hash)
        for (const key of Object.keys(hash)) {
            if (key == 'data_a') {
                this._data_a = hash[key].map((_datahash) => {
                    let _data = new Data(new TableInfo(hash['table_info']));
                    _data.setInstanceData(_datahash)
                    return _data
                })
                continue;

            }
            this['_' + key] = hash[key];
        }
    }

    get label(): string {
        return this._label;
    }

    get data_a(): Array<Data> {
        return this._data_a;
    }

    get table_info(): TableInfo {
        return this._table_info;
    }

    get table(): string {
        return this._table;
    }

    get view_mode(): boolean {
        return this._view_mode;
    }

    getViewFields(_table_info: TableInfo): Array<any> {
        if (!_table_info) {
            return []
        }
        return _table_info.fields.filter((field) => {
            return this._view_fieldname_a.indexOf(field['Field']) >= 0;
        });
    }
}
