<!-- テーブル定義画面のみ -->
<div class="row" *ngIf="is_dataset_edit" id="pc-edit-view" [ngClass]="table_info.getJaClassName()">
    <div class="col-lg-12" id="dataset_definition">
        <dataset-form [is_system_table]="isEditSystemTable()" [system_table_info]="system_table_info" [data]="data" [data_lastchanged]="data.last_dirty_changed"
                      [workflow_template_a]="workflow_template_a" [parent]="getThis()"></dataset-form>
        <div class="my-2">
            <button *ngIf="dataset_edit_mode=='dataset_field'" class="btn btn-success" type="button"
                    (click)="openSettingModal(null,true)">
                <i class="fa fa-plus mr-1"></i>項目を追加する
            </button>
            <button *ngIf="dataset_edit_mode=='grant'" class="ml-2 btn btn-default" type="button"
                    (click)="dataset_edit_mode='dataset_field'">
                &lt;&lt;&nbsp;項目設定に戻る
            </button>
            <ng-container *ngIf="dataset_edit_mode=='dataset_field' && (mode=='add' || (target_table_grant && target_table_grant.table_author))">
                <button class="ml-2 btn btn-default" type="button"
                        (click)="dataset_edit_mode='grant'">
                    <i class="fa fa-gear"></i> 権限設定
                </button>
            </ng-container>
        </div>
        <div bsModal #settingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel" [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{settingTitle}}</h4>
                        <button type="button" class="close" (click)="settingModal.hide()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row text-center" *ngIf="settingType==null">
                            <ng-container *ngFor="let type of this.settingTypes;let child_i = index">
                                <div *ngIf="(this.type.show_list!==false && this.settingType==null)"
                                     class="col-sm-6 mb-3">
                                    <button
                                        [disabled]="(settingType == null && (isExistFieldSelected() && !canConvertField(type.value))) "
                                        class="btn btn-lg btn-block btn-light-gray"
                                        type="button"
                                        (click)="selectFieldType(type)"><i class="fa fa-{{type.icon}}"></i> <span> {{
                                        type.label }}</span></button>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="this.settingType!=null">
                            <div class="pl-4">
                                <button class="btn btn-md btn-default mb-2" (click)="changeFieldType()"><i class="fa fa-arrow-circle-left mr-1"></i>項目タイプ変更</button>
                                <div class="flex">
                                    <h5 class="">{{this.settingType != null ? getSettingTypeObj().label : ''}}</h5>
                                </div>
                                <div *ngIf=" this.settingType!=null &&   getSettingTypeObj().explain" class="text-left mt-3" [innerHTML]="getSettingTypeObj().explain">
                                </div>
                            </div>
                            <div class="pl-4 mt-4">
                                <dataset-field-one [system_table_info]="system_table_info" [data]="settingData"
                                                   [type]="settingType" [parent]="getThis()"
                                                   [mode]="(!!settingData.raw_data['id'])?'edit':'add'"></dataset-field-one>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="this.settingType!=null;else fieldTypeSettingMode" class="modal-footer">
                        <button type="button" class="btn btn-secondary text-bold" (click)="settingModal.hide()">キャンセル</button>
                        <button type="button" class="btn btn-primary text-bold"
                                (click)="addFieldData()">{{settingBtnLabel}}</button>
                    </div>
                    <ng-template #fieldTypeSettingMode>
                        <ng-container *ngIf="isExistFieldSelected()">
                            <button type="button" class="btn btn-secondary"
                                    (click)="settingType = settingData.raw_data['type']">キャンセル
                            </button>
                        </ng-container>

                    </ng-template>
                </div>
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</div>
<!-- /テーブル定義画面のみ END-->

<ng-container *ngIf="full_display_message;else NOT_DISPLAY_MESSAGE">
    <div class="row pc-edit-view" id="pc-edit-view" [ngClass]="{'iframe':IS_IFRAME_MODE,'public-form':IS_PUBLIC_FORM}" [ngClass]="table_info.getJaClassName()">
        <div class="col-lg-12">
            <div class="card" [ngClass]="{'mb-0':IS_EMBED_MODE}">
                <div class="message ">
                    {{full_display_message}}
                </div>
            </div>
        </div>

    </div>

</ng-container>

<ng-template #NOT_DISPLAY_MESSAGE>

    <div class="row pc-edit-view" id="pc-edit-view" [ngClass]="setClasses()">
        <div class="col-lg-12" *ngIf="before_html!=undefined">
            <div class="card card-accent-primary">
                <div class="card-block" [innerHTML]="before_html | safeHtml"></div>
            </div>
        </div>
        <memo class="col-lg-12" [table_info]="table_info" [view_name]="'edit'"></memo>
        <div class="col-lg-12">
            <div class="card" [ngClass]="{'mb-0':IS_EMBED_MODE}">
                <div class="card-header" *ngIf="!IS_IFRAME_MODE && !IS_EMBED_MODE">
                    <a href="javascript:void(0)" (click)="goList()"><i class="fa fa-th-list"></i> {{page_title}}一覧</a>&ngsp;<i class="fa fa-chevron-right ml-2" aria-hidden="true"></i> <span
                    *ngIf="mode=='edit';else IsNew">編集 (ID:{{id}})</span>
                    <ng-template #IsNew>追加</ng-template>

                    <span class="ml-3">
                    <custom-filter-pulldown *ngIf="hasEditFilter() && !IS_IFRAME_MODE"
                                            [mode]='"edit"'
                                            [table_info]="table_info"
                                            [customFilter]="customFilter"
                                            (onReset)="resetFilter()"
                                            (onSelectFilter)="selectFilter($event)"

                    ></custom-filter-pulldown>
                </span>
                </div>
                <div class="card-block">

                    <div *ngIf="IS_PUBLIC_FORM && table_info" class="iframe-ex">
                        <h2 class="iframe-title">{{table_info.menu.public_form_title}}</h2>
                        <div class="iframe-description">{{table_info.menu.public_form_description}}</div>
                    </div>

                    <div *ngIf="loading;then loading_block else loaded_block"></div>
                    <ng-template #loading_block>
                        <div class="sk-circle">
                            <div class="loader">Loading...</div>
                        </div>
                    </ng-template>
                    <ng-template #loaded_block>
                        <ng-container *ngIf="data==null">
                            <div class="data_not_found" style="padding:40px;text-align:center">
                                DATA NOT FOUND
                            </div>
                        </ng-container>
                        <ng-container *ngIf="data!=null">
                            <div *ngIf="is_dataset_edit && data.child_data_by_table['dataset_field'].length==0 && dataset_edit_mode!='grant'"
                                 class="p-3">項目がありません。
                            </div>


                            <!-- MAIN FORM START -->
                            <ng-container>
                                <form enctype="multipart/form-data" class="form-horizontal" [hidden]="is_dataset_edit && dataset_edit_mode!='dataset_field'">
                                    <admin-forms [customFilter]="customFilter" [table_info]="table_info" [fields]="fields" [data]="data"
                                                 [dataStructured]="dataStructured"
                                                 [forms]="forms" [grant_menu_a]="grant_menu_a" [error_a]="error_a" [mode]="mode"
                                                 [is_setting]="is_setting"
                                                 [is_custom_table_definition]="is_dataset_edit" [parent]="getThis()"
                                                 [is_add_new_button_select_other_table]="!IS_IFRAME_MODE && is_add_new_button_select_other_table"
                                                 [IS_IFRAME_MODE]="IS_IFRAME_MODE"
                                                 (rendered)="setDataStructured($event)" (valueChanged)="changed()"
                                    >
                                    </admin-forms>

                                </form>
                            </ng-container>
                            <!-- MAIN FORM END -->


                            <!-- CHILD FORM START -->
                            <div class="grant_patterns ml-2" *ngIf="(is_dataset_edit &&  dataset_edit_mode=='grant') || table_info.table==='dataset_group'">
                                <div class="flex">
                                    <div class="col-sm-2" *ngIf="table_info.table === 'dataset_group'">
                                        <button class="quick-button active" [ngClass]="{'active':dataset_grant_type=='none'}" (click)="changeGrantType('none')">
                                            <i class="fa fa-ban"></i>
                                            <p>無し</p>
                                        </button>
                                    </div>
                                    <div class="col-sm-2" *ngIf="isDatasetEdit()">
                                        <button class="quick-button active" [ngClass]="{'active':dataset_grant_type=='me'}" (click)="changeGrantType('me')">
                                            <i class="fa fa-user"></i>
                                            <p>作成者のみ</p>
                                        </button>
                                    </div>
                                    <div class="col-sm-2">
                                        <button class="quick-button" [ngClass]="{'active':dataset_grant_type=='everyone'}" (click)="changeGrantType('everyone')">
                                            <i class="fa fa-group"></i>
                                            <p>全員編集可能</p>
                                        </button>
                                    </div>

                                    <div class="col-sm-2">
                                        <button class="quick-button" [ngClass]="{'active':dataset_grant_type=='custom'}" (click)="changeGrantType('custom')" [disabled]="mode=='add'">
                                            <i class="fa fa-gear"></i>
                                            <p>詳細設定</p>

                                        </button>
                                    </div>
                                </div>
                                <div class="mt-4 pl-2" *ngIf="is_dataset_edit && !this.id">
                                    ※詳細設定は一度保存後に設定可能です。
                                </div>
                            </div>
                            <ng-container *ngFor="let child of data.child_a;let child_i = index">
                                <div class="mt-4"
                                     *ngIf="child.is_child_form && ((isDatasetOrGroupEdit() && ( (child_i!=0 &&dataset_edit_mode=='grant') || !is_dataset_edit) && mode!='add' && dataset_grant_type=='custom') || !isDatasetOrGroupEdit())"
                                     id="child-{{child.table}}">
                                    <hr>
                                    <ng-template [ngIf]="child.menu && child.menu.multiple_mode=='normal'">
                                        <h4 style="    padding: 10px; margin: 20px;"><span class="pt-1">{{child.getLabel()}}</span>
                                            <button class="btn btn-success ml-3 btn-sm" type="button" style="margin-top: -3px;"
                                                    (click)="addChildData(child)">
                                                <i class="fa fa-plus mr-1"></i>追加する

                                            </button>
                                        </h4>
                                        <div
                                            *ngFor="let c_data of data.child_data_by_table[child.table];let i = index"
                                            [attr.data-index]="i" class="child-container">
                                            <button class="btn btn-default btn-sm"
                                                    (click)="orderChange(data.child_data_by_table[child.table],i,i-1)"
                                                    *ngIf="child.forms.byFieldName(child.order_field)!=undefined">上へ
                                            </button>
                                            <button class="btn btn-default btn-sm"
                                                    (click)="orderChange(data.child_data_by_table[child.table],i,i+1)"
                                                    *ngIf="child.forms.byFieldName(child.order_field)!=undefined">下へ
                                            </button>

                                            <button class="btn btn-sm btn-danger pull-right"
                                                    (click)="deleteChild(child,i)">
                                                削除
                                            </button>

                                            <div *ngIf="child.forms as forms">
                                                <admin-forms [parent]="getThis()" [table_info]="child"
                                                             [dataStructured]="dataStructured"
                                                             [fields]="child.fields" [forms]="forms" [data]="c_data"
                                                             [error_a]="child_error_a_by_table[child.table][i]?child_error_a_by_table[child.table][i]:{}"
                                                             [mode]="mode"
                                                             [is_custom_table_definition]="is_dataset_edit"
                                                             [child_index]="child_i"
                                                             (rendered)="setDataStructured($event)"
                                                ></admin-forms>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </ng-container>
                            <!-- CHILD FORM END -->


                        </ng-container>
                    </ng-template>
                </div>
                <div class="card-footer text-right" *ngIf="data!=null && table_info!=undefined && !loading">
                    <button *ngIf="!IS_IFRAME_MODE" class="btn btn-default btn-ladda" data-style="expand-right" [ladda]='sending' type="button" (click)="cancel()">
                        キャンセル
                    </button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary btn-ladda" data-style="expand-right" [ladda]='sending' type="submit"
                            (click)="go_edit()" [ngClass]="{'btn-warning':table_info.menu.is_workflow && !IS_IFRAME_MODE}">
                        <div *ngIf="isWorkflowApplyAvailable();then workflow_block;else non_workflow_block"></div>
                        <ng-template #workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE">
                                <span>保存</span>
                            </ng-container>
                            <ng-container *ngIf="!IS_IFRAME_MODE">
                                <span>下書き保存</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #non_workflow_block>
                            <ng-container *ngIf="IS_IFRAME_MODE || IS_EMBED_MODE;else NOT_IFRAME">
                                <span>{{submit_text}}</span>
                            </ng-container>
                            <ng-template #NOT_IFRAME>
                                <span *ngIf="mode=='add'">登録</span>
                                <span *ngIf="mode=='edit'">更新</span>
                            </ng-template>
                        </ng-template>
                    </button>
                    &nbsp;
                    <button class="btn btn-primary btn-ladda" data-style="expand-right" [ladda]='sending' type="submit"
                            (click)="workflow_apply_start()" *ngIf="isWorkflowApplyAvailable() && !IS_IFRAME_MODE">
                        <span>申請</span>
                    </button>
                </div>
            </div>
        </div>
        <!--/.col-->
    </div>
    <!--/.row-->
    <div class="d-none d-sm-block" *ngIf="isQuestionnaireStyle()" style="position: fixed;right:10px;bottom:10px;">
        <button type="button" class="btn btn-primary" (click)="go_edit()" [ladda]='sending'>保存する</button>
    </div>
    <div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-warning" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                    <button type="button" class="close" (click)="editModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p *ngIf="mode=='add'">本当に追加してもよろしいですか？</p>
                    <p *ngIf="mode=='edit'">本当に更新してもよろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="editModal.hide()">キャンセル</button>
                    <button type="button" class="btn btn-warning" *ngIf="mode=='add'" (click)="add()">追加する</button>
                    <button type="button" class="btn btn-warning" *ngIf="mode=='edit'" (click)="edit()">更新する</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->


</ng-template>

<ng-container *ngIf="table_info && table_info.menu && table_info.menu.is_workflow">
    <div bsModal #worflowModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
        <workflow-modal [workflow_template]="workflow_template" [workflow_base]="workflow"
                        (valueChanged)="workflowChanged($event)" (applied)="workflow_store()" [parent]="getThis()"
                        [sending]="sending"></workflow-modal>

    </div><!-- /.modal -->
</ng-container>

<ng-container *ngIf="table_info && table_info.menu && table_info.menu.popup_comment_after_save">
    <div bsModal #commentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
        <comment-modal #commentModalComponent (valueChanged)="commentChanged($event)" (applied)="store()"
                       [parent]="getThis()" [sending]="sending"></comment-modal>

    </div><!-- /.modal -->
</ng-container>


<confirm-modal #publicFormConfirmModal [submit_button_text]="'はい'" [text]="'送信してよろしいですか？'" (onOk)="store()"
               [sending]="sending" [is_iframe]="true"></confirm-modal>
