import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Connect} from '../services/connect';
import {SharedService} from '../services/shared';
import ToastrService from '../toastr-service-wrapper.service';
import {ActivatedRoute} from '@angular/router';


declare var paypal;

@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.css']
})
export class PaymentPageComponent implements OnInit {

    private view_terms_and_conditions;
    public quantity = 5;
    public user_num = 5;
    public price = 5500;

    public is_update: boolean = false;

    public min_user_num: number = 5;
    public max_user_num: number = 3000;
    public is_valid_num: boolean = true;
    public agreed: boolean = false;

    private paypal_id: string;

    public loading: boolean = true
    public sentRequest: boolean = false
    public sending: boolean = false
    private toasterService: ToastrService;
    private is_debug: boolean;

    public updated_status: string = null;

    @ViewChild('paypal') paypalElement: ElementRef;

    @ViewChild('updatePaymentModal') updatePaymentModal: any;

    constructor(public _connect: Connect, public _share: SharedService, toasterService: ToastrService, private _route: ActivatedRoute) {
        this.toasterService = toasterService
    }

    public getPaypalButtonId(): string {
        return 'paypal-button-container-' + this.paypal_id;

    }


    ngOnInit(): void {
        this.loading = true;
        this.approve_url = null;
        console.info(this._share.env)

        this._route.params.subscribe(params => {
            this.updated_status = params.update_status
            if (this.updated_status) {
                this.sentRequest = true
                this.is_update = true;
            }
        });
        this._share.loadAdminDatas().then((_shared: SharedService) => {
            console.info(_shared.env)
            this.is_debug = _shared.env !== 'production'
            this.is_update = !_shared.isTrial()
            this.quantity = _shared.cloud_setting['max_user']
            this.onQuantityChange(this.quantity.toString())
            if (this.is_debug) {
                //DEBUG_MODE
                console.info('SANDBOX MODE=======')
                this.paypal_id = 'P-5FT4600364607682RMKI43YY'
            } else {
                //PRODUCTION
                this.paypal_id = 'P-2UR090042T367892LMJ2FTVI'

            }

            this.loading = false;

            //add paypal js
            const script = document.createElement('script');
            script.async = true;
            if (this.is_debug) {
                script.src = 'https://www.paypal.com/sdk/js?client-id=AaMHbDxm495QgOHvljWKfxsZMsVQX6S0VcuFFH2TpzyowUPQiMydYptoojLduoVJqEGpzBJVIgJf0mFp&vault=true&intent=subscription';
            } else {
                script.src = 'https://www.paypal.com/sdk/js?client-id=AVJ6nz2eqkJyaK7AWdXhq2aq-DjFXdjDUqBg-mADeHvzL78LKA8relziCxJSSLBKZD04ju-qhSs-TBLB&vault=true&intent=subscription';
            }

            const div = document.getElementById('paypal_script');
            div.insertAdjacentElement('afterend', script);


            if (this.is_update) {

            } else {
                setTimeout(() => {
                    if (!document.getElementById(this.getPaypalButtonId())) {
                        return
                    }


                    this._connect.get(this._connect.getApiUrl() + '/admin/showPaymentPage').subscribe((response) => {
                        var app = this;
                        let inter = setInterval(() => {
                            console.log('check')
                            if (paypal !== undefined) {
                                clearInterval(inter)
                                paypal.Buttons({
                                    style: {
                                        shape: 'pill',
                                        color: 'white',
                                        layout: 'vertical',
                                        label: 'subscribe'
                                    },
                                    createSubscription: (data, actions) => {
                                        return actions.subscription.create({
                                            /* Creates the subscription */
                                            plan_id: app.paypal_id,
                                            quantity: app.user_num // The quantity of the product for a subscription
                                        });
                                    },
                                    onApprove: (data, actions) => {
                                        app._connect.post(app._connect.getApiUrl() + '/admin/paymentSuccess',
                                            {
                                                facilitatorAccessToken: data.facilitatorAccessToken,
                                                orderID: data.orderID,
                                                paymentSource: data.paymentSource,
                                                subscriptionID: data.subscriptionID,
                                                user_num: this.user_num
                                            }
                                        ).subscribe((payment_response) => {
                                            this.sentRequest = true
                                        })

                                    }
                                }).render('#' + this.getPaypalButtonId()); // Renders the PayPal button
                            }

                        }, 300)

                    }, (error) => {
                        window.location.replace('/admin/dashboard');
                    })

                }, 300)

            }

        })
    }


    onQuantityChange(input_qty_string: string): void {
        if (this.quantity < this.min_user_num) {
            this.is_valid_num = false;
            return;
        } else if (this.quantity > this.max_user_num) {
            this.is_valid_num = false;
            return;
        }


        this.is_valid_num = true;
        this.user_num = this.quantity;
        let input_qty = parseInt(input_qty_string);

        if (input_qty < 11) {
            if (input_qty < 5) {
                this.price = 5000;
                this.user_num = 5;
            } else {
                this.price = input_qty * 1000;
            }
        } else if (input_qty < 26) {
            this.price = 10000 + (input_qty - 10) * 500;
        } else if (input_qty < 101) {
            this.price = 17500 + (input_qty - 25) * 400;
        } else if (input_qty > 100) {
            this.price = 47500 + (input_qty - 100) * 300;
        }

        //消費税
        this.price *= 1.1;

    }

    public approve_url: string = null;
    sendUpdateRequest() {
        this.sending = true
        this._connect.post('/admin/paymenUpdate', {'user_num': this.user_num}).subscribe(res => {
            console.log(res)
            if (res.approve_url) {
                this.toasterService.success('契約更新依頼を送信しました.URLリンクより支払い設定を行って下さい。')
                this.approve_url = res.approve_url;
            } else {
                this.toasterService.success('契約更新依頼を送信しました')
            }
            this.sending = false;
            this.updatePaymentModal.hide()
            this.sentRequest = true
        })

    }

    open_approval() {
        location.href = this.approve_url;
    }


}
