import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Data} from '../class/Data';
import {TableInfo} from '../class/TableInfo';
import {Grant} from '../class/Grant';
import {Connect} from 'app/services/connect';
import {Router} from '@angular/router';

@Component({
    selector: 'admin-tree',
    templateUrl: './admin-tree.component.html',
})
export class AdminTreeComponent implements OnChanges {
    @Input() data_a: Array<Data>;
    @Input() checked_delete_id_a: Array<number> = []
    @Input() export_data: boolean;
    @Input() toasterService;
    @Input() isSummarizeMode: boolean = false;
    @Input() isShowManageCol: boolean = false;
    @Input() is_relation_table: boolean = false;
    @Input() checkboxChange: Function;
    @Input() table_info: TableInfo;
    @Input() isEditMode: boolean;
    @Input() openDeleteModal: Function;
    @Input() switchGroupEdit: boolean;
    @Input() group_edit_data_a;
    @Input() is_group_opening: { [group: string]: boolean };
    @Input() group_edit_data;
    @Input() group_sort_data_a;
    @Input() onCheckboxChange: Function;
    @Output() onGroupChange = new EventEmitter();
    @Output() openDuplicateModal = new EventEmitter();

    @ViewChild('relationConfirmModal') relationConfirmModal: any;
    private relationChecked = 'false';


    public grant: Grant;
    public sorted_data_a: Array<Data> = [];
    private new_group_count: number = 1;
    private _connect: Connect;
    public second_stages: Array<string> = [];
    public second_stages_id: Array<String> = [];

    constructor(_connect: Connect, private _router: Router) {
        this._connect = _connect;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data_a) {
            this.sortGroupDataset();
            let i = 0;
            this.group_edit_data.forEach(element => {
                i++;
                this.second_stages.push('second-stage-' + i);
                this.is_group_opening[element.group] = false
            });
            this.second_stages.push('first-stage');
            this.second_stages.push('remove_container');
            this.grant = this.table_info.grant;
            this.changeAllGroupOpenClose(true)
        }
    }

    sortGroupDataset() {
        //order順にdata_aを並び替える

        //without this line is a bug when u call reload page in admin.component.ts line 1081
        this.group_edit_data = [];

        this.sorted_data_a = this.data_a;
        this.sorted_data_a.sort(function (a, b) {
            var keyA = Number(a['raw_data']['order']),
                keyB = Number(b['raw_data']['order']);
            // Compare the 2 dates
            if (keyA < keyB) {
                return -1;
            }
            if (keyA > keyB) {
                return 1;
            }
            return 0;
        });

        this.sorted_data_a.forEach((dataset, key) => {
            let group_next_index: number = this.group_edit_data.length;
            if (dataset['raw_data']['group'] == null) {
                this.group_edit_data[group_next_index] = dataset;
            } else if (key != 0 && this.isKey(dataset['raw_data']['group']).is_key) {
                this.group_edit_data[this.isKey(dataset['raw_data']['group']).group_id]['data_a'].push(dataset);
            } else {
                this.group_edit_data[group_next_index] = [];
                this.group_edit_data[group_next_index]['group'] = dataset['raw_data']['group'];
                this.group_edit_data[group_next_index]['data_a'] = [];
                this.group_edit_data[group_next_index]['data_a'].push(dataset);
                //make formcontrol
                this.group_edit_data[group_next_index]['formControl'] = new FormControl(dataset['view_data']['group']);
                this.group_edit_data[group_next_index]['group_id'] = dataset['raw_data']['dataset_group_id'];
            }
        });

        // second-stageのidを作成
        this.group_edit_data.forEach((data, key) => {
            this.second_stages_id[key] = 'second-stage-' + key;
        });

        // console.log('sorted_data_a');
        // console.log(this.sorted_data_a);
    }

    openRelationConfirmModal() {
        this.relationConfirmModal.show();
    }

    hideRelationConfirmModal() {
        this.relationConfirmModal.hide();

    }

    isKey(group_name: string) {
        let search_results: { is_key: boolean, group_id: number } = {is_key: false, group_id: 0};
        for (let i = 0; i < this.group_edit_data.length; i++) {
            if (this.group_edit_data[i]['group'] == group_name) {
                search_results.is_key = true;
                search_results.group_id = i;
            }
        }
        return search_results;
    }

    clickGroup(key: string) {
        this.is_group_opening[key] = !this.is_group_opening[key];
    }

    clickGroupAdd() {
        while (this.isKey('新規グループ' + this.new_group_count).is_key) {
            this.new_group_count++;
        }
        let new_group_edit_data_one = new Array();
        new_group_edit_data_one['group'] = '新規グループ' + this.new_group_count;
        new_group_edit_data_one['data_a'] = [];
        new_group_edit_data_one['formControl'] = new FormControl('新規グループ' + this.new_group_count);
        new_group_edit_data_one['group_id'] = this.getNewRandomId();
        this.group_edit_data.push(new_group_edit_data_one);
        this.new_group_count = 1;
    }

    private getNewRandomId() {
        var number = Math.random() // 0.9394456857981651
        number.toString(36); // '0.xtis06h6'
        var id = number.toString(36).substr(2, 14); // 'xtis06h6'
        return 'new_' + id;
    }

    groupChange() {
        this.onGroupChange.emit({'group_edit_data': this.group_edit_data});
    }


    firstDrop(event: CdkDragDrop<Array<Data>>) {
        console.log('firstDrop');
        console.log(event);

        if (event.previousContainer == event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        this.onGroupChange.emit({'group_edit_data': this.group_edit_data});

    }

    secondDrop(event: CdkDragDrop<Array<Data>>) {
        console.log('secondDrop');
        console.log(event);

        if (event.previousContainer == event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (event.previousContainer != event.container && event.previousContainer.data[event.previousIndex]['group'] == undefined) {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        } else if (event.previousContainer != event.container && event.previousContainer.data[event.previousIndex]['group'] != undefined) {
            this.toasterService.error('この操作はできません', 'エラー');
        }
        this.onGroupChange.emit({'group_edit_data': this.group_edit_data});
    }

    parseIntCo(id) {
        return parseInt(id);
    }

    removeFromGroup(event: CdkDragDrop<Array<Data>>) {
        transferArrayItem(event.previousContainer.data,
            this.group_edit_data,
            event.previousIndex,
            100000);  //最後に追加
    }

    public changeAllGroupOpenClose(is_open = true) {
        this.group_edit_data.forEach(data => {
            this.is_group_opening[data.group] = is_open
        })

    }

    getLink(data) {
        if (data['raw_data']['system_table']) {
            return '/admin/' + data['raw_data']['system_table']
        }
        return '/admin/dataset__' + data['raw_data']['id'];
    }

    copy(data: Data) {
        this.openDuplicateModal.emit({
            'duplicate_data': data
        });
        //this._router.navigate(['admin', 'dataset', 'edit', 'new', {ref: data['raw_data']['id']}]);
    }

    goGroupEdit($event, group) {
        $event.stopPropagation()
        console.log(group)
        this._router.navigate(['admin', 'dataset_group', 'edit', group['group_id']]);
    }

    allCheck(event,group_id) {
        let checked = event.target.checked
        const checkbox_a = document.getElementsByName('data_check_one');
        let group_data = [];
        for (let j = 0; j < this.group_edit_data.length; j ++){
            if (this.group_edit_data[j]['group_id']==group_id) {
             group_data = group_data.concat(this.group_edit_data[j]['data_a']);
                break;
            }
        }
        for (let k =0; k < group_data.length; k++) {
            for (let i = 0; i < checkbox_a.length; i++) {
            if(group_data[k]['raw_data']['id']==checkbox_a[i]['defaultValue']){
            const checkbox = <HTMLInputElement>checkbox_a[i];
            checkbox.checked = checked
            }
        }
    }
        this.onCheckboxChange();
    }

    openClose(event){
            event.stopPropagation();
    }
}


