import {NgModule} from '@angular/core';
import 'rxjs/Rx';
import {Connect} from './connect';

@NgModule({
    imports: [],
    providers: [Connect],
    declarations: []
})
export class CL {
    constructor(private _connect: Connect) {
    }

    cbl() {
        return new Promise((resolve, reject) => {
            resolve({
                'result': 'success',
                'client': {'froala_key': '4A8D5F4F6G5D4bA2B6C4E4F4B2E2C3I2C1uENARBFSTb2D1QJd1RA==', 'expired': '2050-11-11 00:00:00'}
            });
        });
    }
}
