<div class="modal-dialog modal-default" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">
                申請

            </h4>
            <button type="button" class="close" (click)="close()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-4 font-weight-bold">
                    承認ルート
                </div>
                <div class="col-md-8 mb-3">
                    <span *ngIf="isWorkflowTemplateExist() && !isWorkflowTemplatePathExist()">
                        合致するワークフローテンプレートの条件がないため、申請できません
                    </span>
                    <workflow-setting [disabled]="isWorkflowTemplateExist()" [workflow]="isWorkflowTemplateExist()?workflow_template.workflow:workflow"
                                      (valueChanged)="onWorkflowChanged($event)"></workflow-setting>
                </div>

                <div *ngIf="!isWorkflowTemplateExist()" class="col-md-12 text-center" style="margin-top:10px;" (click)="add()">
                    <button class="btn btn-success"><i class="fa fa-plus"></i></button>
                </div>

                <div class="col-md-4 font-weight-bold">
                    申請コメント（任意）
                </div>
                <div class="col-md-8 mt-3">
                    <div class="col-md-12 row">
                        <div class="col-md-10">
                            <textarea class="form-control" [(ngModel)]="workflow_comment" (change)="changed()"></textarea>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="close()">キャンセル</button>
            <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="isWorkflowTemplateExist() && !isWorkflowTemplatePathExist()" [ladda]="sending">申請する</button>
        </div>
    </div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
