import {ChangeDetectorRef, Component, Input, OnInit, NgModule, ViewChild, ElementRef, TemplateRef, ChangeDetectionStrategy, OnChanges, SimpleChanges} from '@angular/core';
import {SharedService} from 'app/services/shared';
import {Router} from '@angular/router';
import {Data} from '../class/Data';
import {Connect} from '../services/connect';
import {TableInfo} from '../class/TableInfo';
import * as FileSaver from 'file-saver';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';
import ToastrService from '../toastr-service-wrapper.service';
import {ModalModule, BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {GrantGroupData} from '../class/GrantGroupData';
import {Form} from '../class/Form';
import {Observable} from 'rxjs/Observable';

@Component({
    selector: 'dynamic-data-view',
    templateUrl: './dynamic-data-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DynamicDataViewComponent implements OnChanges, OnInit {
    @Input() table_info: TableInfo;
    @Input() up: Function;
    @Input() down: Function;
    @Input() dataType;
    @Input() data: Data;
    @Input() field_name: string;
    @Input() download: Function;
    @Input() loading = false;
    @Input() nolink: boolean = false;

    @Input() update: Observable<any>;

    //optional
    @Input() grantGroupData: GrantGroupData = null;
    @Input() loadGrantGroupAuto: boolean = true

    @Input() is_view_mode: boolean = false;

    private downloading: boolean = false;
    private isdownloading: boolean = false;
    private isdownloadpdf: string = '1';
    private _share: SharedService;
    public arrayValue: Array<any>;
    public rawArrayValue: Array<any>;
    public isTextField: boolean = false;
    public form: Form;

    public page = 1;

    public pageLabel!: string;

    public ispdf: boolean=false;

    src = '';

    //GrantGruop

    private toasterService: ToastrService;

    modalRef: BsModalRef;

    constructor(_share: SharedService, private _router: Router, private _connect: Connect, private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig, public changeDetectorRef: ChangeDetectorRef, toasterService: ToastrService, private modalService: BsModalService, private cd: ChangeDetectorRef) {
        this._share = _share;
        this.toasterService = toasterService
        _lightboxConfig.centerVertically = true;
    }

    ngOnInit(): void {
        if (this.update) {
            this.update.subscribe(value => {
                this.cd.markForCheck();
            });
        }
    }

    is_string(data) {
        return data instanceof String;
    }

    download_file(url, no_action_log = false, file_name: string = null, isdownload = '1') {
        this.downloading = true;
        this._share.download_file(url, () => {
            this.downloading = false;
            this.changeDetectorRef.detectChanges();
        }, no_action_log, file_name,"pdf", isdownload);
    }

    async showFileIfPossibleClicked(url, file_name, data, template: TemplateRef<any>) {
        let fileExt = file_name.split('.').pop();
        let image_extensions = ['jpg', 'jpeg', 'png'];
        let video_extensions = ['mp4', 'webm', 'mov', 'wmv', 'mkv'];
        let other_extensions = ['pdf'];
        let allowed_extensions = image_extensions.concat(video_extensions);
        allowed_extensions = allowed_extensions.concat(other_extensions);
        if(!allowed_extensions.includes(fileExt)) {
            this.download_file(url,false, file_name);
            return;
        }
        let form_array = this.table_info.forms.getArray();
        let field_name_array: string[] = [];
        // get forms when custom field, show_file, is true
        for (let i = 0; i < form_array.length; i++) {
            if (form_array[i]['_custom_field']) {
                if(form_array[i]['_custom_field']['show_file']) {
                    if(form_array[i]['field']['Field']==this.field_name)
                        this.isdownloadpdf="0";
                    field_name_array.push(form_array[i]['field']['Field']);
                }
            }
        }

        if(field_name_array.length > 0) {
            if(image_extensions.includes(fileExt)) {
                let images: { src: string, thumb: string }[] = [];
                const src = url;
                const thumb = url + '/thumbnail';

                let image = {
                    src: src,
                    thumb: thumb
                };
                images.push(image);
                this._lightbox.open(images, 0);
            }
            if(video_extensions.includes(fileExt)) {
                let video_url = '';
                await this.getPresignedURL(url, data.id).then( res => video_url = res.body.presignedUrl);
                this.src = video_url;
                this.modalRef = this.modalService.show(template);
            }
            if(other_extensions.includes(fileExt)) {
                this.download_file(url,false, file_name,this.isdownloadpdf);
                return;
            }
        }
        else {
            // show file condition is false
            this.download_file(url,false, file_name);
        }
    }

     getPresignedURL(url, id) {
        return this._connect.get('/admin/get-presigned-url',
            {
                'url': url,
                'id' : id,
            },
            {observe: 'response'}
        ).toPromise();
    }

    toDetail(link_data) {
        this._router.navigate([this._share.getAdminTable(), link_data['table'], 'view', link_data['data']]);
    }

    private getArrayValue(is_raw: boolean = false): Array<string> {
        if (!this.getValue(is_raw)) {
            return []
        }
        if (!Array.isArray(this.getValue(is_raw))) {
            return [this.getValue(is_raw)];
        }
        return this.getValue(is_raw);

    }

    getValue(is_raw: boolean = false) {
        if (!this.field_name) {
            return null
        }
        if (is_raw) {
            return this.data.raw_data[this.field_name]
        }
        return this.data.view_data[this.field_name];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isTextField = ['image', 'thumbnail', 'file', 'url', 'number', 'richtext', 'grant_group'].indexOf(this.dataType) == -1;
        if (!this.isTextField) {
            if (this.data && this.data.table_info) {
                let form = this.data.table_info.forms.byFieldName(this.field_name);
                if (form && form.is_multi_value_mode && !!this.data.child_data_by_table[form.multiple_table_name]) {
                    this.arrayValue = this.data.child_data_by_table[form.multiple_table_name].map(d => d.view_data['value'])
                    this.rawArrayValue = this.data.child_data_by_table[form.multiple_table_name].map(d => d.raw_data['value'])
                } else {
                    this.arrayValue = this.getArrayValue();
                    this.rawArrayValue = this.getArrayValue(true);
                }
            }
        }

        if (this.field_name) {
            this.form = this.table_info.forms.byFieldName(this.field_name)
        }

        if (this.dataType === 'grant_group' && this.loadGrantGroupAuto && this.data.raw_data[this.field_name]) {
            this._connect.get('/admin/view/grant_group/' + this.data.raw_data[this.field_name]).subscribe((data) => {
                this._share.getTableInfo('grant_group').subscribe(_table_info => {
                    this.grantGroupData = new GrantGroupData(_table_info)
                    this.grantGroupData.setInstanceData(data.data)
                    this.changeDetectorRef.detectChanges();
                })
            });
        }

    }

    is_show_download() {
        //1週間前まで
        let date = new Date(this.data.raw_data['created']);

        let week_ago = new Date();
        week_ago.setDate(week_ago.getDate() - 7)
        console.log(date)
        console.log(week_ago)

        return date > week_ago

    }

    download_csv() {
        //console.time('download_csv:');
        let csv_id = this.data.raw_data['id']

        const url = this._connect.getApiUrl() + '/admin/download-csv/' + csv_id;
        this.downloading = true;
        this._connect.get(url, null, {'responseType': 'blob'}).subscribe((data: any) => {
            this.downloading = false;
            this.changeDetectorRef.detectChanges();
            if (data.size === 0) {
                alert('ダウンロードに失敗しました。権限を確認して下さい。');
                return;
            }
            const blob = new Blob([data], {type: 'text/csv'});
            //let filename = this.table_info.getLabel() + '_' + this._share.dateFormat.format(new Date(), 'yyyyMMdd_hhmm') + '.csv';
            FileSaver.saveAs(blob, this.data.raw_data['filename']);
        })

        //console.timeEnd('download_csv:');
    }


    view() {
        this._router.navigate([this._share.getAdminTable(), this.table_info.table, 'view', this.data.raw_data['id']]);
    }

    openImg(i: number) {
        let album = this.arrayValue.map(val => {
            return {
                src: val.replace('/thumbnail', ''),
                thumb: val,
            }
        })
        this._lightbox.open(album, i);
    }

    clickLink(url: string) {
        if (url.match(/^file/)) {
            this._share.copyMessage(url)
            this.toasterService.success('URLをコピーしました')
        } else {
            window.open(url, '_blank')
        }
    }

    isPdfFile(filename) {
        return filename && filename.match(/.pdf$/)
    }

}
