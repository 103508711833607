<div *ngIf="table_info" class="btn-group mr-2 mr-lg-0 pl-1" dropdown container="body">
    <button id="button-basic" dropdownToggle type="button"
            class="btn btn-sm btn-default dropdown-toggle"
            *ngIf="table_info.saved_filters.length>0 || !!customFilter" aria-controls="dropdown-basic">
        <i class="fa fa-filter"></i><span
        class="filter-label pl-1">{{getFilterDisplayName()}}</span><span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu " role="menu"
        aria-labelledby="button-basic">
        <ng-container *ngIf="isFilterMode()">
            <ng-container *ngIf="mode==='index'">
                <li role="menuitem" *ngIf="!customFilter.id || customFilter.editable"><a class="dropdown-item" href="javascript:void(0)"
                                                                                         (click)="editFilter()"><i class="fa fa-pencil"></i>表示中のフィルタを編集</a>
                </li>
                <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                       (click)="chartTitleModal.show()"><i class="fa fa-save"></i>表示中のフィルタを新規保存</a>
                </li>
                <ng-container *ngIf="customFilter.id!=null && customFilter.editable">
                    <ng-container *ngIf="has_filter_params">
                        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                               (click)="confirmFilterOverwriteConfirmModal.show()"><i
                            class="fa fa-save"></i>表示中のフィルタを上書き</a>
                        </li>
                    </ng-container>

                    <li role="menuitem"><a class="dropdown-item" href="javascript:void(0)"
                                           (click)="deleteFilterModal.show()"><i
                        class="fa fa-trash-o"></i>表示中のフィルタを削除</a>
                    </li>
                </ng-container>
            </ng-container>
            <li role="menuitem "><a class="dropdown-item" href="javascript:void(0)"
                                    (click)="resetSearch()"><i class="fa fa-times"></i>フィルタを解除</a>
            </li>

            <li class="divider dropdown-divider"></li>
        </ng-container>

        <div cdkDropList [cdkDropListDisabled]="!dragMode" (cdkDropListDropped)="dropMenu($event)">
            <ng-container *ngFor="let saved_filter of getFilters()">
                <li role="menuitem" style="list-style: none" cdkDrag><a class="dropdown-item"
                                                                        [class.active]="customFilter && customFilter.id==saved_filter.id"
                                                                        href="javascript:void(0)"
                                                                        (click)="selectFilter(saved_filter)">{{saved_filter.name}}</a>
                </li>
            </ng-container>
        </div>
        <ng-container *ngIf="getFilters().length==0">
            <li role="menuitem"><a class="dropdown-item"
                                   href="javascript:void(0)"
            >フィルタはありません</a>
            </li>

        </ng-container>
    </ul>
</div>
