import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'

import ToastrService from '../../toastr-service-wrapper.service';

import {Connect} from '../../services/connect';
import {SharedService} from '../../services/shared';
import {DomSanitizer} from '@angular/platform-browser';
import * as cloneDeep from 'lodash/cloneDeep';
import {TableInfo} from '../../class/TableInfo';
import {Grant} from '../../class/Grant';
import {Data} from '../../class/Data';
import {Form} from '../../class/Form';
import {Forms} from '../../class/Forms';
import {Workflow} from '../../class/Workflow';
import {v4 as uuidv4} from 'uuid';
import {WorkflowTemplate} from '../../class/Workflow/WorkflowTemplate';
import {CustomFilter} from '../../class/Filter/CustomFilter';
import {Conditions} from '../../class/Conditions';
import {FormsComponent} from './forms.component';
import {SortingService} from 'app/services/utils/sorting-service';
import {GroupService} from 'app/services/utils/group-service';
import {UserTableSetting} from '../../class/UserTableSetting';
import {Observable} from 'rxjs/Observable';
import {CanComponentDeactivate} from '../../shared/guards/can-deactivate-guard.service';
import {PublicFormService} from '../../services/public-form-service';

declare var $: any;


@Component({
    selector: 'edit-component',
    templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit, OnChanges, CanComponentDeactivate {
    @Input() IS_IFRAME_MODE: boolean = false;
    @Input() IS_PUBLIC_FORM: boolean = false;
    @Input() filter_id: number = null;
    @Input() public_form_hash: string = null;
    @Input() submit_text: string = '送信';
    @Input() _id: number = null;
    @Input() _mode: string = null;
    @Input() _add_post_params: Object = {};
    @Input() is_add_new_button_select_other_table: boolean = true;
    @Input() public_form_rid: number = null;

    @Output() onSubmit: EventEmitter<Object> = new EventEmitter();
    @Output() onCancel: EventEmitter<Object> = new EventEmitter();

    //for IFRAME
    @Input() IS_EMBED_MODE: boolean = false;
    @Input() _table: string = null;

    public is_edited: boolean = false;

    public full_display_message: string = ''

    public page_title: string;
    public loading = true;
    public mode: string; // add or edit
    public before_html: string;

    public table_info: TableInfo;
    public grant: Grant;

    // 設定ページかどうか
    public is_setting = false;


    // データ定義ページかどうか
    public is_dataset_edit: boolean = false;
    private workflow: Workflow = null;

    private workflow_template: WorkflowTemplate;

    /**
     * DATASET DEFINITION
     */
    public dataset_edit_mode = 'dataset_field';
    public dataset_grant_type = 'me';
    public workflow_template_a: Array<WorkflowTemplate> = [];
    public target_table_grant: Grant = null;

    public id: number;
    public table: string;
    public fields: Array<any>;
    public forms: Forms;
    public data: Data;
    public error_a: {} = {};

    public order_field: string;

    // 項目にgrantがある場合
    public grant_menu_a: Array<any> = [];
    public sending = false;
    public is_finish_edit = false;

    //child  by index by table
    public child_error_a_by_table: Object = {};


    private toasterService: ToastrService;
    private extend_scripts: Array<string> = [];


    // delete modal
    @ViewChild('editModal') EditModal: any;
    @ViewChild('worflowModal') WorflowModal: any;
    @ViewChild('commentModal') CommentModal: any;
    @ViewChild('commentModalComponent') commentModalComponent: any;
    @ViewChild('publicFormConfirmModal') publicFormConfirmModal: any;
    @ViewChild('confirmModal') confirmModal: any;

    @ViewChild(FormsComponent) forms_component;

    // child-table image mode
    private image_file: File;


    public field_reloading = false;

    /**
     * for dataset edit
     */
        //target table (ex.user)
    public system_table: string;
    public system_table_info: TableInfo;

    /**
     * Custom data set
     */
    public original_info_data: {};


    /**
     * workflow
     */
    public apply_workflow_flg: boolean = false

    public everyone_hide_fields: Array<any> = [];

    @ViewChild('settingModal') settingModal: any;
    public settingData: Data;
    private settingIndex;
    private settingModalIsNew;
    public settingTitle;
    public settingBtnLabel;
    public settingType: string;
    public settingTypes = [
        {
            value: 'auto-id',
            label: '自動採番',
            icon: 'list-ol',
            'option': {'auto-id-format': 'ID-{YYYY}-{MM}-{ID:4}'},
            'explain': '自動採番をフォーマットに従って行います。<br>' + '過去データに設定を行いたい場合、CSVアップロードを行うことで自動採番の項目が空データに対しても自動で値が入ります。'
        },
        {value: 'text', label: '文字列(一行)', icon: 'pencil'},
        {value: 'textarea', label: '文章(複数行)', icon: 'pencil-square-o'},
        {value: 'number', label: '数値', icon: 'bar-chart', 'option': {'switch_num': 'integer', 'editable': true, 'show-list': true}},
        {value: 'boolean', label: 'Yes / No', icon: 'check-circle'},
        {value: 'radio', label: '選択肢(単一選択)', icon: 'dot-circle-o'},
        {value: 'select', label: '選択肢(単一選択)', icon: 'dot-circle-o', show_list: false},
        {value: 'checkbox', label: '選択肢(複数選択)', icon: 'check-square', option: {'input_type': 'checkbox', 'editable': true, 'show-list': true}},
        {value: 'datetime', label: '日時', icon: 'calendar'},
        {value: 'image', label: '画像', icon: 'picture-o'},
        {value: 'file', label: 'ファイル', icon: 'paperclip'},
        {value: 'select_other_table', label: '他テーブル参照', icon: 'table'},
        {value: 'calc', label: '計算', icon: 'calculator', 'option': {'switch_num': 'integer', 'calc_result_type': 'number', 'show-list': true}},
        {value: 'relation_table', label: '関連レコード一覧', icon: 'table'},
        {value: 'fixed_html', label: '固定テキスト', icon: 'commenting-o', 'option': {'show_edit_page': true, 'show_view_page': true}},
    ];
    public convertableTypes = {
        'text': ['text', 'textarea', 'number', 'boolean', 'radio', 'select', 'datetime'],
        'textarea': ['text', 'textarea', 'number', 'boolean', 'radio', 'select', 'datetime'],
        'number': ['text', 'textarea', 'number', 'radio', 'select', 'datetime'],
        'select': ['text', 'textarea', 'number', 'radio', 'select', 'datetime'],
        'radio': ['text', 'textarea', 'number', 'radio', 'select', 'datetime'],
        'datetime': ['text', 'textarea'],
        'date': ['text', 'textarea'],
        'time': ['text', 'textarea'],
        'year_month': ['text', 'textarea'],
    };
    private settingTypesHash = {email: 'text', richtext: 'textarea', 'year_month': 'datetime', 'url': 'text', 'date': 'datetime', 'time': 'datetime'}; // DBのtypeとsettingTypesのキーが異なる場合
    private isTableSettingOptionCollapsed;
    private ref = null;

    private editingTimer;
    private field_xy = [];
    private hasChild = false;
    public dataStructured = false;

    constructor(private _router: Router, private _route: ActivatedRoute, private _connect: Connect, public _share: SharedService, toasterService: ToastrService, protected sanitizer: DomSanitizer, public sortingService: SortingService, public groupService: GroupService, private publicFormService: PublicFormService) {
        this.toasterService = toasterService;
        this.settingTypes.forEach(_type => {
            if (!_type['option']) {
                // @ts-ignore
                _type['option'] = {'show-list': true, 'editable': true}
            } else {
                _type['option']['show-list'] = true;
                _type['option']['editable'] = true;

            }
        })


    }

    ngOnDestroy(): void {
        if (this.table_info && this.table_info.isDatasetTable() && this.mode == 'edit') {
            if (this.editingTimer) {
                clearInterval(this.editingTimer)
            }
            navigator.sendBeacon(this._connect.getApiUrl() + '/admin/' + this.table + '/' + this.id + '/finish-edit', new FormData());
        }
    }


    canDeactivate() {
        return this.is_finish_edit || (!this.is_edited && this.table !== 'dataset') || window.confirm('保存されていないデータがあります。破棄してよろしいですか？');
    }


    setFroalaOptions(_forms: Forms, field) {
        //const froala_type = _this.forms.byFieldName(field.Field).option.froala_type;
        field.froala_option = this._share.froala_option_by_type['full'];
        field.froala_option.key = this._share.froala_key;
        field.froala_option.imageUploadURL = this._connect.getApiUrl() + '/admin/upload-froala-img/' + this.table + '/image'
        field.froala_option.fileUploadURL = this._connect.getApiUrl() + '/admin/upload-froala-file/' + this.table + '/file'
        /*
        if (this._share.use_s3) {
        } else {
            field.froala_option.imageUploadURL = this._connect.getApiUrl() + '/admin/upload-to-db';
            field.froala_option.fileUploadURL = this._connect.getApiUrl() + '/admin/upload-to-db';
        }
         */
    }

    ngOnInit() {

        // debugger;
        this.init()

        if (this.IS_PUBLIC_FORM) {
            document.getElementsByTagName('body')[0].style.backgroundColor = '#f0f0f0'
        }
        if (!this.IS_IFRAME_MODE) {
            this._share.getTableInfo(this.table).subscribe(table_info => {

                if (table_info.isDatasetTable() && this.mode == 'edit') {
                    this._connect.post('/admin/' + this.table + '/' + this.id + '/start-edit', {}).subscribe(_res => {
                    })

                    //1分
                    this.editingTimer = setInterval(() => {
                        this._connect.post('/admin/' + this.table + '/' + this.id + '/start-edit', {}).subscribe(_res => {
                        })
                    }, 1000 * 60);
                }
            });
        }

    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event) {
    //     if (this.mode == 'add') {
    //         if(!window.confirm('保存されていないデータがあります。破棄してよろしいですか？')) {
    //             window.history.forward()
    //         }
    //     }
    // }

    ngOnChanges(changes: SimpleChanges) {
        this.init()
    }

    setClasses(): Object {

        let class_hash = {'iframe': this.IS_IFRAME_MODE, 'public-form': this.IS_PUBLIC_FORM}
        if (this.table_info) {
            class_hash[this.table_info.getJaClassName()] = true;
            class_hash[this.table_info.getClassName()] = true;
        }
        return class_hash
    }

    setDataStructured(value) {
        console.log('set data')
        this.dataStructured = false;
    }


    private url_set_params: Object = {}

    private init() {

        this._route.params.subscribe(params => {
            this.table = params['table'];
            Object.keys(params).forEach(k => {
                if (k.match(/body$/)) {
                    //一旦bodyだけ
                    this.url_set_params[k] = params[k]
                }
            })
            if (params['id'] === 'new' || !params['id']) {
                // add
                this.mode = 'add';
                if (params['ref']) {
                    this.ref = params['ref'];
                }
            } else {
                // edit
                this.id = params['id'];
                this.mode = 'edit';
            }

            if (params['public_form'] === 'true') {
                this.IS_PUBLIC_FORM = true
            }
            this.fields = [];
            this.forms = new Forms({});
            this.data = null;


            //INPUTにある場合
            if (this._id) {
                if (this._id == -1) {
                    this.id = null;
                    this.mode = 'add'
                } else {
                    this.id = this._id;
                    this.mode = 'edit'
                }
            }
            if (this._table) {
                this.table = this._table;
            }
            if (this._mode) {
                this.mode = this._mode
            }

            if (params['system_table']) {
                this.system_table = params['system_table']


                this._share.getTableInfo(this.table).subscribe(table_info => {
                    let filter = new CustomFilter();
                    filter.conditions = new Conditions()
                    filter.conditions.addCondition('eq', 'system_table', this.system_table)
                    this._connect.getList(table_info, 1, Number.MAX_SAFE_INTEGER, filter).subscribe(res => {
                        if (res['data_a'].length > 0) {
                            this._router.navigate([this._share.getAdminTable(), this.table, 'edit', res['data_a'][0]['raw_data']['id']]);
                            return;
                        }

                        //まだそのsystem tableのdatasetがない時
                        this.load();
                    });
                    this.everyone_hide_fields = [table_info.getFieldByFieldName('everyone_hide_fields')]
                })
                return;
            }

            this.publicFormService.publicPostParams = this.getPublicFormParam()
            this.load();
        });
    }

    private isDatasetEdit() {
        return this.table === 'dataset';
    }

    public isEditSystemTable() {
        return !!this.system_table;
    }

    private checkIsAlreadyStoredOfPublicForm(): void {
        this._connect.post('/iframe/is_already_stored/' + this.table, this.getPublicFormParam()).subscribe(res => {
            if (res.stored) {
                this.full_display_message = '既に送信済みです';
            }
        })

    }

    private getPublicFormParam(): Object {
        return {
            'filter_id': this.filter_id,
            'hash': this.public_form_hash,
            'table': this.table,
            'rid': this.public_form_rid
        };

    }

    load() {
        if (this.system_table) {
            this._share.getTableInfo(this.system_table).subscribe(table_info => {
                this.system_table_info = table_info
            })
        }

        this.loading = true;
        let params = null;
        if (this.IS_IFRAME_MODE) {
            params = this.getPublicFormParam()
        }
        if (this.IS_PUBLIC_FORM && this.public_form_rid) {
            this.checkIsAlreadyStoredOfPublicForm();
        }

        this._share.getTableInfo(this.table, this.IS_IFRAME_MODE, params).subscribe(async (data) => {


                if (!data) {
                    if (this.IS_IFRAME_MODE) {
                        alert('エラーが発生しました');
                        return
                    }
                    this._router.navigate([this._share.getAdminTable(), 'login']);
                    return;
                }

                this.page_title = data.menu.name;
                this._route.snapshot.data.title = this.page_title;
                this.before_html = data.edit_before_html;

                this.order_field = data['order_field']
                this.is_dataset_edit = data.menu.is_custom_table_definition;

                //ワークフローテンプレートがある場合は、フロー設定は出来ず、テンプレートのPATHとなる
                if (data.workflow_template) {
                    this.workflow_template = data.workflow_template;
                }

                this.table_info = data;
                this.forms = this.table_info.forms;
                this.fields = this.table_info.fields;
                this.grant = this.table_info.grant;
                this.data = new Data(this.table_info);

                if (this.isDatasetEdit()) {
                    this.everyone_hide_fields = [data.getFieldByFieldName('everyone_hide_fields')]
                }

                //workflow needs ADMIN list view grant
                if (this.table_info.menu.is_workflow) {
                    this.workflow = new Workflow();

                }

                if (this.is_dataset_edit) {
                    this.page_title = '項目';
                }
                // アカウント設定の時。（テーブル権限自体は無いが、自分の情報は編集可能）
                this.is_setting = (data.grant.edit === false);


                let group_fields = []

                if (this.fields.length > 0) {
                    this.fields.forEach(field => {


                        if (group_fields.length === 0 || field.y == undefined) {
                            group_fields.push([field]);
                        } else {
                            this.groupService.groupByAxis(group_fields, field)
                        }


                        if (this.forms.byFieldName(field.Field).type === 'richtext') {
                            this.setFroalaOptions(this.forms, field);
                        }
                    });
                }


                if (group_fields.length > 1) {
                    this.sortingService.selectionSort(group_fields, 0, 'y');

                }

                this.fields = group_fields;
                this.dataStructured = true;
                const info_data = data;

                const parent = this;
                const onFinishLoad = () => {
                    if (parent.is_dataset_edit) {
                        // create preview form from field definition
                        parent.setFormByOriginalData()
                        parent.convertChildDataToFields()
                        if (this.isEditSystemTable()) {
                            this._share.getTableInfo(this.system_table).subscribe(table_info => {
                                this.data.setRawData({'system_table': this.system_table});
                                this.system_table_info = table_info
                                if (this.data.raw_data['label'] == '') {
                                    this.data.setRawData({'label': this.system_table_info.getLabel()});
                                }
                            })
                        }
                    }
                    if (parent.IS_IFRAME_MODE) {
                        parent.fitIframeHeight()
                    }
                }
                this.data.child_a.forEach((child) => {
                    this.child_error_a_by_table[child.table] = {}
                });

                //set Default Custom Filter
                this.userTableSetting = this._share.getUserTableSetting(this.table)

                if (this.userTableSetting.filter_id && !this.IS_IFRAME_MODE) {
                    this.setFilter(this.userTableSetting.filter_id);
                }
                //set Default Custom Filter End


                if (this.mode === 'edit') {
                    this.loading = true
                    this._connect.get('/admin/view/' + this.table + '/' + this.id).subscribe((data_hash) => {
                        // console.log(data)
                        if (data_hash['result'] !== 'success') {
                            this.toasterService.error(data_hash['error_a'].join(','), 'エラー');
                            return;
                        }
                        data_hash = data_hash['data']
                        this.loading = false;
                        this.data.setInstanceData(data_hash);

                        //ワークフロー申請中の場合、redirect
                        if (!this.is_dataset_edit && !this.data.isEditable()) {
                            this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.id]);
                            return;
                        }


                        if (this.mode == 'edit' && this.table === 'admin') {
                            this.data.setRawData({'password': this._share.dummy_password});
                        }
                        if (this.table_info.menu.is_workflow) {
                            if (data_hash['workflow']['id']) {
                                this.workflow = new Workflow(data_hash['workflow']);
                                if (this.workflow.isEnd()) {
                                    //拒否等の再申請の場合は、新しいワークフロー
                                    if (this.workflow_template) {
                                        this.workflow = this.workflow_template.workflow;
                                    } else {
                                        this.workflow = new Workflow();
                                    }
                                }
                            }

                        }

                        if (this.isDatasetEdit() && this.system_table) {
                            if (this.data.raw_data['table'] !== this.system_table) {
                                this.toasterService.error('正しいデータではありません。一度トップ画面からやり直して下さい。', 'エラー');
                                return;
                            }

                        }

                        if (this.data.is_locked) {
                            this.toasterService.error('他のユーザーが編集中のため、編集できません。', 'エラー');

                        }

                        if (this.isDatasetOrGroupEdit()) {
                            this.dataset_grant_type = data_hash['raw_data']['grant_type'];
                        }
                        if (this.isDatasetEdit()) {

                            /**
                             * テーブル編集の場合、workflow pathのテンプレート設定
                             */
                            if (data_hash['raw_data']['workflow_template_a_json']) {
                                let workflow_template_a: Array<Object> = JSON.parse(data_hash['raw_data']['workflow_template_a_json']);
                                workflow_template_a.forEach(workflow_template_hash => {
                                    this.workflow_template_a.push(new WorkflowTemplate(workflow_template_hash))
                                })
                            }

                            //ターゲットのテーブルのgrantを取得する
                            if (this.mode == 'edit') {
                                this._share.getTableInfo('dataset__' + this.id).subscribe(async (_target_table_info) => {
                                    this.target_table_grant = _target_table_info.grant
                                    console.log(this.target_table_grant)

                                });

                            }
                        }

                        /**
                         * Froala 設定
                         */
                        this.data.child_a.forEach((child, index) => {
                            console.log(child.forms)
                            child.fields.forEach(field => {
                                if (child.forms.byFieldName(field.Field).type === 'richtext') {
                                    this.setFroalaOptions(child.forms, field);
                                }
                            });
                        });

                        // if pigeon cloud and master user, grant cannot be edited
                        if (this._share.is_cloud && this.table_info.is_admin_table && this.data['type'] === 'master') {
                            this.fields.forEach((field) => {
                                if (field.Field === 'pigeon_admin_grant_id') {
                                    field.editable = false;
                                }
                            })
                        }

                        if (this.data.raw_data['system_table']) {
                            this.system_table = this.data.raw_data['system_table']
                        }

                        onFinishLoad()
                    });
                } else {
                    //IF ADD
                    // デフォルト値セット
                    if (!!this.ref && this.grant.duplicate) {
                        this.loading = true
                        this._connect.get('/admin/view/' + this.table + '/' + this.ref).subscribe((data) => {
                            if (data['result'] !== 'success') {
                                this.toasterService.error(data['error_a'].join(','), 'エラー');
                                return;
                            }
                            this.loading = false;
                            let fields_to_omit = [
                                'id',
                                'created',
                                'updated',
                                'admin_id',
                                'comments',
                                'workflow'
                            ];
                            let field_type_to_omit = [
                                /*
                                'file',
                                'image'
                                 */
                            ];
                            delete data.data.raw_data.id;
                            delete data.data.view_data.id;
                            delete data.data.workflow;
                            delete data.data.grant;
                            for (var form in data.data.raw_data) {
                                if (!!this.forms.byFieldName(form) && Object.prototype.hasOwnProperty.call(data.data.raw_data, form)) {
                                    if (fields_to_omit.includes(form) || field_type_to_omit.includes(this.forms.byFieldName(form).type)) {
                                        delete data.data.raw_data[form];
                                        delete data.data.view_data[form];
                                        for (var child_prop in data.data.child_data_a_by_table) {
                                            if (Object.prototype.hasOwnProperty.call(data.data.child_data_a_by_table, child_prop)) {
                                                if (child_prop.includes(form)) {
                                                    data.data.child_data_a_by_table[child_prop] = []
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            let remove_child_table_id = (_data) => {
                                for (var child_prop in _data.child_data_a_by_table) {
                                    if (Object.prototype.hasOwnProperty.call(_data.child_data_a_by_table, child_prop)) {
                                        _data.child_data_a_by_table[child_prop].forEach(child => {
                                            delete child.raw_data.id;
                                            delete child.raw_data.data_id;
                                            delete child.view_data.id;
                                        });
                                        _data.child_data_a_by_table[child_prop].forEach(child => {
                                            if (child['child_data_a_by_table']) {
                                                remove_child_table_id(child)
                                            }
                                        });
                                    }
                                }
                            };

                            remove_child_table_id(data.data);

                            this.data.setAddDefualtValue(this.forms, this._share)
                            this.data.setInstanceData(data.data);
                            onFinishLoad()
                        });
                    } else {
                        this.data.setAddDefualtValue(this.forms, this._share)
                        if (this.url_set_params) {
                            this.data.setRawData(this.url_set_params)
                        }
                        this.loading = false;
                    }
                    onFinishLoad()
                }


                if (this.data.child_a.length != 0) {
                    this.hasChild = true;
                }

            },
            error => {
                this.loading = false;
                this.toasterService.error(error.error.error_a.join(','), 'エラー');
            });
    }

    handleLoadInfo() {

    }


    add() {
        if (this.table == 'dataset') {
            this.post('/admin/' + this.table);
        } else {
            if (this.IS_IFRAME_MODE) {
                this.post('/iframe/add/' + this.table);
            } else {
                this.post('/admin/add/' + this.table + '/');
            }
        }
    }

    edit() {
        this.post('/admin/edit/' + this.table + '/' + this.id);
    }

    addChildData(child: TableInfo, scroll_after_add = true, order = -1) {
        // debugger;
        this.dataStructured = false;
        const new_data = {}
        if (order === -1) {
            //this.data.child_data_by_table[child.table].push(new_data);
            this.data.setChildData(child, {});
        }

        //child.error_a.push({});
        if (scroll_after_add) {
            const scroll = $('#child-' + child.table).offset().top + $('#child-' + child.table).height() - 60;
            $('html,body').animate({scrollTop: scroll}, 500, 'swing');
        }
        return new_data;
    }


    orderChange(child_data_a, from_index, to_index) {
        if (from_index < 0 || to_index < 0 || from_index >= child_data_a.length || to_index >= child_data_a.length) {
            return;
        }
        const tmp = child_data_a[to_index];
        child_data_a[to_index] = child_data_a[from_index];
        child_data_a[from_index] = tmp;

    }

    deleteChild(child, i) {
        this.data.child_data_by_table[child.table].splice(i, 1);
        this.data.child_data_by_table[child.table].forEach((_data: Data, i) => {
            _data.setRawData({'order': (i + 1)})
        })
    }

    isString(val) {
        return (typeof val) === 'string';
    }

    private validate_data(form: Form, val, debug = false) {
        if (val == null) {
            val = '';
        }
        let error_a: Array<string> = [];
        let field = form.field;
        if (!field) {
            return [];
        }
        // field base
        if (field['Field'] === 'password') {
            if (this.data['password_conf'] !== this.data[field['Field']]) {
                error_a[field['Field']] = '確認パスワードが一致しません';
            }
        }

        if (form.original_type === 'condition' && val) {
            let conditions = new Conditions(JSON.parse(val));
            if (!conditions.validate()) {
                error_a[field['Field']] = conditions.error_message
            }
        }

        /*
        validate server side
        const val_len = val ? val.length : 0;
        // param base
        if (form.min_len !== null) {
            const min_len = form.min_len;
            if (val_len < min_len) {
                error_a[field['Field']] = form.label + 'は最低' + min_len + '文字必要です';
            }
        }
        if (form.max_len !== null) {
            const max_len = form.max_len;
            if (val_len > max_len) {
                error_a[field['Field']] = form.label + 'は最大' + max_len + '文字までです';
            }
        }

         */
        return error_a;
    }

    validate() {
        let error_a = {};

        if (this.is_dataset_edit && this.data.raw_data['label'] === '') {
            error_a['table'] = 'テーブル名は必須です。';
        }

        this.fields.forEach(field => {
            if (this.is_editable_field(field)) {
                let form = this.forms.byFieldName(field.Field);
                let val = this.data.raw_data[field.Field];
                if (form.is_multi_value_mode) {
                    val = this.data.getMultiDataAry(field.Field, true);
                    if (val) {
                        val.forEach((_mval) => {
                            Object.assign(error_a, this.validate_data(form, _mval, true))
                        })
                    }
                } else {
                    Object.assign(error_a, this.validate_data(form, val))
                }
            }
        });


        this.data.child_a.forEach(child => {
            this.data.getChildDataAry(child.table).forEach((_child_data: Data) => {
                //DATASET_FIELD
                if (_child_data.table_info.table == 'dataset_field') {
                    if (_child_data.raw_data['option']['copy-fields']) {
                        //関連テーブルの条件
                        _child_data.raw_data['option']['copy-fields'].forEach(copy_field => {
                            if (copy_field['to'] == '' || copy_field['from'] == '') {
                                error_a['_dummy'] = _child_data.raw_data['name'] + 'の条件がすべて選択されていません。';
                            }
                        })
                    }
                }
                child.fields.forEach(c_field => {
                    Object.assign(error_a, this.validate_data(child.forms.byFieldName(c_field.Field), _child_data.raw_data[c_field.Field]))
                })

                if (child.child_a.length > 0) {
                    child.child_a.forEach((_child_multi_child: TableInfo) => {
                        //子要素のmultiple
                        _child_data.getChildDataAry(_child_multi_child.table).forEach((_multi_child_data: Data) => {
                            if (!_multi_child_data.raw_data['value']) {
                                error_a['_dummy'] = _child_multi_child.forms.byFieldName('value')['label'] + 'がすべて入力されていません。';
                            }
                        });
                    });
                }
            });


        })

        if (Object.keys(error_a).length !== 0) {
            Object.keys(error_a).forEach((error_key) => {
                const error = error_a[error_key];
                this.toasterService.error(error, 'エラー');
            })

            return false;
        }
        return true;
    }

    is_editable_field(field) {
        return ['created', 'updated'].indexOf(field.Field) === -1 && field.editable && this.forms.byFieldName(field.Field).label !== null && this.table_info.copyto_fields.indexOf(field['Field']) < 0;
    }


    go_edit() {
        // debugger;
        if (!this.validate()) {
            return;
        }
        this.loading = false;

        if (this.table_info.menu.show_confirm) {
            this.EditModal.show();
        } else if (this.table_info.menu.popup_comment_after_save && !this.IS_IFRAME_MODE) {
            this.CommentModal.show();
        } else if (this.IS_PUBLIC_FORM) {

            window.parent.postMessage({'mode': 'scroll_to_top'}, '*');
            this.publicFormConfirmModal.show();
        } else {
            this.store()
        }
    }

    cancel() {
        if (this.IS_EMBED_MODE) {
            this.onCancel.emit();
            return;
        }
        if (!this.id) {
            this._router.navigate([this._share.getAdminTable(), this.table]);
        } else {
            this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.id]);
        }
    }

    workflow_store() {
        this.apply_workflow_flg = true;
        this.store()
    }

    store() {
        if (this.mode === 'add') {
            this.add();
        } else {
            this.edit();
        }
    }

    post(url: string) {
        if (this.is_dataset_edit) {
            this.loading = true;
            this.beforeCustomDatasetPost()
        }
        this.EditModal.hide();

        this.sending = true;
        this.toasterService.clear();
        console.log(this.data)
        let formData = this._share.get_post_data(this.table_info, this.data, this.fields, this.forms, this.data.child_a, this.mode, this.is_setting, this.is_dataset_edit);
        if (this.apply_workflow_flg && this.workflow && this.workflow.isSetWorkflow()) {
            formData.append('workflow_json', JSON.stringify(this.workflow.toArray(this._share.getMainDivisionId())));
            formData.append('workflow_comment', this.workflow_comment ? this.workflow_comment : '');
            this.workflow.workflow_path_a
        }
        if (this.table_info.menu.popup_comment_after_save) {
            formData.append('comment', this.comment ? this.comment : '');
        }

        if (this.table_info.table === 'dataset') {
            if (this.workflow_template_a) {
                let workflow_template_hash_a = []
                this.workflow_template_a.forEach((workflowTemplate => {
                    workflow_template_hash_a.push(workflowTemplate.toArray())
                }));
                formData.append('workflow_template_a_json', JSON.stringify(workflow_template_hash_a));
            }

        }
        if (this.table_info.table === 'dataset' || this.table_info.table === 'dataset_group') {
            formData.append('grant_type', this.dataset_grant_type);
        }
        /*
        this.workflow_admin_id_a.forEach((admin_id) => {
            if (admin_id != null) {
                formData.append('workflow_admin_id[]', admin_id.toString());
            }
        })
         */

        if (this.IS_IFRAME_MODE) {
            formData.append('table', this.table);
            if (this.filter_id) {
                formData.append('filter_id', this.filter_id.toString());
            }
            formData.append('hash', this.public_form_hash);
            if (this.public_form_rid) {
                formData.append('rid', this.public_form_rid.toString());
            }
        }

        if (this.ref) {
            formData.append('ref', this.ref);
        }

        // debugger;
        /*
        if (this.field_xy.length != 0) {
            formData.append('field_xy', JSON.stringify(this.field_xy))
            this.field_xy.forEach(field_xy=>{
                if(field_xy.match(/dummy_field/)){
                    let index = field_xy.replace('dummy_field','')

                }

            })
        }
         */
        //追加のパラメータ
        Object.keys(this._add_post_params).forEach(_key => {
            formData.append(_key, this._add_post_params[_key])
        })

        this._connect.postUpload(url, formData).subscribe(
            (jsonData) => {
                if (this.IS_PUBLIC_FORM) {
                    this.publicFormConfirmModal.hide();
                }
                // console.log(jsonData);
                if (jsonData['result'] === 'success' && jsonData['id']) {
                    this.is_finish_edit = true;
                    this.id = jsonData['id']
                    if (this.IS_IFRAME_MODE) {
                        this.full_display_message = this.table_info.menu.public_form_sent_text ?? '送信が完了しました。'
                        window.parent.postMessage({'mode': 'scroll_to_top'}, '*');
                        this.fitIframeHeight();
                        return;
                    }
                    this._share.resetTableInfoCache()
                    if (this.IS_EMBED_MODE) {

                        this.onSubmit.emit({
                            'id': this.id
                        })
                        this.sending = false;
                        return;
                    }
                    if (this.is_setting || !this.grant.list) {
                        this.toasterService.success('データの編集が完了しました。', '成功');
                        this.sending = false;
                        if (this.table === 'dataset') {
                            if (this._share.prev_page) {
                                this._router.navigate([this._share.prev_page.split('/')]);
                            } else {
                                this._router.navigate([this._share.getAdminTable(), 'dataset__' + jsonData['id']]);
                            }

                        }
                    } else {
                        this._share.loadAdminDatas().then(() => {
                            if (this.table_info.menu.is_onlyone) {
                                this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.id]);
                            } else if (this.table_info.table === 'dataset_group') {
                                this._router.navigate([this._share.getAdminTable(), 'dataset']);

                            } else {
                                if (this.is_dataset_edit && this._share.prev_page) {
                                    this._router.navigate(this._share.prev_page.split('/'));
                                } else {
                                    if (this.is_dataset_edit && !this.isEditSystemTable()) {
                                        this._router.navigate([this._share.getAdminTable(), 'dataset__' + this.id]);
                                    } else {
                                        this._router.navigate([this._share.getAdminTable(), this.table]);
                                    }
                                }
                            }
                        });
                    }
                } else {
                    this.sending = false;
                    if (jsonData['error_a']) {
                        this.error_a = jsonData['error_a']
                        console.log(this.error_a)
                        this.showError(jsonData['error_a']);
                    } else {
                        this.toasterService.error('エラーが発生しました。', 'エラー')
                    }
                    scrollTo(0, 0);
                }
            },
            (error) => {
                if (this.IS_PUBLIC_FORM) {
                    this.publicFormConfirmModal.hide();
                }
                try {
                    console.error(JSON.stringify(error))
                } catch (e) {
                }
                this.sending = false;
                this.loading = false;
                this.error_a = error['error']['error_a']
                this.showError(error['error']['error_a']);
                if (this.is_dataset_edit) {
                    this.convertChildDataToFields();
                }

                if (this.IS_IFRAME_MODE) {
                    window.parent.postMessage({'mode': 'scroll_to_top'}, '*');
                    this.fitIframeHeight()
                }
            }
        );
    }


    showError(error_a) {
        try {
            let body = this._share.getErrorBodyByResponse(error_a, this.data.child_a, this.child_error_a_by_table);

            this.toasterService.error(body, 'エラー');
        } catch (e) {
            console.log(error_a)
            this.toasterService.error('不明なエラーが発生しました.', 'エラー');


        }
    }

    setChildFieldForms(reset: boolean = false) {
        this.field_reloading = true;
        const pre_data_a = this.data.child_data_by_table['dataset_field'];
        const pre_field_a = pre_data_a.map(data => {
            return data['field'];
        });
        const current_field_a = [];
        if (!this.data.child_data_by_table['dataset_field'] || reset) {
            this.data.child_data_by_table['dataset_field'] = [];
        }
        this._connect.get('/admin/table/raw-info/' + this.data['table']).subscribe((data) => {
            data.fields.forEach((field) => {
                current_field_a.push(field.Field);
                const pre_order = pre_field_a.indexOf(field.Field);
                let new_data;
                const form = this.forms.byFieldName(field.Field);
                if (pre_order !== -1) {
                    // tslint:disable-next-line:no-shadowed-variable
                    new_data = pre_data_a.filter((data) => {
                        return data.field === field.Field
                    })[0]
                } else {
                    new_data = this.addChildData(this.data.child_a[0], false);
                    new_data['option'] = {'show-list': 'true', 'editable': 'true', 'sort-field': 'id', 'sort-order': 'desc'};
                    new_data['type'] = form.type;
                    new_data['name'] = field.Field
                }
                new_data['field'] = field.Field

                if (field.is_autoincrement) {
                    new_data['use'] = 'false';
                    new_data['__disabled'] = true;
                }

                if (new_data['type'] === 'select') {
                    new_data['option']['items'] = form.option.filter((val) => {
                        return val.value !== '';
                    })
                }
            })
            // 存在しない項目の削除
            const removed_field_a = pre_field_a.filter((pre_field) => {
                return current_field_a.indexOf(pre_field) === -1;
            })

            removed_field_a.forEach((field) => {
                this.data.child_data_by_table['dataset_field'].forEach((c_data, i) => {
                    if (c_data['field'] === field) {
                        this.data.child_data_by_table['dataset_field'].splice(i, 1);
                    }
                })
            });

            this.field_reloading = false;
        })
    }

    reloadField() {
        this.setChildFieldForms()

    }


    getThis() {
        return this;
    }

    private setFormByOriginalData() {

        this.fields = []
        this.forms = new Forms({});


    }

    private convertChildDataToFields() {
        // debugger;
        console.log('method_convertchilddatatofields')
        this.fields = [];
        this.forms = new Forms({});
        if (this.data.child_data_by_table['dataset_field'] == undefined) {
            this.data.child_data_by_table['dataset_field'] = []
        }

        this.data.getChildDataAry('dataset_field').forEach((data: Data, i) => {
            // console.log(data)
            let data_raw = data.raw_data;
            if (!data_raw) {
                data.setInstanceData({})
                data_raw = {};
            }
            let uniq_id = 'dummy_field' + i;
            if (data_raw['id'] !== undefined) {
                uniq_id = 'field__' + data_raw['id'];
            } else {
                data_raw['dummy_id'] = uniq_id
            }
            const field = {
                Field: uniq_id,
                Comment: data_raw['name'],
                editable: true,
                fixed_value: data_raw['option'].value,
                x: data_raw['edit_component_x_order'] != undefined ? data_raw['edit_component_x_order'] : '1',
                y: data_raw['edit_component_y_order'] != undefined ? data_raw['edit_component_y_order'] : i + 1,
                only_add: false,
                Default: (data_raw['option'].value !== '' && data_raw['option'].value !== undefined)
                    ? data_raw['option'].value : data_raw['option'].default
            };
            const form = cloneDeep(data_raw);
            Object.keys(data_raw['option']).forEach(opkey => {
                form[opkey] = data_raw['option'][opkey];
            })
            form['']
            form['option'] = [];
            form.label = form.name;
            // type option
            if (['checkbox', 'radio', 'select'].indexOf(data_raw['type']) >= 0) {
                if (data_raw['option']['items'] !== undefined) {
                    data_raw['option']['items'].split(',').forEach((item) => {
                        form['option'].push({'label': item, 'value': item})
                    })
                }
            }


            let _form = new Form(form)
            _form.createDummyForm('field__' + form['id'])
            this.forms.add(uniq_id, _form)
            this.fields.push(field)
        });
        let group_fields = [];
        this.fields.forEach(field => {
            if (group_fields.length === 0) {
                group_fields.push([field]);
            } else {
                this.groupService.groupByAxis(group_fields, field)

            }
            if (this.forms.byFieldName(field.Field).type === 'richtext') {
                this.setFroalaOptions(this.forms, field);
            }
        });
        if (group_fields.length > 1) {
            this.sortingService.selectionSort(group_fields, 0, 'y');

        }

        this.fixYorderForNewField(group_fields)
        let total_items = this.fields.length
        this.fields = group_fields;

        if (this.fields.length > 1 || total_items > 1) {
            for (let i = 0; i < (this.fields.length * 2) / 2; i += 2) {
                this.fields.splice(i, 0, [])

                this.fields[i + 1].map(column => {
                    column.y = i + 2;
                })
            }

            this.fields.splice(this.fields.length, 0, [])
        }

        this.dataStructured = true;
        let xy = [];
        group_fields.map(dataset_field_row => {
            dataset_field_row.map(dataset_field_column => {
                xy.push({
                    'field_id': dataset_field_column.Field.startsWith('field__') ? dataset_field_column.Field.slice(7) : dataset_field_column.Field,
                    'x': dataset_field_column.x,
                    'y': dataset_field_column.y
                })
            })

        })
        this.field_xy = xy;
        this.data.updateLastDirtyChanged()
        //this.data.setDefaultData()
    }


    fixYorderForNewField(fields) {
        // console.log(fields)
        fields.map((row, y_index) => {
            row.map((column, x_index) => {
                // console.log(column,y_index)
                if (column.y != y_index + 1) {
                    column.y = y_index + 1
                }
                if (column.x != x_index + 1) {
                    column.x = x_index + 1
                }
            })
        })
    }


    private beforeCustomDatasetPost() {
        this.fields = this.table_info.fields;
        this.forms = this.table_info.forms;
    }

    /**
     * Dataset Definition Field setting modal
     * @param field
     * @param isNew
     */
    openSettingModal(field = null, isNew: boolean = false) {
        this.settingType = null;
        this.settingTitle = '項目追加';
        this.settingBtnLabel = '追加する';
        this.settingData = new Data(this.data.getChildTableInfoByTable('dataset_field'));
        this.settingData.setInstanceData(
            {
                raw_data: {
                    'type': 'text',
                    'option': {
                        'show-list': true,
                        'editable': true
                    },
                    'unique_key_name': uuidv4()
                }
            }
        );
        this.settingIndex = null;
        this.settingModalIsNew = isNew;
        this.isTableSettingOptionCollapsed = true;
        if (!isNew) {
            this.settingTitle = '項目編集';
            this.settingBtnLabel = '変更する';
            const child_id = field.Field;
            this.data.getChildDataAry('dataset_field').forEach((child, i) => {
                let id = 'field__' + child.raw_data['id'];
                if (child.raw_data['id'] === undefined) {
                    id = child.raw_data['dummy_id'];
                }
                if (id === child_id) {
                    const data = cloneDeep(child);
                    if (!!!data.raw_data['unique_key_name']) {
                        data.raw_data['unique_key_name'] = uuidv4();
                    }
                    this.settingData = data;
                    this.settingIndex = i;
                    console.log(data.raw_data.type)
                    this.settingType = data.raw_data.type in this.settingTypesHash ? this.settingTypesHash[data.raw_data.type] : data.raw_data.type;
                    console.log(this.settingType)
                    return false;
                }
            })
        }
        if (this.settingData.raw_data['option'] && this.settingData.raw_data['option']['item-table']) {
            this._share.loadTableFields(this.settingData.raw_data['option']['item-table'], () => {
                this.settingModal.show();
            });
        } else {
            this.settingModal.show();
        }
    }

    isExistFieldSelected() {
        return this.settingData && !!this.settingData.raw_data['id'];
    }

    selectFieldType(_setting) {
        const setting = cloneDeep(_setting)
        this.settingType = setting.value;
        this.settingData.raw_data['type'] = setting.value;
        if (setting.option) {
            this.settingData.raw_data['option'] = setting.option;
        }
    }

    getSettingTypeObj(): Object {

        let _selected_setting_type = null;
        this.settingType = this.settingType in this.settingTypesHash ? this.settingTypesHash[this.settingType] : this.settingType;
        this.settingTypes.forEach(setting => {
            if (setting['value'] === this.settingType) {
                _selected_setting_type = setting;
            }
        })
        if (!_selected_setting_type) {
            console.error('ERROR')
            console.error(this.settingType)
        }
        return _selected_setting_type;
    }

    canConvertField(to_type: string) {
        let preSettingType = this.settingData.raw_data['type'];
        if (!this.convertableTypes[preSettingType]) {
            return false;
        }
        return this.convertableTypes[preSettingType].indexOf(to_type) >= 0
    }

    changeFieldType() {
        this.settingType = null;
    }

    addFieldData() {
        // debugger;
        const data = this.settingData.getCopy();

        // validate
        const error_a = [];
        if (data.raw_data['type'] === null) {
            error_a.push('種類は必須です');
        }
        if (data.raw_data['name'] === '' || data.raw_data['name'] === null || data.raw_data['name'] === undefined) {
            error_a.push('項目名は必須です');
        }
        if (data.raw_data['type'] === 'calc' && !data.raw_data['option']['expression']) {
            error_a.push('計算式は必須です');

        }
        if (data.raw_data['type'] === 'relation_table') {
            if (!data.raw_data['option']['item-table']) {
                error_a.push('対象テーブルは必須です');

            }

        }
        if (data.raw_data['type'] === 'boolean') {
            if (!data.raw_data['option']['boolean-text']) {
                error_a.push('ラベルは必須です');

            }

        }
        if (data.raw_data['type'] === 'select_other_table') {
            if (!data.raw_data['option']['item-table']) {
                error_a.push('対象テーブルは必須です');
            } else {
                if (!data.raw_data['option']) {
                    error_a.push('表示項目を指定して下さい');
                } else if (!data.raw_data['option']['is_child_form']) {
                    if (!data.raw_data['option']['label-fields'][0]) {
                        error_a.push('表示項目を指定して下さい');
                    }
                }
            }
            if (data.raw_data['option']['copy-fields']) {
                data.raw_data['option']['copy-fields'].forEach(copy_field => {
                    if (!copy_field['from']) {
                        error_a.push('コピー元項目は必須です');
                    }
                    if (!copy_field['to']) {
                        error_a.push('コピー先項目は必須です');
                    }
                })

            }
        }
        if (['select', 'radio', 'checkbox'].indexOf(data.raw_data['type']) >= 0) {
            if (!data.raw_data['option']['items'] || data.raw_data['option']['items'].length == 0) {
                error_a.push('選択肢は必須です');
            }

        }


        /*
        if (!!data['__default_value'] || data['__default_value'] === 0) {
            data['option']['default'] = data['__default_value']
        } else {
            delete data['option']['default'];
        }
        if (!!data['__fixed_value'] || data['__fixed_value'] === 0) {
            data['option']['value'] = data['__fixed_value']
        } else {
            delete data['option']['value'];
        }
         */


        if (error_a.length > 0) {
            this.toasterService.clear();
            this.toasterService.error(error_a.join(','), 'エラー');

            return
        }

        //from form 削除
        if (data.raw_data['option']['copy-fields']) {
            data.raw_data['option']['copy-fields'].forEach(copy_field => {
                delete copy_field['from_form'];
                //copy_field['to'] = 'field__'+copy_field['to']
            })

        }


        console.log('add field')

        if (!data.raw_data['edit_component_x_order']) {
            data.raw_data['edit_component_x_order'] = 1;
            data.raw_data['edit_component_y_order'] = this.fields.length + 1;
        }


        this.settingModal.hide();


        this.settingData.setInstanceData({
            raw_data: {'option': {}}
        })
        if (this.settingIndex !== null) {
            this.data.child_data_by_table['dataset_field'][this.settingIndex] = data;
        } else {
            this.data.child_data_by_table['dataset_field'].push(data);
        }
        this.convertChildDataToFields()
    }

    // データ定義の項目削除
    deleteFieldData(field) {
        let dataset_fields = this.data.child_data_by_table['dataset_field'];

        let index = dataset_fields.findIndex(dataset_field => field.Field == 'field__' + dataset_field.raw_data.id || field.Field == dataset_field.raw_data.dummy_id)

        dataset_fields.splice(index, 1);

        this.convertChildDataToFields()
    }

    // データ定義の項目順番変更
    swapFieldData(from_index: number, to_index: number) {
        this.data.changeChildIndex('dataset_field', from_index, to_index)
        this.convertChildDataToFields()
    }

    moveFieldData(moved_field_obj) {
        // debugger;
        this.field_xy = this.data.moveChildIndex('dataset_field', 0, 0, moved_field_obj);
        this.convertChildDataToFields();
    }

    isFormStyle() {
        return !this.table_info || !this.table_info.menu.style || this.table_info.menu.style == 'form' || this.is_dataset_edit;
    }

    isQuestionnaireStyle() {
        return !this.isFormStyle();
    }

    goList() {
        this._router.navigate([this._share.getAdminTable(), this.table]);
    }

    /**
     * WORKFLOW
     */
    workflow_apply_start() {
        if (!this.validate()) {
            return;
        }
        this.WorflowModal.show();

    }

    isWorkflowApplyAvailable() {
        return this.table_info.menu.is_workflow && (!this.workflow.id || ['rejected', 'done'].indexOf(this.workflow.status) >= 0);
    }

    closeWorkflow() {
        this.WorflowModal.hide();
    }

    closeCommentModal() {
        this.CommentModal.hide();
    }

    public workflow_comment: string = null;

    workflowChanged($event) {
        this.workflow = $event.workflow;
        this.workflow_comment = $event.workflow_comment;
    }

    /**
     * COMMENT
     */

    private comment: string = null;

    commentChanged($event) {
        this.comment = $event.comment;

    }


    onChangeGrant($event) {
        this.data.setRawData({'everyone_grant_json': $event.grant_value});
    }


    /**
     * IFRAME FUNC
     */
    fitIframeHeight() {
        setTimeout(() => {
            window.parent.postMessage({'mode': 'style', 'style': {height: (document.getElementById('pc-edit-view').offsetHeight + 100) + 'px'}}, '*');
        }, 500);
    }


    /**
     * フィルタ系
     */

    public customFilter: CustomFilter;
    public userTableSetting: UserTableSetting = null;

    resetFilter() {
        this.userTableSetting.filter_id = null;
        this.customFilter = null;

    }

    selectFilter($event) {
        this.customFilter = $event.filter
        this.userTableSetting.filter_id = this.customFilter.id

    }


    private setFilter(filter_id) {
        this.table_info.saved_filters.forEach(_filter => {
            if (_filter['id'] == filter_id && _filter.edit_use_show_fields) {
                this.userTableSetting.filter_id = filter_id
                if (!this.customFilter) {
                    //const params = JSON.parse(_filter['params_json'])
                    this.customFilter = cloneDeep(_filter)
                    //FIXME: 後でasyncにする
                    this.customFilter.conditions.reloadViewValuesTmp(this.table_info, this._connect, this._share)
                }
            }
        })

    }

    hasEditFilter() {
        if (!this.table_info) {
            return [];
        }
        return this.table_info.saved_filters.find(_filter => {
            return _filter.edit_use_show_fields
        })
    }

    changed() {
        console.log('changed!!!')
        this.is_edited = true
    }


    changeGrantType(type: string) {
        if (type == 'custom' && this.mode == 'add') {
            return;
        }
        this.dataset_grant_type = type;
    }

    isDatasetOrGroupEdit(): boolean {
        return ['dataset', 'dataset_group'].indexOf(this.table) >= 0
    }
}
