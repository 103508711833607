import {Component, HostListener, Input, OnChanges} from '@angular/core';
import {SharedService} from '../../services/shared';
import {Router} from '@angular/router';
import {Data} from '../../class/Data';
import {Forms} from '../../class/Forms';
import { GroupService } from 'app/services/utils/group-service';
import { SortingService } from 'app/services/utils/sorting-service';
import { OrientationChangeService } from 'app/services/utils/orientation-handler-service';
import {GlobalVariables} from 'app/common/global-variables';
import {CustomFilter} from '../../class/Filter/CustomFilter';

@Component({
    selector: 'admin-view-table',
    templateUrl: './table.component.html',
})

export class ViewTableComponent implements OnChanges {
    @Input('fields') fields: Array<any>;
    @Input('ignore_fieldname_a') ignore_fieldname_a: Array<string> = [];
    @Input('table') table: string;
    @Input('forms') forms: Forms;
    @Input('data') data: Data;
    @Input('extend_headers') extend_headers: Array<any>;
    @Input('filter') customFilter: CustomFilter = null;
    public fieldrows: Array<any>;
    public downloading: Object = {};
    private narrow_view_fields;
    private wide_view_fields;

    constructor(public _share: SharedService, private _router: Router, public groupService: GroupService, public sortingService: SortingService, private orienationChangeService: OrientationChangeService) {

    }

    @HostListener('window:orientationchange', ['$event'])
    handleOrientationChange(event) {
        this.fieldrows = this.orienationChangeService.changeFieldOrientation(this.wide_view_fields, this.narrow_view_fields)
        console.log(this.fieldrows)
    }


    ngOnChanges() {
        let wide_grouped_fields = []
        let narrow_grouped_fields = []
        if (this.table) {
            this._share.getTableInfo(this.table).subscribe(_table_info => {

                this.fields.forEach(field => {
                    narrow_grouped_fields.push([field])

                    if (wide_grouped_fields.length == 0 || field.y == undefined || !_table_info.menu.layout_apply_view || this.customFilter) {
                        wide_grouped_fields.push([field])
                    } else {
                        this.groupService.groupByAxis(wide_grouped_fields, field)
                    }
                })

                if (wide_grouped_fields.length > 1) {
                    this.sortingService.selectionSort(wide_grouped_fields, 0, 'y')
                }

                this.wide_view_fields = wide_grouped_fields;
                this.narrow_view_fields = narrow_grouped_fields
                if (window.visualViewport.width < GlobalVariables.maxSmartPhoneWidth) {
                    this.fieldrows = this.narrow_view_fields
                } else {
                    this.fieldrows = this.wide_view_fields
                }
            })
        }

    }

    download_file(url, field) {
        this.downloading[field] = true;
        this._share.download_file(url, () => {
            this.downloading[field] = false;
        });
    }

    is_string(data) {
        return data instanceof String;
    }

    extendDataClicked(extend_data) {
        if (extend_data['link'] != undefined) {
            this._router.navigate(extend_data['link'].split('/'));
        }
    }
}
