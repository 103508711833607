<link *ngIf=" _share.admin_setting && _share.admin_setting['custom_css_file_info_id']" rel="stylesheet" [href]="_share.custom_css_url">
<link *ngIf="_share.env==='development'" rel="stylesheet" href="http://localhost/sample/custom_test.css">
<span id="extend_scripts"></span>
<span id="extend_styles"></span>
<header class="app-header navbar">
    <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
    <a class="navbar-brand" [routerLink]="'/'+_share.getAdminTable()+'/dashboard'">{{_share.title}}</a>

    <ul class="nav navbar-nav d-md-down-none b-r-1">
        <li class="nav-item">
            <a class="nav-link navbar-toggler" href="javascript:void(0)" appSidebarToggler>&#9776;</a>
        </li>
    </ul>
    <div *ngIf="_share.getHeaderDatasetName()!=''" class="header_extend d-none d-lg-inline-block m-auto">
        <h5>
            {{_share.getHeaderDatasetName()}}
            <small>合計</small>
            <strong class="h5 mx-1">{{_share.getHeaderDatasetCount()}}</strong>
            <small>件</small>
        </h5>
    </div>
    <ul class="nav navbar-nav" [ngClass]="{'ml-auto': _share.getHeaderDatasetName() == ''}">
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <button id="bell-notify-icon-link" class="nav-link nav-pill" style="position: relative" (click)="toggleNotificationList()" data-toggle="dropdown" role="button"
                    aria-haspopup="true" aria-expanded="false"
                    dropdownToggle>
                <i class="icon-bell"></i>
                <span *ngIf="_share.getNotificationCount()>0" class="badge badge-pill badge-danger ml-2">{{_share.getNotificationCount()}}</span>
            </button>
            <div id="header_notification" class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu aria-labelledby="simple-dropdown">
                <div class="dropdown-header text-center"><strong>You have {{_share.notification_a.length}} notifications</strong></div>
                <div *ngFor="let noti of _share.notification_a">
                    <span *ngIf="!noti.url" class="dropdown-item" [innerHTML]="noti.content">
                        <i class="icon-doc text-success"></i> {{noti.content}}
                    </span>

                    <a href="{{noti.url}}" class="dropdown-item" *ngIf="!!noti.url" [innerHTML]="noti.content">
                        <i class="icon-doc text-success"></i> {{noti.content}}
                    </a>
                </div>
                <div class="text-center p-2"><strong>
                    <a href="javascript:void(0)" (click)="goNotificationPage()">通知一覧 &gt;&gt;</a>
                </strong></div>
            </div>
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link nav-pill avatar" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle
               [ngClass]="{'nobackground':_share.user!=null && _share.user.image!=null}">
                <img *ngIf="_share.user!=null && _share.user.image_url!=null; else NO_UESR_IMG" src="{{_share.user.image_url}}">
                <ng-template #NO_UESR_IMG>
                    <i *ngIf="_share.user!=null && _share.user.image==null" class="fa fa-user" aria-hidden="true"></i>

                </ng-template>
            </a>
            <div
                class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
                <!--
                                                                <a class="dropdown-item" href="javascript:void(0)"><i class="fa fa-user"></i> Profile</a>
                                                                -->
                <a class="dropdown-item" href="javascript:void(0)" (click)="toEditProfile()" *ngIf="_share.show_setting && _share.account_editable">
                    <i class="fa fa-user"></i>
                    {{_share.setting_name}}</a>
                <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.isMasterUser()" (click)="toDatasetPage()">
                    <i class="fa fa-database"></i>
                    テーブル管理</a>
                <!--
                <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.hasMenu('view')" (click)="toViewPage()" >
                    <i class="fa fa-list"></i>
                    View管理</a>
                -->
                <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.isMasterUser()  && _share.hasMenu('admin')" (click)="toUserPage()">
                    <i class="fa fa-users"></i>
                    ユーザー管理</a>
                <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.isMasterUser() && _share.hasMenu('division')" (click)="to('division')">
                    <i class="fa fa-building-o"></i>
                    組織管理</a>
                <a class="dropdown-item" href="javascript:void(0)" *ngIf="_share.isMasterUser() && _share.hasMenu('position')" (click)="to('position')">
                    <i class="fa fa-tag"></i>
                    役職管理</a>
                <a *ngIf="_share.hasMenu('notification')" class="dropdown-item" href="javascript:void(0)" (click)="toNotiPage()">
                    <i class="fa fa-bell-o"></i>
                    通知設定</a>
                <a *ngIf="!_share.system_view_disabled && _share.isMasterUser()" class="dropdown-item" href="javascript:void(0)" (click)="toStorageManagement()">
                    <i class="fa fa-bar-chart"></i>
                    システム利用状況</a>
                <a *ngIf="_share.user!=null && _share.isMasterUser()" class="dropdown-item" href="javascript:void(0)" (click)="toCommonGrantSetting()">
                    <i class="fa fa-lock"></i>
                    権限設定</a>
                <a *ngIf="_share.user!=null && _share.isMasterUser()" class="dropdown-item" href="javascript:void(0)" (click)="toEditAdminSetting()">
                    <i class="fa fa-cog"></i>
                    その他設定</a>
                <a *ngIf="_share.user!=null && _share.isMasterUser() && (_share.isTrial() || _share.cloud_setting['payment_method']=='paypal')" class="dropdown-item" href="javascript:void(0)"
                   (click)="toPayment()">
                    <i class="fa fa-file-text-o"></i> 契約設定</a>
                <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" id="logout">
                    <i class="fa fa-sign-out"></i>
                    ログアウト</a>
            </div>
        </li>
        <li
            class="nav-item d-md-down-none" style="min-width: 12px;"><!--
												          <a class="nav-link navbar-toggler" href="javascript:void(0)" appAsideMenuToggler>&#9776;</a>
												          -->
        </li>
    </ul>
</header>

<div class="app-body" >
    <div class="sidebar " [ngClass]="{'smart-sidebar':_share.sorted_menu.length>20}">

        <nav class="sidebar-nav">
            <ul class="nav list-unstyled">
                <!--
                                                                <li class="nav-title text-center">
                                                                  <span>Menu</span>
                                                                </li>
                                                                -->

                <ng-container *ngFor="let sorted_menu_one of _share.sorted_menu">
                    <ng-container *ngIf="!isArray(sorted_menu_one.value) && sorted_menu_one.value.show_menu">
                        <li class="nav-item">
                            <a routerLinkActive="active" (click)="clickMenu()" [routerLink]="sorted_menu_one.value.link" onMouseOut="this.style.background=''"
                               onMouseOver="this.style.background='rgb(38, 43, 44)'"
                               class="pt-2 pb-2 nav-link" style="display: block; padding-left: 8px;">
                                <i style="color: white; margin-right: 17px;" class="fa fa-{{sorted_menu_one.value.icon_class}} pl-2" aria-hidden="true"></i>
                                <span style="color: white;">{{sorted_menu_one.value.name}}</span>
                                <span class="badge badge-danger ml-2" *ngIf="!!sorted_menu_one.value.badge_text">{{sorted_menu_one.value.badge_text}}</span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="isArray(sorted_menu_one.value)">
                        <ng-container *ngIf="sorted_menu_one.stage_3">
                            <div class="nav-item nav-dropdown" dropdown [autoClose]="false">
                                <a class="nav-link nav-dropdown-toggle nav-arrow-left" dropdownToggle >
                                    <span style="color: white;">{{ sorted_menu_one.key }}</span>
                                </a>
                                <ul class="list-unstyled">
                                    <li *ngFor="let group_2 of sorted_menu_one.value" class="nav-item">
                                        <ng-container *ngIf="group_2.stage_3">
                                            <a (click)="clickGroup(sorted_menu_one.key + '////' + group_2.key)" onMouseOut="this.style.background=''"
                                               onMouseOver="this.style.background='rgb(38, 43, 44)'" class="pt-2 pb-2" style="display: block; padding-left: 35px;">
                                                <i *ngIf="!click_group[sorted_menu_one.key + '////' + group_2.key]" style="color: white;" class="fa fa-angle-right mr-3 pl-2" aria-hidden="true"></i>
                                                <i *ngIf="click_group[sorted_menu_one.key + '////' + group_2.key]" style="color: white;" class="fa fa-angle-down mr-3 pl-2" aria-hidden="true"></i>
                                                <span style="color: white;">{{ group_2.key }}</span>
                                            </a>
                                            <div class="list-unstyled" *ngIf="click_group[sorted_menu_one.key + '////' + group_2.key]">
                                                <div *ngFor="let group_3 of group_2.value">
                                                    <a routerLinkActive="active" (click)="clickMenu()" [routerLink]="group_3.link" onMouseOut="this.style.background=''"
                                                       onMouseOver="this.style.background='rgb(38, 43, 44)'"
                                                       class="pt-2 pb-2 nav-link" style="display: block; padding-left: 60px;">
                                                        <i style="color: white; margin-right: 15px;" class="fa fa-{{group_3.icon_class}} pl-2" aria-hidden="true"></i>
                                                        <span style="color: white;">{{ group_3.name }}</span>
                                                        <span class="badge badge-danger ml-2" *ngIf="!!group_3.badge_text">{{group_3.badge_text}}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!group_2.stage_3">
                                            <a routerLinkActive="active" (click)="clickMenu()" [routerLink]="group_2.link" onMouseOut="this.style.background=''"
                                               onMouseOver="this.style.background='rgb(38, 43, 44)'" class="nav-link pt-2 pb-2" style="display: block; padding-left: 35px;">
                                                <i style="color: white; margin-right: 15px;" class="fa fa-{{group_2.icon_class}} pl-2" aria-hidden="true"></i>
                                                <span style="color: white;">{{ group_2.name }}</span>

                                                <span class="badge badge-danger ml-2" *ngIf="!!group_2.badge_text">{{group_2.badge_text}}</span>
                                            </a>
                                        </ng-container>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!sorted_menu_one.stage_3">
                            <!-- <div [class.open]="activedropdown=== sorted_menu_one.key " (click)="opennav(sorted_menu_one.key)" class="nav-item nav-dropdown" dropdown [autoClose]="false"> -->
                            <div [ngClass]="{'open show': activedropdown === sorted_menu_one.key}" class="nav-item nav-dropdown" dropdown [autoClose]="false">
                                <a class="nav-link nav-dropdown-toggle nav-arrow-left" dropdownToggle>
                                    <span style="color: white;">{{ sorted_menu_one.key }}</span>
                                    <span class="badge badge-danger ml-2" *ngIf="getTotal(sorted_menu_one.value) != 0">{{ getTotal(sorted_menu_one.value) }}</span>
                                </a>
                                <ul class="list-unstyled">
                                    <ng-container *ngFor="let table_2 of sorted_menu_one.value">
                                        <li class="nav-item" *ngIf="table_2.show_menu">
                                            <a routerLinkActive="active" (click)="clickMenu()" [routerLink]="table_2.link" onMouseOut="this.style.background=''"
                                               onMouseOver="this.style.background='rgb(38, 43, 44)'" class="pt-2 pb-2 nav-link" style="display: block; padding-left: 35px;">
                                                <i style="color: white; margin-right: 15px;" class="fa fa-{{table_2.icon_class}} pl-2" aria-hidden="true"></i>
                                                <span style="color: white;">{{ table_2.name }}</span>
                                                <span class="badge badge-danger ml-2" *ngIf="!!table_2.badge_text">{{table_2.badge_text}}</span>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <li class="divider"></li>
                <li class="nav-item nav-link button_center" *ngIf="_share.common_grant && _share.common_grant.table_create">
                    <i class="fa fa-plus" (click)="addNewDataset()"></i>
                </li>
            </ul>
            <div class="text-center mt-50" *ngIf="_share.license=='trial'">Powered by
                <a href="https://pigeon-fw.com/cloud" target="_blank">Pigeon Cloud</a>
            </div>
        </nav>
    </div>
    <!-- Main content -->
    <main class="main mt-4" [ngClass]="{'not-dashboard':this._router.url != '/admin/dashboard'}">
        <div class="warning" *ngIf="_share.other_user_view">
            <div>
                現在プレビューモード中です。
                <button class="btn btn-default text-bold pull-right" type="submit" (click)="backtomyaccount()">
                    元のアカウントに戻る
                </button>
                <br style="clear:both">
            </div>
        </div>
        <div class="danger" *ngIf="_share.menu_a!=undefined && !_share.isValidMenuNumber()">
            無料プランで設定出来るメニュー数は３つまでです。３つ以上メニューを設定したい場合、<a href="https://pigeon-fw.com/mypage" target="_blank">こちら</a>よりライセンス購入を行って下さい。
        </div>
        <div class="warning" *ngIf="_share.isTrial() && _share.getTrialRestDays()<8">
            <div>
                トライアル期間は残り{{_share.getTrialRestDays()}}日です。プラン購入は<a href="/admin/payment/pay">こちら</a>。お問い合わせは担当者または <a href="mailto:sales@loftal.jp">sales@loftal.jp</a> までご連絡下さい。
            </div>
        </div>
        <div class="warning" *ngIf="!recommended_browser">
            <div>
                推奨されているブラウザは Edge, chrome, safariの最新版となります。それ以外のブラウザでは予期しない動作をする可能性があります。
            </div>
        </div>

        <div class="warning" *ngIf="!_share.is_mail_limit_exceed && _share.is_mail_count_reach_alert && this._share.isMasterUser()">
            <div>
                メール通知が上限の90%を超えています。上限を超えると通知メールが送られなくなります。<br>
                上限を変更するには <a href="mailto:sales@loftal.jp">sales@loftal.jp</a> までご連絡下さい。
            </div>
        </div>
        <div class="danger" *ngIf="_share.is_mail_limit_exceed">
            <div>
                メールの送信制限に達しているため、通知等のメールが送信されません。上限を変更するには <a href="mailto:sales@loftal.jp">sales@loftal.jp</a> までご連絡下さい。
            </div>
        </div>

        <div class="warning" *ngIf="_share.is_notification_exceed">
            <div>
                1日の通知制限に達しているため、通知が送信されません。上限を変更するには <a href="mailto:sales@loftal.jp">sales@loftal.jp</a> までご連絡下さい。
            </div>
        </div>

        <div class="warning" *ngIf="_share.debug_mode && false" style="text-align: center">
            Debug Mode
        </div>

        <!-- Breadcrumb -->
        <ol class="breadcrumb mb-0">
            <app-breadcrumbs></app-breadcrumbs>
        </ol>
        <div class="container-fluid" id="main-container">
            <router-outlet></router-outlet>

            <div bsModal #tableShortcut="bs-modal" class="modal fade " tabindex="-1" role="dialog"
                 aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-warning ">
                    <div class="modal-body">
                        <form class="shortcut_modal_form" (submit)="toTable()">
                            <input #inputField type="text" [(ngModel)]="labelText" class="shortcut-input" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto"
                                   id="table_shortcut">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)='toTable()'>
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                            <button (click)="toTable()" type="button" class="btn btn-sm btn-primary ml-2 pl-2 mb-1 btn-circle-30 shortcut-icon-btn">
                                <i class="fa fa-search"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.conainer-fluid -->
    </main>
    <app-aside></app-aside>

</div>

<footer class="app-footer" [hidden]="this._router.url != '/admin/dashboard'">
    <div class="text-right">
        Ver.{{_share.version}}
        Powered by
        <a href="https://pigeon-fw.com/cloud?from=poweredby" target="_blank">Pigeon Cloud</a>
    </div>
</footer>

