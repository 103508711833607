import {Data} from './Data';


export class GrantGroupData extends Data {


    public getDivisionLabel(): string {
        return this._child_data_by_table['grant_group_division_ids_multi'].map((_data: Data) => {
            return _data.view_data['value']
        }).join(',')
    }

    public getAdminLabel(): string {
        return this._child_data_by_table['grant_group_admin_ids_multi'].map((_data: Data) => {
            return _data.view_data['value']
        }).join(',')
    }
}
