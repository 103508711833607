import {CustomFilter} from './Filter/CustomFilter';

export class UserTableSetting {
    private _table: string = null;
    private _filter_id: number = null;
    private _open_memo: boolean = true;


    //clear when reload
    private _tmp_filter: CustomFilter = null;


    constructor(table: string) {
        this._table = table;
    }

    setByHash(hash = null) {
        if (!hash) {
            return;
        }
        for (const key of Object.keys(hash)) {
            if (!key.match(/^_tmp/)) {
                this[key] = hash[key];
            }
        }
    }

    private hasProperty<K extends string>(
        x: unknown,
        name: K
    ): x is { [M in K]: unknown } {
        return x instanceof Object && name in x;
    }

    get filter_id(): number {
        return this._filter_id;
    }

    set filter_id(value: number) {
        this._filter_id = value;
        this.save();
    }

    get open_memo(): boolean {
        return this._open_memo;
    }

    set open_memo(value: boolean) {
        this._open_memo = value;
        this.save();
    }


    get tmp_filter(): CustomFilter {
        return this._tmp_filter;
    }

    set tmp_filter(value: CustomFilter) {
        this._tmp_filter = value;
    }

    private save() {
        try {
            localStorage.setItem(this._table, JSON.stringify(this));
        } catch (e) {
            console.log('localstorage error')
        }
    }
}
