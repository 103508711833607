import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {TableInfo} from '../../class/TableInfo';
import {SharedService} from '../../services/shared';
import {UserTableSetting} from '../../class/UserTableSetting';


@Component({
    selector: 'memo',
    templateUrl: './memo.component.html'
})
export class MemoComponent implements OnChanges {


    // list , edit , view
    @Input() table_info: TableInfo;
    @Input() view_name: string
    @Output() onOpenClose: EventEmitter<Object> = new EventEmitter();

    public userTableSetting: UserTableSetting;
    public isDisplay: boolean = false;

    constructor(private _share: SharedService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.table_info) {
            return
        }
        this.userTableSetting = this._share.getUserTableSetting(this.table_info.table)
        this.isDisplay = this.table_info.menu && this.table_info.menu.isDisplayMemo('list')

        this.openCloseMemo(true)
    }

    openCloseMemo(init = false) {
        if (!init) {
            this.userTableSetting.open_memo = !this.userTableSetting.open_memo;
        }
        this.onOpenClose.emit({
            'status': this.userTableSetting.open_memo ? 'open' : 'close',
        })
    }


}
