import {Objectable} from '../../Objectable';

export class SummarizeFilterOptions extends Objectable {
    //塗りつぶし
    private _fill: boolean = false;
    //累積
    private _sum: boolean = false;

    private _sum_previous: boolean = false;
    //積み上げ棒グラフ
    private _stacked: boolean = false;
    private _stacked_100per: boolean = false;


    constructor(hash) {
        super();
        for (const key of Object.keys(hash)) {
            this['_' + key] = hash[key];
        }
    }


    get fill(): boolean {
        return this._fill;
    }

    set fill(value: boolean) {
        this._fill = value;
    }

    get sum(): boolean {
        return this._sum;
    }

    set sum(value: boolean) {
        this._sum = value;
    }

    get sum_previous(): boolean {
        return this._sum_previous;
    }

    set sum_previous(value: boolean) {
        this._sum_previous = value;
    }

    get stacked(): boolean {
        return this._stacked;
    }

    set stacked(value: boolean) {
        this._stacked = value;
    }

    get stacked_100per(): boolean {
        return this._stacked_100per;
    }

    set stacked_100per(value: boolean) {
        this._stacked_100per = value;
    }
}
