import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges,OnInit} from '@angular/core';
import {Router} from '@angular/router'
import ToastrService from '../toastr-service-wrapper.service';

import {SharedService} from 'app/services/shared';
import {Connect} from 'app/services/connect';
import {TableInfo} from '../class/TableInfo';
import {Data} from '../class/Data';
import {CrossTableHeader} from '../class/CrossTableHeader';
import {SelectOptionItemsFilter} from '../class/SelectOptionItemsFilter';
import * as cloneDeep from 'lodash/cloneDeep';
import {Forms} from '../class/Forms';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: '[dataset-table-row]',
    templateUrl: './dataset-table-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DatasetTableRowComponent implements OnChanges, OnInit {
    @Input() data: Data;
    @Input() view_fields: Array<any>;
    @Input() is_setting: boolean;
    @Input() child_a: Array<any>;

    @Input() showCheckbox: boolean;
    @Input() movable: boolean;
    @Input() isSummarizeMode: boolean;
    @Input() chart_params;
    @Input() showCRUDControls: boolean;
    //@Input() onDelete: Function;
    @Input() reload: Function;
    @Input() selectedCellId: number;
    @Input() setSelectedCellId: Function;
    @Input() setFormEditData: Function;
    @Input() showFormEditModal: Function;
    @Input() closeFormEditModal: Function;
    @Input() required_fields = [];
    @Input() table_info: TableInfo;
    @Input() data_index: number;
    @Input() isEditModalShown: boolean;
    @Input() isEditMode: boolean;

    @Input() is_relation_table: boolean = false;

    @Input() disable_float_management_buttons: boolean = false;
    @Input() stored_width_setting: [];

    @Input() stored_fields: [];
    @Input() dataset: '';
    @Input() crossTableHeader: CrossTableHeader;
    @Input() workflow_cell_left: 0;

    @Output() onCheckboxChange: EventEmitter<Object> = new EventEmitter();
    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();
    @Output() onUpDown: EventEmitter<Object> = new EventEmitter();
    @Output() dataSaved: EventEmitter<Object> = new EventEmitter();
    @Output() onFormatViewData: EventEmitter<Object> = new EventEmitter();
    // @Output() modalOpen: EventEmitter<any> = new EventEmitter();
    @Output() onDelete: EventEmitter<Object> = new EventEmitter();
    @Output() onOpenUnlockModal: EventEmitter<Object> = new EventEmitter();
    @Output() ctrlClickEvent: EventEmitter<any> = new EventEmitter();

    public update$ = new BehaviorSubject({last_changed: null});

    public editingData: Data;
    public table: string;
    public primary_key: string = 'id'
    private toasterService: ToastrService;
    private _connect: Connect;
    public required_data_errors: Array<any> = [];
    private has_required_errors: boolean = false;
    private editMode: boolean = false;
    public cellIds = {};
    private is_new_obj: boolean = false;
    public downloading: Object = {};
    public is_viewable_detail: boolean = true;
    public viewdata: Object;

    public selectOptionItemsFilter: SelectOptionItemsFilter;

    public rowForms: Forms;

    constructor(private _router: Router, private _share: SharedService, toasterService: ToastrService, _connect: Connect, private el: ElementRef, private _renderer: Renderer2) {
        this.toasterService = toasterService;
        this._connect = _connect;
    }


    getType(field_name) {
        if (this.table_info.forms.byFieldName(field_name) === undefined) {
            return '';
        }
        return this.table_info.forms.byFieldName(field_name).type || '';
    }

    validate = () => {
        let errors = [];
        this.view_fields.forEach(field => {
            if (this.table_info.forms.byFieldName(field.Field).required && !!!this.data.raw_data[field.Field] && this.data.raw_data[field.Field] !== 0) {
                errors.push(field.Field);
            }
        });
        this.has_required_errors = errors.length > 0;
        this.required_data_errors = errors;
    }


    ngOnInit() {
        this.editingData = cloneDeep(this.data)

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.table = this.table_info.table
        // this.fixedDataset = this.fixed_field_dataset == location.pathname.split('/admin/')[1];
        this.is_viewable_detail = this.table_info.grant.detail && this.data['raw_data']['id'] > 0;

        if (this.selectedCellId === null) {
            return
        }
        this.editMode = Object.keys(this.cellIds).map((cid) => this.cellIds[cid]).indexOf(this.selectedCellId) > -1;
        this.is_new_obj = this.data.raw_data[this.primary_key] < 1;
        if (this.editMode || this.is_new_obj) {
            this.validate();
        }
        if (this.view_fields) {
            this.view_fields.forEach(field => {
                this.cellIds[field.Field] = this.data.raw_data[this.primary_key] + '_' + field.Field;
            });
        }
        if (changes.data) {
            this.selectOptionItemsFilter = this.table_info.getSelectItemOptionsFilter(this.data)
        }
        this.rowForms = cloneDeep(this.table_info.forms);
    }

    goToEdit(event) {
        if (event && (event.ctrlKey || event.metaKey)) {
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`${this._share.getAdminTable()}/${this.table}/edit/${this.data.raw_data[this.primary_key]}`])
            );
            window.open(url, '_blank')
            return;
        }
        this._router.navigate([this._share.getAdminTable(), this.table, 'edit', this.data.raw_data[this.primary_key]]);
    }

    toComment() {
        const params = {};
        params['comment_open']=true;
        this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.data.raw_data[this.primary_key], params]);
    }

    view(event) {
        if (event && (event.ctrlKey || event.metaKey)) {
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`${this._share.getAdminTable()}/${this.table}/view/${this.data.raw_data[this.primary_key]}`])
            );
            window.open(url, '_blank')
        } else {
            this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.data.raw_data[this.primary_key]]);
        }
    }


    extendDataClicked(extend_data) {
        if (extend_data['link'] !== undefined) {
            this._router.navigate(extend_data['link'].split('/'));
        }
    }


    download_file(url, data, field, no_action_log = false) {
        this.downloading[field] = true;

        this._share.download_file(url, () => {
            this.downloading[field] = false;
        }, no_action_log);
    }

    onValueChanged($event) {
        console.log($event)
        let hash = {}
        hash[$event['form'].field['Field']] = $event.value
        this.editingData.setRawData(hash)
        console.log(this.editingData)
        this.selectOptionItemsFilter = this.table_info.getSelectItemOptionsFilter(this.editingData)
        this.valueChanged.emit($event);
        this.table_info.reflectRequiredShowCondition($event['form'].field['Field'], this._share, this._connect, this.rowForms, this.data, !!this.data.raw_data['id'] ? 'edit' : 'add').subscribe(res => {
            this.update$.next({last_changed: this.data.last_dirty_changed.toString()});
        })
    }

    onFormatViewDataRow($event) {
        if (!['image', 'file'].includes($event.field.Type)) {
            let formData = new FormData();
            if ($event.raw_data == null) {
                formData.append($event.field.Field, '');
            } else {
                formData.append($event.field.Field, $event.raw_data);
            }
            this._connect.postUpload(`/admin/format/data/${this.table}`, formData).subscribe((jsonData) => {
                this.data.view_data[$event.field.Field] = jsonData['view_data'][$event.field.Field]
            });
        }
    }


    getSummarizeType(field_name) {
        if (field_name.match(/^y/)) {
            return 'number';
        }
        return 'text'
    }

    up(data) {
        this._connect.post('/admin/order/' + this.table + '/' + data.raw_data[this.table_info.primary_key] + '/up', {}).subscribe(
            (jsonData) => {
                this.onUpDown.emit('up');

            },
        );
    }

    down(data) {
        this._connect.post('/admin/order/' + this.table + '/' + data.raw_data[this.table_info.primary_key] + '/down', {}).subscribe(
            (jsonData) => {
                this.onUpDown.emit('up');
            },
        );
    }

    checkboxChange($event) {
        this.onCheckboxChange.emit()
    }


    private isPlainByFieldCache = {}

    isPlainData(field): boolean {
        if (field['Field'] == 'x1') {
            return false;
        }
        if (!this.isPlainByFieldCache[field['Field']]) {
            this.isPlainByFieldCache[field['Field']] = !['textarea', 'image', 'thumbnail', 'file', 'url', 'number', 'richtext', 'select_other_table', 'calc'].includes(this.getDataType(field))
        }
        return this.isPlainByFieldCache[field['Field']]
    }

    private dataTypeByFieldCache = {}

    getDataType(field: Object): string {
        if (!this.dataTypeByFieldCache[field['Field']]) {
            this.dataTypeByFieldCache[field['Field']] = this.table_info.forms.byFieldName(field['Field']) != undefined ? this.table_info.forms.byFieldName(field['Field']).original_type : (this.isSummarizeMode ? this.getSummarizeType(field['Field']) : null)
        }
        return this.dataTypeByFieldCache[field['Field']]
    }

    openUnlockModal() {
        this.onOpenUnlockModal.emit({
            'data': this.data
        });
    }


    getCellStyle(field_name: string, simple_view_mode: boolean = false): Object {
        let style = {};
        if (simple_view_mode) {
            style = this.table_info.forms.byFieldName(field_name) != undefined ? this.table_info.forms.byFieldName(field_name).getCellStyle() : {};
        }

        if (this.data.col_style_by_field && this.data.col_style_by_field[field_name]) {
            style = Object.assign(style, this.data.col_style_by_field[field_name])
        }
        if(this.stored_width_setting) {
            this.stored_width_setting.map(setting => {
                if (setting['field'] == field_name) {
                    style = {'width': setting['current_width'], 'text-overflow': 'ellipsis'}
                }}
            )
        }
        if(this.stored_fields.length != 0) {
            this.stored_fields.map((x,i)=> {
                if(Object.keys(x)[0] == field_name) {
                    style = Object.assign({},style, {'left':`${x[field_name]}px`, 'position': 'sticky', 'z-index': 2, 'background': 'white'})
                }
            })
        }
        // if(this.stored_fields.length == 0) {
        //     style = Object.assign(style, {'background': 'black'})
        // }
        if (style['width'] !== undefined) {
            style = Object.assign(style, {'overflow': 'hidden', 'white-space': 'normal'})
        }

        return cloneDeep(style);
    }


    crlClickEventRow($event) {
        this.ctrlClickEvent.emit($event);
    }
    check_grant_permission(data) {
        this._connect.post('/api/admin/addpermissionview/'+data.raw_data['id'],{}).subscribe(_data => {
            this._share.loadAdminDatas().then(() => {
                window.location.reload()
            });
        })
    }

}
