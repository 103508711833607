import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Data} from 'app/class/Data';
import { TableInfo } from 'app/class/TableInfo';
import { Workflow } from 'app/class/Workflow';
import { Connect } from 'app/services/connect';
import { SharedService } from 'app/services/shared';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-modal',
  templateUrl: './view-modal.component.html',
})
export class ViewModalComponent implements OnChanges {

  @Input() data: Object;
  private toasterService : ToastrService;
  public table_info: TableInfo
  public page_title: string;
  public loading = true;
  public viewdata: Data = null;
  public before_html;
  public content_html;
  public content_html_before;


  private table: string;
  private unique_val: number;
  private hide_table = false;
  private extend_scripts: Array<string> = [];
  private extend_styles: Array<string> = [];

  private extend_headers: Array<any>
  private extend_data: {};

  public workflow: Workflow = null;
  public sending: boolean;

  // @Output() onOk: EventEmitter<Object> = new EventEmitter();
  // @Output() onCancel: EventEmitter<Object> = new EventEmitter();
    @Output() onLoad: EventEmitter<Object> = new EventEmitter();

  @ViewChild('viewModal', {static: false}) viewModal: any;

  constructor(private connect: Connect, private share: SharedService) {
  }

  public show() {
      this.viewModal.show()
  }

  public hide() {
      this.viewModal.hide()
  }

  ngOnChanges(){
    this.callback(this.data)
  }

  callback(data){
    if(Object.keys(data).length > 0){

        this.loading = true;

            this.before_html = data.tbinfo.view_before_html;
            this.page_title = data.tbinfo.menu.name + '詳細';

            this.table_info = data.tbinfo;

            this.viewdata = new Data(this.table_info);


            this.extend_headers = data.tbinfo.extend_headers;
            this.connect.get('/admin/view/' + this.table_info.table + '/' + data.id).subscribe((_data) => {

                // console.log(_data)
                this.content_html = _data['content_html'];
                this.content_html_before = _data['content_html_before'];

                _data = _data['data']
                this.loading = false;
                this.viewdata.setInstanceData(_data);
                //FIXME:ngrx
                this.share.setCurrentData(this.viewdata, this.table_info);

                this.onLoad.emit()

            });

    }

  }
}
