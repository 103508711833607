import {Base} from '../Base';
import {Conditions} from '../Conditions';
import {Workflow} from '../Workflow';

export class WorkflowTemplate extends Base {

    private _conditions: Conditions;
    private _workflow: Workflow;

    constructor(hash = {}) {
        super(hash);
        this._conditions = new Conditions();
        this._workflow = new Workflow();

        if (hash) {
            if (hash['conditions']) {
                this._conditions = new Conditions(hash['conditions']['condition_a']);
            }

            if (hash['workflow']) {
                this._workflow = new Workflow(hash['workflow']);
            }
        }

    }

    get conditions(): Conditions {
        return this._conditions;
    }

    get workflow(): Workflow {
        return this._workflow;
    }

    set workflow(value: Workflow) {
        this._workflow = value;
    }
}
