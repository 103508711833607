import {Condition} from './Condition';
import {Objectable} from './Objectable';
import {TableInfo} from './TableInfo';
import {Connect} from '../services/connect';
import {SharedService} from '../services/shared';

export class Conditions extends Objectable {
    get condition_or_group(): Array<Array<Condition>> {
        return this._condition_or_group;
    }


    private _condition_a: Array<Condition>;
    private _condition_or_group: Array<Array<Condition>>

    protected array_ignore_fields = ['condition_or_group'];

    public error_message: string = null;

    constructor(condition_param_a: Array<any> = []) {
        super();
        this._condition_a = [];
        this.setByParamAry(condition_param_a)
    }

    setByJson(condition_json) {
        this._condition_a = [];
        try {
            let condition_param_a = JSON.parse(condition_json);
            this.setByParamAry(condition_param_a)
        } catch (e) {
        }
    }

    setByParamAry(condition_param_a, reset = false) {
        if (reset) {
            this._condition_a = [];
        }

        condition_param_a.forEach(condition_param => {
            let condition = this.getConditionByParam(condition_param)
            this._condition_a.push(condition);
        })
        this.reloadConditionGroup();

    }

    setByParamAndIndex(condition_param: Object, index: number) {
        this.condition_a[index] = this.getConditionByParam(condition_param)
        this.reloadConditionGroup();

    }

    private getConditionByParam(condition_param: Object): Condition {
        const condition = new Condition(condition_param['condition'], condition_param['field'], condition_param['use_variable'] ? condition_param['variable_id'] : condition_param['value'], condition_param['and_or'], condition_param['use_variable'] ? condition_param['use_variable'] : false, condition_param['inc_table'], condition_param['inc_field'], condition_param['inc_filter_id']);

        if (condition_param['type'] == 'select') {
            //条件指定の場合
            condition.setSelectCondition(condition_param['select_condition'])
        }

        if (condition_param['subfield_a']) {
            //下層の項目条件がある場合
            condition_param['subfield_a'].forEach((obj: { table: string, field: string }) => {
                condition.addSubField(obj.table, obj.field)
            })

        }
        return condition
    }

    get condition_a(): Array<Condition> {
        return this._condition_a;
    }


    /**
     *
     * @param condition eq|noteq|inc|notinc|gt|lt|gt_ne|lt_ne\null|not_null
     * @param field
     * @param value
     * @param and_or
     */
    addCondition(condition: string = 'eq', field: string = 'id', value: string = null, and_or: string = 'and'): Condition {
        const cond = new Condition(condition, field, value, and_or);
        this._condition_a.push(cond);
        this.reloadConditionGroup();

        return cond;
    }

    addConditionToAllAndGroup(condition: string = 'eq', field: string = 'id', value: string = null) {
        if (this._condition_a.length == 0) {
            let cond = new Condition(condition, field, value, 'and');
            this._condition_a.push(cond)
            return;
        }

        for (let i = this._condition_a.length - 1; i >= 0; i--) {
            if (i == this._condition_a.length - 1) {
                let cond = new Condition(condition, field, value, 'and');
                this._condition_a.push(cond)
                console.log(cond)
            } else if (this.condition_a[i].and_or == 'or' && i != 0) {
                let cond = new Condition(condition, field, value, 'and');
                this._condition_a.splice(i, 0, cond)
                console.log(cond)
            }
        }

    }


    deleteConditionById(id: string) {
        this._condition_a.forEach((condition, index: number) => {
            if (condition.id === id) {
                this.deleteCondition(index);
            }
        })

        this.reloadConditionGroup()

    }

    deleteCondition(index: number): void {
        this.condition_a.splice(index, 1);
        console.log(this.condition_a)
        this.reloadConditionGroup()
    }

    deleteAllConditions(): void {
        this._condition_a = [];
        this.reloadConditionGroup()
    }

    replaceCondition(index: number, condition: Condition) {

        console.log(condition)
        this._condition_a[index].clone_by(condition)
        this.reloadConditionGroup()
    }

    getSearchParam(): Array<any> {
        let param_a = [];
        this._condition_a.forEach(condition => {
            if (condition.use_variable) {
                param_a.push({
                    'and_or': condition.and_or,
                    'variable_id': condition.variable_id,
                    'field': condition.field,
                    'type': condition.type,
                    'condition': condition.condition,
                    'use_variable': true
                })
            } else if (condition.type == 'select') {
                param_a.push({
                    'and_or': condition.and_or,
                    'select_condition': condition.select_condition,
                    'type': condition.type
                })
            } else {
                param_a.push({
                    'subfield_a': condition.sub_fields.map((obj) => {
                        return {'table': obj.table, 'field': obj.field}
                    }),
                    'and_or': condition.and_or,
                    'value': condition.value,
                    'field': condition.field,
                    'condition': condition.condition,
                    'type': condition.type,
                    'inc_table': condition.inc_table,
                    'inc_field': condition.inc_field,
                    'inc_filter_id': condition.inc_filter_id
                })
            }
        })
        return param_a
    }

    getSearchParamJson(): string {
        let param_a = this.getSearchParam()

        return JSON.stringify(param_a)


    }

    getConditionsByOrGroup(): Array<Array<Condition>> {
        let or_group = [];
        let group = [];
        this._condition_a.forEach((condition, index: number) => {
            if (condition.and_or == 'or') {
                or_group.push(group)
                group = [];
            }
            group.push(condition)
            if (this._condition_a.length - 1 === index) {
                or_group.push(group)
            }
        })

        return or_group;
    }

    reloadConditionGroup() {
        this._condition_or_group = this.getConditionsByOrGroup();
    }

    reloadViewValuesTmp(table_info: TableInfo, _connect: Connect, _share: SharedService) {
        this._condition_a.forEach((condition: Condition) => {
            condition.loadExtraDetail(table_info, _share, _connect)
        })

    }

    public validate(): boolean {
        this.error_message = '';
        let validate_flg = true;
        this._condition_a.forEach(condition => {
            if (!condition.validate()) {
                this.error_message += condition.error_message + ' ';
                validate_flg = false
            }
        })
        return validate_flg

    }
}

