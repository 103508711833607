import {ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {Connect} from '../../services/connect';
import ToastrService from '../../toastr-service-wrapper.service';
import {SharedService} from '../../services/shared';
import {ActivatedRoute, Router} from '@angular/router';
import {TableInfo} from '../../class/TableInfo';
import {Data} from '../../class/Data';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Forms} from '../../class/Forms';
import {Form} from '../../class/Form';
import {Board} from 'app/class/Board';
import {isArray} from 'jquery';
import {OrientationChangeService} from 'app/services/utils/orientation-handler-service';
import {CustomFilter} from '../../class/Filter/CustomFilter';
import * as cloneDeep from 'lodash/cloneDeep';
import {GroupService} from 'app/services/utils/group-service';
import {SortingService} from 'app/services/utils/sorting-service';
import {GlobalVariables} from 'app/common/global-variables';
import {SelectOptionItemsFilter} from '../../class/SelectOptionItemsFilter';
import {PublicFormService} from '../../services/public-form-service';

@Component({
    selector: 'admin-forms',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.css']
})

export class FormsComponent implements OnInit, OnChanges {
    @Input() table_info: TableInfo;
    @Input() fields: Array<any>;
    @Input() forms: Forms;
    @Input() data: Data;
    @Input() image_data: {};
    @Input() mode: string; // add or edit
    @Input() error_a;
    @Input() grant_menu_a;
    @Input() is_setting: boolean;
    @Input() IS_IFRAME_MODE: boolean = false;
    //for disabled inputs
    @Input() customFilter: CustomFilter;

    // テーブル定義用
    @Input() is_custom_table_definition: boolean = false;
    @Input() is_custom_table_definition_popup: boolean = false;
    @Input() is_exist_table_definition: boolean;
    @Input() parent;
    @Input() child_index: number = null;
    @Input() dataStructured;
    @Input() is_add_new_button_select_other_table: boolean = true;

    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();

    public show_fields: Array<any> = [];
    public board = new Board({});

    public other_tables: Array<any> = [];
    public isTableSettingOptionCollapsed = true;
    // テーブル定義のフォーマットを項目から選択か、自分でフォーマット記述するかどうか
    public otherTableOption = 'select';

    public grant: {} = {};
    private toasterService: ToastrService;

    //VIEw DEFINITION
    private view_dataset_fields: Array<any> = [];
    private view_target_table: string;
    public view_menu: Array<any>;

    public child_data_a_by_fieldname = {};

    //forms fieldへの更新検知用
    public last_updated: Date = null

    public select_other_edit_modal_form: Form = null;
    //@ViewChild('selectTypeModal', { static: false }) selectTypeModal: any;

    public selectOptionItemsFilter: SelectOptionItemsFilter;


    // テーブル定義項目用削除モーダル
    @ViewChild('deleteFieldModal') deleteFieldModal: any;
    @ViewChild('selectOtherEditModal') selectOtherEditModal: any;


    private froala_option: Object = {
        placeholderText: '',
        charCounterCount: false
    };
    private datetime_config_ja = {
        firstDayOfWeek: 0,
        dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    };

    private modal_data;
    private view_data;
    private raw_data;

    private modal_field;


    private exist_table_a: Array<any> = [];
    private exist_table_field_by_table = {}
    /**
     * Table定義用
     */
    private pre_table: string = null;
    public auto_complete_values: Array<any>
    public placeholder_visible = false;
    private wide_view_fields;
    private narrow_view_fields;

    @Output() rendered: EventEmitter<any> = new EventEmitter();


    constructor(private _router: Router, private _connect: Connect, toasterService: ToastrService, private _share: SharedService, private _route: ActivatedRoute, private renderer: Renderer2, private orientationChangeService: OrientationChangeService, private cd: ChangeDetectorRef, private groupService: GroupService, private sortingService: SortingService, private publicFormService: PublicFormService) {
        this.toasterService = toasterService;
    }

    ngOnChanges(changes: SimpleChanges): void {
        //select_other_tableの項目の、絞り込みフィルタ設定
        this.setSelectOptionItemFilter();
    }


    @HostListener('window:orientationchange', ['$event'])
    handleOrientatoinChange(event) {
        this.fields = this.orientationChangeService.changeFieldOrientation(this.wide_view_fields, this.narrow_view_fields)
    }


    ngOnInit() {
        // debugger;
        console.log('form rendered')
        this.selectOptionItemsFilter = new SelectOptionItemsFilter()
        this.rendered.emit(true)

        if (!this.dataStructured) {
            let group_fields = []
            this.fields.forEach(field => {
                if (group_fields.length == 0 || field.y == undefined) {
                    group_fields.push([field])
                } else {
                    this.groupService.groupByAxis(group_fields, field)
                }
            })


            if (group_fields.length > 1) {
                this.sortingService.selectionSort(group_fields, 0, 'y');

            }

            this.fields = group_fields;
        }
        this.wide_view_fields = this.fields;

        this.onChangeData()
        let mobile_view_fields = []
        this.fields.map(field => {
            if (isArray(field)) {
                field.map(field_column => {
                    mobile_view_fields.push([field_column])

                })
            } else {
                mobile_view_fields.push(field)

            }

        })
        this.narrow_view_fields = mobile_view_fields
        if (!this.is_custom_table_definition && window.visualViewport.width < GlobalVariables.maxSmartPhoneWidth) {
            this.fields = this.narrow_view_fields

        }


        this.reflectRequiredShowCondition(null);

        console.log(this.fields)
    }


    onChangeData() {
        //FIXME:後で親componentに伝える
        console.log('IS_ADD')
        console.log(this.is_add_new_button_select_other_table)
        this.view_data = this.data.view_data;
        this.raw_data = this.data.raw_data;
        this.fields.forEach((field) => {
            if (isArray(field)) {
                field.forEach((field_column) => {
                    this.grant[field_column.Field] = this.raw_data[field_column.Field] === 'all' ? 'all' : 'custom';

                    if (this.forms.byFieldName(field_column.Field) && this.forms.byFieldName(field_column.Field).is_multi_value_mode) {
                        this.child_data_a_by_fieldname[field_column.Field] = this.getChildDataAry(field_column.Field);
                    }
                })
            } else {
                this.grant[field.Field] = this.raw_data[field.Field] === 'all' ? 'all' : 'custom';

                if (this.forms.byFieldName(field.Field) && this.forms.byFieldName(field.Field).is_multi_value_mode) {
                    this.child_data_a_by_fieldname[field.Field] = this.getChildDataAry(field.Field);
                }
            }

        })
        this.forms.getArray().forEach(form => {
            form.resetConditionRequiedShowFlg()
            //FIXME: comment
            //this.onEdit(form.field['Field'])
        })
        // this.cd.detectChanges()
        // console.log('form on chang data', this.data.last_dirty_changed)
    }

    public isTableFieldDefinition() {
        return (this.is_custom_table_definition || this.is_exist_table_definition) && (this.is_custom_table_definition_popup || this.isShowFieldDefinitionForm(null));
    }


    getFroalaOption() {

        let option = this._share.getFroalaOption('simple', this.table_info.table);
        option['key'] = this._share.froala_key;
        return option;
    }

    clickOverArea($event, field) {
        if ($event) {
            if ($event.target.tagName.toUpperCase() === 'DIV') {
                this.parent.openSettingModal(field, false);
            }
        }
    }

    openSettingModal(field) {
        this.parent.openSettingModal(field, false);
    }

    openTableDetectionDeleteModal(field) {
        this.modal_field = field;
        this.deleteFieldModal.show();
    }


    haveLargeItem(form) {
        return (form.option.length > 30)
    }

    isEditableField(field) {
        return this.isShowField(field) && !field.fixed_value && (this.mode !== 'edit' || field.only_add === false) && this.table_info.grant.isEditableField(field['Field']) &&
            (!this.data.grant || this.data.grant.edit);
    }

    isShowField(field) {
        // console.log(this.data.raw_data)
        let form = this.forms.byFieldName(field.Field);
        if (!form) {
            return false;
        }

        let is_hide_field_by_custom_filter: boolean = false;
        if (this.customFilter && this.customFilter.edit_use_show_fields && this.customFilter.show_fields.length > 0) {
            if (this.mode === 'add') {
                is_hide_field_by_custom_filter = this.customFilter.show_fields.indexOf(field['Field']) == -1 && !form.isRequired()
            } else if (this.mode === 'edit') {
                is_hide_field_by_custom_filter = this.customFilter.show_fields.indexOf(field['Field']) == -1
            }
        }

        return ['created', 'updated'].indexOf(field.Field) === -1 &&

            field.editable && this.forms.byFieldName(field.Field).label !== null &&
            (field.show_edit === undefined || field.show_edit) &&
            !this.raw_data['__disabled'] &&
            ((this.parent != undefined && this.parent.table === 'dataset') || this.forms.byFieldName(field.Field).type !== 'calc' || this.raw_data['id'] !== undefined || this.forms.byFieldName(field.Field).is_calc_auto_reload_off) &&
            form.is_show_by_condition &&
            !is_hide_field_by_custom_filter;
    }


    isEdittableFieldType(field_type) {
        return ['created_at', 'updated_at'].indexOf(field_type) === -1
    }

    is_string(data) {
        return data instanceof String;
    }

    getTablenameFromTable(table) {
        for (const key of Object.keys(this.exist_table_a)) {
            const exist_table = this.exist_table_a[key];
            if (exist_table['value'] === table) {
                return exist_table['label'];
            }
        }

        return null;
    }

    getThis() {
        return this;
    }

    getEditComponent() {
        return this.parent;
    }

    /**
     * Table定義用
     */
    setChildFieldForms(reset = false) {
        this.parent.setChildFieldForms(reset)
    }

    /**
     * itemsの項目追加時
     */
    addSelectItem(data) {
        if (!data['option']['items']) {
            data['option']['items'] = [];
        }
        data['option']['items'].push({'value': '', 'label': ''})

    }


    /**
     * テーブル定義項目削除
     * @param field
     */
    deleteField() {
        this.parent.deleteFieldData(this.modal_field)

        this.deleteFieldModal.hide();
    }

    isShowFieldDefinitionForm(field) {
        if (!this.is_exist_table_definition || (field !== null && (field.Field === 'use' || field.Field === 'field'))) {
            return true;
        }
        return this.raw_data['use'] !== 'false';
    }

    getValue(field) {
        let value = this.raw_data[field['Field']];
        if (!value) {
            return value;
        }
        if (value instanceof Date) {
            value = this._share.getDateTimeStringByDate(value, this.forms.byFieldName(field['Field']).type);
        }
        return value;

    }

    shouldShowForm(form: Form) {
        if (form.label === '通知するワークフローステータス') {
            let flg = form.shouldShowForm(this.raw_data)
        }
        return form.shouldShowForm(this.raw_data)
    }

    getAutoCompleteValues(table) {
        this._share.getTableInfo(table).subscribe(table_info => {
            let fields_array = [];
            if (!table_info.forms) {
                return;
            }
            table_info.forms.getArray().forEach((form: Form) => {
                fields_array.push(form.label)
                //fields_array.push(key)
            })
            // console.log(fields_array)
            this.auto_complete_values = fields_array;
        })
    }

    private pre_select_other_value: string;

    onValueChanged($event) {
        this.last_updated = new Date();
        if ($event.child_table == null) {
            let form = this.table_info.forms.byFieldName($event.field_name)
            if (form && form.original_type == 'select_other_table') {
                if ($event.value === '__NEW__') {
                    this.pre_select_other_value = $event.pre_value
                    this.addNewSelectOtherTableData(form)
                    return;
                }

            }

            let updHash = {};
            updHash[$event.field_name] = $event.value;
            this.data.setRawData(updHash);
            this.raw_data = this.data.raw_data
            this.view_data = this.data.view_data

            //copy fieldを読み込む
            let value = $event.value;
            if ($event.field_name == 'table' && $event.value != null) {
                this.getAutoCompleteValues($event.value)
            } else {
                if ($event.field_name == 'table' && $event.value == null) {
                    this.auto_complete_values = []
                }
            }
            if (form && form.original_type === 'select_other_table' && form.copy_fields && form.copy_fields.length > 0 && value && !this.isAutoFillField(form.field['Field'])) {
                this._share.getTableInfo(form.item_table, false, null, true, false).subscribe(_table_info => {
                    this._connect.getOne(_table_info, value).subscribe(_data => {
                        let copy_from_data = new Data(_table_info)
                        copy_from_data.setInstanceData(_data['data_a'][0])
                        let updHash = {}

                        let is_update_exist = false;
                        form.copy_fields.forEach(copy_field => {
                            is_update_exist ||= (!!this.data.getRawData(copy_field['to']) && !copy_field['is_auto_reflect']);
                        })

                        let update_confirmed = false;
                        if (is_update_exist && form.is_lookup_update_confirm) {
                            update_confirmed = confirm('現在の値を上書きしてよろしいですか？');

                        }

                        let uncopied_field_name_a = []
                        form.copy_fields.forEach(copy_field => {
                            if (this.data.getRawData(copy_field['to']) && !copy_field['is_auto_reflect'] && !update_confirmed) {
                                uncopied_field_name_a.push(this.table_info.forms.byFieldName(copy_field['to']).label)
                                return;
                            }
                            updHash[copy_field['to']] = copy_from_data.getRawData(copy_field['from'])
                        })
                        this.data.setRawData(updHash);
                        if (uncopied_field_name_a.length > 0) {
                            this.toasterService.warning(uncopied_field_name_a.join(',') + 'は空ではないため自動入力されませんでした');
                        }
                        //this.data = this.data.getCopy()
                    })
                }, error => {
                    let uncopied_field_name_a = []
                    form.copy_fields.forEach(copy_field => {
                        if (!this.data.getRawData(copy_field['to']) && !copy_field['is_auto_reflect']) {
                            uncopied_field_name_a.push(this.table_info.forms.byFieldName(copy_field['to']).label)
                        }
                    });
                    this.toasterService.warning('ルックアップ先の閲覧権限がないため、' + uncopied_field_name_a.join(',') + 'は更新されません');
                })

            }


            //絞り込みフィールドを読み込む
            if (form) {
                console.log(form)
                this.setSelectOptionItemFilter()

            }

        } else {
            //multiple value
            this.data.setChildData(this.data.getChildTableInfoByTable($event.child_table), {'value': $event.value}, $event.data_index);
        }


        //テーブル定義用 Dataset Difinition
        if (this.is_exist_table_definition || this.is_custom_table_definition) {
            if (this.is_custom_table_definition) {
            } else {
                // admin tableとtableが選択されたら、項目を自動的に読み込む
                this.onSelectTable(this.raw_data);
            }
        }

        this.onEdit($event)

        this.valueChanged.emit($event);
    }

    setSelectOptionItemFilter() {
        this.selectOptionItemsFilter = this.table_info.getSelectItemOptionsFilter(this.data)
    }

    onEdit($event = null) {

        console.log('onedit')
        if (!this.is_custom_table_definition && this.table_info.table.match(/^dataset_/)) {
            this.reflectRequiredShowCondition($event ? $event.field_name : null)
        }
    }

    reflectRequiredShowCondition(field_name: string) {
        this.table_info.reflectRequiredShowCondition(field_name, this._share, this._connect, this.forms, this.data, this.mode, this.is_setting, this.is_custom_table_definition, this.IS_IFRAME_MODE, this.publicFormService.publicPostParams).subscribe(res => {
        })
    }

    onSelectTable(data) {
        // FIXME: confirm
        if (data.admin_table === null || data.table === null) {
            return;
        }


        this.setChildFieldForms(this.pre_table !== null && this.pre_table !== data.table);

        // 昇順・降順の項目設定
        this._connect.get('/admin/all-table-field-select/' + this.raw_data['table']).subscribe((result) => {
            this.forms.byFieldName('default_order_field').option = result.items;

        });

    }

    /**
     * VIEW DEFINITION
     */


    viewFieldIsCheck(data, value) {
        if (data !== undefined && data !== null) {
            const data_a = data.split(',');
            // FIXME: [checked]イベント発火が多いが問題ない？（適当な場所をクリックするだけで発火など）
            // console.log(data_a);
            if (data_a.indexOf(value) >= 0) {
                return true;
            }
        }
        return false;
    }

    viewFieldCheckboxChange() {

        const checkbox_a = document.getElementsByName('view_field');
        let value = '';
        let continue_next_edit = false;

        for (let i = 0; i < checkbox_a.length; i++) {
            if (continue_next_edit) {
                continue_next_edit = false;
                continue;
            }
            const checkbox = <HTMLInputElement>checkbox_a[i];
            if (checkbox.value.match(/_view$/)) {
                // 閲覧権限がない場合、編集権限も無しとする
                if (!checkbox.checked) {
                    continue_next_edit = true;
                }
            }
            if (checkbox.checked) {
                value += checkbox.value + ',';
            }
        }
        // 最後のカンマを消す
        value = value.slice(0, -1);
        // console.log(value);
        this.raw_data['fields'] = value;
    }


    /**
     * VIEW DEFINITION END
     */


    /**
     * MULTIPLE
     */
    getValueCount(field_name) {
        let number = 1;
        if (this.forms.byFieldName(field_name)['is_multi_value_mode']) {
            if (this.raw_data[field_name] == undefined) {
                number = 0;
            } else {
                number = this.raw_data[field_name].length;
            }
        }

        return Array(number).fill(1).map((x, i) => i);
    }

    addChildDatafromformsfield($event) {
        const totalformfieldfilemulti = $event.total_file + $event.return_data_index;
        this.addChildData(this.getChildByField($event['field_name']), true, -1, $event, $event.fileList, $event.field_name, $event.return_data_index, totalformfieldfilemulti)
    }

    canAddChildData(field_name: string): boolean {
        let form = this.table_info.forms.byFieldName(field_name)
        if (!form) {
            return true;
        }

        if (!form.max_child_number) {
            return true;
        }

        return form.max_child_number > this.child_data_a_by_fieldname[field_name].length

    }

    addChildData(child, scroll_after_add = true, order = -1, $event, addfile_data = null, filed_name = null, return_data_index = null, totalformfieldfilemulti = null) {
        if ($event.x == 0 && $event.y == 0) {
            //on enter
            return;
        }
        const new_data = {}
        if (this.is_exist_table_definition) {
            new_data['option'] = {}
        }

        if (order === -1) {
            this.data.setChildData(child, new_data);
            //this.data.child_data_by_table[child.table].push(new_data)
        }

        const index = this.data.child_data_by_table[child.table].length - 1;
        child.fields.forEach(field => {
            const _data = (this.data.child_data_by_table[child.table][index] === undefined) ? {} : this.data.child_data_by_table[child.table][index];
            this.setFieldDefault(_data, child.forms, field);
            this.data.setChildData(child, new_data, index);
        });
        if(filed_name!=null){
            let dataindex=return_data_index+1;
            if(!this.child_data_a_by_fieldname[filed_name][dataindex].getRawData('value')){
                let hashData= {};
                hashData['value']=addfile_data;
                hashData['ismultiple']=1;
                this.child_data_a_by_fieldname[filed_name][dataindex].setRawData(hashData);
            }
            else{
                for(let i = dataindex; i <= totalformfieldfilemulti; i++){
                    if(!this.child_data_a_by_fieldname[filed_name][i].getRawData('value')){
                        let hashData= {};
                        hashData['value']=addfile_data;
                        hashData['ismultiple']=1;
                        this.child_data_a_by_fieldname[filed_name][i].setRawData(hashData);
                        break;
                    }
                }
            }
        }
        this.onChangeData()
        return new_data;
    }

    addAllFormData(child, field_name: string) {
        this.data.child_data_by_table[child.table] = [];
        this.table_info.forms.byFieldName(field_name).option.forEach((_data) => {
            const new_data = {'value': _data.value}
            this.data.setChildData(child, new_data);
        })
        this.onChangeData()
    }

    deleteChildData(child, i) {
        this.data.child_data_by_table[child.table].splice(i, 1);
        this.onChangeData()
    }

    setFieldDefault(_data, _forms, field) {
        if (field.Default !== null) {
            _data[field.Field] = field.Default;
        }

    }

    getChildTablenameByFieldname(field_name) {
        if (!this.table_info) {
            return null;
        }
        let table_name = this.data.table_info.table + '_' + field_name + '_multi';
        return table_name;

    }

    getChildDataAry(field_name): Array<Data> {
        let table_name = this.getChildTablenameByFieldname(field_name)
        let data_a = this.data.getChildDataAry(table_name);
        return data_a;
    }

    getChildByField(field_name) {
        let child = null;
        this.data.child_a.forEach((_child) => {
            var _table_name = this.getChildTablenameByFieldname(field_name)
            if (_child.table == _table_name) {
                child = _child
                return false;
            }

        })
        return child;
    }


    drop(event: CdkDragDrop<string[]>, field, dropped_index) {
        // debugger;
        this.placeholder_visible = false;
        // moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
        let start_y = event.item.data.y;
        let end_y;
        if (event.previousContainer === event.container) {
            if (event.previousIndex != event.currentIndex) {
                end_y = start_y;
                moveItemInArray(this.fields[end_y - 1], event.previousIndex, event.currentIndex)
                if (this.fields[end_y - 1].length != 0) {
                    for (let i = 0; i < this.fields[end_y - 1].length; i++) {
                        this.fields[end_y - 1][i].x = i + 1;
                    }
                }
                this.parent.moveFieldData(this.fields);

            }
        } else {
            if (field.length == 0) {
                end_y = dropped_index + 1;
            } else {
                end_y = field[0].y;
            }
            if (this.fields[end_y - 1].length <= 3) {
                transferArrayItem(this.fields[start_y - 1], this.fields[end_y - 1], event.previousIndex, event.currentIndex)

                if (end_y != start_y) {
                    for (let i = 0; i < this.fields[end_y - 1].length; i++) {
                        this.fields[end_y - 1][i].x = i + 1;
                        this.fields[end_y - 1][i].y = end_y;
                        // console.log(this.fields[end_y-1][i])
                    }
                }

                if (this.fields[start_y - 1].length != 0) {
                    for (let i = 0; i < this.fields[start_y - 1].length; i++) {
                        this.fields[start_y - 1][i].x = i + 1;
                    }
                }

                if (
                    this.fields[start_y - 1].length == 0 &&
                    start_y - 1 != this.fields.length - 1
                ) {
                    // console.log(this.fields)
                    for (let i = start_y - 1; i < this.fields.length; i++) {
                        if (i != this.fields.length - 1) {
                            this.fields[i] = this.fields[i + 1];
                            this.fields[i].map((field) => {
                                field.y = i + 1;
                            });
                        } else {
                            this.fields[i] = [];
                        }
                    }
                }
                this.parent.moveFieldData(this.fields);
            }
        }
    }

    isFormStyle() {
        return !this.table_info.menu.style || this.table_info.menu.style == 'form' || this.is_custom_table_definition;
    }

    isQuestionnaireStyle() {
        return !this.isFormStyle();
    }


    isAutoFillField(field) {
        if (this.table_info.auto_copyto_fields.indexOf(field['Field']) >= 0) {
            return true;
        }
        if (this.forms.byFieldName(field['Field']) && this.forms.byFieldName(field['Field']).type === 'auto-id') {
            return true;
        }
        return false;

    }


    isChildForm() {
        return this.child_index !== null;
    }


    //select_othertableの新規データ追加
    addNewSelectOtherTableData(form: Form) {
        this._share.getTableInfo(form.item_table).subscribe(_table_info => {
            if (_table_info.grant.add) {
                this.select_other_edit_modal_form = form;
                this.selectOtherEditModal.show();
            } else {
                this.toasterService.error('追加権限がありません');
            }
        })

    }


    onSubmitSelectOtherNewData($event) {
        if (!this.select_other_edit_modal_form.is_multi_value_mode) {
            let form = this.table_info.forms.byFieldName(this.select_other_edit_modal_form.field['Field']);
            form.clearOptions()
            let updHash = {}
            updHash[this.select_other_edit_modal_form.field['Field']] = $event.id.toString();
            this.data.setRawData(updHash)
            this.onChangeData()

        }
        this.selectOtherEditModal.hide();
    }

    onCancelSelectOtherNewData() {
        this.selectOtherEditModal.hide();
    }


    /**
     * selectFieldPopup
     */
    public selectPopupField = null;

    showFieldPopup(field) {
        this.selectPopupField = field;
    }

}
