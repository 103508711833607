export class SimpleTableInfo {
    public table: string;
    public name: string;
    public group: string;

    public view_label: string;

    constructor(_table: string, _name: string, _group: string) {
        this.table = _table;
        this.name = _name;
        this.group = _group;

        this.view_label = this.getLabel()
    }

    public getLabel() {
        return (this.group ? this.group + ' / ' : '') + this.name;
    }

    public isDatasetTable() {
        return this.table.indexOf('dataset_') >= 0
    }

}
