<ng-container *ngIf="isTextField;else notText">
    <ng-container *ngIf="data.table_info.forms.byFieldName(field_name) && data.table_info.forms.byFieldName(field_name).is_child_form;else notChild">
        <ng-container *ngFor="let _child_data of data.view_data[field_name];last as isLast">
            {{_child_data}}
            <ng-container *ngIf="!isLast">,</ng-container>
        </ng-container>
    </ng-container>
    <ng-template #notChild>
        <ng-container *ngIf="data.table_info.forms &&  data.table_info.forms.byFieldName(field_name) && data.table_info.forms.byFieldName(field_name).is_multi_value_mode;else notMulti">
            <ng-container *ngFor="let _child_data of data.getMultiDataAry(field_name)">
                <ng-container *ngIf="!_child_data.link_data['value'] || nolink;else childHasLinkData">
                    {{_child_data.view_data['value']}}
                </ng-container>
                <ng-template #childHasLinkData>
                    <a href="javascript:void(0)"
                       (click)="toDetail(_child_data.link_data['value'])">
                        {{_child_data.view_data['value']}}
                    </a>

                </ng-template>
            </ng-container>
        </ng-container>
        <ng-template #notMulti>
            <ng-container *ngIf="!data.link_data[field_name] || nolink;else hasLinkData">
                <div class="view_{{dataType}} view_block"
                     [ngClass]="{'table-admin-list__cell-inner':!this.is_view_mode,'pre-wrap':this.table_info.forms.byFieldName(this.field_name) && this.table_info.forms.byFieldName(this.field_name).hasCustomTextLen()}">
                    {{data.view_data[this.field_name]}}
                    <a (click)="download_csv()"
                       *ngIf="table_info.table === 'csv' && form.field['Field'] === 'type' && data.raw_data[field_name]==='download' && data.raw_data['status']=='done' && is_show_download()">
                        <i style="cursor:pointer" class="fa fa-cloud-download blue" aria-hidden="true"></i>
                    </a>
                </div>

            </ng-container>
            <ng-template #hasLinkData>
                <div class="text-truncate">
                    <a href="javascript:void(0)"
                       (click)="toDetail(data.link_data[field_name])">
                        {{data.view_data[this.field_name]}}
                    </a>
                </div>
            </ng-template>
        </ng-template>


    </ng-template>


</ng-container>
<ng-template #notText>
    <ng-container [ngSwitch]="dataType">

        <div *ngSwitchCase="'grant_group'">
            <ng-container *ngIf="grantGroupData;else NotLoadGrantGroupData;">
                <ng-container *ngIf="grantGroupData.raw_data['everyone']==='true';else NotAllUser;">
                    全ユーザー
                </ng-container>
                <ng-template #NotAllUser>
                    <table class="table table-sm table-bordered grant-group-table">
                        <tr>
                            <th><i class="fa fa-users pl-2"></i><span class="pl-2">ユーザー</span></th>

                            <td style="background: white">
                                {{grantGroupData.getAdminLabel()}}
                            </td>
                        </tr>
                        <tr>
                            <th><i class="fa fa-building-o pl-2"></i><span class="pl-3">組織</span></th>
                            <td style="background: white">
                                {{grantGroupData.getDivisionLabel()}}
                            </td>
                        </tr>

                    </table>
                </ng-template>
            </ng-container>
            <ng-template #NotLoadGrantGroupData>
            </ng-template>
        </div>
        <div *ngSwitchCase="'richtext'" [froalaView]="data.view_data[this.field_name]"></div>


        <div *ngSwitchCase="'number'">
            <a *ngIf="field_name === 'id';else NotId" href="javascript:void(0)" (click)="view()">
                {{data.view_data[this.field_name]}}
            </a>
            <ng-template #NotId>
                <ng-container *ngFor="let _data of arrayValue">
                    <div>
                        <span *ngIf="form && !form.num_separator;else NoNumSep">
                        {{_data}}
                        </span>
                        <ng-template #NoNumSep>
                            {{_data}}
                        </ng-template>
                    </div>
                </ng-container>
            </ng-template>
        </div>
        <div *ngSwitchCase="'url'">
            <div>
                <a (click)="clickLink(rawArrayValue[i])" *ngFor="let _data of arrayValue;let i=index" href="javacript:void(0)" target="_blank">
                    {{_data}}
                </a>&nbsp;
            </div>
        </div>
        <div *ngSwitchCase="'image'">
            <img style="margin:5px" *ngFor="let _data of arrayValue;let i=index" class="pc-list-view__image" [src]="_data" (click)="openImg(i)" alt="">
        </div>
        <div *ngSwitchCase="'file'">
            <ng-container *ngFor="let _data of arrayValue">
                <a class="d-lg-none" [href]="_data['display_url']" target="_blank">
                    {{_data['name']}}
                </a>
                <a class="d-none d-lg-inline" *ngIf="_data!=null && !is_string(_data)"
                   href="javascript:void(0)"
                   (click)="showFileIfPossibleClicked(_data['url'],_data['name'],_data, template)">{{_data['name']}}</a>
                <a class="d-none d-lg-inline" *ngIf="_data!=null && is_string(_data)"
                   href="javascript:void(0)"
                   (click)="download_file(_data)">Download</a>
                <div
                    *ngIf="_data!=null && _data['hit_html']!=undefined"
                    [innerHTML]="_data['hit_html']">
                </div>
                <br>


            </ng-container>
            <div *ngIf="downloading">
                <div class="loader_small">Loading...</div>
            </div>

        </div>
    </ng-container>


</ng-template>

<ng-template #template>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <video width="100%" height="auto" controls>
                    <source [src]="src" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">Close</button>
    </div>
</ng-template>




