import {Grant} from './Grant';
import {Menu} from './Menu';
import {Forms} from './Forms';
import {CustomFilter} from './Filter/CustomFilter';
import {WorkflowTemplate} from './Workflow/WorkflowTemplate';
import * as cloneDeep from 'lodash/cloneDeep';
import {AdminTableSetting} from './AdminTableSetting';
import {Connect} from '../services/connect';
import {Observable} from 'rxjs/Observable';
import {Form} from './Form';
import {Ledger} from './Ledger';
import {OptionItem} from './OptionItem';
import {SelectOptionItemsFilter} from './SelectOptionItemsFilter';
import {Data} from './Data';
import {SharedService} from '../services/shared';

export class TableInfo {
    get copyto_fields(): Array<string> {
        return this._copyto_fields;
    }

    get is_child_form(): boolean {
        return this._is_child_form;
    }

    get table() {
        return this._table;
    }


    private _table;

    private _primary_key: string;
    private _fields: Array<any>;
    private _forms: Forms;
    private _parents: Array<Object> = [];

    private _order_field: string;
    private _is_admin_table: boolean = false;
    private _menu: Menu;

    private _list_before_html: string = null;
    private _view_before_html: string = null;
    private _edit_before_html: string = null;

    private _grant: Grant;

    private _saved_filters: Array<CustomFilter>;
    private _copyto_fields: Array<string> = [];
    private _auto_copyto_fields: Array<string> = [];

    private _child_a: Array<TableInfo>;
    //VIEW
    private _is_view: boolean = false;


    private _is_child_form: boolean;

    private _extend_headers: Array<any>;
    private _extend_search_forms: Array<any>;

    private _workflow_template: WorkflowTemplate;
    private _admin_table_setting: AdminTableSetting;

    private _is_ledger_active: boolean;
    private _system_table: string = null;

    private _ledger_a: Array<Ledger>;

    constructor(hash) {
        if (!hash) {
            return;
        }

        this._grant = new Grant(hash['grant']);
        this._child_a = [];
        if (hash['child_a'] != undefined) {
            hash['child_a'].forEach((child) => {
                child['grant'] = hash['grant'];
                let child_table_info = new TableInfo(child);
                this._child_a.push(child_table_info);
            })
        }
        for (const key of Object.keys(hash)) {
            if (this['_' + key] != undefined) {
                continue;
            }
            if (key == 'forms') {
                this._forms = new Forms(hash[key])
            } else if (key == 'saved_filters') {
                this._saved_filters = hash[key].map(_filter_hash => {
                    return new CustomFilter(_filter_hash)
                })
            } else if (key == 'workflow_template') {
                this._workflow_template = new WorkflowTemplate(hash[key])
            } else if (key == 'admin_table_setting') {
                this._admin_table_setting = new AdminTableSetting(hash[key])
            } else if (key == 'ledger_a') {
                this._ledger_a = [];
                hash[key].forEach(_ledger_hash => {
                    this._ledger_a.push(new Ledger(_ledger_hash))
                })
            } else if (key == 'menu') {
                this._menu = new Menu(hash[key]);
            } else {
                this['_' + key] = hash[key];
            }
        }

        if (hash['copyto_fields'] && hash['copyto_fields'].length > 0) {
            this._copyto_fields = hash['copyto_fields']
        }
        if (hash['auto_copyto_fields'] && hash['auto_copyto_fields'].length > 0) {
            this._auto_copyto_fields = hash['auto_copyto_fields']
        }
    }

    get primary_key(): string {
        return this._primary_key;
    }

    get fields(): Array<any> {
        return this._fields;
    }

    get forms(): Forms {
        return this._forms;
    }

    get parents(): Array<Object> {
        return this._parents;
    }

    get order_field(): string {
        return this._order_field;
    }

    get is_admin_table(): boolean {
        return this._is_admin_table;
    }

    get menu(): Menu {
        return this._menu;
    }

    get grant(): Grant {
        return this._grant;
    }

    get saved_filters(): Array<CustomFilter> {
        return this._saved_filters;
    }


    set saved_filters(value: Array<CustomFilter>) {
        this._saved_filters = value;
    }

    get is_view(): boolean {
        return this._is_view;
    }

    get child_a(): Array<TableInfo> {
        return this._child_a;
    }


    get list_before_html(): string {
        return this._list_before_html;
    }

    get view_before_html(): string {
        return this._view_before_html;
    }

    get edit_before_html(): string {
        return this._edit_before_html;
    }

    get extend_headers(): Array<any> {
        return this._extend_headers;
    }

    get extend_search_forms(): Array<any> {
        return this._extend_search_forms;
    }

    get workflow_template(): WorkflowTemplate {
        return this._workflow_template;
    }


    get admin_table_setting(): AdminTableSetting {
        return this._admin_table_setting;
    }


    get is_ledger_active(): boolean {
        return this._is_ledger_active;
    }


    get auto_copyto_fields(): Array<string> {
        return this._auto_copyto_fields;
    }


    get ledger_a(): Array<Ledger> {
        return this._ledger_a;
    }

    getFieldByFieldName(field_name: string): Object {
        let field: {} = null;
        this.fields.forEach(_field => {
            if (_field['Field'] == field_name) {
                field = _field;
            }
        });
        return cloneDeep(field);
    }

    hasSystemTable(): boolean {
        return !!this._system_table;
    }

    public getViewFields(customFilter: CustomFilter, isMasterUser: boolean, isSummarizeMode: boolean, is_relation_table: boolean, is_list: boolean = false): Array<Object> {
        let fields = [];
        if (customFilter && customFilter.isSetSummarizeParam() && customFilter.summarizeFilter.fields.length > 0) {
            customFilter.summarizeFilter.fields.forEach((_, key) => {
                if (customFilter.summarizeFilter.cross_table && key == 0) {
                    return true
                }
                if (_['_is_date']) {
                    fields.push({
                        'Field': 'x' + (key + 1),
                        'show_list': true,
                    })
                } else if (_['_table']) {
                    //customFilterに、その他のテーブル参照がある場合
                    fields.push({
                        'Field': 'x' + (key + 1),
                        'show_list': true,
                    })
                } else {
                    console.log(_)
                    fields.push(this.forms.byFieldName(_['_field']).field)
                }
                /*
                 */
            })
            if (!customFilter.summarizeFilter.cross_table) {
                customFilter.summarizeFilter.summary_a.forEach((_, key) => {
                    fields.push({
                        'Field': 'y' + (key + 1),
                        'show_list': true,
                    })
                })
            }

        } else {
            this.fields.forEach(val => fields.push(Object.assign({}, val)));

            if (customFilter) {
                //フィルタがある場合、その表示設定を優先
                let show_fields: Array<string> = customFilter.getShowFields(this);
                if (show_fields.length > 0) {
                    fields = [];
                    show_fields.forEach(field_name => {
                        let field = this.getFieldByFieldName(field_name);
                        if (field) {
                            field['show_list'] = true;
                            fields.push(field)
                        }
                    })
                }
            } else {
                let isShowField = (field) => {
                    if (isSummarizeMode) {
                        return true;
                    }
                    if (!is_relation_table) {
                        if (field['Field'] == 'id') {
                            return this.menu.show_id;
                        }
                        if (field['Field'] == 'updated') {
                            return this.menu.show_updated;
                        }
                        if (field['Field'] == 'created') {
                            return this.menu.show_created;
                        }
                        if (field['Field'] == 'admin_id') {
                            return this.menu.show_admin;
                        }
                    }
                    return is_list ? field.show_list : true
                }
                fields = fields.filter(isShowField)
            }

        }

        if (is_list) {
            fields = fields.filter(f => {
                let _form = this._forms.byFieldName(f['Field'])
                if (_form) {
                    if (_form.original_type === 'fixed_html') {
                        return false;
                    }
                }

                return true;
            })

        }


        return fields;
    }

    public getFieldNames(): Array<string> {
        return this.fields.map(field => {
            return field['Field']
        })
    }

    public getLabel(): string {
        if (!this.menu.group) {
            return this.menu.name;
        }
        return this.menu.group + '/' + this.menu.name;
    }

    public getJaClassName(): string {
        if (!this.menu.group) {
            return this.menu.name;
        }
        return this.menu.group + '-' + this.menu.name;
    }

    public getFilterName(id: number) {
        let filter = this.getFilterById(id)
        if (!filter) {
            return '-';
        }
        return filter.name;

    }

    public getFilterById(id: number): CustomFilter {
        return this.saved_filters.find((filter) => filter.id == id);

    }

    public isDatasetTable() {
        return this._table.indexOf('dataset_') >= 0
    }

    public getDatasetId() {
        return this._table.replace('dataset__', '');
    }

    public getClassName() {
        if (this._table.match(/dataset__/)) {
            return 'pc-table-' + this.getDatasetId()
        } else {
            return 'pc-' + this._table;
        }
    }

    public getChildTableInfo(table_name: string) {
        return this.child_a.find(child => child.table == table_name)
    }


    private loading_options_by_key: Object = {};

    getSelectOptions(form: Form, _connect: Connect, selectOptionItemsFilter: SelectOptionItemsFilter = null): Observable<Array<OptionItem>> {
        let load_key = this.table + '_' + form.field['Field'];

        if (this.loading_options_by_key[load_key]) {
            //同じテーブルを同時にロードしないように
            return new Observable((observer) => {
                let counter = 0;
                let interval = setInterval(() => {
                    if (form.option) {
                        clearInterval(interval)
                        observer.next(cloneDeep(form.option).map(option => {
                            return new OptionItem(option)
                        }));
                    } else if (++counter == 10) {
                        clearInterval(interval)
                        this.loading_options_by_key[load_key] = false;
                        this.getSelectOptions(form, _connect, selectOptionItemsFilter).subscribe(option_items => {
                            observer.next(option_items);
                        });
                    }
                }, 100)
                return {
                    unsubscribe() {
                    }
                };
            });
        }

        return new Observable((observer) => {

            if (form.option && selectOptionItemsFilter && !selectOptionItemsFilter.hasWhere(form.field['Field'])) {
                observer.next(cloneDeep(form.option.map(option => {
                    return new OptionItem(option)
                })));

            } else {
                this.loading_options_by_key[load_key] = true;
                const url = _connect.getApiUrl() + '/admin/table/form-option/' + this.table + '/' + form.field['Field'];
                _connect.post(url, {'data': selectOptionItemsFilter ? selectOptionItemsFilter.getConditions(form.field['Field'], this).toArray() : null}).subscribe(_data => {
                    this.loading_options_by_key[load_key] = false;
                    form.option = _data['option'];
                    observer.next(_data['option'].map(option => {
                        return new OptionItem(option)
                    }))
                })
            }

            return {
                unsubscribe() {
                }
            };
        });
    }

    /**
     * テーブルで、field_nameが必須または表示条件となるフォームがあるかどうか
     * field_name=null の場合、１つでも条件があるフィールドがあるかどうか
     * @param field_name
     */
    public hasRequiredOrShowCondition(field_name: string = null) {
        let flg: boolean = false;
        this.forms.getArray().forEach(form => {
            if (!flg && form.required_conditions) {
                form.required_conditions.condition_a.forEach(condition => {
                    if (!field_name || condition.field === field_name) {
                        flg = true;
                    }
                })
            }
            if (!flg && form.show_conditions) {
                form.show_conditions.condition_a.forEach(condition => {
                    if (!field_name || condition.field === field_name) {
                        flg = true;
                    }
                })
            }

        })
        return flg
    }


    /**
     * 組織選択時に、ユーザーを絞り込む機能
     * @param data
     */
    public getSelectItemOptionsFilter(data: Data): SelectOptionItemsFilter {
        let selectOptionItemsFilter = new SelectOptionItemsFilter()

        this.forms.getArray().forEach(compare_form => {
            this.forms.getArray().forEach(_form => {
                let value = data.getRawData(compare_form.field['Field'])
                if (_form.display_condition_fields && _form.display_condition_fields.length > 0) {
                    _form.display_condition_fields.forEach(display_condition_field => {
                        //編集したフィールドが、他の他テーブル参照項目の比較対象の値だった場合
                        if (display_condition_field['compare_field'] == compare_form.field['Field']) {
                            selectOptionItemsFilter.reset(_form.field['Field'], display_condition_field['compare_field'])
                            if (!value) {
                                value = null
                            }
                            selectOptionItemsFilter.add(_form.field['Field'], display_condition_field['select_other_table_field'], display_condition_field['compare_field'], value)

                        }
                    })
                }
            })
        });
        return selectOptionItemsFilter
    }


    public resetMemory() {
        this.forms.getArray().forEach(_form => {
            _form.setCellStyle()
        })
    }

    public reflectRequiredShowCondition(field_name: string, _share: SharedService, _connect: Connect, forms: Forms, _data: Data, mode: string, is_setting: boolean = false, is_custom_table_definition: boolean = false, is_iframe: boolean = false, iframe_params = {}): Observable<any> {


        return new Observable((observer) => {
            //編集されたフィールドが、他のフィールドの必須・表示に影響する場合
            let needOnEditPost: boolean = this.hasRequiredOrShowCondition(field_name);

            //編集されたフィールドが、他のフィールドの計算に影響する場合
            this.forms.getArray().filter(f => f.original_type == 'calc').forEach(_form => {
                if (!field_name) {
                    needOnEditPost = true;
                }
                needOnEditPost ||= _form.isReferenceTarget(field_name)
            })


            if (needOnEditPost) {
                let formData = _share.get_post_data(this, _data, this.fields, this.forms, _data.child_a, mode, is_setting, is_custom_table_definition, true);
                let url = '/admin/' + this.table + '/on-edit';
                if (is_iframe) {
                    url = '/iframe/' + this.table + '/on-edit';
                    Object.keys(iframe_params).forEach((k) => {
                        if (iframe_params[k]) {
                            formData.append(k, iframe_params[k]);
                        }
                    })
                }
                _connect.post(url, formData, {}, false).subscribe(res => {
                    Object.keys(res.fields).forEach(field_name => {
                        let field_condition_result = res.fields[field_name];
                        let form = forms.byFieldName(field_name)
                        if (!form) {
                            return;
                        }
                        form.is_required_by_condition = field_condition_result['required'];
                        form.is_show_by_condition = field_condition_result['show'];

                    })

                    this.forms.getArray().forEach(form => {
                        let updHash = {}
                        if (form.original_type === 'calc') {
                            if (res.tmp_view_data[form.field['Field']]) {
                                updHash[form.field['Field']] = res.tmp_view_data[form.field['Field']]
                            }
                        }
                        _data.setRawData(updHash);
                        observer.next({
                            'forms': forms,
                            'data': _data
                        })
                    })

                });
            }
            return {
                unsubscribe() {
                }
            };
        });


    }

}
