<!-- TEXT -->
<ng-container *ngIf="isInputFieldCondition() || force_use_textfield ">
    <input [(ngModel)]="value" (change)="changed()" [maxLength]="form['max_len']?form['max_len']:10000000" [minLength]="form['min_len']?form['min_len']:0"
           placeholder="{{this.placeholder ? this.placeholder : form.placeholder}}"
           type="text" name="{{field['Field']}}" id="_form_{{field['Field']}}" class="form-control custom-40-height">
</ng-container>

<!-- NUMBER -->
<input OnlyNumber (change)="changed($event)" *ngIf="form['type'] == 'number' || form['type'] == 'calc' || isTimingCondition()" [(ngModel)]="value" [object]="value" placeholder="{{form.placeholder}}"
       type="text"
       name="{{field['Field']}}" id="_form_{{field['Field']}}" class="form-control custom-40-height" [min]="min_num">

<!-- COLOR -->
<ng-template [ngIf]="form['type']=='color'">
    <input [(ngModel)]="value"
           type="text" name="{{field['Field']}}" class="form-control custom-40-height">
    <input type="color" [(ngModel)]="value"
           name="{{field['Field']}}">
</ng-template>

<!-- DATETIME -->
<div *ngIf="isDateTimeType() && !isTimingCondition()">
    <ng-container *ngIf="isTimingCondition() && !isDaysShow()">
        <input class="datetime"
               [owlDateTime]="owl_datetime"
               [owlDateTimeTrigger]="owl_datetime"
               name="{{field['Field']}}" [(ngModel)]="date_value">

        &nbsp;<i class="fa fa-calendar"></i>
        <owl-date-time #owl_datetime (afterPickerClosed)="datetimeValueChanged()" [pickerType]="'both'"></owl-date-time>


    </ng-container>
    <ng-container *ngIf="!isTimingCondition() && !isDaysEnabled() && !isDaysShow()">
        <label *ngIf="!select_date_btn" class="mb-0">
            <input [(ngModel)]="is_check_current_date" (change)="currentDateCheckChanged()" type="checkbox" [value]="true" name="is_check_current_date{{id}}">
            <span *ngIf="form.type==='time'">
            現在時刻
            </span>
            <span *ngIf="form.type!=='time'">
            現在日時
            </span>

            &nbsp;&nbsp;
        </label>
        <label class="mb-0">
            <input [(ngModel)]="select_date_btn" type="checkbox" name="select_date_btn{{id}}" (change)="onSelectDateBtn()">
            相対値
        </label>
        <ng-container *ngIf="select_date_btn">
            <div class="row ml-1">
                <select *ngIf="!select_date_option['is_number_form']" [(ngModel)]="select_date_option['value']" (change)="selectBtnChanged(select_date_option['value'])" class="col-5">
                    <option value="0 date">今日</option>
                    <option value="-1 date">昨日</option>
                    <option value="1 date">明日</option>
                    <option value="0 week">今週</option>
                    <option value="-1 week">先週</option>
                    <option value="1 week">来週</option>
                    <option value="0 month">今月</option>
                    <option value="-1 month">先月</option>
                    <option value="1 month">来月</option>
                    <option value="0 year">今年</option>
                    <option value="-1 year">去年</option>
                    <option value="1 year">来年</option>
                </select>
                <div *ngIf="select_date_option['is_number_form']" class="col-5">
                    <ng-container *ngIf="select_date_option['num'] < 0">
                        <span>{{-1 * select_date_option['num']}}</span>
                        <span>{{select_date_option['unit']}}</span>
                        <span>前</span>
                    </ng-container>
                    <ng-container *ngIf="select_date_option['num'] > 0">
                        <span>{{select_date_option['num']}}</span>
                        <span>{{select_date_option['unit']}}</span>
                        <span>後</span>
                    </ng-container>
                </div>
                <div class="col-3" style="min-width: 90px; !important">
                    <button class="btn btn-sm btn-default" (click)="selectButtonLeftChanged()"><i class="fa fa-angle-double-left"></i></button>
                    <button class="btn btn-sm btn-default" (click)="selectButtonRightChanged()"><i class="fa fa-angle-double-right"></i></button>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!select_date_btn">
            <i class="fa fa-trash text-danger" style="    font-size: 19px; padding: 5px;cursor: pointer" (click)="clearDateValue()"></i>
            <input class="datetime" [disabled]="is_check_current_date"
                   [owlDateTime]="owl_datetime"
                   [owlDateTimeTrigger]="owl_datetime"
                   name="{{field['Field']}}" [(ngModel)]="date_value"
            >


            &nbsp;<i *ngIf="form['type'] != 'time'" class="fa fa-calendar"></i>
            <i *ngIf="form['type'] == 'time'" class="fa fa-clock-o"></i>
            <owl-date-time #owl_datetime (afterPickerClosed)="datetimeValueChanged()"
                           [pickerType]="form['type'] == 'datetime' && set_time_flg_by_field[field['Field']]? 'both': form['type'] == 'time'? 'timer': 'calendar'"></owl-date-time>
            <ng-container *ngIf=" form['type'] == 'datetime'">
                <label style="margin-left:5px"><input type="checkbox" [(ngModel)]="set_time_flg_by_field[field['Field']]" value="true">&nbsp;時間も設定</label>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isTimingCondition() && isDaysEnabled()">
        <input (change)="changed()" [(ngModel)]="value" type="number"
        step="1" name="{{field['Field']}}" id="_form_{{field['Field']}}" class="form-control custom-40-height">
    </ng-container>
    <ng-container *ngIf="isDaysShow()">
        <input value="dammy" type="text" name="{{field['Field']}}" id="_form_{{field['Field']}}" class="form-control" type="hidden">
    </ng-container>
</div>

<!-- SELECT -->
<ng-select
    *ngIf="isUseNgSelect()"
    ng-required="true"
    class="custom form-control"
    style="height: 40px ; padding-top: 0px;"
    [items]="options_include_own"
    appendTo="body"
    [searchWhileComposing]="false"
    [virtualScroll]="true"
    bindLabel="label"
    bindValue="value"
    [multiple]="true"
    [(ngModel)]="value"
    (change)="changed()"
></ng-select>
<ng-template [ngIf]="form.type == 'boolean'">
    <div class="checkbox">
        <label>
            <!-- ngModelはhiddenに紐付け、checkboxでhiddenのvalueを変える -->
            <input type="checkbox" [checked]="value=='true'"
                   (change)="value=(value=='true')?'false':'true';" (change)="changed()">
            {{form.option_label}}
        </label>
    </div>
</ng-template>
