<div *ngIf="loading;then loading_block else loaded_block"></div>
<ng-template #loading_block>
    <div class="loader">Loading...</div>

</ng-template>
<ng-template #loaded_block>
    <ng-container *ngIf="data==null">
        <div class="data_not_found" style="padding:40px;text-align:center">
            DATA NOT FOUND
        </div>
    </ng-container>

    <ng-container *ngIf="data!=null">
        <admin-view-table [filter]="customFilter" [table]="table_info.table" [fields]="fields" [forms]="table_info.forms" [data]="data"
                          [extend_headers]="extend_headers"></admin-view-table>

        <!-- 子テーブル -->
        <ng-container *ngFor="let child of data.child_a">
            <ng-template
                [ngIf]="data.child_data_by_table[child.table] && data.child_data_by_table[child.table].length > 0 && child.is_child_form">
                <h4 class="mt-5">{{child.getLabel()}}</h4>

                <ng-container *ngIf="child.menu.multiple_mode=='normal'">
                    <ng-container *ngFor="let data of data.child_data_by_table[child.table]">
                        <div class="" *ngIf="child.forms != undefined">
                            <admin-view-table class="view-children" [ignore_fieldname_a]="['created','updated','id','order','admin_id']"
                                              [fields]="child.fields" [forms]="child.forms" [data]="data" [table]="child.menu.table">
                            </admin-view-table>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='table'">
                    <admin-view-table-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                        [data_a]="data.child_data_by_table[child.table]"></admin-view-table-list>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='image'">
                    <admin-view-image-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                           [data_a]="data.child_data_by_table[child.table]"></admin-view-image-list>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='chip' || child.menu.multiple_mode=='chip_toxi'">
                    <admin-view-chip-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                          [data_a]="data.child_data_by_table[child.table]"></admin-view-chip-list>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>


<!-- 関連テーブル -->
<ng-container *ngIf="data!=null">
    <ng-container *ngFor="let relation_table of data.relation_table_a">
        <div class="mt-3" id="relation_tables">
            <h3>{{relation_table.label}}</h3>

            <div *ngIf="relation_table.data_a.length==0;else relationDataExists" style="text-align: center"> データはありません
            </div>
            <ng-template #relationDataExists>
                <ng-container *ngIf="relation_table.view_mode">
                    <div class="mb-4" *ngFor="let _relation_data of relation_table.data_a">
                        <admin-view-table *ngIf="relation_table_info_by_table[relation_table.table]" [fields]="relation_table.getViewFields(relation_table_info_by_table[relation_table.table])"
                                          [forms]="relation_table_info_by_table[relation_table.table].forms" [data]="_relation_data"
                                          [table]="relation_table.table"></admin-view-table>
                    </div>
                </ng-container>
                <ng-container *ngIf="!relation_table.view_mode">
                    <admin-table [view_fields]="getRelationViewFields(relation_table)|async"
                                 [is_relation_table]="true" [table_info]="getRelationTableInfo(relation_table) | async"
                                 [data_a]="relation_table.data_a" [hide_scroll]="true" [isShowManageCol]="true" [reload]="reload"
                                 [isMovable]="false" [child_a_by_id]="{}"></admin-table>
                </ng-container>

            </ng-template>

        </div>
    </ng-container>

</ng-container>
