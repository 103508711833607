<div *ngIf="fieldrows" class="table table-bordered mb-0 table-admin-view">
    <div *ngFor="let row of fieldrows;" style="display:flex" class="table-admin-view__1row pc-view-row">
        <ng-container *ngFor="let field of row">
            <div class="table-admin-view__field-row" *ngIf="ignore_fieldname_a.indexOf(field.Field)==-1 && forms.byFieldName(field.Field).shouldShowForm(data.raw_data) && field.show_view"
                 [ngClass]="'field_type_'+forms.byFieldName(field.Field).original_type">
                <div class="table-admin-view__field-name pc-field-label">{{forms.byFieldName(field.Field).label}}
                    <ng-template appDebugMode="true">
                        <a>( {{field.Field}} )</a>
                    </ng-template>
                    <div *ngIf="forms.byFieldName(field.Field)['sub-label']"><small class="text-gray-dark">{{forms.byFieldName(field.Field)['sub-label']}}</small></div>
                </div>
                <div class="table-admin-view__field-value pc-field-value" [ngStyle]="forms.byFieldName(field.Field).detail_style">
                    <dynamic-data-view [is_view_mode]="true" [table_info]="data.table_info" [field_name]="field['Field']" [dataType]="forms.byFieldName(field['Field']).type"
                                       [data]="data"></dynamic-data-view>
                </div>
            </div>
            <ng-container *ngIf="forms.byFieldName(field['Field']).type=='fixed_html' && forms.byFieldName(field.Field)['_custom_field'].show_view_page">
                <div class="fixed_html_wrapper">
                    <div [innerHTML]="forms.byFieldName(field['Field']).fixed_html | safeHtml"></div>
                </div>
            </ng-container>
        </ng-container>
        
        <div *ngIf="row.length==3" class="table-admin-view__field-row" style="border:none">
            <!-- dummy -->

        </div>
    </div>

    <div *ngIf="extend_headers">
        <div *ngFor="let row of fields;" style="display:flex" class="table-admin-view__1row pc-view-row">
            <div class="table-admin-view__field-row" *ngFor="let field of row">
                <ng-container *ngFor="let header of extend_headers; let e = index">
                    <tr *ngIf="header.order_after==field.Comment">
                        <th class="table-admin-view__field-name pc-field-label">{{header.name}}</th>
                        <td *ngIf="!_share.is_object(data.extend_data[header.header_id]['data'])"
                            [innerHTML]="data.extend_data[header.header_id]['data']"></td>
                        <td *ngIf="_share.is_object(data.extend_data[header.header_id]['data'])"
                            [innerHTML]="data.extend_data[header.header_id]['data']['html']"
                            (click)="extendDataClicked(data.extend_data[header.header_id]['data'])"></td>
                    </tr>
                </ng-container>
            </div>

        </div>
    </div>


    <ng-container *ngFor="let header of extend_headers; let e = index">
        <tr *ngIf="header.order_after==-1 && data.extend_data[header.header_id]">
            <th class="table-admin-view__field-name pc-field-label">{{header.name}}</th>
            <td *ngIf="!_share.is_object(data.extend_data[header.header_id]['data'])" [innerHTML]="data.extend_data[header.header_id]['data']"></td>
            <td *ngIf="_share.is_object(data.extend_data[header.header_id]['data'])" [innerHTML]="data.extend_data[header.header_id]['data']['html']"
                (click)="extendDataClicked(data.extend_data[header.header_id]['data'])"></td>
        </tr>
    </ng-container>
</div>
