<ng-container *ngFor="let workflowPath of workflow.workflow_path_a;let i = index">
    <div class="row mt-2">
        <div class="col-md-4">
            <h5>承認者（組織）{{i + 1}}</h5>
        </div>
        <div class="col-md-8">
            <hr style="width:100%">
        </div>

    </div>
    <div class="col-md-12 row">
        <div class="col-md-10">
            <div class="radio">
                <label>
                    <input [(ngModel)]="workflowPath.type" name="type_{{i}}" (change)="changed()" type="radio" value="admin" [disabled]="disabled"> ユーザー
                </label>
                <label>
                    <input [(ngModel)]="workflowPath.type" name="type_{{i}}" (change)="changed()" type="radio" value="division" [disabled]="disabled"> 組織(役職)
                </label>
            </div>
            <div style="margin-bottom: 5px" *ngIf="workflowPath.type == 'admin'">
                <user-forms-field [disabled]="disabled" [index]="i" [default_admin_id]="workflowPath.admin_id" (valueChanged)="pathChanged($event,'admin_id')"></user-forms-field>
            </div>
            <div style="margin-bottom: 5px" *ngIf="workflowPath.type == 'division'">
                <div class="radio">
                    <label>
                        <input [(ngModel)]="workflowPath.division_grant_type" (change)="changed()" name="division_type_{{i}}" type="radio" value="all" [disabled]="disabled"> 全員の承認が必要
                    </label>
                    <label>
                        <input [(ngModel)]="workflowPath.division_grant_type" (change)="changed()" name="division_type_{{i}}" type="radio" value="one" [disabled]="disabled"> 一人の承認が必要
                    </label>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <division-forms-field [added_values]="getDivisionAddedValues()" [disabled]="disabled" [index]="i" [default_division_id]="workflowPath.division_id"
                                              (valueChanged)="pathChanged($event,'division_id')"></division-forms-field>
                    </div>
                    <div class="col-md-2 p-1">
                        の
                    </div>
                    <div class="col-md-4 ">
                        <!-- 役職 -->
                        <position-forms-field [add_all]="true" [disabled]="disabled" [index]="i" [default_position_id]="workflowPath.position_id"
                                              (valueChanged)="pathChanged($event,'position_id')"></position-forms-field>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1" *ngIf="!disabled">
            <button *ngIf="i!=0" class="btn btn-danger btn-sm" (click)="delete(i)"><i class="fa fa-minus"></i></button>
        </div>
    </div>


</ng-container>
