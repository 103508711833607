import {ChangeDetectorRef, EventEmitter, Component, HostListener, OnInit, Output, Renderer2, TemplateRef, SimpleChanges, ViewChild, ElementRef, OnDestroy, ContentChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized} from '@angular/router'
import ToastrService from '../toastr-service-wrapper.service';

import {Connect} from '../services/connect';
import {SharedService} from '../services/shared';
import {HttpHeaders} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {interval, Observable} from 'rxjs';
import {Grant} from '../class/Grant';
import {TableInfo} from '../class/TableInfo';
import * as cloneDeep from 'lodash/cloneDeep';
import {DataGrant} from '../class/DataGrant';
import {Data} from '../class/Data';
import {Form} from '../class/Form';
import {Condition} from '../class/Condition';
import {CustomFilter} from '../class/Filter/CustomFilter';
import {Variable} from '../class/Filter/Variable';
import {UserTableSetting} from '../class/UserTableSetting';
import {Conditions} from '../class/Conditions';
import {Ledger} from '../class/Ledger';
import {ModalModule, BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DynamicOverFlowService} from 'app/services/utils/dynamic-overflow';
import {CrossTableHeader} from '../class/CrossTableHeader';
import {filter, pairwise, windowCount} from 'rxjs/operators';
import {RecordListService} from '../services/RecordListService';

@Component({
    selector: 'app-dashboard',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
})

export class AdminComponent implements OnInit, OnDestroy {
    private calcTime = performance.now();

    /**
     * FOR VIEW
     */
    public isSummarizeMode = false;
    public fields = [];
    public field: {};


    /**
     * FOR COMPONENT
     */

        // no menu / search result etc
    public simpleViewMode: boolean = false;

    public hasChildren: boolean;

    private page_title: string;
    public first_loading: boolean;
    public loading: boolean;
    private page: number;
    private per_page: number;
    public table: string;
    private numbers: Array<number>;

    private data_a: Array<Data>;


    //private original_get_params: Object;
    //private search_params: Object;
    //public conditions: Conditions;


    public child_a: Array<any>;
    public child_a_by_id: Object = {};

    public table_info: TableInfo;
    public grant: Grant;

    public total_count: number;

    // ユーザーによって作成されたテーブルか
    public is_custom_dataset: boolean;

    // configファイルから読み込む場合
    private extend_datas: Array<any>;
    private extend_search_forms: Object;

    public checked_id_a: Array<number>;
    private csv_load_timer = null;
    private csv_upload_timer;

    public is_use_filter_for_csv_dl = false;

    private resetBeforeCsvUpload = 'false';
    private resetBeforeJSONUpload = 'false';

    private select_date_option = {
        'is_str': false,
        'num': 0,
        'value': '',
        'title': '',
    }


    private is_group_edit: boolean = false;

    //private isDirty = false;

    public before_html: string;
    public deletedStyle = {'background-color': '#fdd'};

    public downloading: Object = {};
    public sending = false;
    public isSpecialMenuCollapsed = true;
    public current_url: string;

    // search_logs用
    public search_log_base_fields;
    public search_log_base_forms;

    //view内の一時的な変数
    public __tmp_data: Object = {};

    // summarize & search
    private all_search_text = null;
    public customFilter: CustomFilter;

    public all_search_variables: Array<Variable> = [];

    //order
    public isMovable: boolean = false;

    //commit
    public isEditMode = false;
    public to_delete_commit: Array<Number> = [];

    public showContext = false;
    public selectedRowIndex = -1;
    public original_data_a: Data[];
    public contextMenuX = -1;
    public contextMenuY = -1;

    public value = null;

    //MEMO
    public userTableSetting: UserTableSetting = null;

    // tsvインポート用
    @ViewChild('adminTsvImport') AdminTsvImport: any;

    // 帳票用
    @ViewChild('adminLedgerImport') AdminLedgerImport: any;

    //chartModal
    //@ViewChild('chartModal', {static: false}) chartModal: any;
    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();

    private toasterService: ToastrService;

    public sort_params: Object = {
        'field': '',
        'asc_desc': ''
    };
    public tutorial_flag;
    public hide_scroll = true;

    public selectedCellId = null;

    public switchGroupEdit = false;
    public group_edit_data_a = [];
    public group_edit_data = [];
    public group_sort_data_a = {};
    public click_group: { [group: string]: boolean } = {};

    // カレンダー表示
    public switchCalendarView: boolean = null;

    //クロス集計
    public crossTableHeader: CrossTableHeader = null;

    public searchable: boolean = true;

    // delete modal   deleteCheckedConfirmModal
    private modal_data: Array<any>;
    @ViewChild('deleteModal') deleteModal: any;
    @ViewChild('exportModal') exportModal: any;
    @ViewChild('exportCheckedConfirmModal') exportCheckedConfirmModal: any;
    @ViewChild('truncateModal') truncateModal: any;
    @ViewChild('chartModal') chartModal: any;
    @ViewChild('chartTitleModal') chartTitleModal: any;
    @ViewChild('deleteFilterModal') deleteFilterModal: any;
    @ViewChild('editFormFieldModal') editFormFieldModal: any;
    @ViewChild('exitEditModeModal') exitEditModeModal: any;
    @ViewChild('cancelEditModeModal') cancelEditModeModal: any;
    @ViewChild('deleteCheckedConfirmModal') deleteCheckedConfirmModal: any;
    @ViewChild('confirmFilterOverwriteModal') confirmFilterOverwriteModal: any;
    @ViewChild('tableSettingModal') tableSettingModal: any;
    @ViewChild('embedModal') embedModal: any;
    @ViewChild('publicFormModal') publicFormModal: any;
    @ViewChild('duplicateModal') duplicateModal: any;
    @ViewChild('unlockModal') unlockModal: any;
    @ViewChild('deleteLedgerConfirmModal') deleteLedgerConfirmModal: any;
    @ViewChild('indexWorkflowModal') indexWorkflowModal: any;
    @ViewChild('mailPublicFormModal') mailPublicFormModal: any;

    @ViewChild('confirmFilterOverwriteConfirmModal') confirmFilterOverwriteConfirmModal: any;
    @ViewChild('viewModal') viewModal: any;

    // csv modal
    private modal_error_title: string;
    private modal_error_message: string;
    private csv: File;
    private json: File;
    // private missing_datasets = [];
    public missing_datasets: Array<string> = [];
    public query_string: string = '';
    public group_name: string = '';
    public export_ready = false;
    public export_data = false;
    private dataset_view = true;

    private hasFileField = false;
    @ViewChild('csvModal') csvModal: any;
    @ViewChild('dlCsvModal') dlCsvModal: any;
    @ViewChild('jsonModal') jsonModal: any;
    @ViewChild('inputCsv') inputCsv: any;
    @ViewChild('inputJSON') inputJSON: any;
    @ViewChild('errorModal') errorModal: any;


    @ViewChild('stickyTableWrapper') stickyTableWrapper: ElementRef;
    private scrollable : boolean = false;
    public checkPosition : {};
    private previous_url = '';
    private currentUrl = '';

    public formEditData = null;
    private emptyObjId = 0;

    private shouldScrollToNewRow = false;

    private original_get_params: Object = {};

    private has_filter_params: boolean = false;

    public modalevent: Object = {};
    public modal_rendered = false;
    private memo_height: number = 0;

    public fileName = '';
    public file: File;
    public mail_public_form_option = {
        'use_data_related_form': true,
    }

    modalRef: BsModalRef;
    config = {
        keyboard: false,
        ignoreBackdropClick: true
    };
    // public freezeColCount : {};
    // public correct_offsetWdith : Array<any>;

    constructor(private _router: Router, private _route: ActivatedRoute, public _connect: Connect
        , public _share: SharedService, toasterService: ToastrService, private renderer: Renderer2, public dynamicOverflowService: DynamicOverFlowService, private cdRef: ChangeDetectorRef, private modalService: BsModalService) {
        //console.time("constructor")
        this.toasterService = toasterService;
        this.per_page = 30;
        this._router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
            this.previous_url = events[0].urlAfterRedirects
        })


        this._router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.fields = this.getViewFields()
            } else if (event instanceof NavigationEnd) {
                this.currentUrl = event.url
                this.scrollable = true
                this.isEditMode = false;
                this.data_a = [];
            }

            document.querySelector('body').classList.add('aside-menu-hidden');

        })

    }

    ngOnDestroy(): void {
        console.log('destroy')
        this.crossTableHeader = null;
    }

    ngAfterContentChecked() {

    }

    ngAfterViewChecked() {
        if(this.scrollable ) {
            if ( this.checkPosition && this.stickyTableWrapper ) {
                if (Object.keys(this.checkPosition)[0] == this.table) {
                        this.stickyTableWrapper.nativeElement.scrollTo({top:parseInt(this.checkPosition[this.table]), behavior: 'smooth'});
                }else{
                    this.stickyTableWrapper.nativeElement.scrollTo({top:0, behavior: 'smooth'});
                }
            }
        }

        this._route.queryParams.subscribe(params => {
            if (params.data_id != undefined && params.viewModal != undefined && this.viewModal != undefined && this.modal_rendered == false) {
                this.modal_rendered = true;
                this.modalevent = {id: params.data_id, tbinfo: this.table_info}
                this.viewModal.show();
            }
        })
    }

    ngOnInit() {
        console.log('init')
        this.switchCalendarView = null;

        this.first_loading = false;
        this.customFilter = new CustomFilter();

        this._share.prev_page = location.pathname;


        this._route.params.subscribe(params => {

            if (this._share.license !== 'advance' && (this._share.getAdminTable() === params['table'])) {
                // //console.time('nav')
                // this._router.navigate(['dashboard']);
                // return;
            }
            this.page = (params['page'] !== undefined) ? parseInt(params['page'], 10) : 1;
            this.all_search_text = null;
            this.table = params['table'];
            this.extend_search_forms = {};
            this.extend_datas = [];
            this.sort_params = {
                'field': '',
                'asc_desc': ''
            };

            this.original_get_params = {}
            this.all_search_variables = [];

            this.checked_id_a = [];
            this.isSpecialMenuCollapsed = true;
            this.has_filter_params = false;
            this.customFilter = null;

            this.hasChildren = this._route.children.length > 0;
            console.log(params)
            for (const key of Object.keys(params)) {
                if (key !== 'table' && key !== 'parent_table') {
                    //FIXME:delete
                    this.original_get_params[key] = params[key];
                }
                if (key === '_all') {
                    this.all_search_text = params[key]
                } else if (key === 'sort_params') {
                    this.sort_params = JSON.parse(params[key])
                } else if (key === 'filter_params') {
                    this.customFilter = new CustomFilter(JSON.parse(params[key]))
                    this.has_filter_params = true;
                } else if (key === 'chart_params') {
                    this.customFilter.setSummarizeParam(JSON.parse(params[key]))
                } else if (key === 'condition_json') {
                    if (!this.customFilter) {
                        this.customFilter = new CustomFilter({'table': this.table})
                        this.customFilter.setAsTable();
                    }
                    this.customFilter.setSearchParam(JSON.parse(params[key]))
                    this.has_filter_params = true
                } else if (key == 'simple') {
                    if (params[key] === 'true') {
                        this.simpleViewMode = true
                    }
                }

            }


            this.isSummarizeMode = this.isSummarizeModeFunc()

            this.userTableSetting = this._share.getUserTableSetting(this.table)
            console.log('TMP=====')
            console.info(this.userTableSetting.tmp_filter)

            this.load(this.page, true, false).then(() => {
                if (!this.customFilter) {
                    if (params['_filter_id']) {
                        this.userTableSetting.filter_id = params['_filter_id']
                        if (!this.customFilter) {
                            //filter_idは指定しているが、カスタマイズのパラメータの表示にしている時
                            this.setFilter(params['_filter_id']);
                        } else {
                            this.customFilter.id = params['_filter_id']

                        }

                        this.isSummarizeMode = this.isSummarizeModeFunc()
                        if (params['_filter_edit']) {
                            this.summarize(false)

                        }
                    } else if (this.userTableSetting.tmp_filter) {
                        this.customFilter = this.userTableSetting.tmp_filter
                    } else if (this.userTableSetting.filter_id) {
                        this.setFilter(this.userTableSetting.filter_id);
                    }

                }


                if (this.customFilter) {
                    this.userTableSetting.tmp_filter = this.customFilter
                    this.customFilter.getAllVariables(this._share).subscribe(variables => {
                        this.all_search_variables = variables
                    })

                    this.customFilter.conditions.condition_a.forEach(condition => {
                        condition.loadExtraDetail(this.table_info, this._share, this._connect)
                    })
                }

                if (!this.customFilter || !this.customFilter.isChart()) {
                    this.loadList(() => {
                        this.switchCalendarView = this.table_info.menu.is_calendar_view_enabled
                    })
                } else {
                    this.loading = false
                }

                this.onInitCloud();

            });

        });


        this._router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
            //console.time('end');
            this.hasChildren = this._route.children.length > 0;
            if (!this.hasChildren && !this.loading) {
                // 子コンポーネントから戻ってきたとき
                // this.load(this.page);
            }
        });


        /*
        this._share.loadAdminDatas().then((data) => {
            this.tutorial_flag = data.tutorial_flag;
        })
         */
        //console.timeEnd('ngOnInit:');

        this.dynamicOverflowService.accordionChanged(this.userTableSetting.open_memo)

    }

    ngDoCheck() {
        this.current_url = this._router.url;
    }

    onInitCloud() {
        //console.time('onInitCloud:');

        if (this._share.is_cloud && this.table === 'pigeon_admin_grant') {
            this.data_a.forEach((data) => {
                if (data['id'] === 1) {
                    data['__disabled'] = true
                }
            })
        }


        //console.timeEnd('onInitCloud:');
    }

    setClasses(): Object {
        let class_hash = {};
        class_hash[this.table_info.getJaClassName()] = true;
        class_hash[this.table_info.getClassName()] = true;
        return class_hash
    }


    resetUrl() {
        history.replaceState({}, null, document.location.pathname)
    }

    copyUrl() {
        navigator.clipboard.writeText(document.location.href);
        this.toasterService.success('URLがコピーされます', '成功')
    }

    canDeactivate() {
        if (this.isEditMode) {
            return window.confirm('保存されていないデータがあります。破棄してよろしいですか？');
        }
        this.crossTableHeader = null;
        return true;
    }

    sort(field) {
        //console.time('sort:');


        if (this.sort_params['field'] === field) {
            this.sort_params['asc_desc'] = (this.sort_params['asc_desc'] === 'asc') ? 'desc' : 'asc';
        } else {
            this.sort_params['asc_desc'] = 'asc';
        }
        this.sort_params['field'] = field;
        //this.load(1);
        let params = this.original_get_params;
        if (!params) {
            params = {}
        }
        params['sort_params'] = JSON.stringify(this.sort_params);

        this._router.navigate([this._share.getAdminTable(), this.table, params], {replaceUrl: true});
        //console.timeEnd('sort:');
    }


    load(page, display_loading = true, load_list = true) {
        // console.time('load:');
        this.checkPosition = JSON.parse(localStorage.getItem('set_position'))

        this.dataset_view = this._router.url == '/admin/dataset'
        return new Promise<void>((resolve) => {
            this.page = page;
            this.loading = display_loading;
            let table = this.table;
            if (this.customFilter) {
                this.customFilter.conditions.reloadConditionGroup();
            }
            this._share.getTableInfo(this.table).subscribe(async (data) => {
                if (table !== this.table) {
                    return;
                }

                /*
                if (data['result'] !== 'success') {
                    this._router.navigate([this._share.getAdminTable(), 'login']);
                    return;
                } else if (data['page_flg']) {
                    this._router.navigate([this._share.getAdminTable(), this.table, 'page']);
                    return;
                } else if (data['admin_setting_flg']) {
                    this.loading = false;
                    this._router.navigate([this._share.getAdminTable(), this.table, 'edit', data['data_id']]);
                    return;
                } else if (data['menu'] === null) {
                    this._router.navigate([this._share.getAdminTable(), 'dashboard']);
                    return;
                }
                 */


                this.table_info = data;
                this.is_custom_dataset = this.table_info.menu.is_custom_table_definition

                this.child_a = this.table_info.child_a;

                this.child_a.forEach((child, index) => {
                    child.data_a = [];
                    child.error_a = [];
                });

                this.page_title = (this.table_info.menu.group ? this.table_info.menu.group + ' / ' : '') + this.table_info.menu.name;
                this.before_html = this.table_info.list_before_html;
                this.grant = this.table_info.grant;
                // console.log(this.grant);

                this._route.snapshot.data.title = this.page_title; // パンくず
                this.isMovable = this.table_info.table == 'dataset';
                //this.page_title =
                this.hasFileField = false;

                this.group_edit_data = [];
                this.group_sort_data_a = [];
                this.group_sort_data_a = [];

                for (const key in this.table_info.forms['_forms']) {
                    if (['image', 'file'].includes(this.table_info.forms['_forms'][key]['_type'])) {
                        this.hasFileField = true;
                        break;
                    }
                }
                for (const prop in this.table_info.forms) {
                    const form: Form = this.table_info.forms.byFieldName(prop);
                    if (form) {
                        if (['image', 'file'].includes(form.type)) {
                            this.hasFileField = true;
                            break;
                        }
                    }
                }

                this.extend_search_forms = this.table_info.extend_search_forms;


                // フィルタのセット

                this.fields = this.getViewFields()


                if (!this.grant.summarize_list && this.customFilter && this.customFilter.isSetSummarizeParam()) {
                    this._router.navigate([this._share.getAdminTable(), this.table]);
                    return;
                }

                if (!load_list) {
                    resolve();
                    return;
                }

                if (!this.isChartMode()) {
                    this.loadList(() => {

                        //console.time('load end!');
                        if (display_loading) {
                            this.setLoadingFalse()
                            scrollTo(0, 0);
                        }

                        resolve();
                    });
                } else {
                    if (display_loading) {
                        this.setLoadingFalse()
                    }
                    resolve();
                }
            })
        });
        //console.time('load:');
    }

    setLoadingFalse() {
        this.first_loading = false;
        this.loading = false;
    }

    async loadList(callback = null) {
        //console.time('loadList')

        let table = this.table;
        this.loading = true;
        await this._connect.getList(this.table_info, this.page, this.table_info.menu.per_page, this.customFilter, this.sort_params, this.all_search_variables).subscribe((data) => {
            if (data['result'] === 'error') {
                // console.log('ERROR RESULT')
                return;
            }
            if (table !== this.table) {
                //console.time('if:');
                // console.log('NOT SAME TABLE')
                return;
            }


            this.total_count = parseInt(data['count'], 10);

            // ヘッダー用
            this._share.setHeaderDatasetName(this.page_title, this.total_count);

            this.data_a = [];

            let recordListService = new RecordListService(data, this.customFilter, this.table_info)
            this.crossTableHeader = recordListService.crossTableHeader
            this.data_a = recordListService.data_a

            if (this.table_info.menu.is_onlyone) {
                //１データのみ登録可能な場合
                // console.log(this.data_a)
                if (this.data_a.length == 0) {
                    // new
                    this._router.navigate([this._share.getAdminTable(), this.table, 'edit', 'new']);
                } else {
                    // exist
                    this._router.navigate([this._share.getAdminTable(), this.table, 'view', this.data_a[0].raw_data['id']]);
                }
            }


            this.numbers = Array(data['total_page']).fill(0).map((x, i) => i);

            this.original_data_a = this.data_a.map(_data => _data.getCopy())

            if (this.table === 'search_logs') {
                this.search_log_base_fields = this.table_info.fields;
                this.search_log_base_forms = this.table_info.forms;
            }

            if (this.loading) {
                this.setLoadingFalse()
                scrollTo(0, 0);
                setTimeout(() => {
                    // テーブルが横スクロール可能かどうか
                    // ロード後少し空けないと要素が取得できない
                    let sticky_table = document.getElementById('sticky-table-wrapper');
                    if (sticky_table) {
                        this.hide_scroll = sticky_table.scrollWidth == sticky_table.clientWidth;
                    }

                }, 10);
            }
            if (callback) {
                callback()
            }
        }, (error) => {
        });

        //console.time('loadList')

    }

    isInsertable() {
        return this.grant.add && !this.isSummarizeMode && !this.table_info.is_child_form;
    }


    resetSearch() {
        //let calcTime = performance.now()
        localStorage.removeItem(`fixed-field-name-${this.customFilter.id}`);
        this.userTableSetting.filter_id = null;
        this.userTableSetting.tmp_filter = null;
        this.customFilter = null;
        this.crossTableHeader = null
        this.table_info.resetMemory();
        this._router.navigate([this._share.getAdminTable(), this.table, {'ts': (new Date()).getTime()}]);

    }


    onPageChange(page) {
        //let calcTime = performance.now()
        let params = this.original_get_params;
        params = this.getGetParams(params)
        params['page'] = page;
        this._router.navigate([this._share.getAdminTable(), this.table, this.original_get_params]);
        //if(performance.now() - calcTime > 1)//console.log((performance.now() - calcTime));
    }

    get_csv(csv_id) {
        //console.time('get_csv:');

        const url = this._connect.getApiUrl() + '/admin/csv-info/' + csv_id;

        return new Observable(observer => {
            this._connect.get(url).subscribe(data => {
                const csv = data['csv'];
                observer.next(csv);
            });
        });
    }

    handle_csv_download_csv(csv, filename: string) {
        //console.time('handle_csv_download_csv:');

        if (['failed', 'done'].indexOf(csv['status']) >= 0) {
            if (this.csv_load_timer !== null) {
                this.csv_load_timer.unsubscribe();
                this.csv_load_timer = null;
            } else {
                //timerがない場合は、既にダウンロード済み
                return;
            }
            //const result = JSON.parse(csv['result']);
            if (csv['status'] === 'failed') {
                this.toasterService.error('エラーが発生しました', 'エラー');
            } else {
                // done
                this.download_csv(csv['id'], filename);
                return true;
            }
        }

        //console.timeEnd('handle_csv_download_csv:');
        return false;
    }


    download_csv(csv_id, filename) {
        //console.time('download_csv:');

        const url = this._connect.getApiUrl() + '/admin/download-csv/' + csv_id;
        this._connect.get(url, null, {'responseType': 'blob'}).subscribe((data: any) => {
            this.downloading['csv'] = false;
            if (data.size === 0) {
                alert('ダウンロードに失敗しました。権限を確認して下さい。');
                return;
            }
            const blob = new Blob([data], {type: 'text/csv'});
            FileSaver.saveAs(blob, filename);
        })

        //console.timeEnd('download_csv:');
    }

    prepare_csv() {
        this.dlCsvModal.hide()
        if (this.downloading['csv']) {
            this.toasterService.warning('別のCSVをダウンロード中です', '警告');
            return;
        }
        //console.time('prepare_csv:');

        const params = [];
        Object.keys(this.original_get_params).map((key) => {
            params.push(key + '=' + encodeURIComponent(this.original_get_params[key]));
        });
        const url = this._connect.getApiUrl() + '/admin/prepare-download/' + this.table + '/csv?' + params.join('&');
        this.downloading['csv'] = true;

        let search_params = {};
        if (this.customFilter && this.is_use_filter_for_csv_dl) {
            search_params = this.customFilter.getFilterParam();
        }
        let filename = this.page_title + '_' + this._share.dateFormat.format(new Date(), 'yyyyMMdd_hhmm') + '.csv';
        this._connect.post(url, {
            search: search_params,
            sort: this.sort_params
        }).subscribe((data) => {
            this.toasterService.success('CSVダウンロードを開始しました。時間がかかる場合があります。', '成功');
            const csv_id = parseInt(data['csv_id'], 10);
            const onceTimer = interval(200).subscribe(x => {
                onceTimer.unsubscribe();
                // 5秒後に１度実行して、まだなければ３秒間隔でチェック
                this.get_csv(csv_id).subscribe((csv) => {
                    // 5秒タイマー作成

                    this.csv_load_timer = interval(2000).subscribe(x2 => {
                        this.get_csv(csv_id).subscribe((_csv) => {
                            this.handle_csv_download_csv(_csv, filename);
                        });
                        //console.time('test')
                    });

                })
            });

        })
        ;

    }


    checkboxChange = (event) => {
        //console.time('prepare_csv:');
        this.checked_id_a = [];
        const checkbox_a = document.getElementsByName('data_check');
        for (let i = 0; i < checkbox_a.length; i++) {
            const checkbox = <HTMLInputElement>checkbox_a[i];
            if (checkbox.checked) {
                this.checked_id_a.push(parseInt(checkbox.value, 10));
            }
        }

        var query_string = '';
        if (this.dataset_view) {
            this.checked_id_a.map((id, index) => index == this.checked_id_a.length - 1 ? query_string += `dataset_id[]=${id}` : query_string += `dataset_id[]=${id}&`);
            if (query_string != '') {
                this._connect.get(`/admin/download-json?${query_string}&check_relation=true`).subscribe(
                    (jsonData) => {
                        if (this.missing_datasets != null) {
                            this.missing_datasets = jsonData;
                        }
                        // console.log(jsonData);
                    }
                ).add(() => {
                    this.export_ready = true;
                    // console.log(this.missing_datasets);

                })
            }
            this.query_string = query_string;
        }

        // console.log(this.query_string)
    }
    allCheckboxChange = (event) => {
        this.checked_id_a = [];
        const checkbox_a = document.getElementsByName('data_check_one');
        for (let i = 0; i < checkbox_a.length; i++) {
            const checkbox = <HTMLInputElement>checkbox_a[i];
            if (checkbox.checked) {
                this.checked_id_a.push(parseInt(checkbox.value, 10));
            }
        }
        var query_string = '';
        if (this.dataset_view) {
            this.checked_id_a.map((id, index) => index == this.checked_id_a.length - 1 ? query_string += `dataset_id[]=${id}` : query_string += `dataset_id[]=${id}&`);
            if (query_string != '') {
                this._connect.get(`/admin/download-json?${query_string}&check_relation=true`).subscribe(
                    (jsonData) => {
                        if (this.missing_datasets != null) {
                            this.missing_datasets = jsonData;
                        }
                    }
                ).add(() => {
                    this.export_ready = true;
                })
            }
            this.query_string = query_string;
        }
    }

    getViewFields(): Array<Object> {
        return this.table_info.getViewFields(this.customFilter, this._share.isMasterUser(), this.isSummarizeMode, false);
    }


    getType(field_name) {
        if (this.table_info.forms.byFieldName(field_name) === undefined) {
            return '';
        }
        return this.table_info.forms.byFieldName(field_name).type || '';
    }


    isShowManageColFunc() {
        //console.log('isShowManageCol:');
        //let calcTime = performance.now()
        if (this.customFilter && this.customFilter.isSetSummarizeParam()) {
            return false;
        }


        //console.timeEnd('isShowManageCol:');
        return this.grant.detail || this.grant.edit || this.grant.delete;
    }

    deleteAll() {
        if (this.isEditMode) {
            this.deleteCheckedConfirmModal.show();
            return;
        }
        this.modal_data = this.checked_id_a;
        this.deleteModal.show();
    }

    exportAll() {

        if (this.isEditMode) {
            this.exportCheckedConfirmModal.show();
            return;
        }
        this.modal_data = this.checked_id_a;
        this.exportModal.show();
    }

    openTruncateModal() {
        //console.time('openTruncateModal:');

        this.truncateModal.show();

        //console.timeEnd('openTruncateModal:');
    }


    view(data) {
        //console.time('view:');

        this._router.navigate([this._share.getAdminTable(), this.table, 'view', data[this.table_info.primary_key]]);

        //console.timeEnd('view:');
    }

    add() {
        //console.time('add:');

        if (this.tutorial_flag === 'false') {
            this._connect.get('/admin/tutorial/complete').subscribe(
                (jsonData) => {
                    if (jsonData.status) {
                        this.tutorial_flag = true;
                    }
                },
            );
        }
        //console.timeEnd('add:');
        this._router.navigate([this._share.getAdminTable(), this.table, 'edit', 'new']);

    }

    search(is_new = false) {
        this.modalCustomFilter = new CustomFilter();
        this.modalCustomFilter.table = this.table;
        this.modalCustomFilter.setAsTable();
        this.chartModal.show();
    }

    edit(data) {

        if (data[this.table_info.primary_key] === null) {
            this.toasterService.error('primary-keyがNULLです', 'エラー');
            return;
        }
        this._router.navigate([this._share.getAdminTable(), this.table, 'edit', data[this.table_info.primary_key]]);

    }


    openDeleteModal = (data: Data) => {
        // console.log(data)
        if (data.getRawData('id') === null) {
            this.toasterService.error('primary-keyがNULLです', 'エラー');
            return;
        }
        this.modal_data = [data.getRawData('id')];
        // console.log(this.modal_data)
        this.deleteModal.show();

    }

    delete(id_a, table: string = null) {
        if (!table) {
            table = this.table;
        }
        this.sending = true;
        //console.time('delete:');
        if (this.isEditMode) {
            this.addCheckedToDelete();
            return;
        }
        if (table == 'admin' && this.table_info.is_admin_table && this.total_count === 1) {
            this.toasterService.error('管理者は最低一人は必要です。', 'エラー');
            return;
        }
        this._connect.post('/admin/delete/' + table, {'id_a': id_a}).subscribe(
            (jsonData) => {
                this.sending = false;
                if (jsonData['result'] === 'success') {
                    this._share.resetTableInfoCache()
                    this.load(this.page);
                    this.checked_id_a = [];
                    this._share.loadAdminDatas();
                    this.deleteModal.hide();
                    this.toasterService.success(jsonData['success_count'] + '件のデータを削除しました。', '成功');
                } else {
                    this.toasterService.error(jsonData['error_a'], 'エラー');
                    this.deleteModal.hide();
                }
            }, (error) => {
                this.sending = false;
                this.deleteModal.hide();
            }
        );
        //console.timeEnd('delete:');
    }

    truncate() {
        //console.time('truncate:');

        if (this.table_info.is_admin_table && this.total_count === 1) {
            this.toasterService.error('管理者は最低一人は必要です。', 'エラー');
            return;
        }
        this._connect.post('/admin/truncate/' + this.table, {}).subscribe(
            (jsonData) => {
                this.isSpecialMenuCollapsed = true;
                this._share.resetTableInfoCache()
                if (jsonData['result'] === 'success') {
                    this.load(this.page);
                    this.checked_id_a = [];
                    this.truncateModal.hide();
                    this.toasterService.success('全てのデータを削除しました。', '成功');
                } else {
                    this.toasterService.error(jsonData['error_a'], 'エラー');
                }
            },
        );

        //console.timeEnd('truncate:');
    }

    openDlCsvModal() {
        if (!this.customFilter) {
            this.prepare_csv()
        } else if (this.simpleViewMode) {
            //force current filter
            this.is_use_filter_for_csv_dl = true;
            this.prepare_csv()
        } else {
            this.dlCsvModal.show();
        }

    }

    openCsvModal() {
        //console.time('openCsvModal:');

        this.csvModal.show();

        //console.timeEnd('openCsvModal:');
    }

    openJSONModal() {
        this.jsonModal.show();
    }


    changeCsv(event) {
        //console.time('changeCsv:');

        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.csv = fileList[0];
        } else {
            this.resetCsvInput();
        }

        //console.timeEnd('changeCsv:');
    }

    changeJson(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.json = fileList[0];
        } else {
            this.resetCsvInput();
        }
    }

    downloadCsvForUpload() {
        if (this.downloading['csv']) {
            this.toasterService.warning('別のCSVをダウンロード中です', '警告');
            return;
        }
        //console.time('downloadCsvForUpload:');

        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.getItem(this._share.getAdminTable() + '_access_token'),
        });
        const params = [];
        Object.keys(this.original_get_params).map((key) => {
            params.push(key + '=' + encodeURIComponent(this.original_get_params[key]));
        });
        const url = this._connect.getApiUrl() + '/admin/download-csv-for-upload/' + this.table;

        this._connect.get(url, null, {headers: headers, 'responseType': 'blob'}).subscribe(data => {
            const blob = new Blob([data], {type: 'text/csv'});
            FileSaver.saveAs(blob, this.page_title + '_empty.csv');
        })

        //console.timeEnd('downloadCsvForUpload:');
    }

    uploadCsv() {
        //console.time('uploadCsv:');

        this.toasterService.clear();
        if (this.csv !== null) {
            this.sending = true;
            const formData: FormData = new FormData();
            formData.append('csv', this.csv, this.csv.name);
            formData.append('reset', this.resetBeforeCsvUpload);
            this._connect.postUpload('/admin/upload-csv/' + this.table, formData).subscribe(
                (jsonData) => {
                    this.sending = false;
                    const csv_id = jsonData['csv_id'];
                    if (jsonData['result'] === 'success') {
                        this.toasterService.success('CSVアップロードしました。反映まで少しかかる場合があります。', '成功');
                        this.csvModal.hide();
                        this.resetCsvInput();
                        if (this.resetBeforeCsvUpload === 'true') {
                            this.load(this.page);
                        }
                        this.csv_upload_timer = interval(3000).subscribe((x) => {
                            this.get_csv(csv_id).subscribe((csv) => {
                                if (['failed', 'done'].indexOf(csv['status']) >= 0) {
                                    this.csv_upload_timer.unsubscribe();
                                    if (csv['status'] === 'done') {
                                        const result = JSON.parse(csv['result']);
                                        //console.time(result);
                                        // tslint:disable-next-line:no-shadowed-variable
                                        this.toasterService.success(result['insert'] + '件のデータを追加、' + result['update'] + '件のデータを更新しました。', '成功');
                                        if (result['error_a']) {
                                            let body = result['error'].length + '件のデータ投入に失敗しました。' + '\n';
                                            result['error_a'].forEach((errorHash) => {
                                                body += errorHash['row'] + '行目:' + errorHash['error_a'].join(',') + '\n'
                                            })
                                            this.toasterService.error(body, 'エラー');
                                        }
                                        this.load(this.page);
                                    } else {
                                        const result = JSON.parse(csv['result']);
                                        this.toasterService.error('CSVインポートに失敗しました。' + result['error_a'].join(','), 'エラー');
                                    }
                                }
                            });
                        })
                    } else {
                        this.csvModal.hide();
                        this.resetCsvInput();
                        this.modal_error_title = jsonData['title'];
                        this.modal_error_message = jsonData['message'];
                        this.errorModal.show();
                    }
                    this.resetBeforeCsvUpload = 'false';
                },
                (error) => {
                    this.toasterService.error('エラーが発生しました。ファイルCSVアップロード用のファイルをダウンロードし、そのファイルを編集してアップロードを行ってください。', 'エラー');
                    this.sending = false;
                }
            );
        } else {
            this.toasterService.error('CSVファイルが選択されていません。', 'エラー');
        }

        //console.timeEnd('uploadCsv:');
    }

    uploadJSON() {
        this.toasterService.clear();
        if (this.json != null) {
            this.sending = true;
            const formData: FormData = new FormData();
            formData.append('json', this.json);
            formData.append('group_name', this.group_name.trim());
            formData.append('reset', this.resetBeforeJSONUpload);

            this._connect.postUpload('/admin/upload-json', formData).subscribe(
                (jsonData) => {
                    if (jsonData['result'] === 'success') {
                        this.toasterService.success('JSONアップロードしました。反映まで少しかかる場合があります。', '成功');
                        this.data_a = [];
                        this.checked_id_a = [];
                        this.reloadPage();
                        this._share.loadAdminDatas();
                    } else {
                        this.modal_error_title = jsonData['title'];
                        this.modal_error_message = jsonData['message'];
                        this.errorModal.show();
                    }
                    this.jsonModal.hide();
                    this.resetJSONInput();
                    this.sending = false;
                }
                ,
                (error) => {
                    this.toasterService.error('エラーが発生しました。', 'エラー');
                    this.sending = false;
                })
        } else {
            this.toasterService.error('JSONファイルが選択されていません。', 'エラー');
        }
    }

    resetCsvInput() {
        //console.time('resetCsvInput:');

        this.csv = null;
        this.inputCsv.nativeElement.value = '';

    }

    resetJSONInput() {
        //console.time('resetCsvInput:');

        this.json = null;
        this.inputJSON.nativeElement.value = '';

    }

    hideJSONModal() {
        this.jsonModal.hide();
        this.json = null;
        this.inputJSON.nativeElement.value = '';
        this.toasterService.clear();

        if (this.sending) {
            this.sending = false;
        }
    }


    is_child_table(key) {
        return key.match(/\[.*\]/);

    }

    get_child_table(key) {
        const g = key.match(/\[(.*)\](.*)/);
        return g[1];

    }

    get_child_key(key) {
        const g = key.match(/\[.*\](.*)/);
        return g[1];

    }


    is_string(data) {
        return data instanceof String;
    }


    download_zip() {
        const link = document.createElement('a');
        link.href = `${this._connect.getApiUrl()}/admin/download/${this.table}/zip`;
        link.click();
    }

    /**
     * For Summarize
     */
    public modalCustomFilter: CustomFilter;

    summarize(is_new = true, type = 'table') {
        //console.time('summarize')
        if (is_new || !this.customFilter) {
            this.modalCustomFilter = new CustomFilter();
            this.modalCustomFilter.table = this.table;
            if (type == 'table') {
                this.modalCustomFilter.setAsTable();
            } else {
                this.modalCustomFilter.setAsChart();
            }
            this.modalCustomFilter.initSummarizeFilter()
        } else {
            this.modalCustomFilter = cloneDeep(this.customFilter)
        }
        //console.timeEnd('summarize')
        this.chartModal.show();
    }

    onClickPreviewButton($event) {
        this.chartModal.hide()
        if (!$event) {
            return;
        }
        this.chartModal.hide()
        this.reloadPageByCustomFilter($event.customFilter)
    }

    private reloadPageByCustomFilter(customFilter: CustomFilter) {
        const params = {};
        if (!customFilter.isNoFilter()) {
            if (customFilter.id) {
                params['_filter_id'] = customFilter.id;
            }
            params['filter_params'] = JSON.stringify(customFilter.getFilterParam());
            params['condition_json'] = customFilter.conditions.getSearchParamJson();
        }
        delete params['page'];
        this._router.navigate([this._share.getAdminTable(), this.table, params]);


    }

    private getGetParams(params: Object = {}) {
        if (this.customFilter) {
            if (this.customFilter.id) {
                params['_filter_id'] = this.customFilter.id;
            }
            params['filter_params'] = JSON.stringify(this.customFilter.getFilterParam())
            params['condition_json'] = this.customFilter.conditions.getSearchParamJson();
        }
        return params
    }

    saveFilter(save_new = false) {
        this.customFilter.save(this._connect, save_new).then((result: boolean) => {
            this.sending = false;
            if (result) {
                this.toasterService.success('フィルタを保存しました', '成功');
                this.chartTitleModal.hide();

                this.confirmFilterOverwriteConfirmModal.hide()

                //filter再読み込み
                this._share.getTableInfo(this.table, false, null, false).subscribe(_table_info => {
                    this.table_info = _table_info
                    this._router.navigate([this._share.getAdminTable(), this.table, {'_filter_id': this.customFilter.id}]);
                })
            } else {
                this.error(this.customFilter.error_a.join(','));
            }

        })
    }

    deleteFilter() {
        //console.time('deleteFilter:');


        this._connect.post('/admin/delete-filter', {
            'id': this.customFilter.id
        }).subscribe((data) => {
            this._share.getTableInfo(this.table, false, null, false).subscribe(_table_info => {
                this.table_info = _table_info
                this.toasterService.success('現在のフィルタを削除しました', '成功');
                this.deleteFilterModal.hide();
                this.customFilter = null;
                this._router.navigate([this._share.getAdminTable(), this.table]);
            })

        });
        //console.timeEnd('deleteFilter:');
    }


    /**
     * VIEW
     */
    create_view() {
        this._router.navigate([this._share.getAdminTable(), 'view', 'edit', 'new', {target_table: this.table}]);
    }

    isSummarizeModeFunc() {
        //console.time('isSummarizeMode:');

        return this.customFilter && this.customFilter.isSetSummarizeParam()

    }

    isFilterMode() {

        return this.customFilter && this.customFilter.id !== null;

    }

    getThis() {
        return this;

    }


    selectSavedFilter($event) {
        let saved_filter: CustomFilter = $event.filter;
        this.customFilter = null;
        this.crossTableHeader = null;
        // this.getCorrectOffsetWidth();
        this._router.navigate([this._share.getAdminTable(), this.table, {'_filter_id': saved_filter.id}]);
    }

    private setFilter(filter_id) {
        this.table_info.saved_filters.forEach(_filter => {
            if (_filter['id'] == filter_id) {
                this.userTableSetting.filter_id = filter_id
                if (!this.customFilter) {
                    //const params = JSON.parse(_filter['params_json'])
                    this.customFilter = cloneDeep(_filter)
                    //FIXME: 後でasyncにする
                    this.customFilter.conditions.reloadViewValuesTmp(this.table_info, this._connect, this._share)
                    this.fields = this.getViewFields()
                }
            }
        })

        this.table_info.resetMemory()

    }

    scrollTable(event) {
        const left = event.target.scrollLeft + event.target.offsetWidth;
        const max_left = event.target.scrollWidth;
        if (left === max_left) {
            this.hide_scroll = true;
        } else {
            this.hide_scroll = false;
        }
        // this.stickyTableWrapper.nativeElement.scrollTo({top: event.target.scrollTop})
        this.scrollable = false
        localStorage.setItem(`set_position`, JSON.stringify({[this.table]:event.target.scrollTop}))
    }

    isShowMenu() {
        return !this.isEditMode && this.table != 'view' && ((this.grant.add || this.grant.create_view || this.grant.summarize || this.grant.csv_download || this.grant.csv_upload));


    }


    add_chart() {
        this._router.navigate([this._share.getAdminTable(), 'dashboard', {'chart_params': JSON.stringify({'table': this.table})}]);
    }

    add_template() {
        this._router.navigate([this._share.getAdminTable(), 'dashboard', {'type': 'add_template'}]);
    }

    error(message) {
        this.toasterService.error(message, 'エラー');
        return;
    }

    createEmptyDataObject() {
        //console.time('createEmptyDataObject:');
        let editables = this.fields.filter(function (ele) {
            return ele.editable;
        });

        let emptyObj = {
            id: this.emptyObjId
        }
        editables.forEach(function (editable) {
            emptyObj[editable.Field] = null;
        })
        this.emptyObjId--;
        let newdata = new Data(this.table_info);
        newdata.setInstanceData({
            'raw_data': cloneDeep(emptyObj),
            'view_data': cloneDeep({
                id: null
            }),
            'grant': new DataGrant()
        })
        newdata.setDefaultData();
        //console.timeEnd('createEmptyDataObject:');
        return newdata;
    }

    appendEmptyDataObject() {
        this.data_a.push(this.createEmptyDataObject());
        this.shouldScrollToNewRow = true;
    }

    scrollToNthRow(nth) {
        let rows = document.querySelectorAll('#table-admin-list tr');
        rows[nth].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    }

    reload = () => {
        this.load(this.page)
    }


    getPostObj(r_data): FormData {
        //console.time('getPostObj:');
        let obj = new FormData();
        const addToFormData = (field, type, forms, data, menu) => {
            if (['updated', 'created'].indexOf(field) >= 0 || (field === 'id' || field.indexOf('[id]') >= 0) && data === undefined) {
                return;
            }

            if (!!!menu['editable'] && field === 'grant') {
                return;
            }

            if ((type === 'datetime' || type === 'date' || type === 'time') && data === undefined) {
                data = '';
            } else if (data === undefined || data == null) {
                data = '';
            }
            obj.append(field, data);
        };
        this.fields.forEach(field => {
            addToFormData(field.Field, this.table_info.forms.byFieldName(field.Field).type, this.table_info.forms, r_data[field.Field], this.table_info.menu);
        });

        return obj;
    }


    setSelectedCellId = (cellId) => {
        this.selectedCellId = cellId;
    }

    @HostListener('click', ['$event'])
    onClick(e) {
        if (['input', 'select', 'label', 'textarea'].includes(e.target.localName)) {
            return;
        }
        const floarlClassPrefix = /fr-*/;
        const deleteBtnPrefix = /delete-btn/;
        let sourceTarget = e.target;
        let elementsTobeTested = [];
        while (sourceTarget) {
            elementsTobeTested.unshift(sourceTarget);
            sourceTarget = sourceTarget.parentElement;
        }
        for (let i = 0; i < elementsTobeTested.length; i++) {
            let classList = elementsTobeTested[i].classList;
            for (let cl of classList.entries()) {
                if (floarlClassPrefix.test(cl) || deleteBtnPrefix.test(cl)) {
                    return;
                }
            }
        }
        if (!this.isEditModalShown) {
            this.selectedCellId = null;
        }
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (this.customFilter && !this.simpleViewMode) {
            this.resetSearch()
        }
    }

    setFormEditData = (formEditData) => {
        this.formEditData = formEditData;
    }

    public isEditModalShown: boolean = false;
    showFormEditModal = (data_id) => {
        if (data_id < 1) {
            //新規の場合
            this.child_a_by_id[data_id] = cloneDeep(this.child_a);
            this.formEditData.child_a = this.child_a_by_id[data_id];
            this.editFormFieldModal.show()
            this.isEditModalShown = true;
            return;
        }
        this._connect.get('/admin/view/' + this.table + '/' + data_id).subscribe((data) => {
            this.child_a_by_id[data_id] = cloneDeep(this.child_a);
            if (data['result'] !== 'success') {
                this.toasterService.error(data['error_a'].join(','), 'エラー');
                return;
            }

            this.child_a_by_id[data_id].forEach((child, index) => {
                /*
                if (child.menu.multiple_mode === 'chip_toxi' || child.menu.multiple_mode === 'chip') {
                    child.data_a = data['child_a'][child.table];
                } else {
                    child.data_a = data['raw_child_a'][child.table];
                }
                 */

                child.data_a.forEach((data) => {
                    child.error_a.push({});
                });

                /*
                child.fields.forEach(field => {
                    if (child.forms.byFieldName(field.Field).type === 'richtext') {
                        this.setFroalaOptions(child.forms, field);
                    }
                });

                child.data_a.forEach((data) => {
                    this._share.loadImg(child.fields, child.forms, data)

                    if ((this.is_custom_table_definition || this.is_exist_table_definition) && data.option !== null && data.option !== '') {
                        // データ定義の場合
                        try {
                            data.option = JSON.parse(data.option);
                        } catch (e) {
                            data.option = {}
                            //console.time('JSON parse error')
                        }
                    } else {
                        data.option = {}
                    }
                });
                 */
            });

            this.formEditData.child_a = this.child_a_by_id[data_id];
            this.editFormFieldModal.show()
            this.isEditModalShown = true;
        });
    };

    onHiddenEditModal() {
        //console.time('onhidden')
        this.formEditData = null;
        this.isEditModalShown = false;
    }

    closeFormEditModal = () => {
        this.editFormFieldModal.hide();
        this.isEditModalShown = false;
    }

    checkDirty = () => {
        //console.time('checkDirty:');
        if (!!!this.data_a || !!!this.original_data_a) {
            return false;
        }
        if (this.data_a.length != this.original_data_a.length) {
            return true;
        }
        return this.data_a.some((data, index) => {
            return JSON.stringify(data.raw_data) != JSON.stringify(this.original_data_a[index].raw_data);
        });
        //console.timeEnd('checkDirty:');
    }

    onEditModalChangeEnd($event) {
        this.data_a[$event.data_index] = $event.data;
        this.child_a_by_id[this.data_a[$event.data_index].raw_data['id']] = $event.child_a;

        if (this.hasRequiredError($event.data_index)) {
            this.onFormatViewData({
                data_index: $event.data_index,
                field: $event.field,
                form: $event.form,
                raw_data: $event.data[$event.field.Field]
            });
        } else {
            if (!this.isEditMode) {
                this.saveData($event.field, $event.form, $event.child_a, $event.data_index, $event.data);
            } else {
                this.editted_row_index_a.push($event.data_index)
                this.editFormFieldModal.hide();
            }
        }
    }


    private editted_row_index_a: Array<number> = [];

    onCellDataChanged($event, _this) {
        //console.time('onCellDataChanged:');
        _this.editted_row_index_a.push($event.data_index)
        if (_this.hasRequiredError($event.data_index)) {
            _this.onFormatViewData({
                data_index: $event.data_index,
                field: $event.field,
                form: $event.form,
                raw_data: $event.value
            });
        } else if (!this.isEditMode) {
            _this.saveData($event.field, $event.form, $event.child_a, $event.data_index, $event.value)
        }
        //console.timeEnd('onCellDataChanged:');
    }


    saveData(field, form, child_tableinfo_a: Array<TableInfo>, data_index, value) {
        let mode = (this.data_a[data_index].raw_data['id'] < 1) ? 'add' : 'edit';
        let post_data = this._share.get_post_data(this.table_info, this.data_a[data_index], this.fields, this.table_info.forms, child_tableinfo_a, mode)

        if (form.required && !form.is_multi_value_mode) {
            if (!!!value && value !== 0) {
                this.toasterService.error(`${form.label}が入力されていません。`, 'エラー');
                return;
            }
        }

        let url = `/admin/edit/column/${this.table}/${this.data_a[data_index].raw_data['id']}`;
        if (mode == 'add') {
            url = `/admin/edit/column/${this.table}/0`;
        }

        return this._connect.postUpload(url, post_data).subscribe((jsonData) => {
            let _data: Data = this.data_a[data_index];
            _data.setInstanceData(jsonData['data'])


            //raw_data.id = jsonData['raw_data']['id'];
            this.toasterService.success('データが保存されました。', 'お知らせ');
            if (!!this.editFormFieldModal) {
                this.editFormFieldModal.hide()
            }

            if (mode == 'add') {
                //if new data, edit delete grant is added
                let grant = new DataGrant();
                grant.setAllGrant(true);
                _data.setInstanceData({
                    'grant': grant
                })
            }
            this.data_a[data_index] = _data;
            this.selectedCellId = null;
        }, (jsonData) => {
            let error_a = {'_child_a': {}}
            let body = this._share.getErrorBodyByResponse(jsonData.error.error_a, child_tableinfo_a, {});
            //let errors = Object.keys(jsonData.error.error_a).map(k => jsonData.error.error_a[k]).reduce((accumulator, current) => (accumulator + current));
            this.toasterService.error(body, 'エラー');
        });

    }


    onUpDown(): void {
        this.reload();
        this.load(this.page, false);
        if (this.table === 'dataset') {
            this._share.loadAdminDatas()
        }
    }

    onFormatViewData($event): void {
        if (!['image', 'file'].includes($event.field.Type)) {
            let formData = new FormData();
            if ($event.raw_data == null) {
                formData.append($event.field.Field, '');
            } else {
                formData.append($event.field.Field, $event.raw_data);
            }
            this._connect.postUpload(`/admin/format/data/${this.table}`, formData).subscribe((jsonData) => {
                this.data_a[$event.data_index].view_data[$event.field.Field] = jsonData['view_data'][$event.field.Field]
            });
        }
    }

    hasRequiredError(data_index: number): boolean {
        return this.table_info.fields.some(field => {
            return (this.table_info.forms.byFieldName(field.Field).required && !!!this.data_a[data_index].raw_data[field.Field] && this.data_a[data_index].raw_data[field.Field] !== 0);
        });
    }


    searchAll(event): void {
        this.loading = true;

        if (!this.customFilter) {
            this.customFilter = new CustomFilter({'table': this.table});
            this.customFilter.setAsTable()
        }
        if (this.customFilter.conditions.condition_a.length > 0) {
            if (this.customFilter.conditions.condition_a.length == 1 && this.customFilter.conditions.condition_a[0].value == this.all_search_text) {
                this.all_search_text = null;
                this.load(this.page)
                return
            } else if (this.customFilter.conditions.condition_a.length >= 1 || this.customFilter.conditions.condition_a[0].value != this.all_search_text) {
                this.customFilter.conditions.deleteAllConditions();
            }
        }
        this.customFilter.conditions.addConditionToAllAndGroup('inc', '_all', this.all_search_text);
        this.all_search_text = null;
        this.reloadPageByCustomFilter(this.customFilter)

    }


    toEditMode() {

        this.loading = true;
        this.isEditMode = true;
        this.editted_row_index_a = [];
        setTimeout(() => {
            this.loading = false;
        }, 50)
        //console.time('toEdit')
    }

    ctrlClickEvent($event) {
        this.toEditMode();
    }

    exitEditMode(commit: boolean) {
        if (commit) {
            this.exitEditModeModal.show();
        } else {
            this.cancelEditModeModal.show();
        }
    }

    addSelectedToDelete() {
        this.to_delete_commit = [...this.to_delete_commit, this.data_a[this.selectedRowIndex].raw_data['id']];
        this.data_a.splice(this.selectedRowIndex, 1);
        this.deleteModal.hide();
    }

    addCheckedToDelete() {
        let to_deletes = this.checked_id_a.map((del_id) => this.data_a.findIndex((data) => data.raw_data['id'] == del_id));
        to_deletes.sort((a, b) => b - a);
        to_deletes.forEach(element => {
            this.data_a.splice(element, 1);
        });
        this.to_delete_commit = [...this.to_delete_commit, ...this.checked_id_a];
        this.checked_id_a = [];
        this.deleteCheckedConfirmModal.hide();
    }

    onSelectRowIndex(index) {
        this.selectedRowIndex = index;
    }


    duplicateSelectedIndex() {
        let newObj = this.data_a[this.selectedRowIndex].getCopy();
        newObj.setRawData({'id': null})
        newObj.id = null;
        console.log(newObj)

        //newObj.raw_data['id'] = this.emptyObjId;
        //newObj.view_data['id'] = this.emptyObjId;
        this.data_a.splice(this.selectedRowIndex + 1, 0, newObj);
        //this.emptyObjId--;
    }


    commit() {
        let commit_data = this._share.getCommitData(this.table_info, this.data_a, this.table_info.fields, this.table_info.forms, this.editted_row_index_a, this.to_delete_commit)
        let url = `/admin/${this.table_info.table}/commit`;
        this.sending = true;
        this._connect.postUpload(url, commit_data).subscribe((jsonData) => {
            if (jsonData['warning_a'].length > 0) {
                this.toasterService.warning(jsonData['warning_a'].join('\n'), '警告')
            }
            this.toasterService.success('データが保存されました。', 'お知らせ');
            this.resetEditParam()
            this.reload();
        }, (jsonData) => {
            let error_message = this._share.getErrorBodyByResponse(jsonData['error'].error_a);
            this.toasterService.error(error_message, 'エラー');
        }).add(() => {
            this.sending = false;
            this.exitEditModeModal.hide()
        });
    }

    private resetEditParam() {
        this._share.resetTableInfoCache()
        this.editted_row_index_a = [];
        this.to_delete_commit = [];
        this.isEditMode = false;

    }

    cancelEdit() {
        this.cancelEditModeModal.hide()
        this.resetEditParam()
        this.reload();

    }

    adminTreeEvent(tree_event) {
        if (tree_event['group_edit_data']) {
            this.group_edit_data = tree_event['group_edit_data'];
            this.is_group_edit = true;
        }
    }

    groupEdit() {
        if (this.switchGroupEdit) {
            // console.log('group_edit_data');
            // console.log(this.group_edit_data);
            let order_count = 1;
            let error_group = {'required': false, 'length': false}
            const MAX_NAME_LENGTH: number = 20;
            const MAX_DEPTH2_NAME_LENGTH: number = 18;
            let length_error = [];
            if (this.is_group_edit) {
                console.log(this.group_edit_data)
                for (let i = 0; i < this.group_edit_data.length; ++i) {
                    if (this.group_edit_data[i]['group'] == undefined) {
                        this.group_edit_data_a[order_count - 1] = {};
                        this.group_edit_data_a[order_count - 1]['order'] = order_count;
                        this.group_edit_data_a[order_count - 1]['id'] = this.group_edit_data[i]['_raw_data']['id'];
                        this.group_edit_data_a[order_count - 1]['previousGroup'] = this.group_edit_data[i]['_raw_data']['group'];
                        this.group_edit_data_a[order_count - 1]['previousGroupId'] = this.group_edit_data[i]['_raw_data']['dataset_group_id'];
                        this.group_edit_data_a[order_count - 1]['currentGroup'] = null;
                        this.group_edit_data_a[order_count - 1]['isChangeGroup'] = this.group_edit_data[i]['_raw_data']['group'] === null ? false : true;
                        this.group_edit_data_a[order_count - 1]['isChangeOrder'] = this.group_edit_data[i]['_raw_data']['order'] == order_count ? false : true;
                        order_count++;
                    } else {
                        if (this.group_edit_data[i]['formControl'].value == '') {
                            error_group['required'] = true;
                        }

                        if (this.group_edit_data[i]['formControl'].value.includes('////')) {
                            let first_stage, second_stage;
                            first_stage = this.group_edit_data[i]['formControl'].value.split('////')[0];
                            second_stage = this.group_edit_data[i]['formControl'].value.split('////')[1];
                            // "////"が複数回使われていないか
                            if (this.group_edit_data[i]['formControl'].value.split('////').length - 1 >= 2) {
                                error_group['length'] = true;
                                length_error.push('1つの入力欄につき\'////\'は一度しか使用できません。');
                            }

                            // 空白か判定
                            if (first_stage == '') {
                                error_group['length'] = true;
                                length_error.push('1段目グループが未入力です。');
                            }
                            if (second_stage == '') {
                                error_group['length'] = true;
                                length_error.push('2段目グループが未入力です。');
                            }

                            // 字数制限
                            if (first_stage.length > MAX_NAME_LENGTH) {
                                error_group['length'] = true;
                                length_error.push('1段目グループ名は' + MAX_NAME_LENGTH + '文字までです。');
                            }
                            if (second_stage.length > MAX_DEPTH2_NAME_LENGTH) {
                                error_group['length'] = true;
                                length_error.push('2段目グループ名は' + MAX_DEPTH2_NAME_LENGTH + '文字までです。');
                            }
                        } else {
                            if (this.group_edit_data[i]['formControl'].value.length > MAX_NAME_LENGTH) {
                                error_group['length'] = true;
                                length_error.push('1段目グループ名は' + MAX_NAME_LENGTH + '文字までです。');
                            }
                        }
                        let group_data = this.group_edit_data[i]
                        group_data['data_a'].forEach(data => {
                            if (data['group'] == undefined) {
                                this.group_edit_data_a[order_count - 1] = {};
                                this.group_edit_data_a[order_count - 1]['order'] = order_count;
                                this.group_edit_data_a[order_count - 1]['id'] = data['_raw_data']['id'];
                                this.group_edit_data_a[order_count - 1]['previousGroup'] = data['_raw_data']['group'];
                                this.group_edit_data_a[order_count - 1]['previousGroupId'] = data['_raw_data']['dataset_group_id'];
                                this.group_edit_data_a[order_count - 1]['currentGroup'] = group_data['formControl']['value'];
                                this.group_edit_data_a[order_count - 1]['currentGroupId'] = group_data['group_id'];
                                this.group_edit_data_a[order_count - 1]['isChangeGroup'] = data['_raw_data']['group'] == this.group_edit_data[i]['formControl']['value'] ? false : true;
                                this.group_edit_data_a[order_count - 1]['isChangeOrder'] = data['_raw_data']['order'] == order_count ? false : true;
                                order_count++;
                            } else {
                                data['data_a'].forEach(data_one => {
                                    this.group_edit_data_a[order_count - 1] = {};
                                    this.group_edit_data_a[order_count - 1]['order'] = order_count;
                                    this.group_edit_data_a[order_count - 1]['id'] = data_one['_raw_data']['id'];
                                    this.group_edit_data_a[order_count - 1]['previousGroup'] = data_one['_raw_data']['group'];
                                    this.group_edit_data_a[order_count - 1]['previousGroupId'] = data_one['_raw_data']['dataset_group_id'];
                                    this.group_edit_data_a[order_count - 1]['currentGroup'] = data['formControl']['value'];
                                    this.group_edit_data_a[order_count - 1]['currentGroupId'] = this.group_edit_data[i]['group_id'];
                                    this.group_edit_data_a[order_count - 1]['isChangeGroup'] = data_one['_raw_data']['group'] == data['formControl']['value'] ? false : true;
                                    this.group_edit_data_a[order_count - 1]['isChangeOrder'] = data_one['_raw_data']['order'] == order_count ? false : true;
                                    order_count++;
                                });
                            }
                        });
                    }
                }
                if (error_group.length) {
                    for (let i = 0; i < length_error.length; i++) {
                        this.toasterService.error(length_error[i], 'エラー');
                    }
                }
                if (error_group.required) {
                    this.toasterService.error('未入力のグループが存在します。', 'エラー');
                }
                if (!error_group.length && !error_group.required) {
                    this.changeGroup(this.group_edit_data_a);
                    // console.log('this.edit_group');
                    // console.log(this.group_edit_data_a);
                }
            } else {
                this.switchGroupEdit = false;
            }
        } else {
            this.switchGroupEdit = true;
            // console.log('group_edit_data_last');
            // console.log(this.group_edit_data);

        }
    }

    groupEditCancel() {
        this.toasterService.success('グループ編集をキャンセルしました。', '成功');
        this.switchGroupEdit = false;
        this.load(this.page);
    }

    changeGroup(change_data) {
        const url = this._connect.getApiUrl() + '/admin/group-edit/dataset';
        this._connect.post(url, {group_edit_data_a: change_data}).subscribe((data: any) => {
            this.toasterService.success('グループ名を変更しました。', '成功');
            // page refresh
            location.reload();
        });
    }

    onChangeSearchValue($event) {
        if (!this.customFilter) {
            this.customFilter = new CustomFilter({'table': this.table});
            this.customFilter.setAsTable()
        }
        this.customFilter.conditions.addCondition($event.is_inc ? 'inc' : 'eq', $event.field, $event.value);
        this.reloadPageByCustomFilter(this.customFilter)

    }

    deleteCondition(condition: Condition) {
        // old code start
        // this.customFilter.conditions.deleteConditionById(condition.id);
        // this.reloadPageByCustomFilter(this.customFilter);
        // old code end


        // test change start
        // i tried to reset it to delete filter after last one
        this.customFilter.conditions.deleteConditionById(condition.id);
        if (this.customFilter.conditions.condition_a.length >= 1) {
            this.reloadPageByCustomFilter(this.customFilter);
        } else {
            localStorage.removeItem(`fixed-field-name-${this.customFilter.id}`)
            this.userTableSetting.filter_id = null;
            this.customFilter = null;
            this._router.navigate([this._share.getAdminTable(), this.table, {'ts': (new Date()).getTime()}]);
        }
        // test change end

    }

    reloadPage() {
        this.loadList();
    }

    gotoSetting() {
        if (!this.table_info.isDatasetTable()) {

            this._share.getTableInfo('dataset').subscribe(_table_info => {
                let filter = new CustomFilter();
                filter.conditions = new Conditions()
                filter.conditions.addCondition('eq', 'system_table', 'admin')
                this._connect.getList(_table_info, 1, Number.MAX_SAFE_INTEGER, filter).subscribe(res => {
                    if (res['data_a'].length > 0) {
                        this._router.navigate([this._share.getAdminTable(), 'dataset', 'edit', res['data_a'][0]['raw_data']['id']]);
                        return;
                    }

                    //まだそのsystem tableのdatasetがない時
                });
                //this._router.navigate([this._share.getAdminTable(), 'dataset', 'edit', 'new', this.table_info.table]);
            })

            return;
        }
        this._router.navigate([this._share.getAdminTable(), 'dataset', 'edit', this.table_info.getDatasetId()]);

    }

    isChartMode() {
        return this.customFilter && this.customFilter.type == 'chart';
    }

    isSearchMode() {
        return this.customFilter && !this.customFilter.isSetSummarizeParam() && this.customFilter.isSetSearchParam()
    }

    onCloseChartModal($event, onSave = false) {
        //this.saveFilter(true)
        this.chartModal.hide();
        if (onSave) {
            this.customFilter = $event.customFilter;
            this.customFilter.conditions.reloadViewValuesTmp(this.table_info, this._connect, this._share)
            this.toasterService.success('フィルタを保存しました。', '成功');
            this.chartTitleModal.hide();
            this.confirmFilterOverwriteConfirmModal.hide();
            this._share.getTableInfo(this.table, false, null, false).subscribe(_table_info => {
                this.table_info = _table_info;
                this.reloadPageByCustomFilter(this.customFilter)
                this._router.navigate([this._share.getAdminTable(), this.table, {'_filter_id': this.customFilter.id, 't': (new Date()).getTime()}]);
            })
            //this.reload();
            //this._router.navigate([this._share.getAdminTable(), this.table, {'_filter_id': this.filter_id}]);
        }
    }

    getEmbedScriptTag() {
        let filter_id = '';
        if (this.customFilter && this.customFilter.id) {
            filter_id = this.customFilter.id.toString()
        }
        return '<script id="pigeon_script" src="' + this._connect.getSiteUrl() + '/widget/embed.js" data-table="' + this.table + '" data-hash="' + this.public_form_hash + '" data-filter="' + filter_id + '"></script>';
    }

    copyPublicFormUrl() {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.public_form_url);
            this.toasterService.success('クリップボードにコピーしました', '成功');
        } else {
            this.toasterService.error('ブラウザ未対応のため、コピーに失敗しました。直接コピーを行ってください。', 'エラー');

        }
    }

    openPublicFormUrl() {
        window.open(this.public_form_url);

    }


    copyEmbedScriptTag() {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.getEmbedScriptTag());
            this.toasterService.success('クリップボードにコピーしました', '成功');
        } else {
            this.toasterService.error('ブラウザ未対応のため、コピーに失敗しました。直接コピーを行ってください。', 'エラー');

        }
    }

    public public_form_url: string = '';
    public public_form_hash: string = '';

    showEmbedLink() {
        this.getPublicFormData().subscribe(() => {
            this.embedModal.show();
        })
    }

    getPublicFormData(): Observable<any> {
        return new Observable(observer => {

            const url = this._connect.getApiUrl() + '/admin/public_form_url';
            let params = {'table': this.table}
            if (this.customFilter && this.customFilter.id) {
                params['filter_id'] = this.customFilter.id
            }
            this._connect.post(url, params).subscribe((res) => {
                this.public_form_url = res['url']
                this.public_form_hash = res['hash']
                observer.next();
            })
        });


    }


    showPublicFormLink() {

        if (!this.customFilter || !this.customFilter.id) {
            this.toasterService.warning('公開ページを作成するには、公開ページ用のフィルタを追加・選択して下さい。', 'エラー');
            return;
        }
        this.getPublicFormData().subscribe(() => {
            this.publicFormModal.show();
        })
    }

    showPublicFormEmailLink() {
        if (!this.customFilter || !this.customFilter.id) {
            this.toasterService.error('公開ページを作成するには、公開ページ用のフィルタを追加・選択して下さい。', 'エラー');
            return;
        }
        this.getPublicFormData().subscribe(() => {
            this.mailPublicFormModal.show()
        })
    }

    getConditionTableInfo(condition: Condition): Observable<TableInfo> {
        return new Observable(observer => {


            if (condition.sub_fields.length > 0) {
                this._share.getTableInfo(condition.sub_fields[condition.sub_fields.length - 1]['table']).subscribe(_table_info => {
                    observer.next(_table_info)
                })

                return;
            } else {
                observer.next(this.table_info)
            }
        });
    }

    variableChanged($event, variable: Variable) {
        variable.value = $event.value;
        this.reloadPage()

    }


    conditionValueChanged($event, condition: Condition) {
        condition.value = $event.value;
        this.reloadPage()
    }

    onSubmitAdminTableSetting() {
        this.tableSettingModal.hide();
        this.toasterService.success('テーブル設定を変更しました', '成功');
    }

    onImportLedger() {
        this._share.resetTableInfoCache()
        this.load(this.page);
        this.checked_id_a = [];
        this._share.loadAdminDatas();
    }


    donwloadLedgerTemplate(ledger: Ledger) {
        let url = '/api/admin/file-by-id/' + ledger.file_info_id;

        this._share.download_file(url, () => {
            //this.downloading = false;
        }, false);

        return;
    }


    private selectedLedger: Ledger;

    confirmDeleteLedgerTemplate(ledger: Ledger) {
        this.selectedLedger = ledger;
        this.deleteLedgerConfirmModal.show();


    }

    deleteLedgerTemplate() {
        this.delete([this.selectedLedger.id], 'ledger')
        this.deleteLedgerConfirmModal.hide();

    }


    public edit_condition_id_a: Array<any> = [];

    //検索条件のインライン編集
    editCondition(condition: Condition) {
        if (condition.field === '_all') {
            let form = new Form({});
            form.createDummyForm('condition_' + condition.id, 'text');
            condition.target_form = form
        }
        //condition.target_form =
        this.edit_condition_id_a.push(condition.id);
    }

    isEditCondition(condition: Condition) {
        return this.edit_condition_id_a.indexOf(condition.id) != -1;
    }

    viewmodalopen(evt) {
        this.modalevent = evt
        this.viewModal.show()
    }

    viewmodalhide() {
        history.replaceState({}, null, document.location.pathname)
        this.viewModal.hide()
    }

    openTableSettingModal() {
        this.tableSettingModal.show()
    }

    openDeliverEmail() {


    }

    onOpenCloseMemo($event) {
        this.dynamicOverflowService.accordionChanged(this.userTableSetting.open_memo)
        setTimeout(() => {
            if (document.querySelector('#memo')) {
                this.memo_height = document.querySelector('#memo').clientHeight
            }
        }, 50)


    }

    public duplicate_hash: Object = {}

    //duplicate Modal
    openDuplicateModal($event) {
        let _duplicate_data: Data = $event.duplicate_data;
        this.duplicate_hash = {
            'dataset_id': _duplicate_data.getRawData('id'),
            'label': _duplicate_data.getRawData('label') + 'のコピー',
            'group': _duplicate_data.getRawData('group'),
            'copy_grant': true,
            'copy_notification': true,
            'copy_filter': true,

        }
        this.duplicateModal.show();
    }

    copyTable() {
        this.loading = true;

        this._connect.post('/api/admin/table/duplicate', this.duplicate_hash).subscribe(_data => {

            this._share.loadAdminDatas().then(() => {
                this.toasterService.success('テーブルをコピーしました', '成功');
                this.duplicateModal.hide();
                this.reload()
            });


        }, (error) => {
            this.loading = false;
        })

    }

    private selected_unlock_data: Data;

    openUnlockModal($event) {

        if (this._share.isMasterUser()) {
            this.selected_unlock_data = $event.data;
            this.unlockModal.show()
        }
    }

    unlock() {
        if (this._share.isMasterUser()) {
            this._connect.post('/admin/' + this.table + '/' + this.selected_unlock_data.getRawData('id') + '/finish-edit', {}).subscribe(_res => {
                this.sending = false;
                this.unlockModal.hide()
                this.toasterService.success('ロックを解除しました', '成功');
                this.reload()
            })
        }
    }

    isAllDataDelable(): boolean {
        if (this.checked_id_a.length == 0) {
            return null;
        }

        let flg: boolean = true;

        let data_a = this.data_a.filter(_data => {
            return this.checked_id_a.indexOf(parseInt(_data.getRawData('id'))) >= 0;
        })
        data_a.forEach(_data => {
            flg &&= _data.isDeletable()
        })
        return flg;
    }

    public index_workflow_param: {
        workflow_status: string,
        data_a: Array<Data>
    } = {workflow_status: null, data_a: []}


    isAllDataAssigned(): boolean {
        if (this.checked_id_a.length == 0) {
            return null;
        }

        let flg: boolean = true;

        let data_a = this.data_a.filter(_data => {
            return this.checked_id_a.indexOf(parseInt(_data.getRawData('id'))) >= 0;
        })

        data_a.forEach(_data => {
            flg &&= _data.workflow.is_assigned;
        })
        return flg;

    }

    isAllDataMyApplying(): boolean {
        if (this.checked_id_a.length == 0) {
            return null;
        }

        let flg: boolean = true;

        let data_a = this.data_a.filter(_data => {
            return this.checked_id_a.indexOf(parseInt(_data.getRawData('id'))) >= 0;
        })


        data_a.forEach(_data => {
            flg &&= _data.getRawData('admin_id') == this._share.user.id && _data.workflow && !_data.workflow.isEnd();
        })
        return flg;

    }

    public workflowAll(workflow_status: string) {
        let data_a = this.data_a.filter(_data => {
            return this.checked_id_a.indexOf(parseInt(_data.getRawData('id'))) >= 0;
        })
        this.index_workflow_param = {
            workflow_status: workflow_status,
            data_a: data_a
        }
        this.indexWorkflowModal.show()
    }

    public onCompleteWorkflowStatusChange() {
        this.indexWorkflowModal.hide();
        this.toasterService.success('ワークフローステータスの変更に成功しました')
        this.reload()

    }

    public uploadFilesByZip(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, this.config);
    }

    public upload_zip_submit() {
        this.loading = true;
        if (this.fileName === '') {
            return;
        }

        for (const key in this.table_info.forms['_forms']) {
            if (['image', 'file'].includes(this.table_info.forms['_forms'][key]['_type'])) {
                let is_multi = this.table_info.forms['_forms'][key]['_is_multi_value_mode'].toString();
                if (is_multi == 'true') {
                    var multi_field_name = this.table_info.forms['_forms'][key]['_field']['Field'];
                } else {
                    var single_field_name = this.table_info.forms['_forms'][key]['_field']['Field'];
                }
            }
        }

        const url = this._connect.getApiUrl() + '/admin/upload-files-as-zip';

        const formData: FormData = new FormData();
        formData.append('zip', this.file, this.fileName);
        formData.append('file_name', this.fileName);
        formData.append('table_name', this.table);
        formData.append('multi_field_name', multi_field_name);
        formData.append('single_field_name', single_field_name);

        this._connect.postUpload(url, formData).subscribe((response) => {
            this.reloadPage();
        }, (error) => {
            this.loading = false;
            alert(error.error.error_a[0]);
        })

        this.modalRef.hide();
    }

    public onFileSelected(event) {
        const file: File = event.target.files[0];
        if (file) {
            this.fileName = file.name;
            this.file = file;
        }
    }


    public conditionEditable(condition: Condition): boolean {
        //FIXME: とりあえずサブフィールドは無し
        return condition.sub_fields.length == 0
    }


    public createEmailTempate() {
        let key_a = ['PUBLIC_FORM']
        key_a.push(this.table)
        if (this.customFilter) {
            key_a.push(this.customFilter.id.toString())
        } else {
            key_a.push('null')
        }
        key_a.push(this.mail_public_form_option.use_data_related_form ? 'true' : 'false')
        let tag = 'https://[' + key_a.join(':') + ']';
        let body = 'test\n' + tag + 'test\n'
        body = `下記URLから回答をお願い致します。\n\n${tag}`
        let params = {
            'body': body,
            'body_html': body.replace(/'n/, '<br>')
        }

        this._router.navigate([this._share.getAdminTable(), 'mail_templates', 'edit', 'new', params], {replaceUrl: true});

    }

    // 検索条件が今日、昨日、明日、今週、先週、来週、今月、先月、来月、今年、去年、来年のときfalseを返す。
    private isDaysShow(condition) {
        let list: Array<string> = [
            'today',
            'yesterday',
            'tomorrow',
            'this_week',
            'last_week',
            'next_week',
            'this_month',
            'last_month',
            'next_month',
            'this_year',
            'last_year',
            'next_year',
        ]
        return list.includes(condition);
    }

    private selectDateChanged(value: string) {
        let num, type;
        [num, type] = value.split(' ', 2);
        this.select_date_option['value'] = value;
        this.select_date_option['num'] = num;
        if (type == 'date') {
            if (num == 0) {this.select_date_option['title'] = `今日`;}
            else if (num == 1) {this.select_date_option['title'] = `明日`;}
            else if (num == -1) {this.select_date_option['title'] = `昨日`;}
            else if (num > 0) {this.select_date_option['title'] = `${num}日後`;}
            else if (num < 0) {this.select_date_option['title'] = `${-1 * num}日前`;}
        } else if (type == 'week') {
            if (num == 0) {this.select_date_option['title'] = `今週`;}
            else if (num == 1) {this.select_date_option['title'] = `来週`;}
            else if (num == -1) {this.select_date_option['title'] = `先週`;}
            else if (num > 0) {this.select_date_option['title'] = `${num}週間後`;}
            else if (num < 0) {this.select_date_option['title'] = `${-1 * num}週間前`;}
        } else if (type == 'month') {
            if (num == 0) {this.select_date_option['title'] = `今月`;}
            else if (num == 1) {this.select_date_option['title'] = `来月`;}
            else if (num == -1) {this.select_date_option['title'] = `先月`;}
            else if (num > 0) {this.select_date_option['title'] = `${num}月後`;}
            else if (num < 0) {this.select_date_option['title'] = `${-1 * num}月前`;}
        } else if (type == 'year') {
            if (num == 0) {this.select_date_option['title'] = `今年`;}
            else if (num == 1) {this.select_date_option['title'] = `来年`;}
            else if (num == -1) {this.select_date_option['title'] = `去年`;}
            else if (num > 0) {this.select_date_option['title'] = `${num}年後`;}
            else if (num < 0) {this.select_date_option['title'] = `${-1 * num}年前`;}
        }
    }

    private enableSelectDate(condition) {
        let value = condition.view_value;
        let field = condition.field;

        let anser: boolean = false;
        this.table_info.fields.forEach(val => {
            if (field == val.Field && (val.Type == 'date' || val.Type == 'datetime')) {
                let type: string = '';
                type = value.split(' ', 2)[1];
                let list = ['date', 'week', 'month', 'year'];
                anser = list.includes(type);
            }
        })

        if (anser) {this.selectDateChanged(value)};

        return anser;
    }

    onViewGrantGroupIdChanged($event: Object) {
        this.customFilter.view_grant_group_id = $event['id'];

    }

    onEditGrantGroupIdChanged($event: Object) {
        this.customFilter.edit_grant_group_id = $event['id'];

    }

    goToEdit() {
        this._router.navigate([this._share.getAdminTable(), this.table, 'edit', this.modalevent['id']]);
    }
    getTableMinHeight() {
        return (50 + (Math.min(this.data_a.length, 10) * 37))
    }

}
