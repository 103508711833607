import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Data} from '../../class/Data';
import {TableInfo} from 'app/class/TableInfo';
import {Observable} from 'rxjs/Observable';
import {Forms} from '../../class/Forms';
import {SharedService} from '../../services/shared';
import {RelationTable} from '../../class/RelationTable';
import {CustomFilter} from '../../class/Filter/CustomFilter';

@Component({
    selector: 'admin-view-content',
    templateUrl: './view-content.component.html',
})

export class ViewContentComponent implements OnChanges {
    @Input('data') data: Data = new Data(new TableInfo([]));
    @Input('reload') reload: Function;
    @Input('loading') loading: Boolean;
    @Input('table_info') table_info: TableInfo;
    @Input('extend_headers') extend_headers: Array<any>
    @Input('extend_data') extend_data: {};
    @Input('customFilter') customFilter: CustomFilter;

    constructor(public _share: SharedService) {

    }


    public relation_table_info_by_table: Object = {}

    public fields: Array<any> = [];

    ngOnChanges(): void {
        if (this.data && this.data.relation_table_a) {
            this.data.relation_table_a.forEach(relation_table => {
                this._share.getTableInfo(relation_table.table).subscribe(_table_info => {
                    this.relation_table_info_by_table[relation_table.table] = _table_info
                })
            })
        }
        this.fields = this.getFields()
    }

    public getRelationTableInfo(relation_table: RelationTable): Observable<TableInfo> {
        return new Observable(observer => {
            this._share.getTableInfo(relation_table.table).subscribe(_table_info => {
                observer.next(_table_info)
            })
        });
    }

    public getRelationTableForms(relation_table: RelationTable): Observable<Forms> {
        return new Observable(observer => {
            this._share.getTableInfo(relation_table.table).subscribe(_table_info => {
                observer.next(_table_info.forms)
            })
        });

    }

    public getRelationViewFields(relation_table: RelationTable): Observable<Array<string>> {
        return new Observable(observer => {
            this._share.getTableInfo(relation_table.table).subscribe(_table_info => {
                if (!_table_info) {
                    observer.next([]);
                } else {
                    observer.next(relation_table.getViewFields(_table_info))
                }
            })
        });

    }

    public getFields() {
        if (!this.table_info) {
            return [];
        }
        if (!this.customFilter || !this.customFilter.view_use_show_fields || this.customFilter.show_fields.length == 0) {
            return this.table_info.fields;
        }
        return this.customFilter.show_fields.map(field_name => {
            return this.table_info.fields.find(f => {
                return f['Field'] === field_name
            })
        }).filter(f => {
            return !!f
        })

    }

}
