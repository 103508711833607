<div *ngIf="data_a.length==0;else Loaded">
    <div class="loader_small">Loading...</div>
</div>
<ng-template #Loaded>
    <select [(ngModel)]="admin_id" (change)="onChange()" class="form-control" [disabled]="disabled">
        <option value=""
                *ngIf="!required">
            選択して下さい
        </option>
        <option *ngFor="let data of data_a"
                value="{{data.raw_data['id']}}">{{data.raw_data['name']}}
        </option>
    </select>

</ng-template>

