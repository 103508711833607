import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Data} from '../class/Data';
import {Router} from '@angular/router'
import {TableInfo} from '../class/TableInfo';
import {SharedService} from 'app/services/shared';
import {Grant} from '../class/Grant';
import {Connect} from 'app/services/connect';
import {CustomFilter} from '../class/Filter/CustomFilter';
import {Conditions} from '../class/Conditions';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import jaLocale from '@fullcalendar/core/locales/ja';
import {Form} from '../class/Form';

@Component({
    selector: 'admin-calendar-view',
    templateUrl: './admin-calendar-view.component.html'
})
export class AdminCalendarViewComponent implements OnInit {
    @Input() checked_delete_id_a: Array<number> = []
    @Input() export_data: boolean;
    @Input() isSummarizeMode: boolean = false;
    @Input() isShowManageCol: boolean = false;
    @Input() is_relation_table: boolean = false;
    @Input() checkboxChange: Function;
    @Input() table_info: TableInfo;
    @Input() isEditMode: boolean;
    @Input() openDeleteModal: Function;
    @Input() current_url: string;
    @Input() customFilter;
    @ViewChild('relationConfirmModal') relationConfirmModal: any;
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;

    calendarOptions: CalendarOptions;

    public grant: Grant;
    public calendar_view_data: Array<Object> = [];
    private _connect: Connect;
    public data_a: Array<Data>;
    public calendarApi;
    public calendar_view_datetime_form: Form;
    public calendar_view_datetime_from_form: Form;
    public calendar_view_datetime_to_form: Form;


    constructor(private _router: Router,_connect: Connect, private _share: SharedService) {
        this._connect = _connect;

    }

    ngOnInit() {
        var today = new Date();

        if (this.table_info.menu.from_to_calendar_view_datetime) {
            this.calendar_view_datetime_from_form = this.table_info.forms.getArray().find((_form: Form, indes) => {
                return _form.field['Field'] == this.table_info.menu.calendar_view_datetime_from;
            });
            this.calendar_view_datetime_to_form = this.table_info.forms.getArray().find((_form: Form, indes) => {
                return _form.field['Field'] == this.table_info.menu.calendar_view_datetime_to;
            });
        } else {
            this.calendar_view_datetime_form = this.table_info.forms.getArray().find((_form: Form, indes) => {
                return _form.field['Field'] == this.table_info.menu.calendar_view_datetime;
            });
        }
        this.getList(today.getFullYear(), today.getMonth());


    }

    ngOnChanges() {
        this.ngOnInit();
    }

    getList(year: number, month: number){
        // let filter = new CustomFilter();
        // var last_date = new Date(Number(year), Number(month), 0);
        // filter.conditions = new Conditions();


        // if (this.table_info.menu['from_to_calendar_view_datetime']) {
        //     let from_field_name = this.calendar_view_datetime_from_form.field['Field'];
        //     let to_field_name = this.calendar_view_datetime_to_form.field['Field'];
        //     if (this.customFilter != null) {
        //         let condition_a = this.customFilter.conditions.condition_a;
        //         console.log(condition_a);
        //         console.log(filter.conditions);
        //         for (let i = 0; i < condition_a.length; i++) {

        //         }
        //     }
        // } else {
        //     let field_name = this.calendar_view_datetime_form.field['Field'];
        //     if (this.customFilter != null) {
        //         let condition_a = this.customFilter.conditions.condition_a;
        //         console.log(condition_a);
        //         console.log(filter.conditions);
        //         for (let i = 0; i < condition_a.length; i++) {
        //             if (condition_a[i].and_or == 'or') {
        //                 filter.conditions.addCondition('gt', field_name, year + '-' + (month) + '-01');
        //                 filter.conditions.addCondition('lt_ne', field_name, year + '-' + (month + 2) + '-' + last_date.getDate());
        //             }
        //             filter.conditions.addCondition(condition_a[i].condition, condition_a[i].field, condition_a[i].value, condition_a[i].and_or);
        //             if (i == condition_a.length - 1) {
        //                 filter.conditions.addCondition('gt', field_name, year + '-' + (month) + '-01');
        //                 filter.conditions.addCondition('lt_ne', field_name, year + '-' + (month + 2) + '-' + last_date.getDate());
        //             }
        //         }
        //     }
        // }
        this._connect.getList(this.table_info, 1, 1000, this.customFilter).subscribe((data) => {
            this.data_a = [];
            data['data_a'].forEach(_data => {
                let newdata = new Data(this.table_info);
                newdata.setInstanceData(_data)
                this.data_a.push(newdata);
            });
            this.convertToCalendarViewData(this.data_a);
        });
    }

    convertToCalendarViewData(data_a: Array<Data>) {
        let calendar_view_datetime_form: Form;
        let calendar_view_datetime_from_form: Form;
        let calendar_view_datetime_to_form: Form;
        let field_displaying_in_calendar_view_form: Form;

        this.calendar_view_data = [];

        // 日時フィールドのデータを取得

        if (this.table_info.menu.from_to_calendar_view_datetime) {
            calendar_view_datetime_from_form = this.table_info.forms.getArray().find(_form => _form.field['Field'] == this.table_info.menu.calendar_view_datetime_from);
            calendar_view_datetime_to_form = this.table_info.forms.getArray().find(_form => _form.field['Field'] == this.table_info.menu.calendar_view_datetime_to);
        } else {
            calendar_view_datetime_form = this.table_info.forms.getArray().find(_form => _form.field['Field'] == this.table_info.menu.calendar_view_datetime);
        }


        field_displaying_in_calendar_view_form = this.table_info.forms.getArray().find(_form => _form.field['Field'] == this.table_info.menu.field_displaying_in_calendar_view);

        this.data_a.forEach((data_one, key) => {
            let title: string, start: string, end: string, color: string, data;
            title = this.convertFormat(this.table_info.menu.field_displaying_in_calendar_view, this.table_info.fields, data_one.view_data);

            // 画像を表示する場合
            let img_link = data_one.view_data[this.table_info['_menu']['_image_field_displaying_in_calendar_view']];
            if(img_link == undefined) {
                img_link = null;
            }

            color = '#67C2EF';
            data = {
                _router: this._router,
                id: data_one.raw_data['id'],
                table: this.table_info.menu.table,
                admin_table: this._share.getAdminTable(),
            };
            if (this.table_info.menu.from_to_calendar_view_datetime) {
                let date_time_from: object = this.convertStringIntoTime(data_one.raw_data[calendar_view_datetime_from_form.field['Field']]);
                start = date_time_from['date'] + 'T' + date_time_from['time'];
                let date_time_to: object = this.convertStringIntoTime(data_one.raw_data[calendar_view_datetime_to_form.field['Field']]);
                end = date_time_to['date'] + 'T' + date_time_to['time'];
                this.calendar_view_data.push({
                    title: title,
                    start: start,
                    end: end,
                    color: color,
                    allDay: date_time_from['all_day'],
                    data: data
                });
            } else {
                let date_time: object = this.convertStringIntoTime(data_one.raw_data[calendar_view_datetime_form.field['Field']]);
                start = date_time['date'] + 'T' + date_time['time'];
                this.calendar_view_data.push({
                    title: title,
                    start: start,
                    color: color,
                    imgurl: img_link,
                    allDay: date_time['all_day'],
                    data: data
                });
            }
        });
        this.drawCalendar();
    }

    // "{ID}などの表現を表示できる形式にする。 ex) ID = 2の時、{ID}は2になる。"
    convertFormat(format_str: string, field: any, view_data: any): string{
        let list = [];
        let result_str: string = '';

        field.forEach(field_one => {
            list.push(["{" + field_one['Comment'] + "}", field_one['Field']]);
        });

        result_str = format_str;
        for (let i = 0; i < list.length; i++) {
            result_str = result_str.replace(list[i][0], view_data[list[i][1]]);
        }
        let result_str_temp: string = '';
        while (result_str != result_str_temp){
            result_str_temp = result_str;
            for (let i = 0; i < list.length; i++) {
                result_str = result_str.replace(list[i][0], view_data[list[i][1]]);
            }
        }
        return result_str;
    }

    convertStringIntoTime(date_string: string): Object{
        let date: string;
        let time: string;
        let all_day: boolean;
        if (!date_string) {
            return {'all_day': false, 'date': null, 'time': null};
        }
        [date, time] = date_string.split(' ', 2);
        all_day = (time == null || time == undefined) ? true : false;
        if (all_day) {
            time = '00:00:00';
        }
        ;
        return {'all_day': all_day, 'date': date, 'time': time};
    }

    convertStringIntoTime2(date_time: string){
        let year: string;
        let month: string;
        let date: string;
        let dummy: string;
        [date_time, dummy] = date_time.split('T', 2);
        [year, month, date] = date_time.split('-', 3);
        return {'year': Number(year), 'month': Number(month), 'date': Number(date)};
    }

    drawCalendar(){
        this.calendarOptions = {
            events: this.calendar_view_data,
            locale: jaLocale,
            headerToolbar: {
                center: 'dayGridMonth,timeGridWeek,timeGridDay',
                end: 'today,prevYear,prev,next,nextYear'
            },
            navLinks: true,
            fixedWeekCount: false,
            eventClick: (event) => {
                this._router.navigate([event['event']['_def']['extendedProps']['data']['admin_table'], event['event']['_def']['extendedProps']['data']['table'], 'view', event['event']['_def']['extendedProps']['data']['id']]);
            },
            views: {
                dayGridMonth: {
                    eventContent: (event, eventElement) => {
                        let imgurl = event.event._def.extendedProps.imgurl;
                        let title = event.event.title;
                        if(imgurl != null) {
                            let html_tag = `<div style="width: 100%; margin: 1%;"><img src="${imgurl}" alt="画像" title="${title}" style="width: 100%; object-fit: contain; height: 80px;"></img><p style="margin-top: 5px; margin-bottom: 4px;">${title}</p></div>`
                            return {html: html_tag}
                        }
                    }
                }
            },
            datesSet: (dateInfo) => {
                let start = this.convertStringIntoTime2(dateInfo.startStr);
                this.getList(start.year, start.month);
            },
            navLinkDayClick: (date, jsEvent) => {
                this._router.navigate([this._share.getAdminTable(), this.table_info.table, 'edit', 'new'], {queryParams: {'year_calendar': date.getFullYear(), 'month_calendar': date.getMonth() + 1, 'date_calendar': date.getDate()}});
            }
        };
    }
}


