import {Base} from './Base';
import {WorkflowPath} from './Workflow/WorkflowPath';

export class Workflow extends Base {
    private _admin_id: number;
    private _table: string;
    private _data_id: number;
    private _status: string;
    private _assigned_admin_id: number;
    private _workflow_path_a: Array<WorkflowPath> = [];


    get workflow_path_a(): Array<WorkflowPath> {
        return this._workflow_path_a;
    }

    set workflow_path_a(value: Array<WorkflowPath>) {
        this._workflow_path_a = value;
    }

    private _is_assigned: boolean;
    private _is_salvage_acceptable: boolean;
    private _next_assigned_username: string;
    private _is_exist: boolean;
    private _is_editable: boolean;


    constructor(hash = {}) {
        super(hash)
        if (!hash) {
            return;
        }
        for (const key of Object.keys(hash)) {
            if (key == 'workflow_path_a') {
                this._workflow_path_a = []

                hash[key].forEach((path_hash) => {
                    let workflow_path = new WorkflowPath();
                    workflow_path.setByHash(path_hash)
                    console.log(workflow_path)
                    this._workflow_path_a.push(workflow_path)
                })


            } else {
                this['_' + key] = hash[key];
            }
        }
    }

    isSetWorkflow() {
        return this.workflow_path_a.length > 0;
    }


    isEnd() {
        return this._status !== 'applying';
    }

    get error_message(): string {
        return this._error_message;
    }


    private _error_message: string = null;

    public validate(main_division_id: number = null): boolean {

        if (this.workflow_path_a.length == 0) {
            this._error_message = '承認先は一つ以上選択して下さい。';
            return false;
        }

        let error_flg = false;
        this.workflow_path_a.forEach(workflowPath => {
            if (!workflowPath.validate(main_division_id)) {
                this._error_message = workflowPath.error_message;
                error_flg = true;
                return false;
            }
        })
        return !error_flg;

    }

    public addWorkflowPath() {
        this.workflow_path_a.push(new WorkflowPath());
    }

    public deleteWorkflowPath(index: number): void {
        this.workflow_path_a.splice(index, 1);
    }

    public getWorkflowPathForPost() {
        return this.workflow_path_a.map(wpath => {
            return wpath.toArray();
        })
    }

    public toArray(main_division_id = null): any {
        /*
        if (main_division_id) {
            //ワークフロー登録時にメインの組織IDを設定する
            this.workflow_path_a.forEach(workflowPath => {
                console.log(workflowPath)
                workflowPath.setMainDivisionId(main_division_id)

            })
        }
         */
        return super.toArray();
    }

    public isApplyUser(admin_id: number) {
        return this._admin_id == admin_id
    }

    get is_assigned(): boolean {
        return this._is_assigned;
    }

    get next_assigned_username(): string {
        return this._next_assigned_username;
    }

    get assigned_admin_id(): number {
        return this._assigned_admin_id;
    }

    get data_id(): number {
        return this._data_id;
    }

    get status(): string {
        return this._status;
    }

    get table(): string {
        return this._table;
    }

    get is_exist(): boolean {
        return this._is_exist;
    }

    get is_editable(): boolean {
        return this._is_editable;
    }


    get is_salvage_acceptable(): boolean {
        return this._is_salvage_acceptable;
    }
}
