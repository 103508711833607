import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {SortParam} from '../../class/Filter/SortParam';
import {TableInfo} from '../../class/TableInfo';
import {SharedService} from '../../services/shared';


@Component({
    selector: 'sort-params-form',
    templateUrl: './sort-params-form.component.html'
})
export class SortParamsFormComponent implements OnChanges {


    @Input() sort_params: Array<SortParam> = [];
    @Input() form_a: TableInfo = null;

    @Input() table_info: TableInfo = null;
    @Input() table: string = null;
    @Output() onChangeValue: EventEmitter<Object> = new EventEmitter();


    constructor(private _shared: SharedService) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (this.table) {
            this._shared.getTableInfo(this.table).subscribe(_table_info => {
                this.table_info = _table_info
            })
        }

        if (!this.sort_params) {
            this.sort_params = [];
        }
    }


    onChange() {
        this.onChangeValue.emit({
            'value': this.sort_params
        })
    }

    public addSortParam(field: string, asc_desc: string = 'asc') {
        this.sort_params.push(new SortParam(field, asc_desc))
        this.onChange()
    }

    public deleteSortParam(index: number) {
        this.sort_params.splice(index, 1)
        this.onChange()
    }


}
