<div class="app flex-row align-items-center" id="login" style="margin-top: 30vh;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="card-group mb-0">
                    <div class="card p-4">
                        <div class="card-block">
                            <ng-container *ngIf="is_ip_error">
                                <div style="text-align: center">
                                    <h3 class="mb-4">Pigeon Cloud</h3>
                                    <div>
                                        この端末からはアクセスすることは出来ません
                                    </div>

                                    <div>
                                        ご不明点は<a href="mailto:sales@loftal.jp">sales@loftal.jp</a>よりお問い合わせ下さい
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="terms_and_conditions_ui">
                                <div [innerHTML]='terms_and_conditions_content'
                                     style="height: 50vh;width: 100%; overflow: auto;">
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" value="agree_to_terms_and_conditions_check" [value]="true" [(ngModel)]="agree_to_terms_and_conditions_check"
                                        />
                                        利用規約に同意します
                                    </label>
                                </div>
                                <button type="button"
                                        class="btn btn-primary px-4" (click)="agreeToTermsAndConditions()" [disabled]="!agree_to_terms_and_conditions_check">続ける
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!is_ip_error && !terms_and_conditions_ui">
                                <ng-container *ngIf="admin_label; else NotLoaded">

                                    <form [formGroup]="myForm" ngNativeValidate
                                          (ngSubmit)="password_changed?login(myForm.value):resetPassword(myForm.value)">

                                        <h1 class="mb-4">{{admin_label}}</h1>
                                        <div *ngIf="before_html;then html_block else no_html_block"></div>
                                        <ng-template #html_block>
                                            <div class="mb-3" [innerHTML]="before_html | safeHtml"></div>
                                        </ng-template>
                                        <ng-template #no_html_block>
                                            <p class="text-muted" *ngIf="sub_label!=undefined"
                                               [innerHTML]="sub_label  | safeHtml"></p>
                                        </ng-template>
                                        <div class="input-group mb-3" *ngIf=password_changed>
                                            <span class="input-group-addon"><i class="icon-user"></i></span>
                                            <input id="id" type="text" class="form-control" placeholder="ID"
                                                   [formControl]="myForm.controls['email']">
                                        </div>
                                        <div class="input-group mb-4" *ngIf=(password_changed)>
                                            <span class="input-group-addon"><i class="icon-lock"></i></span>
                                            <input id="password" type="password" class="form-control"
                                                   placeholder="Password" [formControl]="myForm.controls['password']">
                                        </div>
                                        <div *ngIf="!password_changed && password_mismatch">
                                            <span style="color:red">確認パスワードが一致しませんでした</span>
                                        </div>
                                        <div *ngIf=(!password_changed)>
                                            <label>新しいパスワード</label>
                                            <div class="input-group mb-4">
                                                <span class="input-group-addon"><i class="icon-lock"></i></span>
                                                <input id="new_password" type="password" class="form-control"
                                                       placeholder="New Password"
                                                       [formControl]="myForm.controls['new_password']" required>
                                            </div>

                                        </div>
                                        <div *ngIf=(!password_changed)>
                                            <label>パスワード確認</label>
                                            <div class="input-group mb-4">
                                                <span class="input-group-addon"><i class="icon-lock"></i></span>
                                                <input id="confirm_new_password" type="password" class="form-control"
                                                       placeholder="Confirm New Password"
                                                       [formControl]="myForm.controls['confirm_new_password']" required>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <button style="    width: 100%;" type="submit" [ladda]="loading"
                                                        class="btn btn-primary px-4 font-weight-bold">{{!password_changed ? "パスワード変更" : "ログイン"}}</button>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="row" *ngIf="display_google_login;">
                                        <div class="col-12">
                                            <a href="/api/saml/sso" target="_blank">
                                                <button style="width: 100%;" id="customBtn" type="submit" [ladda]="loading"
                                                        class="btn btn-primary px-4 font-weight-bold">Sign in with Google
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="display_365_login;else Not365Login">
                                        <div class="col-12">
                                                <h1 class="mb-4">{{admin_label}}</h1>
                                                <a href="/api/saml2/sso" target="_blank">
                                                    <button style="width: 100%;" type="submit" [ladda]="loading" class="btn btn-primary px-4 font-weight-bold">
                                                        <object type="image/svg+xml"
                                                                data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                                                                class="x-icon"></object>
                                                            Sign in with Microsoft 365
                                                    </button>
                                                </a>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #NotLoaded>
                                    <!-- <h3 class="mb-4">Pigeon Cloud</h3> -->
                                    <div>ロード中です。しばらくお待ち下さい...</div>
                                </ng-template>

                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            Ver.{{getShare().version}} Powered by &nbsp;
            <a href="https://pigeon-fw.com/cloud" target="_blank">Pigeon Cloud</a>
        </div>
    </div>
</div>




