import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {FormsModule} from '@angular/forms';
import {ConditionFormComponent} from './edit/field/condition-form.component';
import {ConditionsFormComponent} from './edit/field/conditions-form.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {NgSelectModule} from '@ng-select/ng-select';
import {SearchFieldComponent} from './edit/field/search-field.component';
import {UserFieldComponent} from './edit/field/user-field.component';
import {DivisionFieldComponent} from './edit/field/division-field.component';
import {WorkflowSettingComponent} from './workflow/workflow-setting.component';
import {WorkflowModalComponent} from './edit/workflow-modal.component';
import {FieldSelectDragdropComponent} from '../components/field-select/field-select-dragdrop.component';
import {PositionFieldComponent} from './edit/field/position-field.component';
import {IndexWorkflowModalComponent} from '../components/modal/index-workflow-modal.component';
import {DynamicDataViewComponent} from './dynamic-data-view.component';
import {MemoComponent} from '../components/memo/memo.component';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CustomFilterPulldownComponent} from './custom-filter-pulldown';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap';
import {LaddaModule} from 'angular2-ladda';
import {NgImageFullscreenViewModule} from 'ng-image-fullscreen-view';
import {SelectFieldComponent} from '../components/select-field/select-field.component';
import {SortParamsFormComponent} from '../components/custom-form/sort-params-form.component';
import {DebugModeOnDirective} from 'app/debug-mode-on.directive';
import {ConfirmModalComponent} from '../components/modal/confirm-modal.component';
import {GrantGroupFormComponent} from '../components/custom-form/grant-group-form.component';
import {EditComponent} from './edit/edit.component';
import {CommentModalComponent} from './edit/comment-modal.component';
import {FormsComponent} from './edit/forms.component';
import {FormsFieldComponent} from './edit/forms-field.component';
import {FormsImageComponent} from './edit/forms-image.component';
import {DatasetFormComponent} from './edit/dataset-form.component';
import {DatasetFieldOneComponent} from './edit/dataset-field-one.component';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {GrantComponent} from './edit/grant/grant.component';
import {InputNumberDirective} from '../shared/InputNumberDirective';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import { ModuleDragAndDropDirective } from 'app/shared/module-drag-and-drop.directive';
import {MentionModule} from 'kl-angular-mentions';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@NgModule({
    declarations: [ConditionFormComponent,
        ConditionsFormComponent,
        SearchFieldComponent,
        UserFieldComponent,
        DivisionFieldComponent,
        WorkflowSettingComponent,
        PositionFieldComponent,
        DynamicDataViewComponent,
        CustomFilterPulldownComponent,
        IndexWorkflowModalComponent,
        FieldSelectDragdropComponent,
        SelectFieldComponent,
        SortParamsFormComponent,
        ConfirmModalComponent,
        DebugModeOnDirective,
        GrantGroupFormComponent,
        EditComponent,
        WorkflowModalComponent,
        MemoComponent,

        CommentModalComponent,
        FormsComponent,
        FormsFieldComponent,
        FormsImageComponent,
        DatasetFormComponent,
        DatasetFieldOneComponent,
        GrantComponent,
        InputNumberDirective,

        SafeHtmlPipe,
        ModuleDragAndDropDirective

    ],

    exports: [
        Nl2BrPipeModule,
        ProgressbarModule,
        NgbTooltipModule,
        ConditionFormComponent,
        ConditionsFormComponent,
        SearchFieldComponent,
        UserFieldComponent,
        DivisionFieldComponent,
        WorkflowSettingComponent,
        DynamicDataViewComponent,
        CustomFilterPulldownComponent,
        IndexWorkflowModalComponent,
        FieldSelectDragdropComponent,
        MemoComponent,
        SelectFieldComponent,
        SortParamsFormComponent,
        ConfirmModalComponent,
        DebugModeOnDirective,
        GrantGroupFormComponent,
        EditComponent,
        CommentModalComponent,
        FormsComponent,
        FormsFieldComponent,
        FormsImageComponent,
        DatasetFormComponent,
        DatasetFieldOneComponent,
        GrantComponent,
        WorkflowModalComponent,
        InputNumberDirective,
        ModuleDragAndDropDirective
    ],
    imports: [
        NgImageFullscreenViewModule,
        Nl2BrPipeModule,
        FormsModule,
        CommonModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgSelectModule,
        ProgressbarModule.forRoot(),
        FroalaViewModule,
        DragDropModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        LaddaModule.forRoot({
            style: 'slide-up',
            spinnerSize: 25,
            spinnerColor: '#fff',
            spinnerLines: 12
        }),
        NgbCollapseModule,
        FroalaEditorModule,
        NgbTooltipModule,
        MentionModule

    ],
})
export class AdminSharedModule {
}
