import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';


@Component({
    selector: 'field-select-dragdrop',
    templateUrl: './field-select-dragdrop.component.html'
})
export class FieldSelectDragdropComponent implements OnChanges {


    @Input() fields: Array<any> = [];
    @Input() selected_field_name_a: Array<string> = [];
    @Output() onChangeValue: EventEmitter<Object> = new EventEmitter();

    public dd_selected_fields: Array<string> = []
    public dd_unselected_fields: Array<any> = [];


    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

        this.onChange();
    }

    onChange() {
        this.onChangeValue.emit({
            'selected_field_name_a': this.dd_selected_fields.map(f => {
                return f['Field']
            })

        });
    }

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        let field_by_fname = {}
        this.fields.forEach(field => {
            field_by_fname[field['Field']] = field
        })
        this.fields = this.fields.filter(_field => {
            return !_field['Comment'].match(/^fixed_html_/)
        })
        //console.log('app-aside ngoninit')
        this.dd_selected_fields = []
        this.selected_field_name_a.forEach(field_name => {
            let field = field_by_fname[field_name]
            if (field) {
                this.dd_selected_fields.push(field)
            }

        })
        this.dd_unselected_fields = this.fields.filter(f => {
            return this.selected_field_name_a.indexOf(f['Field']) == -1
        })
    }


    ngOnInit() {

    }

    moveAllFieldDisplay() {
        this.dd_selected_fields = this.fields.map(field => {
            return field
        })
        this.dd_unselected_fields = []
        this.onChange();

    }

    moveAllFieldNotDisplay() {
        this.dd_unselected_fields = this.fields.map(field => {
            return field
        })
        this.dd_selected_fields = []
        this.onChange();

    }

}
