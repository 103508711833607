<div *ngIf="type=='fixed_html';else NotFixedHtml">
    <div class="form-group row admin-forms">
        <!--
        <label class="col-md-4 form-control-label">
            表示ページ
        </label>-->
        <div class="col-md-8">
            <div class="checkbox">
                <!--<label>
                    <input type="checkbox" [checked]="this.data.raw_data['option']['show_edit_page']"
                           [value]="true"> 編集画面
                </label>
                <br>-->
                <label>
                    <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['show_view_page']" [value]="true"> 詳細ページでも表示
                </label>
            </div>
        </div>

        <div class="col-md-12 mt-4">
            <div [froalaEditor]="_share.getFroalaOption()"
                 [(froalaModel)]="this.data.raw_data['option']['fixed_html']"></div>
            <input [(ngModel)]="this.data.raw_data['unique_key_name']" type="hidden" [value]="true">
        </div>
    </div>


</div>
<ng-template #NotFixedHtml>
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            項目名 <span class="text-danger">※</span>
        </label>
        <div class="col-md-8">
            <input [(ngModel)]="this.data.raw_data['name']" type="text" name="label" class="form-control">
            <input [(ngModel)]="this.data.raw_data['unique_key_name']" type="hidden" [value]="true">
        </div>
    </div>
</ng-template>

<div *ngIf="type=='auto-id'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        フォーマット <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <input [(ngModel)]="this.data.raw_data['option']['auto-id-format']" type="text" name="label" class="form-control"
               (change)="setDummyFieldAndForm()" placeholder="ID-{YYYY}-{MM}-{ID:4}">
    </div>
</div>
<div *ngIf="type=='text'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        種類 <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <div class="radio">
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" (change)="changeTextType()" type="radio" name="_form_data" value="text"> テキスト
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" (change)="changeTextType()" type="radio" name="_form_data" value="email"> メールアドレス
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" (change)="changeTextType()" type="radio" name="_form_data" value="url"> URL
            </label>
        </div>
    </div>

    <ng-container *ngIf="data.raw_data['type'] !== 'url'">
        <label class="col-md-4 form-control-label">
            値の重榎
        </label>
        <div class="col-md-8">
            <div class="checkbox">
                <label>
                    <input type="hidden" [(ngModel)]="this.data.raw_data['is_unique']">
                    <input type="checkbox" [checked]="this.data.raw_data['is_unique']=='true'"
                           value="true" (change)="onUniqueCheckChange($event)"> 値の重複を禁止する
                </label>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="type=='textarea'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        種類 <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <div class="radio">
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" name="_form_data" value="textarea"> 通常テキスト
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" name="_form_data" value="richtext"> リッチテキスト
            </label>
        </div>
    </div>
</div>
<div *ngIf="type=='boolean'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        ラベル <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <input [(ngModel)]="this.data.raw_data['option']['boolean-text']" type="text" name="label" class="form-control"
               (change)="setDummyFieldAndForm()">
    </div>
</div>
<div *ngIf="type=='radio'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        種類 <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <div class="radio">
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="radio"> ラジオボタン
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="select"> プルダウン
            </label>
        </div>
    </div>
</div>
<div *ngIf="type=='checkbox'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        種類 <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <div class="radio">
            <label>
                <input [(ngModel)]="this.data.raw_data['option']['checkbox_input_type']" type="radio" value="checkbox" [checked]="!this.data.raw_data['option']['checkbox_input_type']"> チェックボックス
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['option']['checkbox_input_type']" type="radio" value="select"> プルダウン
            </label>
        </div>
    </div>
</div>
<div *ngIf="['checkbox','radio','select'].indexOf(type)>=0" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        選択肢 <span class="text-danger">※</span>
        <div><small class="text-gray-dark">カンマ区切りで入力してください。</small></div>
    </label>
    <div class="col-md-8">
        <input *ngIf="this.data.raw_data['option']!==undefined" [(ngModel)]="this.data.raw_data['option']['items']"
               (change)="setDummyFieldAndForm()" type="text" class="form-control">
    </div>
</div>
<div *ngIf="['checkbox'].indexOf(type)>=0" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        選択肢の制限<br>
        <small>空の場合は制限無し</small>
    </label>
    <div class="col-md-8">
        <table>
            <tr>
                <td class="text-nowrap">最小</td>
                <td><input [min]="0" OnlyNumber class="form-control width-max-100 ml-1" [(ngModel)]="this.data.raw_data['option']['num_min']" [object]="data.raw_data" [key]="'num_min'"></td>
                <td class="text-nowrap pl-2">最大</td>
                <td><input [min]="1" OnlyNumber class="form-control width-max-100 ml-1" [(ngModel)]="this.data.raw_data['option']['num_max']" [object]="data.raw_data" [key]="'num_max'"></td>
            </tr>
        </table>
    </div>


</div>
<ng-container *ngIf="type=='calc'">
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            計算式 <span class="text-danger">※</span>
        </label>
        <div class="col-md-8">
            <input [(ngModel)]="this.data.raw_data['option']['expression']" id="Comment" [mention]="relation_fields"
                   [mentionConfig]="{triggerChar:'{',items:relation_fields,mentionSelect:mentionSelect,labelKey:'Comment'}" class="form-control" rows="16">

        </div>


    </div>
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            計算値の自動更新
            <div><small class="text-gray-dark">※OFFの場合一度入力された値は自動的に更新されません</small></div>
        </label>
        <div class="col-md-8">
            <label>
                <input [(ngModel)]="this.data.raw_data['option']['calc_auto_reload_off']" type="checkbox" value="true"> OFF
            </label>
        </div>
    </div>
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            計算値の種類
        </label>
        <div class="col-md-8 row">
            <div class="col-md-12">
                <label class="text-nowrap">
                    <input [(ngModel)]="this.data.raw_data['option']['calc_result_type']" name="calc_result_type" type="radio" value="number"> 数値
                </label>
            </div>
            <div class="col-md-12">
                <label class="text-nowrap">
                    <input [(ngModel)]="this.data.raw_data['option']['calc_result_type']" name="calc_result_type" type="radio" value="date"> 日付
                </label>
            </div>
            <div class="col-md-12">
                <label class="text-nowrap">
                    <input [(ngModel)]="this.data.raw_data['option']['calc_result_type']" name="calc_result_type" type="radio" value="datetime"> 日時
                </label>
            </div>
            <!--
            <div class="col-md-12">
                <label class="text-nowrap">
                    <input [(ngModel)]="this.data.raw_data['option']['calc_result_type']" type="radio" value="time"> 時間
                </label>
            </div>
            -->
        </div>
    </div>
</ng-container>
<ng-container *ngIf="(type=='number' || (type=='calc' && data.raw_data['option']['calc_result_type']=='number'))">
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            数値の形式
        </label>
        <div class="col-md-8">
            <select [(ngModel)]="data.raw_data['option']['switch_num']" (change)="changeSwitchNum()" class="form-control">
                <option value="integer">整数</option>
                <option value="decimal">少数</option>
            </select>
        </div>
    </div>

    <div *ngIf="data.raw_data['option']['switch_num'] === 'decimal'" class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            桁数
        </label>
        <div class="col-md-8">
            <select [(ngModel)]="this.data.raw_data['option']['decimal_places']" class="form-control">
                <option value="1">1.0</option>
                <option value="2">1.00</option>
                <option value="3">1.000</option>
                <option value="4">1.0000</option>
                <option value="5">1.00000</option>
                <option value="6">1.000000</option>
                <option value="7">1.0000000</option>
                <option value="8">1.00000000</option>
            </select>
        </div>
    </div>
</ng-container>


<div *ngIf="type=='calc' || type=='number'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        桁区切り
    </label>
    <div class="col-md-8">
        <div class="checkbox">
            <label>
                <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['num_separator']" value="true"> 桁区切りを表示しない
            </label>
        </div>
    </div>
</div>

<div *ngIf="type=='number'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        値の最小最大
        <div><small class="text-gray-dark">整数で指定</small></div>
    </label>
    <div class="col-md-8">
        <table>
            <tr>
                <td class="text-nowrap">最小</td>
                <td><input OnlyNumber class="form-control width-max-100 ml-1" [(ngModel)]="this.data.raw_data['option']['num_min']" [object]="data.raw_data" [key]="'num_min'"></td>
                <td class="text-nowrap pl-3">最大</td>
                <td><input OnlyNumber class="form-control width-max-100 ml-1" [(ngModel)]="this.data.raw_data['option']['num_max']" [object]="data.raw_data" [key]="'num_max'"></td>
            </tr>
        </table>
    </div>


    <label class="col-md-4 form-control-label">
        値の重榎
    </label>
    <div class="col-md-8">
        <div class="checkbox">
            <label>
                <input type="hidden" [(ngModel)]="this.data.raw_data['is_unique']">
                <input type="checkbox" [checked]="this.data.raw_data['is_unique']=='true'"
                       value="true" (change)="onUniqueCheckChange($event)"> 値の重榎を禁止する
            </label>
        </div>
    </div>
</div>

<div *ngIf="type=='calc' || type=='number'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        単位記号表示
    </label>
    <div class="col-md-8">
        <table>
            <tr>
                <td class="col-md-12 pl-0"><input type="text" class="form-control" [(ngModel)]="this.data.raw_data['option']['num_unit']"></td>
                <td class="col-md-2">
                    <label class="text-nowrap">
                        <input [(ngModel)]="this.data.raw_data['option']['num_unit_order']" type="radio" value="first" name="num_unit_order"> 先頭につける
                        <div><small class="text-gray-dark">例：¥100</small></div>
                    </label>
                </td>
                <td class="col-md-2">
                    <label class="text-nowrap">
                        <input [(ngModel)]="this.data.raw_data['option']['num_unit_order']" type="radio" value="end" name="num_unit_order"> 末尾につける
                        <div><small class="text-gray-dark">例：30%</small></div>
                    </label>
                </td>
            </tr>
        </table>
    </div>
</div>

<div *ngIf="type=='datetime' || type=='date' || type=='time' || type=='year_month'" class="form-group row admin-forms">
    <label class="col-md-4 form-control-label">
        種類 <span class="text-danger">※</span>
    </label>
    <div class="col-md-8">
        <div class="radio">
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="datetime" (change)="setType(this.data.raw_data['type'])"> 日時
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="year_month" (change)="setType(this.data.raw_data['type'])"> 年月
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="date" (change)="setType(this.data.raw_data['type'])"> 日付のみ
            </label>
            <label>
                <input [(ngModel)]="this.data.raw_data['type']" type="radio" value="time" (change)="setType(this.data.raw_data['type'])"> 時刻のみ
            </label>
        </div>
    </div>
    <label class="col-md-4 form-control-label">
        デフォルトで現在日時(時刻)をセット
    </label>
    <label class="col-md-8 form-control-label">
        <input type="checkbox" [checked]="this.data.raw_data['option']['use_current_as_default']" [(ngModel)]="this.data.raw_data['option']['use_current_as_default']" [value]="true">
        ON
    </label>
    <ng-container *ngIf="this.data.raw_data['type']!='time'">
        <label class="col-md-4 form-control-label">
            フォーマット
        </label>
        <div class="col-md-8">
            <label>
                <input type="checkbox" [checked]="this.data.raw_data['option']['is_use_date_format']" [(ngModel)]="this.data.raw_data['option']['is_use_date_format']" [value]="true">
                表示フォーマットを指定する
            </label>
            <ng-container *ngIf="this.data.raw_data['option']['is_use_date_format']">
                <small>
                    <br>
                    Y=年4桁<br>
                    m=月2桁<br>
                    d=日2桁<br>
                    w=曜日<br>
                    H=時間<br>
                    i=分<br>
                    s=秒<br>
                </small>
                <input *ngIf="this.data.raw_data['type'] == 'year_month'" class="form-control" type="text" [(ngModel)]="this.data.raw_data['option']['date-format']"
                       placeholder="Y-m">
                <input *ngIf="this.data.raw_data['type'] == 'date'" class="form-control" type="text" [(ngModel)]="this.data.raw_data['option']['date-format']"
                       placeholder="Y-m-d (w)">
                <input *ngIf="this.data.raw_data['type'] == 'datetime'" class="form-control" type="text" [(ngModel)]="this.data.raw_data['option']['date-format']"
                       placeholder="Y-m-d (w) H:i:s">
            </ng-container>
        </div>
    </ng-container>

    <label class="col-md-4 form-control-label">
        値の重榎
    </label>
    <div class="col-md-8">
        <div class="checkbox">
            <label>
                <input type="hidden" [(ngModel)]="this.data.raw_data['is_unique']">
                <input type="checkbox" [checked]="this.data.raw_data['is_unique']=='true'"
                       value="true" (change)="onUniqueCheckChange($event)"> 値の重榎を禁止する
            </label>
        </div>
    </div>
</div>
<ng-container *ngIf="type=='select_other_table'">
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            対象テーブル <span class="text-danger">※</span>
        </label>
        <div class="col-md-8">
            <div *ngIf="mode=='edit';else addMode">
                <!-- 対象テーブルは編集不可 -->
                {{getTablenameFromTable(data.raw_data['option']['item-table'])}} (変更不可)
            </div>
            <ng-template #addMode>
                <ng-select
                    appendTo="body"
                    ng-required="true"
                    [items]="select_other_table_select_tables"
                    [searchWhileComposing]="false"
                    bindLabel="view_label"
                    bindValue="table"
                    [(ngModel)]="this.data.raw_data['option']['item-table']" class="form-control"
                    (change)="onSelectOtherTable(data,$event)"
                >
                </ng-select>

            </ng-template>
        </div>

        <ng-container *ngIf="this.data.raw_data['option']['item-table'] && !isDivisionOrAdminTable();">
            <label class="col-md-4 form-control-label">
                入力フォームにする（子要素機能）
            </label>
            <div class="col-md-8">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['is_child_form']"
                               (change)="onChangeChildForm()"
                               value="true"> 有効
                        <br>
                        ※有効にした場合、子要素に対する権限設定は無視されます。
                    </label>
                </div>
            </div>
        </ng-container>

    </div>
    <ng-container *ngIf="this.data.raw_data['option']['item-table']!=null && !this.data.raw_data['option']['is_child_form'];">
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                表示項目
            </label>
            <div class="col-md-8">

                <div>
                    <select [(ngModel)]="this.data.raw_data['option']['label-fields'][0]" class="form-control" (change)="setDummyFieldAndForm()">
                        <option *ngFor="let field of _share.exist_table_field_by_table[data.raw_data['option']['item-table']]"
                                value="{{field.Field}}">{{field.Comment}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                選択用表示項目<br>（空の場合は表示項目が表示されます）
            </label>
            <div class="col-md-8">

                <div>
                    <input class="form-control" type="text" [(ngModel)]="this.data.raw_data['option']['label-format-manual']"
                           placeholder="{ID}: {名前}">
                </div>
            </div>
        </div>
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                選択肢で新規追加を表示
                <br>
                <small>
                    ※有効にした場合、編集画面で選択肢を新たに追加が可能です。
                </small>
            </label>
            <div class="col-md-8">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['show_add_on_list']" [value]="true"> 有効
                    </label>
                </div>
            </div>
        </div>
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                項目のコピー（ルックアップ）
            </label>

            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-success pull-left btn-md btn-sm" (click)="addCopyField()"><i class="fa fa-plus"></i></button>
                    </div>
                    <hr style="width:90%">

                    <ng-container *ngFor="let copy_field of this.data.raw_data['option']['copy-fields'];let i=index">
                        <div class="col-md-4">
                            <select [(ngModel)]="copy_field['from']" class="form-control" (change)="copyFromSelected(copy_field)">
                                <option *ngFor="let field of copy_from_field_a"
                                        value="{{field.Field}}">{{field.Comment}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-1"><i style="font-size: 23px; margin-top: 3px;" class="fa fa-arrow-right"></i></div>
                        <div class="col-md-4">
                            <select [(ngModel)]="copy_field['to']" class="form-control" [disabled]="!copy_field['from_form']">
                                <ng-container *ngFor="let hash of can_copy_fields_by_from[copy_field['from']]">
                                    <option [ngValue]="hash['value']"
                                    >{{hash['label']}}
                                    </option>
                                </ng-container>
                            </select>


                        </div>

                        <div class="col-md-1">
                            <button class="btn btn-danger btn-sm" (click)="delCopyField(i)"><i class="fa fa-minus"></i></button>
                        </div>
                        <div class="col-md-12 pt-2">
                            <label>
                                <input type="checkbox" [(ngModel)]="copy_field['is_auto_reflect']" [value]="true"> 自動反映する
                            </label>
                        </div>

                        <hr style="width:90%">
                    </ng-container>
                </div>
                ※&nbsp;同じ項目タイプの項目にのみコピー可能です。<br>
                ※&nbsp;一度作成済みの項目のみ選択可能です。新規作成項目をコピー先として追加したい場合は、一度更新して下さい。
            </div>
        </div>

        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                他の項目で値の絞り込みを行う
            </label>

            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-success pull-left btn-md btn-sm" (click)="addDisplayConditionField()"><i class="fa fa-plus"></i></button>
                    </div>
                    <hr style="width:90%">

                    <ng-container *ngFor="let display_condition_field of this.data.raw_data['option']['display-condition-fields'];let i=index">
                        <div class="col-md-4">
                            <!--
                            <ng-select
                                [items]="display_condition_from_field_a"
                                [bindLabel]="'Comment'"
                                [bindValue]="'Field'"
                                [(ngModel)]="display_condition_field['select_other_table_field']"
                                (change)="displayFieldSelectOtherTableFieldSelected(display_condition_field)"

                            >

                            </ng-select>
                            -->
                            <select [(ngModel)]="display_condition_field['select_other_table_field']" class="form-control"
                                    (change)="displayFieldSelectOtherTableFieldSelected(display_condition_field)">
                                <option *ngFor="let field of display_condition_from_field_a"
                                        value="{{field.Field}}">{{field.Comment}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-1"><i style="font-size: 23px; margin-top: 3px;" class="fa fa-arrow-right"></i></div>
                        <div class="col-md-4">
                            <select [(ngModel)]="display_condition_field['compare_field']" class="form-control" [disabled]="!display_condition_field['select_other_table_field']">
                                <ng-container *ngFor="let hash of can_compare_form_a_by_field_name[display_condition_field['select_other_table_field']]">
                                    <option [ngValue]="hash['value']"
                                    >{{hash['label']}}
                                    </option>
                                </ng-container>
                            </select>


                        </div>

                        <div class="col-md-1">
                            <button class="btn btn-danger btn-sm" (click)="delDisplayConditionField(i)"><i class="fa fa-minus"></i></button>
                        </div>
                        <hr style="width:90%">
                    </ng-container>
                </div>
            </div>
        </div>


        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                表示条件
            </label>
            <div class="col-md-8">
                <div class="mt-3">
                    <ng-container *ngFor="let condition of data.conditions.condition_a; let cond_i = index;">
                        <div style="margin-bottom:10px">
                            <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="data.conditions.deleteCondition(cond_i);onConditionChanged()"><i class="fa fa-minus"></i></button>
                            <condition-form [table]="this.data.raw_data['option']['item-table']" [default_condition]="condition"
                                            (valueChanged)="onConditionChanged(cond_i,$event)" [index]="cond_i"></condition-form>
                        </div>
                    </ng-container>
                </div>
                <button class=" btn btn-success btn-sm" (click)="data.conditions.addCondition()"><i class="fa fa-plus"></i>条件を追加</button>
            </div>
        </div>
        <div class="form-group row admin-forms" *ngIf="type==='select_other_table'">
            <label class="col-md-4 form-control-label">
                並び順
            </label>
            <div class="col-md-8">
                <sort-params-form [sort_params]="data.sort_params" [table]="this.data.raw_data['option']['item-table']" (onChangeValue)="onChangeSortParams($event)"></sort-params-form>
            </div>
        </div>
    </ng-container>

</ng-container>
<hr>
<ng-container *ngIf="type!=='relation_table' ;else isRelationTable">
    <ng-container *ngIf="type!='fixed_html'">
        <div class="my-3">
            <button type="button" class="btn btn-sm btn-outline-info"
                    (click)="isTableSettingOptionCollapsed = !isTableSettingOptionCollapsed"
                    [attr.aria-expanded]="!isTableSettingOptionCollapsed" aria-controls="collapseExample">
                <i class="fa" [class.fa-bars]="isTableSettingOptionCollapsed"
                   [class.fa-times]="!isTableSettingOptionCollapsed"></i> 追加オプション設定
            </button>
        </div>
        <div id="collapseExample" class="animated fadeIn" [ngbCollapse]="isTableSettingOptionCollapsed">
            <div class="form-group row admin-forms" *ngIf="['boolean','calc'].indexOf(type)===-1 && !this.data.raw_data['option']['is_child_form']">
                <label class="col-md-4 form-control-label">
                    必須設定
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['required']"
                                   value="true"> 必須項目にする
                        </label>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="isAdd();else isUpdate;">
                <div class="col-md-4">
                </div>
                <div class="col-md-8">
                    必須条件・表示条件は一度保存後に設定可能です。
                </div>
            </ng-container>
            <ng-template #isUpdate>
                <div class="form-group row admin-forms" *ngIf="this.data.raw_data['option']['required']">
                    <label class="col-md-4 form-control-label">
                        必須条件設定
                    </label>
                    <div class="col-md-8">
                        <conditions-form [table]="getTableName()" [default_condition_json]="this.data.raw_data['required_condition_json']"
                                         (valueChanged)="onChangeConditionJson($event,'required_condition_json')" [ignore_type_a]="['image','file']"></conditions-form>
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        表示条件設定
                    </label>
                    <div class="col-md-8">
                        <conditions-form [table]="getTableName()" [default_condition_json]="this.data.raw_data['show_condition_json']"
                                         (valueChanged)="onChangeConditionJson($event,'show_condition_json')" [ignore_type_a]="['image','file']"></conditions-form>
                    </div>
                </div>

            </ng-template>


            <!--
            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    検索必須設定
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['search_required']"
                                   (click)="this.data.raw_data['option']['search_hide']=''"
                                   value="true"> 検索必須項目にする
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    検索非表示設定
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['search_hide']"
                                   (click)="this.data.raw_data['option']['search_required']=''"
                                   value="true"> 検索非表示項目にする
                        </label>
                    </div>
                </div>
            </div>
            -->

            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    表示設定
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label class="mr-2">
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['show-list']"
                                   value="true"> 一覧に表示
                        </label>
                        <label *ngIf="is_show_old_editable">
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['editable']"
                                   value="true"> 編集可(非推奨)
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="this.data.raw_data['option']['is_child_form']">
                <div *ngIf="this.data.raw_data['option']['show-list']" class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        一覧用表示項目
                    </label>
                    <div class="col-md-8">
                        <div>
                            <input class="form-control" type="text" [(ngModel)]="this.data.raw_data['option']['label-format-manual']"
                                   placeholder="{ID}: {名前}">
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="form-group row admin-forms" *ngIf="this.data.raw_data['option']['show-list'] && ['text','richtext','textarea','select_other_table'].indexOf(type)>=0">
                <label class="col-md-4 form-control-label">
                    一覧表示文字数
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['is_show_all_text']"
                                   value="true"> 全て表示<br>
                        </label>
                        <div class="row" *ngIf="!this.data.raw_data['option']['is_show_all_text']">
                            <div class="col-md-4">
                                表示文字数（空の場合は自動設定）
                            </div>
                            <div class="col-md-8">
                                <input OnlyNumber min="1" class="form-control" [(ngModel)]="this.data.raw_data['option']['list_text_length']" [object]="data.raw_data" [key]="'list_text_length'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    一覧の表示幅(px)
                    <div><small class="text-gray-dark">指定しない場合は自動で設定されます(最低10px)</small></div>
                </label>
                <div class="col-md-8">
                    <input OnlyNumber [min]="10" [max]="1000" class="form-control" [(ngModel)]="this.data.raw_data['option']['list_th_width']" [object]="data.raw_data['option']"
                           [key]="'list_th_width'">
                </div>
            </div>
            <ng-container *ngFor="let list_detail of ['list','detail']">
                <div class="form-group row admin-forms" *ngIf="!this.data.raw_data['option']['is_child_form'] || list_detail=='list'">
                    <label class="col-md-4 form-control-label">
                        <span *ngIf="list_detail==='list';else isDetail;">
                            一覧画面スタイル指定
                        </span>
                        <ng-template #isDetail>
                            詳細画面スタイル指定
                        </ng-template>
                    </label>
                    <div class="col-md-8">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['set_'+list_detail+'_style']"
                                       [value]="true" (change)="changeSetStyle(list_detail)"> スタイルを指定<br>
                            </label>
                            <div class="row mt-3" *ngIf="this.data.raw_data['option']['set_'+list_detail+'_style']">
                                <div class="col-md-4">
                                    文字サイズ
                                </div>
                                <div class="col-md-8 mb-2">
                                    <select class="form-control" [(ngModel)]="this.data.raw_data['option'][list_detail+'_style']['fontSize.px']">
                                        <ng-container *ngFor="let size of _share.getFontSizeList()">
                                            <option [value]="size">{{size}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    フォント
                                </div>
                                <div class="col-md-8 mb-2">
                                    <select class="form-control" [(ngModel)]="this.data.raw_data['option'][list_detail+'_style']['fontWeight']">
                                        <ng-container *ngFor="let size of [{key:'normal',value:'通常'},{key:'bold',value:'太字'}]">
                                            <option [value]="size.key">{{size.value}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    文字色
                                </div>
                                <div class="col-md-8 mb-2">
                                    <input type="color" [(ngModel)]="this.data.raw_data['option'][list_detail+'_style']['color']" name="font_color">
                                </div>
                                <div class="col-md-4">
                                    位置
                                </div>
                                <div class="col-md-8">
                                    <select class="form-control" [(ngModel)]="this.data.raw_data['option'][list_detail+'_style']['text-align']">
                                        <option value="left">左寄せ</option>
                                        <option value="center">中央</option>
                                        <option value="right">右寄せ</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    説明用テキスト
                    <div><small class="text-gray-dark">項目名の下に表示されるテキスト</small></div>
                </label>
                <div class="col-md-8">
                    <input type="text" class="form-control" [(ngModel)]="this.data.raw_data['option']['sub-label']">
                </div>
            </div>
            <div class="form-group row admin-forms">
                <label class="col-md-4 form-control-label">
                    ヘルプテキスト
                    <div><small class="text-gray-dark">ツールチップにで表示されるテキスト</small></div>
                </label>
                <div class="col-md-8">
                    <input type="text" class="form-control" [(ngModel)]="this.data.raw_data['option']['help']">
                </div>
            </div>
            <ng-container *ngIf="['image','file','boolean','calc','auto-id'].indexOf(type)==-1">

                <div class="form-group row admin-forms" *ngIf="!this.data.raw_data['option']['is_multi_value_mode'] && type != 'checkbox' && data.raw_data['is_unique']!='true'">
                    <label class="col-md-4 form-control-label">
                        固定値&nbsp;
                        <button class="btn btn-xs btn-default mr-2" *ngIf="type=='radio'"
                                style="padding-left:7px;padding-right:7px;border-radius:30px;"
                                (click)="clearValue()"><i class="fa fa-trash"></i></button>
                        <div><small class="text-gray-dark">決まった値を設定可能です(編集不可)</small></div>
                    </label>
                    <div class="col-md-8">
                        <admin-forms-field [default_form]="getForm('__fixed_value')" [default_field]="getField('__fixed_value')"
                                           [default_value]="data.raw_data['option']['value']" (valueChanged)="onChange($event)"></admin-forms-field>
                    </div>
                </div>
                <div class="form-group row admin-forms" *ngIf="!this.data.raw_data['option']['is_multi_value_mode'] && !this.data.raw_data['option']['use_current_as_default']">
                    <label class="col-md-4 form-control-label">
                        デフォルト値
                        <button class="btn btn-xs btn-default mr-2" *ngIf="type=='radio'"
                                style="padding-left:7px;padding-right:7px;border-radius:30px;"
                                (click)="clearValue('default')"><i class="fa fa-trash"></i></button>
                        <div><small class="text-gray-dark">固定値がある場合、無効</small></div>

                    </label>
                    <div class="col-md-8">
                        <admin-forms-field [default_form]="getForm('__default_value')" [default_field]="getField('__default_value')"
                                           [default_value]="data.raw_data['option']['default']" (valueChanged)="onChange($event)"></admin-forms-field>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="this.data.raw_data['option']['is_multi_value_mode']" class="">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        個数制限
                    </label>
                    <div class="col-md-8 row">
                        <div class=" row col-md-6">
                            <label class="col-md-6 form-control-label">
                                最小個数
                                <div><small class="text-gray-dark">空の場合無し</small></div>
                            </label>
                            <div class="col-md-6">
                                <input OnlyNumber [min]="0" class="form-control" [(ngModel)]="this.data.raw_data['option']['min_child_number']" [object]="data.raw_data" [key]="'min_child_number'">
                            </div>
                        </div>
                        <div class=" row col-md-6">
                            <label class="col-md-6 form-control-label">
                                最大個数
                                <div><small class="text-gray-dark">空の場合無制限</small></div>
                            </label>
                            <div class="col-md-6">
                                <input OnlyNumber [min]="1" [max]="1000" class="form-control" [(ngModel)]="this.data.raw_data['option']['max_child_number']" [object]="data.raw_data"
                                       [key]="'max_child_number'">
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="['text'].indexOf(type)>=0">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        最小文字数
                        <div><small class="text-gray-dark">空の場合無し</small></div>
                    </label>
                    <div class="col-md-8">
                        <input OnlyNumber [min]="0" class="form-control" [(ngModel)]="this.data.raw_data['option']['min-length']" [object]="data.raw_data" [key]="'min-length'">
                    </div>
                </div>
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        最大文字数
                        <div><small class="text-gray-dark">空の場合180</small></div>
                    </label>
                    <div class="col-md-8">
                        <input OnlyNumber [min]="1" [max]="10000" class="form-control" [(ngModel)]="this.data.raw_data['option']['max-length']" [object]="data.raw_data" [key]="'max-length'">
                    </div>
                </div>

                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        フォーマットを正規表現で指定する
                        <div><small class="text-gray-dark"></small></div>
                    </label>
                    <div class="col-md-8">
                        <input class="form-control" [(ngModel)]="this.data.raw_data['option']['format-regex']" placeholder="\d{4}-\d{2}">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="type=='select_other_table' && data.raw_data['option']['item-table']!=null &&  !this.data.raw_data['option']['is_child_form']">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        CSVアップロード用項目
                        <div>
                            <small class="text-gray-dark">
                                ※他テーブル参照のみ<br>
                                CSVアップロード時、対象テーブルのどの項目の値を用いて更新するか指定できます。
                            </small>
                        </div>
                    </label>
                    <div class="col-md-8">
                        <select [(ngModel)]="this.data.raw_data['option']['csv-fields']" class="form-control">
                            <option *ngFor="let field of _share.exist_table_field_by_table[data.raw_data['option']['item-table']]"
                                    value="{{field.Field}}">{{field.Comment}}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="type!='calc' && type!='checkbox' && type!='radio' && type!='select' && type!='boolean' && type != 'auto-id'  &&  !this.data.raw_data['option']['is_child_form']">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        複数の値の登録を許可する
                    </label>
                    <div class="col-md-8 row">
                        <div class="col-md-12">
                            <ng-container *ngIf="this.previousData.raw_data['option']['is_multi_value_mode'];else notIsmultiModeDefined">
                                有効 ※変更不可
                            </ng-container>
                            <ng-template #notIsmultiModeDefined>
                                <ng-container *ngIf="this.data.raw_data['option']['is_child_form'] || (this.data.raw_data['option']['is_multi_value_mode'] && data['id']); else NotForceMultiple;">
                                    有効 ※変更不可
                                    <input [(ngModel)]="this.data.raw_data['option']['is_multi_value_mode']" type="hidden" value="true"> 有効
                                </ng-container>
                                <ng-template #NotForceMultiple>
                                    <label>
                                        <input [(ngModel)]="this.data.raw_data['option']['is_multi_value_mode']" type="checkbox" value="true"> 有効
                                    </label>
                                    <br>
                                    <span style="color:red">
                                ※この機能は後から変更不可となります。
                            </span>
                                </ng-template>
                            </ng-template>
                        </div>
                        <div class="col-md-12 checkbox" *ngIf="this.data.raw_data['option']['is_multi_value_mode'] && type == 'select_other_table'">
                            <label>
                                <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['show_add_all_option']"
                                       value="true"> 全データ追加ボタンを表示する
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="['image','file','textarea'].indexOf(type)==-1 && ['url'].indexOf(data.raw_data['type'])==-1 ">
                <div class="form-group row admin-forms" *ngIf=" (data.raw_data['type']!='text' ||  !this.data.raw_data['option']['max-length'] || this.data.raw_data['option']['max-length'] <= 180)">
                    <label class="col-md-4 form-control-label">
                        検索高速化(インデックス）
                        <div><small class="text-gray-dark">※高速化される代わりにデータのサイズが追加で使用されます。</small></div>
                    </label>
                    <div class="col-md-8 checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['index']"
                                   value="true"> 検索高速化（インデックス）する
                        </label>
                    </div>
                </div>
                <!--
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        集計用パラメータ
                        <div><small class="text-gray-dark">キーと値を設定することで、集計に使用可能です。例：（key=カテゴリ,value=動物と設定しておくと、カテゴリ毎のデータ集計が可能です）</small></div>
                    </label>
                    <div class="col-md-8 checkbox">
                        <ng-container *ngFor="let summary_param of this.data.raw_data['option']['summary_params']; let i = index;">
                            <div style="margin-bottom:10px" class="row">
                                <div class="col-md-1">
                                    <label>
                                        <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delSummaryParam(i)"><i class="fa fa-minus"></i></button>
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    KEY:<br> <input type="text" [(ngModel)]="summary_param['key']">
                                </div>
                                <div class="col-md-5">
                                    VALUE:<br> <input type="text" [(ngModel)]="summary_param['value']">
                                </div>

                            </div>
                        </ng-container>
                        <br style="clear: both">
                        <label>
                            <button class="btn btn-success pull-left btn-md btn-sm" (click)="addSummaryParam()"><i class="fa fa-plus"></i></button>
                        </label>
                    </div>
                </div>
                -->
            </ng-container>
            <ng-container *ngIf="type==='file'">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        ブラウザで表示する（画像/PDF/動画)
                    </label>
                    <div class="col-md-8">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['show_file']"
                                       value="true"> 有効
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="form-group row admin-forms" *ngIf="type=='select_other_table'">
                <label class="col-md-4 form-control-label">
                    一覧画面にて、詳細画面へリンクしない
                </label>
                <div class="col-md-8">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['no_detail_link']"
                                   value="true"> 詳細画面へリンクしない
                        </label>
                    </div>
                </div>
            </div>

            <!--
            <ng-container *ngIf="['image','file'].indexOf(type)>=0">
                <div class="form-group row admin-forms">
                    <label class="col-md-4 form-control-label">
                        web上に公開
                    </label>
                    <div class="col-md-8 radio">
                        <label>
                            <input name="{{data['field']}}_web_public" type="radio" [(ngModel)]="this.data.raw_data['option']['public']"
                                   value="true" checked="checked">公開
                        </label>
                        <br/>
                        <label>
                            <input name="{{data['field']}}_web_public" type="radio" [(ngModel)]="this.data.raw_data['option']['public']"
                                   value="false">非公開
                        </label>
                    </div>
                </div>
            </ng-container>
            -->
        </div>
    </ng-container>
</ng-container>
<ng-template #isRelationTable>

    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            対象テーブル <span class="text-danger">※</span>
        </label>
        <div class="col-md-8">
            <div *ngIf="mode=='edit';else addMode">
                <!-- 対象テーブルは編集不可 -->
                {{getTablenameFromTable(data.raw_data['option']['item-table'])}} (変更不可)
            </div>
            <ng-template #addMode>
                <select [(ngModel)]="this.data.raw_data['option']['item-table']" class="form-control"
                        (change)="
                        onSelectRelationTable(data,$event)">
                    <ng-container *ngFor="let tableinfo of _share.exist_table_a">
                        <option *ngIf="'dataset__'+parent.data.raw_data['id'] != tableinfo.table"
                                value="{{tableinfo.table}}">{{tableinfo.getLabel()}}
                        </option>
                    </ng-container>
                </select>
            </ng-template>
        </div>


        <label class="col-md-4 form-control-label">
            オプション
        </label>
        <div class="col-md-8">
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="this.data.raw_data['option']['view_mode']" [value]="true"> 縦に表示する
                </label>
            </div>
        </div>
    </div>


    <div *ngIf="!!this.data.raw_data['option']['item-table']">
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                表示する条件
                <i class="fa fa-question-circle ml-1" aria-hidden="true"
                   ngbTooltip="特定の項目の値によって表示するレコードの条件を設定可能です。(項目を選択するには、一度保存する必要があります)"></i>
                <button class="btn-success pull-right btn-md" (click)="addRelationField()"><i class="fa fa-plus"></i></button>
            </label>

            <div class="col-md-8">
                <div class="row">

                    <ng-container *ngFor="let relation_field of this.data.raw_data['option']['relation-fields'];let i=index">

                        <div class="col-md-2">
                            <select class="form-control" [(ngModel)]="relation_field['and_or']" *ngIf="i>0">
                                <option value="and">AND</option>
                                <option value="or">OR</option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <select [(ngModel)]="relation_field['to']" (change)="relationToSelected(relation_field)" class="form-control">
                                <option value="">このテーブルの項目</option>
                                <ng-container *ngFor="let field of relation_fields">
                                    <option [ngValue]="field.Field" *ngIf="!isTmpField(field)">{{field.Comment}}
                                    </option>
                                </ng-container>
                            </select>


                        </div>

                        <div class="col-md-1">＝</div>

                        <div class="col-md-4">
                            <select [(ngModel)]="relation_field['from']" class="form-control" [disabled]="relation_field['to']==''">
                                <option value="">対象テーブルの項目</option>
                                <ng-container *ngFor="let form of getSelectedRelationTableForms()">
                                    <option *ngIf="isSameTypeForRelationTable(form,relation_field) " value="{{form.field['Field']}}">{{form.label}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-danger btn-sm" (click)="delRelationField(i)"><i class="fa fa-minus"></i></button>
                        </div>

                        <hr style="width:90%">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                データ絞り込み条件
                <button class="btn-success pull-right btn-md" (click)="addRelationTableCondition()"><i class="fa fa-plus"></i></button>
            </label>

            <div class="col-md-8" *ngIf="data.conditions">
                <div cdkDropList (cdkDropListDropped)="dropRelationTableCondition($event)">
                    <ng-container *ngFor="let condition of data.conditions.condition_a; let cond_i = index;">
                        <div cdkDrag style="margin-bottom:10px" class="row">
                            <div class="col-md-11">
                                <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delRelationTableCondition(cond_i)"><i class="fa fa-minus"></i></button>
                                <condition-form [table]="data.raw_data['option']['item-table']" [default_condition]="condition" (valueChanged)="onRelationTableConditionChanged(cond_i,$event)"
                                                [index]="cond_i"></condition-form>
                            </div>
                            <div class="dragger col-md-1" style="" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>

        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                表示する項目
                <button class="btn-success  btn-md pull-right" (click)="addRelationTableViewField()"><i class="fa fa-plus"></i></button>
            </label>

            <div class="col-md-8">
                <div class="row">

                    <ng-container *ngFor="let show_field of this.data.raw_data['option']['relation_table_show_fields'];let i=index">
                        <div class="col-md-8">
                            <select [(ngModel)]="show_field['field']" class="form-control">
                                <ng-container *ngFor="let form of getSelectedRelationTableForms()">
                                    <option [ngValue]="form.field['Field']">{{form.field['Comment']}}
                                    </option>
                                </ng-container>
                            </select>


                        </div>

                        <div class="col-md-4">
                            <button class="btn btn-danger btn-sm" (click)="delRelationTableViewField(i)"><i class="fa fa-minus"></i></button>
                        </div>

                        <hr style="width:90%">
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="form-group row admin-forms">
            <label class="col-md-4 form-control-label">
                ソート
                <button class="btn-success  btn-md pull-right" (click)="addOptionValue('sort_params')"><i class="fa fa-plus"></i></button>
            </label>

            <div class="col-md-8">
                <div class="row">

                    <ng-container *ngFor="let sort_param of this.data.raw_data['option']['sort_params'];let i=index">
                        <div class="col-md-6">
                            <select [(ngModel)]="sort_param['field']" class="form-control">
                                <ng-container *ngFor="let form of getSelectedRelationTableForms()">
                                    <option [ngValue]="form.field['Field']">{{form.field['Comment']}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <select [(ngModel)]="sort_param['asc_desc']" class="form-control">
                                <option value="asc">昇順</option>
                                <option value="desc">降順</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <button class="btn btn-danger btn-sm" (click)="delOptionValue('sort_params',i)"><i class="fa fa-minus"></i></button>
                        </div>

                        <hr style="width:90%">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <!--
    <div class="form-group row admin-forms">
        <label class="col-md-4 form-control-label">
            １ページあたり表示データ数(1~100)
        </label>

        <div class="col-md-8">
            <input OnlyNumber class="form-control" max="100" min="1" [(ngModel)]="data.raw_data['option']['relation_table_show_data_num']">
        </div>
    </div>
    -->

</ng-template>
