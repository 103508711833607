<div>
    <div class="row">
        <div class="col-md-6">
            テーブル:
            <ng-select
                [items]="table_info_a"
                [bindLabel]="'view_label'"
                [bindValue]="'table'"
                [(ngModel)]="table_name"
                (change)="onSelectTable()"
                class=" custom form-control"
                appendTo="body"
            >
            </ng-select>
        </div>


        <ng-container *ngIf="selected_table_info">
            <div class="col-md-6" *ngIf="is_select_field">
                項目:
                <ng-select
                    [items]="selected_table_info.fields"
                    [bindLabel]="'Comment'"
                    [bindValue]="'Field'"
                    [(ngModel)]="field_name"
                    appendTo="body"
                    class="custom form-control"
                    (change)="change()"
                >
                </ng-select>
            </div>


            <!--
            フィルタ:
            <select class="form-control" [(ngModel)]="condition.inc_filter_id" (change)="onChange()">
                <option value="">フィルタ選択(任意)</option>
                <option *ngFor="let _filter of selected_inc_table.saved_filters"
                        value="{{_filter['id']}}">{{_filter['name']}}
                </option>
            </select>
            -->


            <div class="mt-1 col-md-12">
                <conditions-form [table]="selected_table_info.table" [target_form_a]="condition_target_form_a" [output_json]="false" (valueChanged)="conditionChanged($event)"
                                 [default_condition_json]="conditions.getSearchParamJson()"></conditions-form>
            </div>
        </ng-container>
    </div>

</div>
