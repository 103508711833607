import {Base} from './Base';
import {Data} from './Data';
import {TableInfo} from './TableInfo';
import {OptionItem} from './OptionItem';
import {Conditions} from './Conditions';

export class SelectOptionItemsFilter extends Base {

    private where_a: Array<SelectOptionItemsFilterWhere> = [];

    private has_where_once_field_a: Array<string> = []

    //ex. target_field = admin_field,target_field_select_other_field = division_ids, condition_field = division_field
    public add(target_field: string, target_field_select_other_field: string, condition_field: string, value: string) {
        let filterWhere = new SelectOptionItemsFilterWhere(target_field, target_field_select_other_field, condition_field, value);
        this.where_a.push(filterWhere)

        //resetされてもここを保持しないと、resetで条件がなくなった場合にreloadされないため。
        this.has_where_once_field_a.push(target_field)
    }

    public reset(target_field: string, condition_field_name: string) {
        this.where_a = this.where_a.filter(where => {
            return !(where.target_field_name === target_field && where.condition_field_name === condition_field_name)
        })

    }

    public toArray(): Array<any> {
        return this.where_a.map(where => {
            return where.toArray()
        })
    }

    public hasWhere(field_name: string): boolean {
        if (this.has_where_once_field_a.indexOf(field_name) >= 0) {
            return true;
        }
        return this.where_a.filter(where => {
            return where.target_field_name === field_name;
        }).length > 0
    }

    public getConditions(target_field: string, table_info: TableInfo): Conditions {
        let conditions = new Conditions();
        this.where_a.filter(where => {
            return where.target_field_name === target_field;
        }).map(where => {
            let condition_cond = 'eq';
            console.log(table_info.forms.byFieldName(where.condition_field_name).original_type)
            console.log(table_info.forms.byFieldName(where.condition_field_name).type)

            if (!table_info.forms.byFieldName(where.condition_field_name).isSelectOtherTable()) {
                condition_cond = 'inc'
            }
            conditions.addCondition(condition_cond, where.target_field_select_other_field, where.value)
            return where;
        })
        return conditions

    }

    public inputCompleteFilterCompareValue(target_field: string) {
        let flg = true

        this.where_a.forEach(where => {
            if (where.target_field_name === target_field && where.value == null) {
                flg = false
            }
        })
        return flg
    }

    public getUninputtedFields(target_field: string) {
        let field_name_a = []
        this.where_a.forEach(where => {
            if (where.target_field_name === target_field && where.value == null) {
                field_name_a.push(where.condition_field_name)
            }
        })
        return field_name_a

    }
}


class SelectOptionItemsFilterWhere {
    private _target_field_name: string = null;
    private _condition_field_name: string = null;
    private _target_field_select_other_field: string = null;
    private _value: string = null;

    public constructor(_target_field_name: string, _target_field_select_other_field: string, _condition_field_name: string, _value: string) {
        this._target_field_name = _target_field_name;
        this._condition_field_name = _condition_field_name
        this._target_field_select_other_field = _target_field_select_other_field
        this._value = _value;
    }


    get target_field_name(): string {
        return this._target_field_name;
    }

    get condition_field_name(): string {
        return this._condition_field_name;
    }


    get target_field_select_other_field(): string {
        return this._target_field_select_other_field;
    }

    get value(): string {
        return this._value;
    }

    public toArray() {
        return {
            'target_field_name': this.target_field_name,
            'condition_field_name': this.condition_field_name,
            'target_field_select_other_field': this.target_field_select_other_field,
            'value': this.value,
        }
    }
}

