<div cdkDropListGroup

>
    <div
        *ngFor="let y_field of fields; let row_index = index"
        cdkDropList
        [cdkDropListData]="y_field"
        cdkDropListOrientation="horizontal"
        [ngClass]="{order_list:y_field.length!=0 ,empty_list:y_field.length==0 &&  placeholder_visible}"
        (cdkDropListDropped)="drop($event,y_field,row_index)"

    >
        <div *ngIf="y_field.length==0 && placeholder_visible" style="text-align: center; "></div>
        <ng-container
            *ngFor="let field of y_field; let field_index = index"

        >
            <div
                *ngIf="(isShowField(field)) && isShowFieldDefinitionForm(field);"
                [ngStyle]="{'cursor':is_custom_table_definition && 'move' }"
                class='order_box pc-field-block'
                [ngClass]="this.forms.byFieldName(field.Field).getCustomJaClassName()"
                cdkDrag
                [cdkDragData]='field'
                [cdkDragDisabled]="table_info.table != 'dataset' || isChildForm() || is_custom_table_definition==false || fields.length==1 && y_field.length==1"
                (cdkDragStarted)="placeholder_visible=true"
            >

                <ng-container *ngIf="(isShowField(field)) && isShowFieldDefinitionForm(field);then showForm else notShowForm"></ng-container>
                <ng-template *ngIf="true" #notShowForm>
                    <div>=======Field:{{field.Field}}=======</div>
                    <div>isShowField<span *ngIf="isShowField(field)">:true</span></div>
                    <div>isShowFieldDefinitionForm<span *ngIf="isShowFieldDefinitionForm(field)">:true</span></div>
                </ng-template>
                <ng-template #showForm>
                    <div [ngClass]="{'card ':isQuestionnaireStyle()}" style="width:100%">

                        <div [ngClass]="{'card-body p-3':isQuestionnaireStyle()}">
                            <div class="form-group row admin-forms"
                                 *ngIf="shouldShowForm(this.forms.byFieldName(field.Field))">

                                <ng-container *ngIf="this.forms.byFieldName(field.Field).type=='fixed_html';else NotFixedHtml">
                                    <div class="fixed_html_wrapper">
                                        <div [innerHTML]="this.forms.byFieldName(field.Field).fixed_html | safeHtml"></div>
                                    </div>
                                </ng-container>
                                <ng-template #NotFixedHtml>
                                    <!-- 項目名  -->
                                    <div class=" form-control-label col-md-12 pc-field-label">

                                        <ng-container
                                            *ngIf="!isAutoFillField(field) && this.forms.byFieldName(field.Field).is_multi_value_mode;else NOT_MULTI">
                                            <label>
                                                {{this.forms.byFieldName(field.Field).label}}
                                            </label>
                                            <button [disabled]="!canAddChildData(field.Field)" class="btn-success btn  btn-sm ml-2"
                                                    (click)="addChildData(getChildByField(field.Field),true,-1,$event)"><i class="fa fa-plus"></i></button>
                                            <br>
                                            <button *ngIf="getChildByField(field.Field) && table_info.forms.byFieldName(field['Field']).show_add_all_option"
                                                    class="btn-success pull-right btn btn-sm"
                                                    (click)="addAllFormData(getChildByField(field.Field),field.Field)">全データ追加
                                            </button>
                                        </ng-container>
                                        <ng-template #NOT_MULTI>
                                            <label>
                                                {{this.forms.byFieldName(field.Field).label}}
                                                <ng-template appDebugMode="true" [add_new_mode]="mode=='add'">
                                                    <span>( {{field.Field}} )</span>
                                                </ng-template>
                                                <i *ngIf="!!field.help_tooltip_text" class="fa fa-question-circle"
                                                   aria-hidden="true" ngbTooltip="{{field.help_tooltip_text}}" container="body"></i>
                                                <span
                                                    *ngIf="this.forms.byFieldName(field.Field).isRequired()"
                                                    class="text-danger"><i class="fa fa-asterisk"></i></span>
                                                <div
                                                    *ngIf="this.forms.byFieldName(field.Field).type=='image' && this.forms.byFieldName(field.Field).option != null && this.forms.byFieldName(field.Field).option.width!=null">
                                                    <small class="text-gray-dark">推奨サイズ:
                                                        {{this.forms.byFieldName(field.Field).option.width}}px
                                                        × {{this.forms.byFieldName(field.Field).option.height}}px
                                                    </small>
                                                </div>
                                                <div
                                                    *ngIf="this.forms.byFieldName(field.Field).type=='file' && this.forms.byFieldName(field.Field).accept">
                                                    <small class="text-gray-dark">対応ファイル:
                                                        {{this.forms.byFieldName(field.Field).accept}}</small>
                                                </div>
                                                <div *ngIf="this.forms.byFieldName(field.Field).sublabel">
                                                    <small
                                                        class="text-gray-dark">{{this.forms.byFieldName(field.Field).sublabel}}</small>
                                                </div>
                                            </label>
                                        </ng-template>


                                    </div>
                                    <hr style="    width: 93%;" *ngIf="isQuestionnaireStyle()">

                                    <!-- フォーム -->

                                    <div class="col-md-12">
                                        <ng-container *ngIf="!isEditableField(field);else isEditable;">
                                            <ng-container *ngIf="field.fixed_value">
                                                {{field.fixed_value}}
                                            </ng-container>

                                            <ng-container *ngIf="!table_info.grant.isEditableField(field['Field']);">
                                            </ng-container>
                                            <span *ngIf="mode ==='add'">
                                                (編集不可)
                                            </span>
                                            <span *ngIf="mode ==='edit'">
                                                <dynamic-data-view [table_info]="table_info" [data]="data" [field_name]="field['Field']"
                                                                   [dataType]="forms.byFieldName(field['Field']).type"></dynamic-data-view>
                                            </span>
                                        </ng-container>
                                        <ng-template #isEditable>
                                            <!-- NOT MULTIPLE -->
                                            <ng-container *ngIf="isAutoFillField(field); else NotAuto;">
                                                (自動挿入)
                                            </ng-container>
                                            <ng-template #NotAuto>
                                                <div class="row">
                                                    <div class="col-md-12 form-field-container fieldname_{{field.Field}} pc-field-value"
                                                         [ngClass]="this.forms.byFieldName(field.Field).getCustomClassName()"
                                                         style="margin-bottom:5px;"
                                                         *ngIf="!this.forms.byFieldName(field.Field).is_multi_value_mode">
                                                        <admin-forms-field [data_changed]="data.last_dirty_changed" [table_info]="table_info" [data]="data"
                                                                           [is_add]="mode=='add'"
                                                                           [default_form]="this.forms.byFieldName(field.Field)"
                                                                           [default_field]="field"
                                                                           [image_thumbnail_url]="(forms.byFieldName(field.Field).type=='image' && view_data && view_data[field.Field]) ?view_data[field.Field]:null"
                                                                           [editComponent]="getEditComponent()" [field_name]="field.Field"
                                                                           [grant_menu_a]="grant_menu_a"
                                                                           [auto_complete_values]="auto_complete_values"
                                                                           (valueChanged)="onValueChanged($event)" [last_updated]="last_updated"
                                                                           [is_add_new_button_select_other_table]="is_add_new_button_select_other_table"
                                                                           [selectOptionitemsFilter]="selectOptionItemsFilter"
                                                        ></admin-forms-field>
                                                        <!-- エラー表示 -->
                                                        <div *ngIf="error_a[field.Field] != undefined" class="text-danger">
                                                            {{error_a[field.Field]}}
                                                        </div>


                                                    </div>
                                                </div>

                                                <!-- MULTIPLE -->
                                                <ng-container *ngIf="this.forms.byFieldName(field.Field).is_multi_value_mode">
                                                    <div class="row multiple-form-field-container"
                                                         *ngFor="let child_data of child_data_a_by_fieldname[field.Field]; index as i">
                                                        <div class="col-lg-11 row " style="margin-bottom:5px;"
                                                             [ngClass]="{'multiple-box':['image','file'].indexOf(this.forms.byFieldName(field.Field).type)>=0}">
                                                            <div class="col-md-11  pc-field-value" [ngClass]="this.forms.byFieldName(field.Field).getCustomClassName()">
                                                                <admin-forms-field [data_changed]="data.last_dirty_changed" [table_info]="table_info" [data]="child_data"
                                                                                   [is_add]="mode=='add'"
                                                                                   [default_form]="this.forms.byFieldName(field.Field)"
                                                                                   [default_field]="field"
                                                                                   [image_thumbnail_url]="(view_data && view_data[field.Field]) ?view_data[field.Field][i]:null"
                                                                                   [editComponent]="getEditComponent()" [field_name]="field.Field"
                                                                                   [grant_menu_a]="grant_menu_a"
                                                                                   [auto_complete_values]="auto_complete_values"
                                                                                   (valueChanged)="onValueChanged($event)"
                                                                                   [child_table]="getChildTablenameByFieldname(field.Field)"
                                                                                   [data_index]="i"
                                                                                   [parent_data]="data" [last_updated]="last_updated"
                                                                                   [is_add_new_button_select_other_table]="is_add_new_button_select_other_table"
                                                                                   [disable_edit]="['image','file'].indexOf(this.forms.byFieldName(field.Field).type)>=0 && !!child_data.raw_data['id']"
                                                                                   (addchildvalue)="addChildDatafromformsfield($event)"
                                                                >
                                                                </admin-forms-field>
                                                                <!-- エラー表示 -->
                                                                <div
                                                                    *ngIf="error_a['_child_a'] != undefined && error_a['_child_a'][getChildTablenameByFieldname(field.Field)] && error_a['_child_a'][getChildTablenameByFieldname(field.Field)][i] &&  error_a['_child_a'][getChildTablenameByFieldname(field.Field)][i]['value'] != undefined"
                                                                    class="text-danger">
                                                                    {{error_a['_child_a'][getChildTablenameByFieldname(field.Field)][i]['value']}}
                                                                </div>

                                                            </div>
                                                            <div class="col-md-1 buttons">
                                                                <button
                                                                    *ngIf="this.forms.byFieldName(field.Field).is_multi_value_mode"
                                                                    class="btn-danger pull-right btn btn-sm"
                                                                    (click)="deleteChildData(getChildByField(field.Field),i)"><i
                                                                    class="fa fa-minus"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </ng-template>

                                    </div>

                                </ng-template>

                                <!-- テーブル定義用マウスオーバーブロック -->
                                <div *ngIf="is_custom_table_definition && !is_custom_table_definition_popup && !isChildForm()"
                                     class="overSetting" (click)="clickOverArea($event,field)">
                                    <div class="back"></div>
                                    <div class="border"></div>
                                    <div class="icon-container">
                                        <a target="_blank"><i class="fa fa-trash red" aria-hidden="true"
                                                              (click)="openTableDetectionDeleteModal(field)"></i></a>

                                        <a target="_blank"><i class="fa fa-gear" aria-hidden="true"
                                                              (click)="openSettingModal(field)"></i></a>
                                        <div class="dragger ml-1" cdkDragHandle>
                                            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>

    </div>

</div>

<div bsModal #deleteFieldModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                <button type="button" class="close" (click)="deleteFieldModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>本当に削除してもよろしいですか？</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="deleteFieldModal.hide()">キャンセル</button>
                <button type="button" class="btn btn-danger" (click)="deleteField()">削除する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<div bsModal #selectOtherEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" [config]="{backdrop: 'static',  keyboard: false}" aria-hidden="true">
    <div *ngIf="selectOtherEditModal.isShown" class="modal-dialog modal-default" role="document">
        <div class="modal-content modal-primary">
            <div class="modal-header">
                <h4 class="modal-title">データ追加</h4>
                <button type="button" class="close" (click)="selectOtherEditModal.hide()"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="table_info">
                <edit-component [_id]="-1" [_table]="select_other_edit_modal_form.item_table" [IS_EMBED_MODE]="true" [submit_text]="'追加'"
                                (onSubmit)="onSubmitSelectOtherNewData($event)" (onCancel)="onCancelSelectOtherNewData()"></edit-component>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
