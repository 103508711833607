import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class ToastrServiceWrapperService {
  protected toasterService: ToastrService;

  constructor(toasterService: ToastrService) {
    this.toasterService = toasterService;

  }

  errorWarningConfig = { disableTimeOut: true, enableHtml: true};

  successConfig = {
    tapToDismiss: true,
    timeOut: 1000,
    newestOnTop: false
  };


    show(message?: string, title?: string) {
        this.toasterService.show(message, title);
    }

    success(message?: string, title?: string) {
        this.toasterService.success(message, title, this.successConfig);
    }

    warning(message?: string, title?: string, option = {}) {
        option = {...this.errorWarningConfig, ...option}
        console.log(option)
        this.toasterService.warning(message, title, option)
    }

    error(message?: string, title?: string) {
        message = message.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
        this.toasterService.error(message, title, this.errorWarningConfig)
    }

    info(message?: string, title?: string) {
        this.toasterService.info(message, title);
    }

    clear(){
    this.toasterService.clear();
  }
}
