import { interval } from 'rxjs';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import 'chartjs-plugin-colorschemes';
import 'chartjs-plugin-stacked100';
import {Chart} from 'chart.js';
import {Connect} from '../services/connect';
import {SharedService} from '../services/shared';
import {CustomFilter} from '../class/Filter/CustomFilter';
import {Router} from '@angular/router';

@Component({
    selector: 'cloud-charts',
    templateUrl: './cloud-charts.component.html',
})

export class CloudChartsComponent implements OnChanges, AfterViewInit {
    @Input() customFilter: CustomFilter;
    @Input() chart_index;
    @Input() parent;
    @Input() type; // table or chart
    @Input() data_hash: Object = null;
    @Input() add_link: boolean = false; // table or chart

    private chart: Object = {};

    public page = 0;
    public is_date = false;
    public chart_labels: Array<any>;

    public date_str = '';


    @ViewChild('myChart')
    private myChart: ElementRef;
    public chartJsObj: any = null;

    public display_text: string = '';

    public title: string = null;

    private externalY: number=0;
    private externalY2: number=0;

    constructor(private _connect: Connect, private _share: SharedService, private changeDetectionRef: ChangeDetectorRef, private _router: Router) {
        this.chartJsObj = null;

    }

    ngAfterViewInit(): void {
        this.page = 0;
        if (this.customFilter) {
            this.loadChartData()
            this._share.getTableInfo(this.customFilter.table).subscribe(_table_info => {
                this.title = '【' + _table_info.getLabel() + '】 ' + (this.customFilter.name ?? 'NO NAME')
            })
        }
        this.changeDetectionRef.detectChanges();
    }

    ngOnInit() {
        this.page = 0;
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customFilter) {
            this.loadChartData()

        }

    }


    loadChartData() {
        if (!this.customFilter.summarizeFilter.summary_a || this.customFilter.summarizeFilter.fields.length == 0) {
            return;
        }
        this.data_hash = null
        this.is_date = this.customFilter.summarizeFilter.fields[0].is_date;
        if (this.customFilter.summarizeFilter.type == 'horizontalBar') {
            this.is_date = false;
        }
        // validate
        const validated = this.customFilter.summarizeFilter.type !== '';

        if (!validated) {
            return false;
        }

        this.chart = {
            'type': this.customFilter.summarizeFilter.getGraphType(),
            'title': this.customFilter.name,
        }
        let color = this._share.chartColorSchemas[0];
        if (this.customFilter.summarizeFilter.color) {
            color = this.customFilter.summarizeFilter.color;

        }
        this.chart['options'] = {
            plugins: {
                colorschemes: {
                    scheme: color,
                },
                stacked100: {enable: this.customFilter.summarizeFilter.options.stacked_100per},
            },
        }
        if (this.is_date) {
            const unit = this.customFilter.summarizeFilter.fields[0].term;
            this.chart['options']['scales'] = {
                xAxes: [{
                    stacked: this.customFilter.summarizeFilter.isStacked(),
                    type: 'time',
                    time:
                        {
                            unit: unit,
                            displayFormats: {
                                year: 'YYYY年',
                                month: 'M月',
                                day: 'M月D日',
                            },
                        },
                    offset: true

                }],
                yAxes: [
                    {
                        id: 'A',
                        stacked: this.customFilter.summarizeFilter.isStacked(),
                        ticks: {}
                    }
                ]
            };

        } else {
            this.chart['options']['scales'] = {
                xAxes: [
                    {stacked: this.customFilter.summarizeFilter.isStacked()}
                ],
                yAxes: [
                    {
                        stacked: this.customFilter.summarizeFilter.isStacked(),
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label, index, labels) {
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            }
                        }
                    }
                ]

            }
            if (this.chart['type'] === 'scatter') {
                //X,Yのラベルを付ける
                this._share.getTableInfo(this.customFilter.table).subscribe(_table_info => {
                    let field_x = this.customFilter.summarizeFilter.fields[0].field;
                    let field_y = this.customFilter.summarizeFilter.fields[1].field;

                    this.chart['options']['scales']['xAxes'][0]['scaleLabel'] = {labelString: _table_info.forms.byFieldName(field_x).label, display: true};
                    this.chart['options']['scales']['yAxes'][0]['scaleLabel'] = {labelString: _table_info.forms.byFieldName(field_y).label, display: true};
                })

            }
        }
        this.customFilter.getStepSize(this._share).subscribe(stepSize => {
            if (stepSize) {
                this.chart['options']['scales']['yAxes'][0]['ticks']['stepSize'] = stepSize;
            }
            if (this.customFilter.summarizeFilter.type == 'horizontalBar') {
                //横棒チャートの場合
                //convert X and Y
                let _tmp = this.chart['options']['scales']['xAxes'];
                this.chart['options']['scales']['xAxes'] = this.chart['options']['scales']['yAxes'];
                this.chart['options']['scales']['yAxes'] = _tmp;
            }
            if (this.chartJsObj) {
                this.chartJsObj.destroy();
                this.chartJsObj = null;
            }
            if (this.data_hash) {
                this.setChartData(this.data_hash)
            } else {
                const params = this.customFilter.getFilterParam();
                params['page'] = this.page;
                this._connect.post('/admin/chart-data', params, {}, false).subscribe((result) => {
                    if (result['data'] === null || result['data'] === undefined) {
                        return;
                    }
                    this.setChartData(result['data'])
                }, (error) => {
                    this.display_text = 'NO TABLE'
                    console.log('chart error')
                });
            }
        })


    }

    setChartData(data: Object) {
        this.chart['data'] = data['data'];
        this.externalY = 0;
        this.externalY2 = 0;
        if (this.is_date) {
            let arrayLeftY=[];
            let leftY = this.chart['data']['datasets'][0]['data'];
            for(let x=0; x<leftY.length; x++)
               arrayLeftY[x]=leftY[x]['y'];
            this.externalY2=Math.max(...arrayLeftY);
          
            if (this.chart['data']['datasets'].length>1){
               var tempData = {};
               var yAxis = {yAxisID: 'A'}
               tempData = { ...this.chart['data']['datasets'][0], ...yAxis };
               this.chart['data']['datasets'][0] = tempData;
 
               var tempDataSecond = {};
               var yAxis2 = {yAxisID: 'B'}
               tempDataSecond = { ...this.chart['data']['datasets'][1], ...yAxis2 };
               this.chart['data']['datasets'][1] = tempDataSecond;
 
               let arrayRightY=[];
               let rightY = this.chart['data']['datasets'][1]['data'];
               for(let x=0; x<rightY.length; x++)
                   arrayRightY[x]=rightY[x]['y'];
               this.externalY=Math.max(...arrayRightY); 
            }
            this.chart['data']['datasets'].forEach(dataset => {
                if (dataset['data'] !== null) {
                    dataset['data'].map((data) => {
                        if (data['x'] !== undefined && (typeof data['x'] === 'string' || data['x'] instanceof String)) {
                            // datetimeの場合
                            data['x'] = new Date(data['x'].replace(/-/g, '/'));
                            return data
                        }
                    });
                }
            });
        }
        this.date_str = data['date_str'];
        this.reloadChart()
    }

    reloadChart() {
        console.time('reload_chart');


        if (this.chartJsObj) {
            this.chartJsObj.destroy();
            this.chartJsObj = null;
        }
        if (this.myChart === undefined) {
            console.log('myChart not found')
        }
        const canvas = this.myChart.nativeElement;
        const ctx = canvas.getContext('2d');

        if(this.externalY!=0 && this.externalY2!=0){
            this.chart['options']['scales'].yAxes.push(
                {
                    id: 'B',
                    position: 'right',
                    stacked: this.customFilter.summarizeFilter.isStacked(),
                    ticks: {
                        valueFormatString:this.externalY*1.1,
                        interval: 1,
                        max: Math.round(this.externalY * 1.1)
                    },
                }
            )
            this.chart['options']['scales'].yAxes[0]['ticks']['max']= Math.round(this.externalY2 * 1.1);
            this.chart['options']['scales'].yAxes[1]['ticks']['max']= Math.round(this.externalY * 1.1);
           
        }

        // datetime
        //const chart_data = {...this.chart}

        /*
        chart_data['data']['datasets'][0]['data'].map((data)=>{data['x'] = new Date(data['x']);return data});
        console.log(this.chart)
         */


        console.log(this.chart)
        this.chartJsObj = new Chart(ctx, this.chart)

        console.timeEnd('reload_chart');
    }

    changePage(page) {
        this.page = page;
        this.loadChartData();
    }

    isShowPaging() {
        if (!this.customFilter) {
            return false;
        }
        if (this.customFilter.summarizeFilter.getXterm() === 'year' && !this.is_date) {
            // ｘ軸が日付でない時は、yearは動かせる
            return true;
        }

        let xterm = this.customFilter.summarizeFilter.getXterm();
        return ['year', 'all'].indexOf(xterm) === -1;
    }

    goToTable() {
        this._router.navigate([this._share.getAdminTable(), this.customFilter.table, {'_filter_id': this.customFilter.id}]);
    }
}

