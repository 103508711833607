import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Connect} from '../../services/connect';
import ToastrService from '../../toastr-service-wrapper.service';
import {Data} from '../../class/Data';
import {WorkflowTemplate} from '../../class/Workflow/WorkflowTemplate';
import {TableInfo} from '../../class/TableInfo';
import {SharedService} from '../../services/shared';


@Component({
    selector: 'dataset-form',
    templateUrl: './dataset-form.component.html',
})

export class DatasetFormComponent implements OnInit, OnChanges {
    @Input('data') data: Data;
    @Input('workflow_template_a') workflow_template_a: Array<WorkflowTemplate>;
    @Input('system_table_info') system_table_info: TableInfo;
    @Input('error_a') error_a;
    @Input('is_system_table') is_system_table: boolean;
    @Input('parent') parent;
    @Input('data_lastchanged') data_lastchanged;

    public isDatasetSettingOptionCollapsed = true;
    // カレンダー表示で参照する日時フィールドの候補
    public calendar_view_datetime_list = [];
    // カレンダーで表示したい画像フィールドの候補
    public calendar_view_image_list = [];
    private toasterService: ToastrService;

    constructor(private _connect: Connect, toasterService: ToastrService, public _share: SharedService) {
        this.toasterService = toasterService;
    }

    ngOnInit() {
        console.log('dataset form onninit')
        console.log(this.data)
        if (!this.data.raw_data['id']) {
            this.setDefault()

        }
        this.isDatasetSettingOptionCollapsed = true;
        setTimeout(() => {
            this.calendarViewActive()
        }, 1000)
    }

    setDefault() {
        this.data.setRawData({
            'show_id': 'true',
            'show_menu': 'true',
            'show_admin': 'true',
            'show_created': 'true',
            'editable_on_list': 'true',
            'sortable': 'true',
            'search': 'true',
            'download_csv': 'true',
            'upload_csv': 'true',
            'sort_order': 'desc',
            'sort_field': 'updated'
        })

    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('dataset form onchange')
        console.log(this.data)
        this.calendarViewActive()
    }

    flgChangeJson(key, val, $event): void {
        if ($event.target.checked) {
            this.data.addValueOfJsonField(key, val)
        } else {
            this.data.removeValueOfJsonField(key, val)
        }
    }

    flgChange(key, $event) {
        let hash = {}
        if ($event.target.checked) {
            hash[key] = 'true'
        } else {
            hash[key] = 'false'
        }
        this.data.setRawData(hash);
        console.log(this.data.raw_data)
    }

    onValueChanged(workflow_template: WorkflowTemplate, cond_index: number, $event) {
        workflow_template.conditions.replaceCondition(cond_index, $event.condition);
    }

    addWorkflowTempate() {
        this.workflow_template_a.push(new WorkflowTemplate())
    }

    getTableName() {
        return 'dataset__' + this.data.raw_data['id'];
    }

    onWorkflowChanged(workflow_template: WorkflowTemplate, $event) {
        workflow_template.workflow = $event.workflow;
    }

    calendarViewActive() {
        console.log('view active')
        this.calendar_view_datetime_list = [];
        this.calendar_view_image_list = [];
        if (!this.data.child_data_by_table['dataset_field']) {
            return;
        }
        this.data.child_data_by_table['dataset_field'].forEach(data => {
            if (data.raw_data.type == 'datetime' || data.raw_data.type == 'date') {
                this.calendar_view_datetime_list.push({
                    name: data.raw_data['name'],
                    value: (data.raw_data['id']) ? 'field__' + data.raw_data['id'] : data.raw_data['unique_key_name']
                });
            }
            if (data.raw_data.type == 'image') {
                this.calendar_view_image_list.push({
                    name: data.raw_data['name'],
                    value: (data.raw_data['id']) ? 'field__' + data.raw_data['id'] : data.raw_data['unique_key_name']
                })
            }
        });
        //this.data.raw_data['from_to_calendar_view_datetime'] = "false";
        console.log(this.calendar_view_datetime_list)
    }


}
