<div id="paypal_script">

</div>
<div class="card" *ngIf="!loading">
    <div class="loader" *ngIf="loading || sending;else NotLoading">Loading...</div>
    <ng-template #NotLoading>
        <div class="form-group row card-header text-center">
            <h1 style="    font-size: 20px;">利用料金のお支払い</h1>
        </div>
        <div class="card-body" style="height: 80vh;">
            <div class="form-group row">
                <div class="col-md-12 ml-4">
                    <img style="width: 240px; " src="https://pigeon-fw.com//assets/cloud2/img/logo.png" class="pigeon-logo" alt="PigeonCloud">
                </div>
                <div class="col-md-12">
                    <div class="white-box mb-4" style="max-width:690px;    margin: 6vh auto;">
                        <h2 class="text-center mt-2" style="    font-size: 23px; margin-bottom: 38px;">ユーザー数プラン契約
                            <ng-container *ngIf="is_update">更新</ng-container>
                        </h2>
                        <ng-container *ngIf="sentRequest;else NotPaid;">
                            <div *ngIf="is_update">
                                <div style="text-align: center">
                                    <ng-container *ngIf="updated_status==='update_success'">
                                        ユーザー数の変更リクエストが完了しました。<br>
                                        支払いが正しく行われていた場合、次回決済日からユーザー数変更が適用されます。<br><br>
                                        キャンセル等で支払いを行わなかった場合は<br>再度右上の契約設定から支払いを行って下さい。<br><br>
                                        <a href="/admin/dashboard">ダッシュボードに戻る</a>
                                    </ng-container>
                                    <ng-container *ngIf="updated_status==='update_cancel'">
                                        <div class="text-danger">ユーザー数変更リクエストに失敗しました。</div>
                                        <a href="/admin/dashboard">ダッシュボードに戻る</a>

                                    </ng-container>
                                    <ng-container *ngIf="!updated_status">
                                        <ng-container *ngIf="approve_url;else NoApproveUrl;">
                                            <div class="text-danger">※まだ変更処理は完了していません。</div>
                                            下記より支払いを行うとユーザー数リクエストが送信されます。<br>
                                            <br>
                                            <button class="btn btn-success" (click)="open_approval()">
                                                支払い変更設定
                                            </button>
                                        </ng-container>
                                        <ng-template #NoApproveUrl>
                                            ユーザー数変更リクエストを送信しました。<br>
                                            <a href="/admin/dashboard">ダッシュボードに戻る</a>
                                        </ng-template>

                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="!is_update">
                                <div style="text-align: center">
                                    お支払いが完了しました。<br>
                                    ご契約頂き、ありがとうございます。<br>
                                    <a href="/admin/dashboard">ダッシュボードに戻る</a>

                                </div>
                            </div>

                        </ng-container>
                        <ng-template #NotPaid>
                            <div *ngIf="is_update" class="row">
                                <div class="col-md-4">
                                    <h3>現在のプラン</h3>
                                </div>
                                <div class="col-md-6">
                                    <div style="font-size: 19px;">
                                        {{_share.cloud_setting['max_user']}}ユーザー
                                    </div>

                                </div>
                                <hr style="width:100%">
                            </div>
                            <div class="mt-4">
                                <ng-container *ngIf="is_update;else IsAdd;">
                                    ※ユーザー数を変更する場合、次の支払日から適用されます。<br>
                                    ※支払日よりも前に変更したい場合、サポートにご連絡下さい。<br>
                                </ng-container>
                                <ng-template #IsAdd>
                                    ※毎月の費用発生となり、自動継続となります。<br>
                                </ng-template>
                                ※最低5ユーザーからの契約となります。
                            </div>

                            <div class="row p-4">
                                <table class="table table-bordered">
                                    <tr>
                                        <th style="    vertical-align: middle;">
                                            変更後ユーザー数
                                        </th>
                                        <td>
                                            <input style="width: 120px;" type="number" id="user_input" class="form-control mr-3" [(ngModel)]="quantity" (input)="onQuantityChange($event.target.value)">ユーザー
                                            <div class="text-danger" *ngIf="!is_valid_num">
                                                ※ユーザー数は5~3000の範囲で選択して下さい。
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                                <div class="col-md-6 text-center">
                                </div>
                                <div class="col-md-6 text-center" style="    font-size: 16px; font-weight: bold;">
                                    月額 <span id="price_calc">{{price | number}}</span> &nbsp; 円(税込)
                                </div>

                            </div>
                            <ng-container *ngIf="is_update;else NotUpdate">
                                <ng-container *ngIf="is_valid_num">
                                    <button class="btn btn-success pull-right" (click)="updatePaymentModal.show()" [disabled]="this.user_num == this._share.cloud_setting['max_user']">契約更新</button>
                                </ng-container>
                                <br style="clear:both">

                            </ng-container>
                            <ng-template #NotUpdate>
                                <div class="form-group row" *ngIf="is_valid_num">
                                    <div class="col-md-12 text-center mt-2">
                                        <mat-checkbox class="btn-block" id="aggrement_checkbox"
                                                      labelPosition="after"
                                                      [(ngModel)]="agreed">
                                            <a href="https://pigeon-fw.com/cloud/terms" target="_blank">利用規約</a>に同意する
                                        </mat-checkbox>
                                        <br>
                                        <div class="mt-2">
                                            <a href="https://pigeon-fw.com/cloud/privacy" target="_blank">プライバシーポリシー</a>
                                            <a href="https://pigeon-fw.com/cloud/trade-raw" target="_blank" class="ml-3">特定商取引法に基づく表記</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center paypal-btn-padding" [hidden]="!agreed">
                                        <div id="{{getPaypalButtonId()}}">

                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</div>

<confirm-modal #updatePaymentModal [submit_button_text]="'契約更新を行う'"
               [text]="'次回支払い日からユーザー数を更新します。よろしいですか？'" (onOk)="sendUpdateRequest()" [sending]="sending"></confirm-modal>
