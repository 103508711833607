import {Component, OnInit, Input, EventEmitter, Output, Inject, Pipe, PipeTransform, HostListener, Renderer2} from '@angular/core';
import {SharedService} from '../../services/shared';
import {Connect} from '../../services/connect';
import ToastrService from '../../toastr-service-wrapper.service';
import {Comment} from '../../class/Comment';
import {CustomFilter} from '../../class/Filter/CustomFilter';
import {TableInfo} from 'app/class/TableInfo';
import {DOCUMENT} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
    selector: 'app-aside',
    templateUrl: './app-aside.component.html'
})
export class AppAsideComponent implements OnInit {

    @Input() customFilter: CustomFilter;
    @Input() comment: string = '';

    private toasterService: ToastrService;
    public list : Array<any>;
    private mentionConfig: Object
    private admin_table: string
    private table_info: TableInfo
    private data_list: Array<any>
    private sending: boolean = false;
    @Output() itemSelected: EventEmitter<any>
    public parent_ele : HTMLElement;
    public tab_content : HTMLElement;

    constructor(public _share: SharedService, private _connect: Connect, toasterService: ToastrService, @Inject(DOCUMENT) private document: any,
    public sanitized: DomSanitizer , private _renderer : Renderer2) {
        this.toasterService = toasterService;
        this.data_list =[]
        this.mentionConfig = {
            items: this.list,
            triggerChar : "@",
            labelKey : 'name',
            insertHTML: true,
            mentionSelect: this.mentionSelect
        }

    }


    ngOnInit() {
        let lists = []

        //console.log('app-aside ngoninit')
        this.admin_table = this._share.getAdminTable()
        this._share.getTableInfo(this.admin_table).subscribe(_table_info => {
            this.table_info = _table_info
            this._connect.getList(_table_info, 1, Number.MAX_SAFE_INTEGER, null, {}, [], false).subscribe(_data => {
                _data['data_a'].forEach(data => {
                    // if(admin_data.user.id != data.view_data.id){
                    lists.push(data.view_data)
                    // }
                });
                this.list = lists
            })

        })
       this.parent_ele = document.querySelector('.comments')
    }

    reload() {
        this._share.reloadCurrentData()

    }

    @HostListener('paste', ['$event'])
    pasteevent(e) {
        e.preventDefault();
        e.stopPropagation();
        var plaintext = e.clipboardData.getData('text/plain');
        document.execCommand('inserttext', false, plaintext);
    }

    mentionSelect(event: any) {
        if(this.parent_ele == null) {
            this.parent_ele = document.querySelector('.comments')
        }
        let ele = document.querySelector('.menu-list-customize')
        let new_block = document.querySelector('.new-block');
        if(ele) {
            $(ele).removeClass('menu-list-customize');
            this.parent_ele.removeChild(new_block);
            
        }
        return '<b id="mention" style="fontSize: small" contenteditable="false">'+event.name +'&nbsp;'+'</b>'

    }

    onSelect(event: any) {
        if(this.data_list.findIndex(x=> x.name == event.name) != 0){
            this.data_list.push({id:event.id,name:event.name})
        }
    }

    commentInput(event){
        if(event.data == '@') {
            let ele = document.querySelector('.auto-complete-font')
            this._renderer.addClass(ele,'menu-list-customize')
            let comment_log_ele = document.querySelector('comment-log-block')
            const new_block = this._renderer.createElement('div');
            this._renderer.addClass(new_block,'new-block')
            if(this.parent_ele == null) {
                this.parent_ele = document.querySelector('.comments')
            }
            this.parent_ele.insertBefore(new_block,comment_log_ele);
        }else{
            let ele = document.querySelector('.menu-list-customize');
            let new_block = document.querySelector('.new-block');
            if(ele) {
                if(this.parent_ele == null) {
                    this.parent_ele = document.querySelector('.comments')
                }
                this.parent_ele.removeChild(new_block);
                this._renderer.removeClass(ele,'menu-list-customize');

            }
        }

        this.comment = event.target.textContent
    }

    addComment() {
        if (!this.comment) {
            this.toasterService.error('コメントを入力して下さい。', 'エラー');
            return;
        }
        if(this.data_list.length>0){
            this.data_list.map(x=>{
                this.comment = this.comment.replace(new RegExp(`@${x.name}`, "gi"),match=>{
                    return '<b>'+match+'</b>'
                })

            })
        }
        let comment_hash = {
            'table': this._share.getCurrentData().table_info.table,
            'data_id': this._share.getCurrentData().raw_data['id'],
            'admin': this._share.getCurrentData().view_data['admin_id'],
            'content': this.comment,
            'url':  this.document.location.pathname,
            'data': this.data_list
        };
        this.sending = true;
        this._connect.post('/admin/comment/add', comment_hash).subscribe((res) => {
            this.reload()
            this.sending = false;

            this.toasterService.success('コメントを追加しました', '成功');
            this.comment = ''
            let cmt = document.getElementById('comment');
            cmt.innerHTML = ' ';
            this.data_list = []
        });

    }

    getDataStr(data, diff) {
        if (!data) {
            return '';
        }
        if (typeof data == 'string') {
            return data;
        } else if (Array.isArray(data)) {
            //IF multiple
            return data.map((_multi_data) => {
                return this.getDataStr(_multi_data, diff)
            }).join(',')
        }

        //file
        return data['name'];
    }


}
