<div bsModal #importMenu="bs-modal" class="modal fade small-dialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">帳票追加</h4>
                <button type="button" class="close" (click)="importMenu.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <edit-component [_table]="'ledger'" [_id]="-1" [_add_post_params]="{'table_name':table_name}" [IS_EMBED_MODE]="true" (onSubmit)="uploadFile()"
                                (onCancel)="importMenu.hide()"></edit-component>
            </div>
        </div>
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
