import {NgModule} from '@angular/core';
import 'rxjs/Rx';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {CustomFilter} from '../class/Filter/CustomFilter';
import {TableInfo} from '../class/TableInfo';
import {Variable} from '../class/Filter/Variable';
import { catchError } from 'rxjs/operators';


@NgModule({
    imports: [
        HttpClientModule
    ],
    declarations: []
})

export class Connect {
    static showUrlErrorAlert = false;

    constructor(private _http: HttpClient) {
    }

    get(url, params = null, request_params = null, handleError = true): Observable<any> {
        if (request_params === null) {
            request_params = {}
        }
        if (!url.match(/http/)) {
            url = environment.api_url + url;
        }

        request_params['params'] = params;
        request_params['withCredentials'] = true;
        if (!handleError) {
            return this._http.get(url, request_params)
        }
        return this._http.get(url, request_params).pipe(catchError(this.handleErrorObservable))
    }

    getFile(url): Observable<any> {
        return this._http.get(url, {responseType: 'blob'});
    }

    post(url, params, request_params = {}, handleError = true): Observable<any> {
        if (!url.match(/http/)) {
            url = environment.api_url + url;
        }
        request_params['withCredentials'] = true;
        if (!handleError) {
            return this._http.post(url, params, request_params);
        }
        return this._http.post(url, params, request_params).pipe(catchError(this.handleErrorObservable));
    }

    postUpload(url, formData: FormData) {
        if (!url.match(/http/)) {
            url = environment.api_url + url;
        }
        //const headers = new Headers();
        /** No need to include Content-Type in Angular 4 */
            // headers.append('Content-Type', 'multipart/form-data');
            //headers.append('Accept', 'application/json');
        const request_params = {};
        request_params['withCredentials'] = true;
        return this._http.post(url, formData, request_params)
    }

    postUploadList(url, formData: FormData[] | any) {
        if (!url.match(/http/)) {
            url = environment.api_url + url;
        }
        //const headers = new Headers();
        //headers.append('Accept', 'application/json');
        const request_params = {};
        request_params['withCredentials'] = true;
        return this._http.post(url, formData, request_params)
    }

    getSiteUrl() {
        return environment.api_url.replace('/api', '');
    }

    getApiUrl() {
        return environment.api_url;
    }

    getList(table_info: TableInfo, page = 1, per_page = Number.MAX_SAFE_INTEGER, filter: CustomFilter = null, sort: Object = {}, variables: Array<Variable> = [], handleError = true): Observable<any> {
        let sort_params = [sort]
        if (filter && filter.sort_params.length > 0) {
            sort_params = filter.sort_params.map(sortParam => sortParam.toArray())
        }

        return this.post('/admin/list/' + table_info.table, {
            page: page,
            per_page: per_page,
            search: filter ? filter.getSearchParam() : [],
            chart_params: filter ? filter.getSummarizeParam() : [],
            show_fields: filter ? filter.getShowFields(table_info) : [],
            sort_params: sort_params,
            variables: variables.map(v => v.toArray())

        }, {}, handleError)

    }

    getOne(table_info: TableInfo, id: number): Observable<any> {
        let filter = new CustomFilter();
        filter.conditions.addCondition('eq', 'id', id.toString())
        return this.post('/admin/list/' + table_info.table, {
            page: 1,
            per_page: 1,
            search: filter ? filter.getSearchParam() : [],
            chart_params: [],
            show_fields: [],
            sort_params: [],
        })

    }


    handleErrorObservable(error: Response | any) {
        try {
            console.error(error.error)
            console.error(error.body)
            console.error(JSON.stringify(error))
        } catch (e) {
        }
        if (error === undefined || (error._body === undefined && (error.error === undefined || error.error.error_a === undefined))) {
            if (!Connect.showUrlErrorAlert) {
                alert('エラーが発生しました。リロードしても改善されない場合、お手数ですがサポートまでお問い合わせください。 sales@loftal.jp');
                Connect.showUrlErrorAlert = true;
            }
            return;
        }
        try {
            if (error.error.error_type !== null && error.error.error_type === 'login_error') {
                return;
            }

            let res = null
            if (error.error) {
                res = (error.error);
                alert(res.error_a.join(','));
            } else {
                res = JSON.parse(error._body);
                alert(res.error_a);
            }
        } catch (e) {
            try {
                if (error.error.error_type !== null && error.error.error_type === 'login_error') {
                    //location.href = '/' + location.pathname.split('/')[1] + '/login';
                } else {
                    alert(error.error.error_a);
                }
            } catch (e) {
                alert('65:unknown error');
            }
        }
        return Observable.throw(error.message || error);
    }
}
