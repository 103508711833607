import {Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import ToastrService from '../../toastr-service-wrapper.service';
import {SharedService} from '../../services/shared';
import {Connect} from '../../services/connect';
import {ActivatedRoute} from '@angular/router'
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Data} from '../../class/Data';
import {TableInfo} from '../../class/TableInfo';
import {EditComponent} from './edit.component';
import {Form} from '../../class/Form';
import {Conditions} from '../../class/Conditions';
import {DataGrant} from '../../class/DataGrant';
import * as cloneDeep from 'lodash/cloneDeep';
import {v4 as uuidv4} from 'uuid';
import {Observable} from 'rxjs/Observable';
import {CustomFilter} from '../../class/Filter/CustomFilter';
import {SelectOptionItemsFilter} from '../../class/SelectOptionItemsFilter';
import {OptionItem} from '../../class/OptionItem';
import {GrantGroupData} from '../../class/GrantGroupData';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';

declare var $: any;


@Component({
    template: `
        <div class="modal-header bg-danger">
            <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
            <button type="button" class="close" (click)="hide()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>本当に削除してもよろしいですか？</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="hide()">キャンセル</button>
            <button type="button" class="btn btn-danger" (click)="confirm()">削除する</button>
        </div>`
})
export class FormFieldFileDeleteModalComponent {
    @Input() public delete: Function;

    constructor(public activeModal: NgbActiveModal) {
    }

    hide() {
        this.activeModal.close();
    }

    confirm() {
        this.delete();
        this.activeModal.close();
    }
}


@Component({
    selector: 'admin-forms-field',
    templateUrl: './forms-field.component.html',
    styleUrls: ['./forms-field.component.css']
})

export class FormsFieldComponent implements OnInit, OnChanges {
    @Input() editComponent: EditComponent;
    @Input() table_info: TableInfo;
    @Input() data: Data;
    @Input() parent_data: Data = null;
    @Input() field_name: string;
    @Input() is_add: boolean = true;
    @Input() password_conf_value: string;
    @Input() is_update_calc: string;

    @Input() disable_edit: boolean = false;
    //if multiple value
    @Input() child_table: string = null;
    @Input() data_index: number = null;
    //if image
    @Input() image_thumbnail_url;

    @Input() default_value: string = null;

    // NOT REQUIRED
    @Input() grant_menu_a: Array<Object>;
    @Input() default_form: Form = null;
    @Input() default_field: {} = null;

    @Input() focus: boolean = false;
    @Input() auto_complete_values: Array<any>;

    //これがないとdataに変更があってもngchangeが呼びだされない
    @Input() data_changed: Date;

    @Input() is_add_new_button_select_other_table: boolean = false;

    //更新検知用
    @Input() last_updated: Date = null;

    //select other 絞り込み
    @Input() selectOptionitemsFilter: SelectOptionItemsFilter = null;
    public is_all_related_condition_value_selected: boolean = true;
    public selectPlaceHolder: string = ''

    @Input() isEditMode: boolean = false;

    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();
    @Output() addchildvalue: EventEmitter<Object> = new EventEmitter();

    public option_items: Array<OptionItem> = null;

    public conditions: Conditions;

    public current_data: Data;
    private toasterService: ToastrService;
    public date_value: Date = null;
    public all_action_index = null;
    public dataset_check_all = false;
    private table: string;
    public admin_notification;

    public field_for_radio = '';

    public form: Form;
    public field: {};

    private grant: DataGrant;

    public value = null;
    public view_value;
    public first_time = 0;

    private isimagecheck: boolean = true;

    //year_month
    public year: number;
    public month: number;

    // 画像用削除モーダル
    @ViewChild('deleteModal') deleteModal: any;
    @ViewChild('grantGroupModal') grantGroupModal: any;
    @ViewChild('verifyModal') verifyModal: any;

    public unique_input_id = null

    private paramsDiffer: KeyValueDiffer<string, any>;
    private dataDiffer: KeyValueDiffer<string, any>;
    private formDiffer: KeyValueDiffer<string, any>;

    private pre_value: string;
    public grantGroupData: GrantGroupData = null;

    public albums = [];

    constructor(toasterService: ToastrService, private _share: SharedService, private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig, private differs: KeyValueDiffers, private _connect: Connect, private _route: ActivatedRoute, private modalService: NgbModal) {
        this.toasterService = toasterService;
        this.paramsDiffer = this.differs.find({}).create();
        this.dataDiffer = this.differs.find({}).create();
        this.formDiffer = this.differs.find({}).create();
        _lightboxConfig.centerVertically = true;

        //this.year = (new Date()).getFullYear()
        //this.month = (new Date()).()
    }


    ngOnInit() {
        if (this.data.raw_data['ismultiple']) {
            const file: File = this.value;
            const max_size = 50 * 1024 * 1024;
            //TODO: fix
            if (file.size > max_size) {
                this.toasterService.error('アップロード可能なサイズは最大50MBです。', 'エラー');
                return
            }
            file['id_generate'] = Math.random().toString(36).substring(3, 9)
            const reader = new FileReader();
            reader.onload = function (e) {
                $('#' + file['id_generate']).attr('src', e.target.result);
            };
            this.value = file;
            reader.readAsDataURL(file);
        }
        if ((this.form['type'] == 'image' || this.form['type'] == 'file') && !this.disable_edit) {
            if (this.value != null) {
                this.isimagecheck = this.isFileImage();
            }
        }
        this.unique_input_id = uuidv4()
        this.field_for_radio = 'radio_' + this._share.uniqid();
        if (this.data) {
            this.password_conf_value = this.data.raw_data['password'];
        }

        if (this.data) {
            this.grant = this.data.grant;
            this.current_data = cloneDeep(this.data)
        }

        if (this.default_form) {
            this.form = this.default_form;
            this.field = this.default_field;
        } else {
            this.field = this.table_info.getFieldByFieldName(this.field_name)
            this.form = this.table_info.forms.byFieldName(this.field_name)
            if (this.form == null) {
                this.form = new Form({'field': this.field_name});
            }

            if (this.field == null && this.field_name) {
                this.field = {'Field': this.field_name};
            }
        }

        if (!!this.form && ['time', 'date', 'datetime'].indexOf(this.form['type']) >= 0) {
            let value: String = String(this.value);
            if (this.form['type'] === 'time') {
                value = '1970-01-01 ' + value;
            }
            const d = new Date(Date.parse(value.toString()));
            if (d.toString() !== 'Invalid Date') {
                if (!this.date_value || this.date_value.getTime() !== d.getTime()) {
                    this.date_value = d;
                }
            } else {
                this.date_value = null;
            }

        } else if (this.form.type === 'year_month') {
            if (this.value) {
                let value: String = String(this.value);
                const d = new Date(Date.parse(value.toString()));
                this.year = d.getFullYear()
                this.month = d.getMonth() + 1
            }
        }
        if (this.field['Field'] && this.field['Field'].match(/condition_json/)) {
            if (this.data && this.data.raw_data[this.field['Field']]) {
                this.conditions = new Conditions();
                this.conditions.setByJson(this.data.raw_data[this.field['Field']])
            }
        }

        if (this.isMultiSelect(this.form) && this.value && !Array.isArray(this.value)) {
            this.value = this.value.split(',')
        }
        if (this._route.queryParams['value']['year_calendar'] != undefined) {
            let calendar_field_name: string = '';
            if (this.table_info.menu['from_to_calendar_view_datetime']) {
                calendar_field_name = this.table_info.menu['calendar_view_datetime_from'];
            } else {
                calendar_field_name = this.table_info.menu['calendar_view_datetime'];
            }
            if (calendar_field_name == this.field_name) {
                let year_calendar = this._route.queryParams['value']['year_calendar'];
                let month_calendar = this._route.queryParams['value']['month_calendar'];
                let date_calendar = this._route.queryParams['value']['date_calendar'];
                this.date_value = new Date(year_calendar, month_calendar - 1, date_calendar);
                this.datetimeValueChanged();
            }
        }

        if (this.data) {
            let id = this.data.raw_data['id'];
            //SET FORM DEFAULT
            if (!id && !this.value && this.form.default_value && this.value !== this.form.default_value) {
                if (this.form.type === 'boolean') {
                    this.value = this.form.default_value
                    this.onChange();
                }


            }
        }


        if (this.table_info) {
            this.setOptionItems()
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.value = null;

        if (changes.default_form) {
            this.form = this.default_form;
            this.field = this.default_field;

        }

        if (!this.form) {
            this.field = this.table_info.getFieldByFieldName(this.field_name)
            this.form = this.table_info.forms.byFieldName(this.field_name)
        }

        if (this.form.is_dummy_form) {
            this.loadSelectOptions()
        }

        if (this.data) {
            if (this.data_index !== null) {
                //childの場合
                this.value = this.data.raw_data['value'];
                this.view_value = this.data.view_data['value'];
            } else {
                this.value = this.data.raw_data[this.field['Field']];
                this.view_value = this.data.view_data[this.field['Field']];
            }
        }
        if (this.form['type'] == 'checkbox') {
            if (this.value && !Array.isArray(this.value)) {
                this.value = this.value.split(',');

            }
        }

        if (this.value === null) {
            if (this.data && this.data.raw_data['id']) {
                if (this.data_index !== null) {
                    //childの場合
                    this.value = this.data.raw_data['value'];
                    this.view_value = this.data.view_data['value'];
                } else {
                    if (this.field['Field'] == 'table') {
                        this.data.raw_data[this.field['Field']] != null && this.valueChanged.emit({
                            'field_name': 'table',
                            'value': this.data.raw_data[this.field['Field']],
                        });

                    }
                    this.value = this.data.raw_data[this.field['Field']];
                    this.view_value = this.data.view_data[this.field['Field']];
                    //console.log('set value by raw_data')
                }
            } else {
                this.value = this.default_value;
            }
        }

        this.setOptionItems()
        if (this.form.type === 'grant_group' && this.value) {
            this.reloadGrantGroupData()
        }


        this.pre_value = this.value
    }


    ngDoCheck() {
        // this function is used to trigger focus on first time only
        if (!this.data) {
            return;
        }
        let id = this.data.raw_data['id'];
        let field = this.field['Field'];
        if (this.isEditMode == true && document.getElementById('_form_' + field + '_' + id)) {
            this.first_time++;
            // i don't know the exact reason but it triggered 15 times for the first time
            if (this.first_time < 15) {
                document.getElementById('_form_' + field + '_' + id).focus();
            }
        }
    }

    ngAfterViewInit() {
        if (!this.data) {
            return;
        }
        let id = this.data.raw_data['id'];
        let field = this.field['Field'];
        if (this.isEditMode == true && document.getElementById('_form_' + field + '_' + id)) {
            document.getElementById('_form_' + field + '_' + id).focus();
        }
    }

    openImg() {
        let album = {
            src: this.image_thumbnail_url,
            thumb: this.image_thumbnail_url,
        }
        this.albums.push(album)
        this._lightbox.open(this.albums, 0);
    }

    mentionSelect(event) {
        return '{' + event.label + '}';
    }

    setOptionItems() {
        let set_value_null_if_value_is_not_in_options = () => {
            if (this.value && !this.option_items.find(item => {
                return item.value == this.value;
            })) {
                //console.log('set value null')
                this.value = null;
                this.onChange()
            }
        }
        if (['fields', 'user_fields', 'email_fields'].indexOf(this.form['type']) >= 0) {
            this.getTableForms(this.form['target_table_field'], this.form['type']).subscribe(_option_items => {
                this.option_items = _option_items.map(item => {
                    return new OptionItem({
                        'label': item.Comment,
                        'value': item.Field,
                    })
                })

                //console.log('OPTION ITEM SET')
                //console.log(this.option_items)
                set_value_null_if_value_is_not_in_options()
            })
        } else if (this.form['type'] == 'table_filter') {
            this.getTableFilter(this.form['target_table_field']).subscribe(_option_items => {
                if (this.value) {
                    this.value = parseInt(this.value)
                }
                this.option_items = _option_items.map((item: CustomFilter) => {
                    return new OptionItem({
                        'label': item.name,
                        'value': item.id,
                    })
                })
                set_value_null_if_value_is_not_in_options()
            })

        } else if (this.isSelect(this.form)) {
            if (this.table_info) {
                // console.log(this.table_info.table)
                this.loadSelectOptions()
            }
        }

    }

    loadSelectOptions() {
        //console.log('loadSelectOption')
        //console.log(this.form.label)
        if (this.form.is_dummy_form) {
            this.setLoadOptions(this.form.option)
        } else {

            this.form.getSelectOptions(this.table_info, this._connect, this.selectOptionitemsFilter).subscribe(option_items => {
                if (this.selectOptionitemsFilter && this.selectOptionitemsFilter.hasWhere(this.form.field['Field'])) {
                    this.is_all_related_condition_value_selected = this.selectOptionitemsFilter.inputCompleteFilterCompareValue(this.field_name)
                    if (!this.is_all_related_condition_value_selected) {
                        console.log('clear')
                        if (this.value != null) {
                            this.value = null;
                            this.onChange();
                        }
                        this.selectPlaceHolder = ''
                        let uninputtedFields = this.selectOptionitemsFilter.getUninputtedFields(this.field_name)
                        let uninput_field_label_a = []
                        uninputtedFields.forEach(field_name => {
                            uninput_field_label_a.push(this.table_info.forms.byFieldName(field_name).label)
                        })
                        this.selectPlaceHolder = uninput_field_label_a.join(',') + 'を選択して下さい'

                    } else {
                        this.selectPlaceHolder = ''
                    }
                    if (!!this.value) {
                        let selected = option_items.filter(item => {
                            return item.value == this.value
                        })
                        if (selected.length == 0) {
                            if (this.value != null) {
                                console.log('clear')
                                this.value = null
                                this.value = null;
                                ;
                                this.onChange();
                            }
                        }
                    }
                }
                this.setLoadOptions(option_items);
            })
        }
    }


    setLoadOptions(option_items) {

        let filter_option = (options, form) => {
            //FIXME: NO USE?
            if (this.form['item_where']) {
                this.form['item_where'].forEach(where => {
                    let where_key = where[0];
                    let where_val = where[1]
                    if (where_val == '{parent_id}') {
                        where_val = this.editComponent.id;
                    }
                    console.log(options)
                    options = options.filter((option) => {
                        return option['hash'][where_key] == where_val;
                    })
                })
            }
            if (!form['is_own_parent_table']) {
                return options;
            }


            return options.filter((option) => {
                return option.value != this.data.raw_data['id'];
            })
        };
        this.option_items = filter_option(option_items, this.form)


        //追加項目
        if (this.form.original_type === 'select_other_table') {
            let _new_exist: boolean = false;
            this.option_items.forEach(item => {
                if (item['value'] == '__NEW__') {
                    _new_exist = true
                }
            })
            if (!_new_exist && this.is_add_new_button_select_other_table && this.form.show_add_on_list && this._share.getMenu(this.form.item_table)) {
                this.option_items.push(new OptionItem({
                    label: '+ 新規追加',
                    value: '__NEW__'
                }));
            }
        }
        if (this.form.required && !this.value && this.option_items.length > 0 && this.option_items[0]['value'] !== '__NEW__' && this.form && this.form.copy_fields && this.form.copy_fields.length == 0) {
            this.value = this.option_items[0]['value'];
            this.pre_value = this.value
            //console.log('SET VALUE BY LOAD OPTIONS')
            this.onChange()
        }


        //filter適用
        /*
        if(this.selectOptionitemsFilter){
            this.option_items = this.selectOptionitemsFilter.filter(this.field_name,this.option_items);

        }
         */


        // console.log('load select option finsih',this.option_items)
        //console.log('value is ')
        //console.log(this.value)
        //this.onChange()
    }


    isSelect(form: Form) {
        return ['select', 'select_other_table', 'table', 'multi-select', 'radio', 'checkbox'].indexOf(form['type']) >= 0;
    }

    isMultiSelect(form: Form) {
        return form.type === 'multi-select' || (form.type === 'checkbox' && form.checkbox_input_type && form.checkbox_input_type === 'select')
    }

    fileChange(data, event) {
        const fileList: FileList = event.target ? event.target.files : event;
        if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                const file: File = fileList[i];
                let MAX_MB = 50;
                //FIXME: use db
                if (location.hostname.match(/dak3l0ucc1/)) {
                    MAX_MB = 3;
                }

                const max_size = MAX_MB * 1024 * 1024;
                if (file.size > max_size) {
                    if (fileList.length > 0) {
                        this.toasterService.error('アップロード可能なサイズは最大' + MAX_MB + 'MBです。', 'エラー');
                        return
                    } else {
                        event.target.value = '';
                        this.toasterService.error('アップロード可能なサイズは最大' + MAX_MB + 'MBです。', 'エラー');
                        delete data[event.target.getAttribute('name')];
                        return
                    }
                }
                if (i == 0) {
                    file['id_generate'] = Math.random().toString(36).substring(3, 9)
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        $('#' + file['id_generate']).attr('src', e.target.result);
                    };
                    this.value = file;
                    if (this.value != null) {
                        this.isimagecheck = this.isFileImage();
                    }
                    reader.readAsDataURL(file);
                    this.onChange();
                } else {
                    const returndata = Array();
                    returndata['field_name'] = this.field_name;
                    returndata['return_data_index'] = this.data_index;
                    returndata['total_file'] = fileList.length;
                    returndata['fileList'] = fileList[i];
                    this.addchildvalue.emit(returndata);
                }
            }
            //data[event.target.getAttribute('name')] = file;
        } else {
            this.onChange();
            this.value = null;
            //delete data[event.target.getAttribute('name')];
        }
    }

    isFileImage() {
        return this.value && this.value['type'].split('/')[0] === 'image';
    }

    checkboxChange(field, target_name) {
        const checkbox_a = document.getElementsByName(target_name);
        let value = '';
        for (let i = 0; i < checkbox_a.length; i++) {
            const checkbox = <HTMLInputElement>checkbox_a[i];
            if (checkbox.checked) {
                value += checkbox.value + ',';
            }
        }
        // 最後のカンマを消す
        value = value.slice(0, -1);
        this.value = value;
        this.onChange();
    }


    onChangeRichText(val) {
        this.onChange(null, val)
    }

    onChange(force_field_name = null, force_value = null, $event = null) {
        if ($event) {
            this.value = $event.target.value;
        }
        if (this.table_info && (this.table_info.table.match(/^notification/) && this.field['Field'].match(/json/))) {
            this.valueChanged.emit({
                'field_name': this.field['Field'],
                'value': this.conditions.getSearchParamJson(),
                'data_index': this.data_index,
            });
        } else {
            this.valueChanged.emit({
                'field_name': force_field_name || this.field['Field'],
                'value': force_value || this.value,
                'data_index': this.data_index,
                'child_table': this.child_table,
                'pre_value': this.pre_value
            });
        }
    }


    onValueChanged($event) {
        this.conditions.replaceCondition($event.index, $event.condition);

        this.onChange();
    }

    onChangeGrant($event) {
        this.value = $event['grant_value'];
        this.onChange();
    }

    isCheck(index, value) {

        if (!this.value) {
            return false;
        }
        if (Array.isArray(this.value)) {
            return this.value.indexOf(value) !== -1;
        }
        return this.value.split(',').indexOf(value) !== -1;
    }

    isObject(data) {
        return (typeof data === 'object');
    }

    datasetId(grant_menu_a) {
        let id = '';
        grant_menu_a.map((data) => {
            const data_split = data.table.split('__');
            id += `${data_split[1]}_`;
        });
        id = id.slice(0, -1);
        return id;
    }


    onOpenDtPicker() {
        $('.cdk-overlay-pane').each(function () {
            if (parseInt($(this).css('left'), 10) < 0) {
                $(this).css('left', 30);
                $(this).css('width', '296px');
                // $(this).css('top',window.scrollY);
            }
        })
    }

    is_string(data) {
        return data instanceof String;
    }


    isOptionsHaveEmptyValue(options) {
        if (!options) {
            return false;
        }
        return options.filter((option) => {
            return option.value === ''
        }).length > 0;
    }

    openDeleteModal = () => {
        let modalRef = this.modalService.open(FormFieldFileDeleteModalComponent);
        modalRef.componentInstance.delete = this.deleteImage;
    }

    deleteImage = () => {
        this.value = '';
        this.deleteModal.hide();
        this.onChange();
    }

    datetimeValueChanged() {
        if (!!this.date_value) {
            const value = this._share.getDateTimeStringByDate(this.date_value, this.form['type']);
            if (this.value !== value) {
                this.value = value;
            }
        } else {
            this.value = null
        }
        this.onChange();
    }

    finishEditDatetime() {
        if (!this.date_value) {
            this.toasterService.warning('日時のフォーマットが無効のため、空の値が設定されます', null, {timeOut: 3000, disableTimeOut: false});
        }

    }

    changeValueToDateValue($event) {
        console.log($event)
        console.log(this.date_value)


        this.datetimeValueChanged()
    }

    yearMonthChagned() {
        if (this.year && this.month) {
            let _dt = new Date();
            _dt.setFullYear(this.year)
            _dt.setMonth(this.month - 1)
            _dt.setDate(1)

            this.date_value = _dt;
            this.datetimeValueChanged()
        }


    }

    textChanged() {
        this.onChange();
    }

    radioChanged() {
        this.onChange();
    }

    isChild() {
        return this.data_index != null;
    }

    count_step() {
        return this._share.count_step(this.form);
    }

    addCondition() {
        if (!this.conditions) {
            this.conditions = new Conditions();
        }
        this.conditions.addCondition('')
        this.onChange();
    }

    delCondition(i) {
        this.conditions.deleteCondition(i)
        this.onChange();
    }

    clearValue() {
        this.date_value = null;
        this.value = null;
        this.datetimeValueChanged()
    }


    onInputGrantGroup($event) {
        let hash = {};
        hash[this.field_name] = $event.id
        this.current_data.setRawData(hash)
        this.value = $event.id;
        this.grantGroupModal.hide()

        this.reloadGrantGroupData()

        this.onChange()

    }

    private reloadGrantGroupData() {
        if (this.value) {
            this._connect.get('/admin/view/grant_group/' + this.value).subscribe((data) => {
                this._share.getTableInfo('grant_group').subscribe(_table_info => {
                    this.grantGroupData = new GrantGroupData(_table_info)
                    this.grantGroupData.setInstanceData(data.data)
                })

            });

        }

    }

    onBooleanChange() {

        this.value = (this.value == 'true') ? 'false' : 'true';
        this.onChange();
    }

    private tableFormCache: Object = {}

    private getTableForms(_target_table_field: string, form_type: string): Observable<Array<any>> {
        let only_user_fields: boolean = form_type === 'user_fields';
        let only_email_fields: boolean = form_type === 'email_fields';
        let target_table = null;
        if (this.parent_data) {
            target_table = this.parent_data.raw_data[_target_table_field]
        } else {
            target_table = this.data.raw_data[_target_table_field]
        }

        return new Observable((observer) => {
            if (target_table) {
                let key = _target_table_field + target_table + form_type;
                if (this.tableFormCache[key]) {
                    observer.next(this.tableFormCache[key])
                } else {
                    this._share.getTableInfo(target_table).subscribe(table_info => {
                        let fields = table_info.fields;
                        if (only_user_fields) {
                            fields = table_info.forms.getArray().filter((form: Form) => {
                                if (form.original_type === 'select_other_table' && form.item_table == 'admin') {
                                    return true;
                                }
                                return false;
                            }).map((form: Form) => {
                                return form.field;
                            })
                        } else if (only_email_fields) {
                            fields = table_info.forms.getArray().filter((form: Form) => {
                                if (form.original_type === 'email') {
                                    return true;
                                }
                                return false;
                            }).map((form: Form) => {
                                return form.field;
                            })
                        }
                        this.tableFormCache[key] = fields;
                        observer.next(fields)
                    })
                }
                return {
                    unsubscribe() {
                    }
                };
            }
        });


    }

    private tableFilterCache: Object = {}

    private getTableFilter(_target_table_field: string): Observable<Array<any>> {
        let target_table = null;
        if (this.parent_data) {
            target_table = this.parent_data.raw_data[_target_table_field]
        } else {
            target_table = this.data.raw_data[_target_table_field]
        }

        return new Observable((observer) => {
            if (target_table) {
                let key = _target_table_field + target_table;
                if (this.tableFilterCache[key]) {
                    observer.next(this.tableFilterCache[key])
                } else {
                    this._share.getTableInfo(target_table).subscribe(table_info => {
                        this.tableFilterCache[key] = table_info.saved_filters;
                        observer.next(this.tableFilterCache[key])
                    })
                }
                return {
                    unsubscribe() {
                    }
                };
            }

        });

    };

    onClickCurrentDatetime($event) {
        //console.log($event);

    }


    public verify_smtp_email: string = ''
    public sending_verify_email: boolean = false;

    verifySmtp() {
        let hash = this.data.raw_data
        hash['verify_email'] = this.verify_smtp_email
        this.sending_verify_email = true;
        this._connect.post(this._connect.getApiUrl() + '/admin/verify-smtp', hash).subscribe(res => {
            this.sending_verify_email = false;
            this.verifyModal.hide()
            if (res.result) {
                this.toasterService.info('メールを送信しました。メールボックスにメールが届いているか確認して下さい。', 'お知らせ');
            } else {
                this.toasterService.error('送信に失敗しました。SMTP情報を確認して下さい。', 'エラー');
            }
        });
    }

    openVerifySmtpModal() {
        this.verifyModal.show();
    }


    call_func(func_name) {
        eval('this.' + func_name + '()')
    }

    setCurrentDateTime($event) {
        if ($event.pointerType == '') {
            //if enter key
            return;
        }
        this.date_value = new Date();
        this.datetimeValueChanged();
    }


    private loading: boolean = false;

    onChangeOtherSelectValue($event) {
        this.onChange()

        if (this.value == '__NEW__') {
            this.loading = true;
            setTimeout(() => {
                this.value = this.pre_value
                this.loading = false;
            }, 100)
        }
    }

    focus_function() {
        console.log('Sharon');
    }
}
