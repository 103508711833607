import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
    selector: '[appResizeColumn]'
})
export class ResizeColumnDirective implements OnInit {
    @Input() appResizeColumn: string;
    @Input() index: number;
    @Input() col_style: "";
    @Input() field : string;

    private startX: number;

    private startWidth: number;

    private column: HTMLElement;

    private newwrap: HTMLElement;

    private table: HTMLElement;

    private pressed: boolean;

    private mousemoveListener;
    private mouseupListener;

    constructor(private renderer: Renderer2, private el: ElementRef) {
        this.column = this.el.nativeElement;
    }

    ngOnInit() {
        const row = this.renderer.parentNode(this.column);
        const thead = this.renderer.parentNode(row);
        this.table = this.renderer.parentNode(thead);
        const resizer = this.renderer.createElement('div');


        this.renderer.addClass(resizer, 'resize-holder');
        // this.renderer.listen(resizer, "click", this.onMouseDown);

        this.renderer.setStyle(resizer, 'cursor', 'col-resize')
        this.renderer.setStyle(resizer, 'width', '20px')
        this.renderer.setStyle(resizer, 'height', '100%')
        this.renderer.setStyle(resizer, 'position', 'absolute')
        this.renderer.setStyle(resizer, 'right', '-10px')
        this.renderer.setStyle(resizer, 'top', 0)
        this.renderer.setStyle(resizer, 'z-index', 1)
        this.renderer.appendChild(this.column, resizer);
        this.renderer.listen(resizer, 'mousedown', this.onMouseDown);


        this.newwrap = this.renderer.createElement('p');
        this.renderer.addClass(this.newwrap,'new-wrap');
        if(this.col_style){
            Object.keys(this.col_style).forEach((key) => {
                this.renderer.setStyle(this.newwrap,key,this.col_style[key]);
            });
        }
        this.renderer.setStyle(this.newwrap,'margin-bottom',0);
        this.newwrap.append(this.column.childNodes[0],this.column.childNodes[2]);
        this.column.appendChild(this.newwrap);
        // Observable.merge(
        //   Observable.fromEvent(resizer, 'mousedown'),
        // )
    }

    onMouseDown = (event: MouseEvent) => {
        this.pressed = true;
        this.startX = event.pageX;
        this.startWidth = this.column.offsetWidth;
        this.mousemoveListener = this.renderer.listen(this.table, 'mousemove', this.onMouseMove);
        this.mouseupListener = this.renderer.listen('document', 'mouseup', this.onMouseUp);
    };

    onMouseMove = (event: MouseEvent) => {
        const offset = 35;
        if (this.pressed && event.buttons) {
            // Calculate width of column
            let width = this.startWidth + (event.pageX - this.startX - offset);
            let ptag = this.column.querySelector('.new-wrap');
            this.renderer.setStyle(ptag, 'width', 'auto');
            let atag = this.column.querySelector('a');
            let calculatelength = 0;
            if (atag) {
                calculatelength = atag.offsetWidth + 20;
            } else {
                let spantag = this.column.querySelector('span');
                calculatelength = spantag.offsetWidth + 20;
            }

            if (width > calculatelength) {
                const tableCells = Array
                    .from(this.table.querySelectorAll('tbody tr'))
                    .map((row: any) => row.querySelectorAll('tr td')
                        .item(this.index + 1));
                this.renderer.setStyle(this.column, 'width', `${width}px`);
                this.renderer.setStyle(this.newwrap, 'width', `${width}px`);
                if (tableCells[0] != null) {
                    for (const cell of tableCells) {
                        this.renderer.setStyle(cell.parentNode, 'width', `${width}px`);
                        let divselector = cell;
                        if (divselector) {
                            if (divselector.classList.contains('fr-view')) {
                                this.renderer.setStyle(divselector, 'width', `${width}px`);   // Set table cells width
                                this.renderer.setStyle(divselector, 'max-width', `${calculatelength}px`);
                                this.renderer.setStyle(divselector, 'text-overflow', 'ellipsis')
                                this.renderer.setStyle(divselector, 'overflow', 'hidden')
                                //this.renderer.setStyle(divselector, 'white-space', 'nowrap')
                            } else {
                                let classes = divselector.classList
                                classes.remove('table-admin-list__cell-inner')
                                this.renderer.setStyle(divselector, 'width', `${width}px`);   // Set table cells width
                                this.renderer.setStyle(divselector, 'max-width', `${calculatelength}px`);
                                this.renderer.setStyle(divselector, 'text-overflow', 'ellipsis')
                                this.renderer.setStyle(divselector, 'overflow', 'hidden')
                                //this.renderer.setStyle(divselector, 'white-space', 'nowrap')
                            }
                            /*
                            let style_container = divselector.querySelector('.style-container');
                            if (style_container) {
                                //this.renderer.setStyle(style_container, 'width', `${width}px`)
                            }
                             */
                        }


                    }
                }
            }
        }
    };

    onMouseUp = (event: MouseEvent) => {
        let width_setting = [];
        let parse_width_setting = JSON.parse(localStorage.getItem('width_setting'));
        if(this.field == 'id') {this.field = location.pathname.split('/admin/')[1]+'_id' }
        if (parse_width_setting) {
            let indexvalue = parse_width_setting.findIndex(x=>x.field == this.field);
            if (indexvalue != -1) {
                parse_width_setting.splice(indexvalue, 1, {"field":this.field, "current_width": this.column.style.width });
            }else {
                parse_width_setting.push({"field":this.field, "current_width": this.column.style.width });
            }
            width_setting = parse_width_setting;
        }else {
            width_setting.push({"field":this.field, "current_width": this.column.style.width });
        }
        localStorage.setItem('width_setting',JSON.stringify(width_setting));
        if (this.pressed) {
            this.pressed = false;
            this.mousemoveListener();
            this.mouseupListener();
        }
    };
}
