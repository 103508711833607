import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'chartjs-plugin-colorschemes';
import {ActivatedRoute, Router} from '@angular/router';
import {Workflow} from '../../class/Workflow';
import {SharedService} from '../../services/shared';

@Component({
    selector: 'workflow-setting',
    templateUrl: './workflow-setting.component.html',
})

export class WorkflowSettingComponent implements OnInit {
    @Input() workflow: Workflow;
    @Input() disabled: boolean = false;

    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();


    constructor(private _router: Router, private _route: ActivatedRoute, private _share: SharedService) {
    }

    ngOnInit() {
    }

    pathChanged($event, key = 'admin_id') {
        console.log('onchange workflow setting')
        console.log($event)
        this.workflow.workflow_path_a[$event.index][key] = $event[key];
        this.changed();
    }

    changed() {
        this.valueChanged.emit({
            'workflow': this.workflow,
        })
    }


    add() {
        this.workflow.addWorkflowPath();
    }

    delete(index) {
        this.workflow.deleteWorkflowPath(index)
        this.changed();
    }


    getDivisionAddedValues() {
        return [
            {value: '{my_main_division_id}', label: '自身のメイン組織'}
        ]
    }
}
