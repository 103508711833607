import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Connect} from '../../../services/connect';
import {Data} from '../../../class/Data';
import {TableInfo} from '../../../class/TableInfo';


@Component({
    selector: 'user-forms-field',
    templateUrl: './user-field.component.html',
})

export class UserFieldComponent {
    @Input() default_admin_id: number = null;
    @Input() index: number = null;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();

    public admin_id: number = null;
    public data_a: Array<Data> = [];

    constructor(private _router: Router, private _route: ActivatedRoute, private _connect: Connect) {
        this._connect.get('/admin/table/info/admin').subscribe((table_info_hash) => {
            let table_info: TableInfo = new TableInfo(table_info_hash);

            this._connect.getList(table_info).subscribe(async (data) => {
                this.data_a = data['data_a'].map((hash: Object) => {
                    let _data: Data = new Data(table_info)
                    _data.setInstanceData(hash)
                    return _data;
                })
            });
        });

    }

    ngOnInit() {
        this.admin_id = this.default_admin_id;
    }

    onChange() {
        this.valueChanged.emit({
            'admin_id': this.admin_id,
            'index': this.index
        })
    }

}
